import { Typography, Grid, Card, CardContent, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SiteDashboardTiles from './SiteDashboardTiles';
import site_Img from '../../assets/images/dashboard-icons/site.svg';
import CommonUtil from '../../components/Util/CommonUtils';
import device_Img from '../../assets/images/dashboard-icons/device.svg';
import door_Img from '../../assets/images/dashboard-icons/door.svg';
import employees_Img from '../../assets/images/dashboard-icons/employee.svg';
import events_Img from '../../assets/images/dashboard-icons/event.svg';
import { useHistory } from 'react-router-dom';
import { REACT_URL_SITE_V4, REACT_URL_DEVICE_V4, REACT_URL_DOOR_V4, REACT_URL_PERSON_V4, REACT_URL_EVENT_V4, JAVA_URL_DASHBOARD_COUNT } from '../../actions/EndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { filterSite, siteListWithAddressV2 } from '../../actions/organization/SiteApiCalls';
import { filterDoors } from '../door/DoorService';
import { filterDevices } from '../device/DeviceService';
import { filterPerson } from '../../actions/organization/PersonApiCalls';
import Tooltip from '@mui/material/Tooltip';
import { getDeviceEventList } from '../event/EventService';
import { loadOrgPersons } from '../Person/PersonService';
import { GET_DASHBOARD_COUNT } from '../../actions/types';
import axios from 'axios';

export default function SiteDashboardCards(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [deviceCount, setDeviceCount] = React.useState(0);
  const [doorCount, setDoorCount] = React.useState(0);
  const [personCount, setPersonCount] = React.useState(0);
  const [eventCount, setEventCount] = React.useState(0);
  useEffect(() => {
    // getSiteCount();
    // getDeviceCount();
    // getDoorCount();
    // getPersonCount();
    // getEventCount();
    axios.get(JAVA_URL_DASHBOARD_COUNT).then((response) => {
      dispatch({
        type: GET_DASHBOARD_COUNT,
        payload: response.data.data,
      });
    });
  }, []);

  useEffect(() => {
    console.log(personCount);
  }, [personCount]);

  const onSelectSite = () => {
    history.push({ pathname: REACT_URL_SITE_V4 });
  };

  const onSelectDevice = () => {
    history.push({ pathname: REACT_URL_DEVICE_V4 });
  };

  const onselectDoor = () => {
    history.push({ pathname: REACT_URL_DOOR_V4 });
  };

  const onSelectEvent = () => {
    history.push({ pathname: '/acc/z/events' });
  };

  const onSelectPerson = () => {
    history.push({ pathname: REACT_URL_PERSON_V4 });
  };

  const getSiteCount = () => {
    // dispatch(filterSite('', '', '', 1, 1000, (res) => {}));
    dispatch(siteListWithAddressV2(1, 0));
  };

  const getDeviceCount = () => {
    let payload = {};
    filterDevices(1, 1000, payload, (res) => {
      setDeviceCount(res.data.data.totalCount);
    });
  };

  const getDoorCount = () => {
    filterDoors(1, 1000, '', (res) => {
      setDoorCount(res.data.data.totalCount);
    });
  };

  const getPersonCount = () => {
    let payload = {
      firstName: '',
      lastName: '',
      code: '',
      email: '',
      phone: '',
    };
    loadOrgPersons(1, 10, payload, (res) => {
      setPersonCount(res.data.data.totalCount);
    });
  };

  const getEventCount = () => {
    getDeviceEventList(1, 10, '', (res) => {
      setEventCount(res.data.data.totalCount);
    });
  };

  return (
    <Grid container spacing={4}>
      {CommonUtil.isEmptyString() && (
        <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '20%'}>
          <Card className='card mb-4' onClick={() => onSelectSite()}>
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('COMMON089')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                    {CommonUtil.isEmptyString(store.dashBoard.dashcardData.sitesCount) ? 0 : store.dashBoard.dashcardData.sitesCount}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={site_Img} />
                </div>
              </div>
            </CardContent>
          </Card>
        </SiteDashboardTiles>
      )}

      <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '20%'}>
        <Card className='card mb-4' onClick={() => onSelectDevice()}>
          <CardContent className='p-4'>
            <Tooltip title='Device of this application'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('COMMON090')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                    {CommonUtil.isEmptyString(store.dashBoard.dashcardData.devicesCount) ? 0 : store.dashBoard.dashcardData.devicesCount}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={device_Img} />
                </div>
              </div>
            </Tooltip>
          </CardContent>
        </Card>
      </SiteDashboardTiles>

      <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '20%'}>
        <Card className='card mb-4' onClick={() => onselectDoor()}>
          <CardContent className='p-4'>
            <div className='align-box-row align-items-start'>
              <div className='font-weight-bold'>
                <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                  {t('DOORI000')}
                </Typography>
                <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                  {CommonUtil.isEmptyString(store.dashBoard.dashcardData.doorsCount) ? 0 : store.dashBoard.dashcardData.doorsCount}
                </span>
              </div>
              <div className='ml-auto'>
                <img alt='...' className='img-fluid' src={door_Img} />
              </div>
            </div>
          </CardContent>
        </Card>
      </SiteDashboardTiles>

      <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '20%'}>
        <Card className='card mb-4' onClick={() => onSelectPerson()}>
          <CardContent className='p-4'>
            <Tooltip title='Person assign to this application'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('PERSON001')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                  {CommonUtil.isEmptyString(store.dashBoard.dashcardData.personsCount) ? 0 : store.dashBoard.dashcardData.personsCount}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={employees_Img} />
                </div>
              </div>
            </Tooltip>
          </CardContent>
        </Card>
      </SiteDashboardTiles>

      <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '20%'}>
        <Card className='card mb-4' onClick={() => onSelectEvent()}>
          <CardContent className='p-4'>
            <div className='align-box-row align-items-start'>
              <div className='font-weight-bold'>
                <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                  {t('Events')}
                </Typography>
                <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                {CommonUtil.isEmptyString(store.dashBoard.dashcardData.eventCount) ? 0 : store.dashBoard.dashcardData.eventCount}
                  
                </span>
              </div>
              <div className='ml-auto'>
                <img alt='...' className='img-fluid' src={events_Img} />
              </div>
            </div>
          </CardContent>
        </Card>
      </SiteDashboardTiles>
    </Grid>
  );
}
