import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_VIEW_ROLE_LIST_V4 } from '../../actions/EndPoints';
import { createRole, getPermissionsByRoleId, getPermissionsList, resetRolePermission, updateRole } from '../../actions/organization/RoleApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import TitleBar from '../../components/v4/TitleBar';
import { defaultPermissionCode } from './defaultPermissionsCode';
import { PermissionList } from './PermissionList';

export const CreateRole = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const permissionListStore = useSelector((state) => state.roleAndPermission.permissionList);
  const [permissionList, setPermissionList] = useState([]);
  const [error, setError] = useState({ name: '', code: '' });
  const [state, setState] = React.useState({
    name: '',
    code: '',
    description: '',
    scope: 'CUSTOM',
  });

  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const [defaultPermissions, setDefaultPermissions] = useState(defaultPermissionCode);

  //This is for taking permission list
  useEffect(() => {
    dispatch(getPermissionsList());
  }, []);

  useEffect(() => {
    if (permissionListStore.permissions instanceof Array && permissionListStore.permissions.length > 0) {
      setPermissionList(permissionListStore.permissions);
    }
  }, [permissionListStore]);

  const handleChange = (event) => {
    const name = event.target.name;
    if(name==='name' || name==='code'){
      if(!CommonUtil.isAlphaNumericSpace(event.target.value)){
        setError({
          ...error,
          [name]: 'Only accepts alphabets, numerics and underscore',
        });
        return false;
      }
    }
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handleSubmit = () => {
    if (!validateField()) {
      return;
    }

    var newPermissions = [];
    permissionList.map((p) => {
      if (permissions[p.code]) {
        var permission = { permissionId: p.id };
        newPermissions.push(p.id);
      }
    });

    const payload = {
      ...state,
      permissionId: newPermissions,
    };

    dispatch(
      createRole(payload, (data) => {
        if (data.code === 'UASI0045') {
          toast.success(data.message);
          history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 });
        } else {
          toast.error(data.message);
        }
      })
    );
  };

  const validateField = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({
        ...error,
        name: 'User role name is required.',
      });
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({
        ...error,
        code: 'User role code is required.',
      });
      return false;
    }
    if (state.name.length > 50) {
      setError({
        ...error,
        name: 'Maximum character is 50 only.',
      });
      return false;
    }
    if (state.code.length > 50) {
      setError({
        ...error,
        code: 'Maximum character is 50 only.',
      });
      return false;
    }
    if (!CommonUtil.isAlphaNumericUnderscore(state.code)) {
      setError({
        ...error,
        code: 'Only accepts alphabets, numerics and underscore',
      });
      return false;
    }
    return true;
  };

  const FieldRoleCode = (key) => (
    <TextField
      fullWidth
      required
      error={error.code}
      name='code'
      label={'User role code'}
      value={state.code}
      size='small'
      variant='outlined'
      onChange={handleChange}
      helperText={error.code}
    />
  );
  const FieldRoleName = (key) => (
    <TextField
      fullWidth
      required
      value={state.name}
      helperText={error.name}
      size='small'
      name='name'
      label={'User role name'}
      variant='outlined'
      onChange={handleChange}
      error={error.name}
    />
  );

  return (
    <Grid>
      <TitleBar
        title={
          <Typography marginLeft={'10px'} fontSize={'20px'} fontWeight={500}>
            {t('Add new role and permission')}
          </Typography>
        }
        path={true}
        onClick={() => history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 })}
      />
      <Typography fontSize={'16px'} color={'rgba(48,70,105,0.8)'}>
        Create different role and permission to control the use of all or part of the functions of the application.
      </Typography>
      <Box item flexGrow={1} paddingTop={2}>
        <Typography variant='title' gutterBottom component='div' fontWeight={500} fontSize={'18px'} color={'#3D4977'}>
          User role info
        </Typography>
      </Box>
      <Grid style={{ backgroundColor: '#ffffff', borderRadius: '8px', overflow: 'hidden' }}>
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
          <Box item width={'30%'} padding={'24px'}>
            {FieldRoleName()}
          </Box>
          <Box item width={'30%'} padding={'24px'}>
            {FieldRoleCode()}
          </Box>
        </Grid>
      </Grid>
      <Box item flexGrow={1} paddingTop={2}>
        <Typography variant='title' gutterBottom component='div' fontWeight={500} fontSize={'18px'} color={'#3D4977'}>
          Permission for this role
        </Typography>
      </Box>
      <PermissionList permissions={permissions} setPermissions={(v) => setPermissions(v)} disabled={false} />
      <Grid container lg={12} xs={12} direction='row' justifyContent='flex-end' alignItems='center' p={1} paddingRight={3}>
        <Box display='flex' p={1}>
          <Button size='large' variant='contained' onClick={() => handleSubmit()} sx={{ width: { xs: '120px', md: '180px' } }}>
            Save
          </Button>
        </Box>
        <Box display='flex' p={1}>
          <Button
            size='large'
            variant='outlined'
            onClick={() => history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 })}
            sx={{ width: { xs: '120px', md: '180px' }, background: '#FFFF' }}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
