const initialState = {
	roles:{},
	rolePermission:{},
	permissionList:{}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'ROLE_LIST':
            return {
                ...state, 
                roles: action.payload
            }
			case 'ROLE_PERMISSION_LIST':
				return {
					...state, 
					rolePermission: action.payload
				}
				case 'PERMISSION_LIST':
					return {
						...state, 
						permissionList: action.payload
					}		
		default:
			return state;
	}
}