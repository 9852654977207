import React, { useEffect } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { updateAuxIn } from '../auxin/AuxInService';
import { toast } from 'react-toastify';


export default function DeviceTopologyAuxInInformartion(props) {
  const { t } = useTranslation();
  const selectedAuxIn = useSelector((state) => state.deviceTopology.selectedDeviceAuxIn);
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({ name: '', remark: '', ...selectedAuxIn });
  const[update,setUpdate] = React.useState(false)

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedAuxIn)) {
      loadAuxInInformation();
    } else {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    setUpdate(props.open)
  }, [props.open]);

  const loadAuxInInformation = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    let updateObj = {
      address: selectedAuxIn.address,
      aux_id: selectedAuxIn.aux_id,
      aux_no: selectedAuxIn.aux_no,
      dev_id: selectedAuxIn.dev_id,
      disable: selectedAuxIn.disable,
      ext_dev_id: selectedAuxIn.ext_dev_id,
      printer_number: selectedAuxIn.printer_number,
      timeseg_id: selectedAuxIn.timeseg_id,
      remark: state.remark,
      name: state.name,
    };
    updateAuxIn(updateObj, (data) => {
      if (data.data.code === 'DMSI00456') {
        toast.success(data.data.msg);
        handleClose();
        setUpdate(true);
        props.applyUpdate(true)
      }
    }); 
  };

  const handleClose = () => {
    props.toggleUpdate();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      {open && (
        <Box sx={{ flexWrap: 'wrap' }}>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={4}>
              <Box mb={1} mt={2} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON044")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
              <Typography >
              {state.name}
                </Typography>
                {/* <TextField disabled={update} autoComplete='off' fullWidth name='name' value={state.name} onChange={handleChange} size='small' /> */}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("DEVI0001")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedAuxIn.deviceName}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON060")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedAuxIn.aux_no}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON067")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedAuxIn.printer_number}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4} mb={3}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON016")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8} mb={3}>
              <Box>
              <Typography>{state.remark}</Typography>
                {/* <TextField autoComplete='off' fullWidth disabled={update} name='remark' value={state.remark} defaultValue={state.remark} onChange={handleChange} size='small' /> */}
              </Box>
            </Grid>
            {/* <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
              <Box component={'span'}>
                <Button variant='contained' fullWidth={true} size='small' sx={{ backgroundColor: 'rgb(54, 201, 109)' }} disabled={update} onClick={handleSubmit}>
                  {t("save")}
                </Button>
              </Box>
              <Box component={'span'}>
                <Button
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  disabled={update}
                  sx={{ border: '2px solid rgb(54, 201, 109)', color: 'rgb(54, 201, 109)', marginLeft: '2px' }}
                  onClick={handleClose}
                >
                  {' '}
                  {t('COMMON008')}
                </Button>
              </Box>
            </Box> */}
          </Grid>
        </Box>
      )}
   
    </>
  );
}
