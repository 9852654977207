import { Grid, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonUtil from '../../components/Util/CommonUtils';
import SubscriptionGroup from '../../assets/cloudaccess-png/SubscriptionGroup.png';
import { Box, Button, Divider, Typography } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb';
import { filterPlans } from './SubscriptionService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { REACT_URL_CREATESUBSCRIBE } from '../../actions/EndPoints';
import SubscriptionPriceCard from '../../components/SubscriptionPriceCard';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
export default function Subscription(props) {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const history = useHistory();
  const [freeData, setFreeData] = useState(null);
  const Dummystore = ['Dollar'];
  const [price, setPrice] = React.useState({
    currency: '',
  });
  useEffect(() => {
    loadData(1, 100);
  }, []);
  const loadData = (page, rowsPerPage) => {
    filterPlans(page + 1, rowsPerPage, (res) => {
      if (res.data.code === '200' && !CommonUtil.isEmpty(res.data.data)) {
        setData(res.data.data.results);
      } else {
        setData([]);
      }
    });
  };
  useEffect(() => {
    if (!CommonUtil.isEmpty(data)) {
      data.map((value) => {
        if (value.name === 'Free') {
          setFreeData(value);
        }
      });
    }
  }, [data]);
  const FieldCurrency = (key) => (
    <CustomAutocomplete
      displayoption={(e) => e}
      options={Dummystore}
      onSelectedValue={(newValue) => setPrice({ ...price, currency: newValue })}
      label={t('Currency')}
    />
  );

  const breadcrumb = [{ path: '', name: t('Subscription and Service') }];
  const breadcrumb1 = [{ path: '', name: t('Feature package') }];
  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
  };

  return (
    <>
      <Breadcrumb links={breadcrumb} />
      <Grid>
        <Paper style={{ backgroundColor: '#F7FFFF', display: 'flex', alignItems: 'center' }}>
          <Box maxWidth={'50vw'} margin='0% 14%'>
            <Typography fontWeight={700} fontSize='150%' color={'#304669'}>Zlink</Typography>
            <Typography color={'#304669'}>You can get your application from our application market.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</Typography>
          </Box>
          <Box>
            <img style={{ justifyItems: 'left' }} alt='subscriptiongrout' src={SubscriptionGroup} />
          </Box>
        </Paper>
        <Box marginTop={'2%'}>
          <Breadcrumb links={breadcrumb1} />
          <Paper style={{ backgroundColor: '#FFFFFF', marginBottom: '5%' }}>
            <Box style={{ maxWidth: '100%', margin: '0% 2%', alignSelf: 'center' }} >
            <Box marginLeft={'90%'}>
                {FieldCurrency()}
                 </Box>
              <Box marginTop={'2%'} minWidth={'75%'} bgcolor='#FFFFFF' minHeight={'150%'} display={'flex'} justifyContent={'center'}>
                <Box style={{ display: 'flex', bgcolor: '#FFFFFF' }} name3={'Multi-access groups'} name4={'Event picture'} name5={'Events video'} name6={'Doors'} name7={'Events history storage'} />
                <Grid container spacing={2} display={'flex'}>
                  <Grid container xs={12}>
                    <Grid item xs={3}>
                      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} marginTop={'180px'}>
                        {feature.map((val) => (
                          <Typography style={{ color: '#304669', fontSize: '14px' }}>{val}</Typography>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <SubscriptionPriceCard name={'Trial'} nam1={SubscriptionPlan[0]} name2={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name3={'-'} name4={'-'} name5={'upto 2 doors'} name6={'1 month'} />
                <SubscriptionPriceCard name={'Free'} nam1={SubscriptionPlan[0]} name2={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name3={'-'} name4={'-'} name5={'upto 2 doors'} name6={'6 months'} />
                <SubscriptionPriceCard name={'Pro plan'} nam1={SubscriptionPlan[1]} name2={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name3={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name4={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name5={'upto 15 doors'} name6={'1 year'} />
                <SubscriptionPriceCard name={'Plus plan'} nam1={SubscriptionPlan[2]} name2={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name3={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name4={<CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />} name5={'upto 30 doors'} name6={'2 years'} />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </>
  );
}

const SubscriptionPlan = [
  {
    planName: 'Free',
    monthlyPrice: '0 USD',
    yearlyPrice: '0 USD',
  },
  {
    planName: 'Pro',
    monthlyPrice: '1.99 USD',
    yearlyPrice: '19.9USD',
  },
  {
    planName: 'Plus',
    monthlyPrice: '2.99 USD',
    yearlyPrice: '29.9 USD',
  },
];
const feature = ['Multiaccessgroups', 'Eventpicture', 'Eventsvideo', 'Doors', 'Eventshistorystorage'];
