import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Grid, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TitleBar from '../../components/v4/TitleBar';
import Dialog from '@mui/material/Dialog';

import Device from '../../assets/v4/pic.png';
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { JAVA_URL_SITE_LIST } from '../../actions/EndPoints';
import { Box, FormControl, FormHelperText, Hidden, InputLabel, MenuItem, Select } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { bindDevice, findDeviceBySn } from './DeviceService';
import { toast } from 'react-toastify';
import { timeZoneList } from '../../screens/Site/TimeZone';
import { useDispatch } from 'react-redux';
import { updateTimeZone } from './DeviceService';
import { filterZoneV3 } from '../Zone/ZoneService';

export default function AddDevice() {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [sites, setSites] = React.useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [zones, setZones] = useState([]);
  const [updateTZ, setUpdateTZ] = useState(false);
  const dispatch = useDispatch();
  const [defaultTimeZone, setDefaultTimeZone] = useState(false);

  const [state, setState] = React.useState({
    deviceId: '',
    sn: '',
    siteId: '',
    zoneId: '',
    timeZone: '',
  });
  const [error, setError] = React.useState({
    deviceId: '',
    sn: '',
    siteId: '',
    timeZone: '',
    zoneId: '',
  });

  useEffect(() => {
    if (open) {
      loadSites();
      setSelectedSite({});
    }
    setState({
      ...state,
      siteId: '',
      zoneId: '',
      timeZone: '',
    });
  }, [open]);

  useEffect(() => {
    if (!CommonUtil.isEmptyString(state.siteId)) {
      sites.forEach((s) => {
        if (s.id === state.siteId) {
          setSelectedSite(s);
        }
      });
      let payload = {
        name: '',
        parentId: '',
        siteId: state.siteId,
      };

      filterZoneV3(1, 1000, payload, (res) => {
        setZones(res.data.data.zones);
      });
    }
  }, [state.siteId]);

  useEffect(() => {
    let s = selectedSite;
    if (!CommonUtil.isEmpty(s) && !CommonUtil.isEmpty(s.address) && !CommonUtil.isEmptyString(s.address.timeZone)) {
      setState({
        ...state,
        timeZone: s.address.timeZone,
      });
      setDefaultTimeZone(false);
    } else {
      setState({
        ...state,
        timeZone: '',
      });
      setDefaultTimeZone(true);
    }
    if (!CommonUtil.isEmptyString(state.siteId)) {
      if (CommonUtil.isEmpty(s) || CommonUtil.isEmpty(s.address) || CommonUtil.isEmptyString(s.address.timeZone)) {
        setUpdateTZ(true);
      } else {
        setUpdateTZ(false);
      }
    }
  }, [selectedSite]);

  useEffect(() => {
    setState({
      ...state,
      zoneId: '',
    });
  }, [zones]);

  const styleDialog = {
    '& .MuiDialog-paper': {
      padding: '12px 24px',
      width: '474px',
    },
  };

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const loadSites = () => {
    axios.get(JAVA_URL_SITE_LIST + '?pageNumber='+1+'&pageSize='+1000).then((response) => {
      if (response.data.code === 'LMSI0000') setSites(response.data.data.site);
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;

    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: '',
    });
  };

  const handleChangeTimeZone = (event) => {
    var site = {
      ...selectedSite,
    };
    if (!CommonUtil.isEmpty(selectedSite) && !CommonUtil.isEmpty(selectedSite.address)) {
      site.address.timeZone = event.target.value;
    } else {
      var address = {
        addressLine1: 'Default address',
        addressLine2: '',
        addressLine3: '',
        country: '',
        state: '',
        city: 'Default address',
        areaCode: '',
        latitude: '',
        longitude: '',
        radius: '',
        isDefault: false,
        timeZone: event.target.value,
        dayLightSaving: '',
      };
      site.address = address;
    }

    if (!CommonUtil.isEmpty(event.target.value)) {
      dispatch(
        updateTimeZone(state.siteId, site, (res) => {
          if (res.data.code === 'LMSE0074') {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            setState({
              ...state,
              timeZone: event.target.value,
            });
          }
        })
      );
    }
    setError({
      [event.target.name]: '',
    });
  };

  const findDevice = () => {
    if (CommonUtil.isEmptyString(state.sn)) {
      setError({
        sn: 'This field is required',
      });
      return;
    }

    findDeviceBySn(state.sn, (res) => {
      if (res != null && res.data.code === 'DMSI0000') {
        setState({
          ...state,
          deviceId: res.data.data.deviceId,
          // deviceModel: res.data.data.deviceModel,
        });
        setOpen(true);
      } else {
        toast.error(res.data.msg);
      }
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.deviceId)) {
      setError({
        deviceId: 'This field is required',
      });
      return;
    }

    if (CommonUtil.isEmptyString(state.sn)) {
      setError({
        sn: 'This field is required',
      });
      return;
    }

    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({
        siteId: 'This field is required',
      });
      return;
    }

    if (CommonUtil.isEmptyString(state.zoneId)) {
      setError({
        zoneId: 'This field is required',
      });
      return;
    }

    bindDevice(state, (res) => {
      if (res != null && res.data.code === 'DMSI0000') {
        toast.success(res.data.msg);
        history.push('/acc/z/device');
      } else {
        toast.error(res.data.msg);
      }
    });
  };

  const getTimezoneName = (value) => {
    var name = value;
    timeZoneList.map((t) => {
      if (value == t.value) {
        name = t.name;
      }
    });
    return name;
  };

  return (
    <>
      <TitleBar title={t('addDevice')} />
      <Typography variant='title' gutterBottom component='div' style={{ marginBottom: '1rem', fontWeight: 'bold', color: '#304669' }}>
        Manual register device
      </Typography>
      <Grid
        style={{
          backgroundColor: '#FFFF',
          padding: '1rem',
          borderRadius: '8px',
        }}
      >
        <Typography variant='title' gutterBottom component='div' style={{ fontSize: '16px', fontWeight: 'bold', color: '#304669' }}>
          Power up and set device network
        </Typography>
        <Typography className='device-instruction'>1. Plug in the network cable if device support ethernet function.</Typography>
        <Typography className='device-instruction'>
          2. Enter your device Ethernet setting/WiFi setting menu to enter communication setting page. Network setup is successful, device will display a QR code in standby page.
        </Typography>
        <Typography className='device-instruction'>3. On the side of device box or on the back of device, can find the device serial number.</Typography>
        <Typography className='device-instruction'>4. Fill in device serial number on system.</Typography>
      </Grid>
      <Typography variant='title' gutterBottom component='div' style={{ margin: '1rem 0rem', fontWeight: 'bold', color: '#304669' }}>
        Enter device details
      </Typography>
      <Grid
        container
        style={{
          backgroundColor: '#FFFF',
          padding: '2rem',
          borderRadius: '8px',
        }}
      >
        <Grid item sm={4} paddingRight={1}>
          <TextField
            error={!CommonUtil.isEmptyString(error.sn)}
            required
            fullWidth
            name='sn'
            label={t('Serial number')}
            helperText={error.sn}
            variant='outlined'
            size='small'
            value={state.sn}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          marginTop: '1rem',
        }}
      >
        <Button
          onClick={() => findDevice()}
          style={{
            width: '200px',
            backgroundColor: '#304669',
            color: 'white',
            height: '48px',
          }}
        >
          {t('Add')}
        </Button>
      </Grid>
      <Dialog sx={styleDialog} onClose={() => setOpen(!open)} open={open}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='title' gutterBottom component='div' style={{ fontSize: '16px' }}>
            Bind devices to your company
          </Typography>
          <IconButton disableRipple onClick={() => setOpen(false)} style={{ padding: '0px', marginTop: '-1rem' }}>
            <Close />
          </IconButton>
        </Grid>

        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
            border: '0.5px solid #FFFF',
            backgroundColor: '#e9e9e9',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.5rem',
            borderRadius: '8px',
            marginTop: '0.5rem',
          }}
        >
          <img alt='complex' src={Device} style={{ height: '60px', width: '60px' }} />
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0rem 0.5rem',
            }}
          >
            <Typography>{state.deviceModel}</Typography>
            <Typography variant='fieldLabel'>{state.sn}</Typography>
          </Grid>
        </Grid>
        <Typography variant='title' gutterBottom component='div' style={{ fontSize: '14px', margin: '0.5rem 0rem' }}>
          Please specify the device to a site.
        </Typography>
        {/* {CommonUtil.isEmpty(selectedSite) || CommonUtil.isEmpty(selectedSite.address) || CommonUtil.isEmptyString(selectedSite.address.timeZone) ? (
          <Typography
            style={{
              color: 'red',
              fontSize: '12px',
              cursor: 'pointer',
            }}
          >
            Please select the site which has timezone.
          </Typography>
        ) : ( */}
        {!CommonUtil.isEmpty(selectedSite) ? (
          <Typography
            style={{
              color: '#36c96d',
              fontSize: '12px',
              cursor: 'pointer',
            }}
          >
            This device will sync the same time zone({defaultTimeZone ? getTimezoneName('+08:00') : getTimezoneName(state.timeZone)}) of the site.
          </Typography>
        ) : (
          ''
        )}

        {/* )} */}
        <Grid>
          <Box display='flex' justifyContent='center' pt={1}>
            <FormControl variant='outlined' size='small' fullWidth required>
              <InputLabel>{t('DEVI0025')}</InputLabel>
              <Select error={!CommonUtil.isEmptyString(error.siteId)} MenuProps={MenuProps} label={t('DEVI0025')} name='siteId' value={state.siteId} onChange={handleChange}>
                {sites ? (
                  sites.map((site, index) => (
                    <MenuItem key={index} value={site.id}>
                      <Typography variant='inherit' noWrap>
                        {site.name}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
              <FormHelperText error={true}>{error.siteId}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        {/* <Grid>
          <Box display='flex' justifyContent='center' pt={1}>
            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel>{t('ADD010') + ' *'}</InputLabel>
              <Select
                onChange={handleChangeTimeZone}
                name='timeZone'
                error={error.timeZone}
                label={t('ADD010') + ' *'}
                value={state.timeZone}
                inputProps={{
                  disabled: !updateTZ,
                }}
              >
                <MenuItem value='' selected>
                  <em>{t('COMMON288')}</em>
                </MenuItem>
                {timeZoneList ? (
                  timeZoneList.map((data, index) => (
                    <MenuItem key={index} value={data.value} style={{ maxWidth: '100%' }}>
                      <Typography variant='inherit' noWrap>
                        {data.name}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
              <FormHelperText>{error.timeZone}</FormHelperText>
            </FormControl>
          </Box>
        </Grid> */}
        <Grid>
          <Box display='flex' justifyContent='center' pt={1}>
            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel>{t('ZONE001') + ' *'}</InputLabel>
              <Select
                label={t('ZONE001')}
                error={error.zoneId}
                name='zoneId'
                onChange={handleChange}
                value={state.zoneId}
                defaultValue={state.zoneId}
                variant='outlined'
                size='small'
                MenuProps={MenuProps}
              >
                <MenuItem value='' selected>
                  <em>{t('none')}</em>
                </MenuItem>
                {zones ? (
                  zones.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      <Typography variant='inherit' noWrap>
                        {data.name}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
              <FormHelperText error={true}>{error.zoneId}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginTop: '2rem',
            gap: '1rem',
          }}
        >
          <Button
            onClick={() => handleSubmit()}
            style={{
              width: '160px',
              backgroundColor: '#36c96d',
              height: '48px',
              color: '#fff',
            }}
          >
            {t('Save')}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            style={{
              width: '160px',
              backgroundColor: '#FFFF',
              height: '48px',
              color: '#36c96d',
              border: '0.5px solid #36c96d',
            }}
          >
            {t('Cancel')}
          </Button>
        </Grid>
      </Dialog>
    </>
  );
}
