import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_AUXOUT } from '../../actions/EndPoints';
import EditAuxout from '../auxout/EditAuxOut';
// import FilterAuxout from './FilterAuxout';
import CommonUtil from '../../components/Util/CommonUtils';
import CachedIcon from '@material-ui/icons/Cached';
import { filterAuxOut } from './AuxOutService';

export default function AuxoutList() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setupdateObject] = useState({});

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    name: '',
    deviceAlias: '',
  });

  const [filter, setFilter] = useState({
    name: '',
    deviceAlias: '',
  });

  const defaultFilter = {
    name: '',
    deviceAlias: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      deviceAlias: filterData.deviceAlias,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    filterAuxOut(page + 1, rowsPerPage, (res) => {
      if (res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data)) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
        setData(res.data.data.doors);
      } else {
        setTotalRecords(0);
        setPage(0);
        setData([]);
      }
    });
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const breadcrumb = [
    //{ path: REACT_URL_AUXOUT, name: t("DEVI0013") },
    { path: REACT_URL_AUXOUT, name: t('AUXOUT001') },
  ];

  const columns = [
    {
      field: 'name',
      title: t('COMMON475'),
      tooltip: t('COMMON068'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
    { field: 'deviceName', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'aux_no', title: t('COMMON011'), tooltip: t('COMMON069'), editable: 'never' },
    { field: 'printer_number', title: t('COMMON067'), tooltip: t('COMMON070'), editable: 'never' },
    { field: 'remark', title: t('COMMON016'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <EditIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setupdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  // eslint-disable-next-line
  const onPageChange = (page) => {
    setQuery({
      ...query,
      page: page,
    });
    loadData();
  };

  // eslint-disable-next-line
  const onPageSizeChange = (pageSize) => {
    setQuery({
      ...query,
      page: 0,
      pageSize: pageSize,
    });
    loadData();
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t("AUXOUT001")}/> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          {/* <FilterAuxout page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} /> */}
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditAuxout
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
