import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react';
import { ReactComponent as FingerPrint } from "../../assets/credentials-svg/fingerprint.svg";

export const CredentialCountBox = (props) => {

  return (
    <>
    <Tooltip title={props.value} placement={'top'}>
      <Box sx={{ 
        border: '1px solid #36C96D', 
        borderRadius: '5px', 
        width: '100%', 
        display: 'flex',
        justifyContent: 'space-around', 
        alignItems: 'center'}}>
          {props.icon ? props.icon : <FingerPrint /> }
          <Typography style={{ fontSize: '80%' }}>{props.value}</Typography>
      </Box>
      </Tooltip>

    </>
  )
}
