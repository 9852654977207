import { GET_INTER_LOCK_LIST, SUCCESS,GET_INTER_LOCK_LIST_DOOR_COUNT,GET_INTER_LOCK_RULES} from "../actions/types";

const initialState = {
    interLockList: [],
    isSuccess: false,
    doorCount: false,
    interLockRules: []
};

export default function (state = initialState, action) {
     
    switch (action.type) {
        case GET_INTER_LOCK_LIST:
            return {
                ...state,
                interLockList: action.payload
            };
            case GET_INTER_LOCK_LIST_DOOR_COUNT:
            return { 
                ...state, 
                doorCount: action.payload, 
            };
            case GET_INTER_LOCK_RULES:
                return {
                    ...state,
                    interLockRules: action.payload
                };
        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

        default:
            return state;
    }
}