import {
  GET_DASHBOARD_SITES_LIST,
  SUCCESS,
  GET_DASHBOARD_DEVICE_COUNT,
  GET_DASHBOARD_EMPLOYEE_COUNT,
  GET_DASHBOARD_NOTIFICATION_LIST,
  GET_DASHBOARD_COUNT,
  GET_TEST_COUNT,
} from '../actions/types';

const initialState = {
  sitesList: [],
  isSuccess: false,
  deviceCount: 0,
  employeeCount: 0,
  notificationList: [],
  dashcardData: {},
  testcount: 0,
  selectedSite: '',
  selectedDoor: {},
  activeDoors: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_SITES_LIST:
      return {
        ...state,
        sitesList: action.payload,
      };
    case GET_DASHBOARD_DEVICE_COUNT:
      return {
        ...state,
        deviceCount: action.payload,
      };
    case GET_DASHBOARD_EMPLOYEE_COUNT:
      return {
        ...state,
        employeeCount: action.payload,
      };
    case GET_DASHBOARD_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case GET_DASHBOARD_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case GET_DASHBOARD_COUNT:
      return {
        ...state,
        dashcardData: action.payload,
      };

    case SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case 'SITE_DASHBOARD_DOOR':
      return {
        ...state,
        selectedDoor: action.payload,
      };
    case 'SITE_DASHBOARD_ACTIVE_DOOR':
      return {
        ...state,
        activeDoors: [action.payload],
      };

    default:
      return state;
  }
}
