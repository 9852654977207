import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelButton from '../../components/CancelButton';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../components/SubmitButton';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import { postAccessGroup, updateAccessGroup } from './AccessGroupService';
import { getTimeZoneIntervalList } from '../schedules/SchedulesService';
import { Autocomplete } from '@mui/material';
import { toast } from 'react-toastify';
export default function AddAccessGroup(props) {
  const [open, setOpen] = React.useState(props.open);
  const { t } = useTranslation();
  const [state, setState] = React.useState();
  const [schedules, setSchedules] = React.useState([]);

  const [payload, setPayload] = React.useState({
    id: props.level.id,
    name: props.level.name,
    schedulerId: props.level.schedulerId,
    startTime: props.level.startTime,
    endTime: props.level.endTime,
  });
  const [error, setError] = React.useState({
    name: false,
    schedulerId: false,
  });

  useEffect(() => {
    setOpen(props.open);
  });

  useEffect(() => {
    loadSchedules(0, 1000, '');
  }, []);

  const handleClose = () => {
    setOpen(props.onClose);
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: true,
      });
      toast.error(t('ACCESSGROUP009'));
      return;
    }
    if (CommonUtil.isEmptyString(payload.schedulerId)) {
      setError({
        ...error,
        schedulerId: true,
      });
      toast.error(t('ACCESSGROUP011'));
      return;
    }

    const state = {
      name: payload.name,
      schedulerId: payload.schedulerId,
    };
    if (CommonUtil.isEmptyString(payload.id)) {
      postAccessGroup(state, (res) => {
        if (res.data.code === 'DMSI0000') {
          toast.success(t('ACCESSGROUP016'));
          handleClose();
        } else {
          toast.error(res.data.msg);
        }
      });
    } else {
      updateAccessGroup(payload.id, state, (res) => {
        if (res.data.code === 'DMSI0000') {
          toast.success(t('ACCESSGROUP017'));
          handleClose();
        } else {
          toast.error(res.data.msg);
        }
      });
    }
  };

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: false,
    });
  };

  const loadSchedules = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name, (res) => {
      if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.timeZone instanceof Array) {
        // setTotalRecords(res.data.data.totalCount);
        // setPage(res.data.data.currentPage - 1);
        setSchedules(res.data.data.timeZone);
      } else {
        // setTotalRecords(0);
        // setPage(0);
        setSchedules([]);
      }
    });
  };

  const findTimeSlotById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return '';
    }
    var timeSlot = { id: '' };
    schedules.map((s) => {
      if (s.id === id) {
        timeSlot = s;
      }
    });
    return timeSlot;
  };

  const scheduleField = () => (
    <Autocomplete
      fullWidth
      value={payload.schedulerId ? findTimeSlotById(payload.schedulerId) : null}
      options={schedules ? schedules : []}
      getOptionLabel={(option) => option.operationName}
      renderInput={(params) => <TextField {...params} label={t('Time slots') + ' *'} variant='outlined' />}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setPayload({
            ...payload,
            schedulerId: newValue.id,
          });
        }
      }}
    />
  );

  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{CommonUtil.isEmptyString(payload.id) ? t('ACCESSGROUP001') : t('ACCESSGROUP003')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.name}
                    name='name'
                    label={t('COMMON014') + ' *'}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={payload.name}
                    value={payload.name}
                    onChange={(data) => handleChange(data)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  {scheduleField()}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
