import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import { filterPlans } from '../screens/Subscription/SubscriptionService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useEffect, useState } from 'react';
import CommonUtil from './Util/CommonUtils';
import { REACT_URL_CREATESUBSCRIBE } from '../actions/EndPoints';

export default function SubscriptionPriceCard(props) {
  const [data, setData] = useState();
  const history = useHistory();
  const [freePlan, setFreePlan] = useState(null);
  const [proPlan, setProPlan] = useState(null);
  const [plusPlane, setPlusPlan] = useState(null);
  const [prices, setPrices] = useState(props.nam1.monthlyPrice);

  useEffect(() => {
    loadData(1, 100);
  }, []);
  const loadData = (page, rowsPerPage) => {
    filterPlans(page + 1, rowsPerPage, (res) => {
      if (res.data.code === '200' && !CommonUtil.isEmpty(res.data.data)) {
        setData(res.data.data.results);
      } else {
        setData([data]);
      }
    });
  };
  useEffect(() => {
    if (!CommonUtil.isEmpty(data)) {
      data.map((value) => {
        if (value.name === 'Free') {
          setFreePlan(value);
        }
        if (value.name === 'Pro plan') {
          setProPlan(value);
        }
        if (value.name === 'Plus plan') {
          setPlusPlan(value);
        }
      });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 250,
          height: 410,
        },
        bgcolor: '#FFF',
      }}
    >
      <Paper style={{ backgroundColor: '#F4F5F7' }} elevation={1}>
        <Typography variant='h6' marginTop={'5%'} textAlign={'center'} color={'#304669 !important'} fontWeight={600}>
          {props.name}
        </Typography>
        <Typography variant='h6' marginTop={'5%'} textAlign={'center'} color={'#36C96D !important'} fontWeight={600}>
          {prices}
        </Typography>
        <Box marginTop={'10%'} justifyContent={'center'}>
          {props.name !== 'Trial' && props.name !== 'Free' ? (
            <Box container marginTop={'5%'} justifyContent={'center'} display='flex'>
              <Button
                marginTop={'2%'}
                variant={prices === props.nam1.monthlyPrice ? 'contained' : 'outlined'}
                sx={{
                  backgroundColor: prices !== props.nam1.yearlyPrice && '#36C96D !important',
                  padding: '5px 20px 5px 20px',
                  color: prices === props.nam1.yearlyPrice && '#304669 !important',
                  borderColor: prices === props.nam1.yearlyPrice && '#36C96D !important',
                }}
                onClick={() => setPrices(props.nam1.monthlyPrice)}
              >
                Monthly
              </Button>
              <Button
                marginTop={'2%'}
                variant={prices === props.nam1.yearlyPrice ? 'contained' : 'outlined'}
                sx={{
                  borderColor: prices !== props.nam1.yearlyPrice && '#36C96D !important',
                  backgroundColor: prices === props.nam1.yearlyPrice && '#36C96D !important',
                  padding: '5px 25px 5px 25px',
                  color: prices !== props.nam1.yearlyPrice && '#304669 !important',
                }}
                onClick={() => setPrices(props.nam1.yearlyPrice)}
              >
                Yearly
              </Button>
            </Box>
          ) : (
            <></>
          )}

          {props.name !== 'Plus plan' && props.name !== 'Pro plan' ? (
            <Box container marginTop={'5%'} justifyContent={'center'} display='flex'>
              <Button
                marginTop={'2%'}
                disabled
                variant='contained'
                color='success'
                sx={{ '&:hover': { backgroundColor: '#36C96D !important' }, padding: '5px 20px 5px 20px' }}
                display={'flex'}
              >
                Monthly
              </Button>
              <Button
                marginTop={'2%'}
                disabled
                variant='outlined'
                color='success'
                sx={{ '&:hover': { backgroundColor: '#36C96D' }, padding: '5px 25px 5px 25px' }}
                display={'flex'}
              >
                Yearly
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name2}
        </Typography>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name3}
        </Typography>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name4}
        </Typography>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name5}
        </Typography>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name6}
        </Typography>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name7}
        </Typography>
        <Typography marginTop={'2%'} textAlign={'center'} fontWeight={500}>
          {props.name7}
        </Typography>
        <Box marginTop={'15%'} justifyContent={'center'} marginRight={'10px'} marginLeft={'10px'}>
          {props.name == 'Plus plan' ? (
            <>
              <Button
                fullWidth
                disabled
                variant='contained'
                size='large'
                sx={{ padding: '10px 20px 10px 20px', marginTop: '20px', backgroundColor: '#C0C1C9 !important' }}
                onClick={() => history.push({ pathname: REACT_URL_CREATESUBSCRIBE, state: plusPlane })}
              >
                BUY
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box marginTop={'15%'} justifyContent={'center'} marginRight={'10px'} marginLeft={'10px'}>
          {props.name == 'Pro plan' ? (
            <>
              <Button
                fullWidth
                disabled
                variant='contained'
                size='large'
                sx={{ padding: '10px 20px 10px 20px', marginTop: '20px', backgroundColor: '#C0C1C9 !important' }}
                onClick={() => history.push({ pathname: REACT_URL_CREATESUBSCRIBE, state: proPlan })}
              >
                BUY
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box marginTop={'15%'} justifyContent={'center'} marginRight={'10px'} marginLeft={'10px'}>
          {props.name == 'Trial' ? (
            <>
              <Button
                fullWidth
                variant='contained'
                size='large'
                sx={{ padding: '10px 20px 10px 20px', marginTop: '20px', backgroundColor: '#36C96D !important' }}
                onClick={() => history.push({ pathname: REACT_URL_CREATESUBSCRIBE, state: freePlan })}
              >
                Free trial
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
