import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { JAVA_API_SITE } from '../../actions/ApiEndPoints';

async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      Authorization: CommonUtil.getCookie('zlink_token'),
    },
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const filterDevices = async (page, rowsPerPage, filter, callBack) => {
  try {
    if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(rowsPerPage)) {
      const res = await axios.post('/web/devices/list?' + 'page=' + page + '&size=' + rowsPerPage + buildQuery(filter));
      callBack(res);
      return;
    }
  } catch (error) {}
};

export const filterDevicesFetch = async (page, rowsPerPage, filter, callBack) => {
  try {
    if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(rowsPerPage)) {
      postData('/web/devices/list?' + 'page=' + page + '&size=' + rowsPerPage + buildQuery(filter), {}).then((data) => {
        callBack(data); // JSON data parsed by `data.json()` call
      });
      return;
    }
  } catch (error) {}
};

export const filterDoorsFetch = async (page, rowsPerPage, filter, callBack) => {
  let query = '';

  if (!CommonUtil.isEmptyString(filter.siteId)) {
    query = '&siteId=' + filter.siteId;
  } else {
    query = '';
  }
  try {
    postData('/web/door?page=' + page + '&size=' + rowsPerPage + query, {}).then((data) => {
      callBack(data); // JSON data parsed by `data.json()` call
    });
  } catch (error) {
    //callBack(null);
  }
};

export const bindDevice = async (payload, callBack) => {
  try {
    const res = await axios.put('/web/devices/bind', payload);
    callBack(res);
  } catch (error) {}
};

export const deleteDevice = async (ids, callBack) => {
  try {
    const res = await axios.delete(`/web/devices/${ids}`);
    callBack(res);
  } catch (error) {}
};

export const rebootDevice = async (id, callBack) => {
  try {
    const res = await axios.post(`/web/devices/${id}/reboot`);
    callBack(res);
  } catch (error) {}
};

export const findDeviceBySn = async (sn, callBack) => {
  try {
    const res = await axios.get('/web/devices/' + sn + '/pre_register');
    callBack(res);
  } catch (error) {}
};

export const listAllDevices = async (callBack) => {
  try {
    const res = await axios.get(`/web/devices`);
    callBack(res);
  } catch (error) {}
};

export const updateDevice = async (deviceId, payload, callBack) => {
  try {
    const res = await axios.put('/web/device/' + deviceId, payload);
    callBack(res);
  } catch (error) {}
};

export const updateTimeZone = (siteId, payload, callBack) => async () => {
  try {
    const query = siteId;
    const res = await axios.put(JAVA_API_SITE + '/' + query, payload);

    callBack(res);
  } catch (error) {}
};

function buildQuery(filter) {
  let query = '';

  if (filter.sn && filter.alias) {
    return (query = query + `&deviceSn=${filter.sn} + &deviceAlias=${filter.alias}`);
  }

  if (filter.sn) {
    return (query = query + `&deviceSn=${filter.sn}`);
  }

  if (filter.alias) {
    return (query = query + `&deviceSn=${filter.alias}`);
  }

  if (filter.zoneId) {
    return (query = query + `&zoneId=${filter.zoneId}`);
  }

  if (filter.siteId) {
    return (query = query + `&siteId=${filter.siteId}`);
  }

  return query;
}

export const findAuxInDetails = async (id, callBack) => {
  try {
    const res = await axios.get('/web/auxiliary_in/' + id);
    callBack(res);
  } catch (error) {}
};

export const findAuxOutDetails = async (id, callBack) => {
  try {
    const res = await axios.get('/web/auxiliary_out/' + id);
    callBack(res);
  } catch (error) {}
};

export const findReader = async (readerid, callBack) => {
  try {
    const res = await axios.get('/web/devices/' + readerid);
    callBack(res);
  } catch (error) {}
};

export const deletePersonCredentialsFromCMS = async (personId, callBack) => {
  try {
    const res = await axios.delete('/web/person/deletePersonCredentialsFromCMS?personId=' + personId);
    callBack(res);
  } catch (error) {}
};

export const getDeviceSpecs = async (devId, sn, callBack) => {
  try {
    const res = await axios.get('/web/device/specs?deviceId=' + devId + '&sn=' + sn);
    callBack(res);
  } catch (error) {}
};

export const getDeviceVerificationMode = async (devId, callBack) => {
  try {
    const res = await axios.get('/web/device/verification-mode?deviceId=' + devId );
    callBack(res);
  } catch (error) {}
};

export const remoteRegistration = async (devId, payload, callBack) => {
  try {
    const res = await axios.post('/web/devices/' + devId + '/remoteRegistration', payload);
    callBack(res);
  } catch (error) {}
};

export const updateDeviceRole = async (devId, personId, role, callBack) => {
  try {
    const res = await axios.put('/web/person/updatePersonCredentials?devId=' + devId + '&personId=' + personId + '&role=' + role);
    callBack(res);
  } catch (error) {}
};

export const updateDevicePasscode = async (devId, personId, passcode, callBack) => {
  try {
    const res = await axios.put('/web/person/updatePersonCredentialspasscode?devId=' + devId + '&personId=' + personId + '&passcode=' + passcode);
    callBack(res);
  } catch (error) {}
};

export const personsFromDevice = async (deviceId, page, size, callBack) => {
  try {
    const res = await axios.get('/web/person/getPersonsFromDevcie?deviceId=' + deviceId + '&page=' + page + '&size=' + size);
    callBack(res);
  } catch (error) {}
};

export const syncPersonToDevice = async (payload, callBack) => {
  try {
    const state = {
      persons: [payload.persons],
    };
    const res = await axios.post('/web/device/' + payload.deviceId + '/' + 'person', state);
    callBack(res);
  } catch (error) {}
};
