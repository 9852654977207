import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { REACT_VIEW_ROLE_LIST_V4 } from '../../actions/EndPoints';
import { getPermissionsByRoleId, resetRolePermission } from '../../actions/organization/RoleApiCalls';
import TitleBar from '../../components/v4/TitleBar';
import OverFlowText from '../../screens/accessgroup/OverFlowText';
import { defaultPermissionCode } from './defaultPermissionsCode';
import { PermissionList } from './PermissionList';

export const ViewRoleAndPermission = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.roleAndPermission.rolePermission);
  const location = useLocation();
  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const [defaultPermissions, setDefaultPermissions] = useState(defaultPermissionCode);

  //This is for getting permission using role id and reseting store.
  useEffect(() => {
    if (store.rolePermissions instanceof Array && store.rolePermissions.length > 0) {
      dispatch(resetRolePermission());
    }
    dispatch(getPermissionsByRoleId(location.state.id));
  }, []);

  //This is for mapping permission as per our requirment.
  useEffect(() => {
    if (store.rolePermissions instanceof Array && store.rolePermissions.length > 0) {
      var newPermissions = { ...permissions };
      store.rolePermissions.map((data) => {
        newPermissions[data.permissionCode] = true;
      });
      setPermissions({
        ...permissions,
        ...newPermissions,
      });
    } else {
      setPermissions({
        ...defaultPermissions,
      });
    }
  }, [store]);

  return (
    <Grid>
      <TitleBar
        title={
          <Typography marginLeft={'10px'} fontSize={'20px'} fontWeight={500}>
            {t('VIEWROLEPERMISSION001')}
          </Typography>
        }
        path={true}
        onClick={() => history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 })}
      />
      <Typography fontSize={'16px'} color={'rgba(48,70,105,0.8)'}>
        {t('VIEWROLEPERMISSION002')}
      </Typography>
      <Box item flexGrow={1} paddingTop={2}>
        <Typography variant='title' gutterBottom component='div' fontWeight={500} fontSize={'18px'} color={'#3D4977'}>
          {t('VIEWROLEPERMISSION003')}
        </Typography>
      </Box>
      <Paper elevation={0} style={{ backgroundColor: '#ffffff', borderRadius: '8px', overflow: 'hidden', margin: '12px' }}>
        <Box display='flex' padding={2}>
          <Box item paddingRight={1} minWidth='150px'>
            <OverFlowText variant='fieldLabel' color={'rgba(48,70,105,0.5)'}>
              {t('VIEWROLEPERMISSION004')}
            </OverFlowText>
          </Box>
          <Box item>
            <Typography variant='fieldValue' color={'rgba(48,70,105)'}>
              {location.state.name}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' padding={2} paddingBottom={2}>
          <Box item paddingRight={1} minWidth='150px'>
            <OverFlowText variant='fieldLabel' color={'rgba(48,70,105,0.5)'}>
              {t('VIEWROLEPERMISSION005')}
            </OverFlowText>
          </Box>
          <Box item>
            <Typography variant='fieldValue' color={'rgba(48,70,105)'}>
              {location.state.code}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Box item flexGrow={1} paddingTop={2}>
        <Typography variant='title' gutterBottom component='div' fontWeight={500} fontSize={'18px'} color={'#3D4977'}>
          {t('VIEWROLEPERMISSION006')}
        </Typography>
      </Box>
      <PermissionList permissions={permissions} setPermissions={(v) => setPermissions(v)} disabled={true} role={location.state} />
    </Grid>
  );
};
