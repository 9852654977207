import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_VIEW_ROLE_LIST_V4 } from '../../actions/EndPoints';
import { getPermissionsByRoleId, getPermissionsList, resetRolePermission, updateRole } from '../../actions/organization/RoleApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import TitleBar from '../../components/v4/TitleBar';
import { defaultPermissionCode } from './defaultPermissionsCode';
import { PermissionList } from './PermissionList';

export const UpdatePermission = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.roleAndPermission.rolePermission);
  const permissionListStore = useSelector((state) => state.roleAndPermission.permissionList);
  const location = useLocation();
  const [permissionList, setPermissionList] = useState([]);
  const [error, setError] = useState({ name: '', code: '' });
  const [state, setState] = React.useState({
    id: location.state.id,
    name: location.state.name,
    code: location.state.code,
    description: location.state.description,
    scope: 'CUSTOM',
  });

  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const [defaultPermissions, setDefaultPermissions] = useState(defaultPermissionCode);

  // This is for geting permission by role id.
  useEffect(() => {
    if (store.rolePermissions instanceof Array && store.rolePermissions.length > 0) {
      dispatch(resetRolePermission());
    }
    dispatch(getPermissionsByRoleId(location.state.id));
  }, []);

  // This is for mapping permission as per our requirment.
  useEffect(() => {
    if (store.rolePermissions instanceof Array && store.rolePermissions.length > 0) {
      var newPermissions = { ...permissions };
      store.rolePermissions.map((data) => {
        newPermissions[data.permissionCode] = true;
      });
      setPermissions({
        ...permissions,
        ...newPermissions,
      });
    } else {
      setPermissions({
        ...defaultPermissions,
      });
    }
  }, [store]);

  //This is for taking permission list
  useEffect(() => {
    dispatch(getPermissionsList());
  }, []);

  useEffect(() => {
    if (permissionListStore.permissions instanceof Array && permissionListStore.permissions.length > 0) {
      setPermissionList(permissionListStore.permissions);
    }
  }, [permissionListStore]);

  const handleChange = (event) => {

    const name = event.target.name;
    if (name === 'name') {
      if (!CommonUtil.isAlphaNumericSpace(event.target.value)) {
        setError({
          ...error,
          [name]: 'Only accepts alphabets, numerics and underscore',
        });
        return false;
      }
    }
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({
        ...error,
        name: 'User role name is required.',
      });
      return false;
    }
    if (state.name.length > 30) {
      setError({
        ...error,
        name: 'Maximum character is 30 only.',
      });
      return false;
    }

    var newPermissions = [];
    permissionList.map((p) => {
      if (permissions[p.code]) {
        var permission = { permissionId: p.id };
        newPermissions.push(p.id);
      }
    });

    const payload = {
      ...state,
      permissionId: newPermissions,
    };

    dispatch(
      updateRole(payload, (data) => {
        if (data.code === 'UASI0046') {
          toast.success(data.message);
          history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 });
        } else {
          toast.error(data.message);
        }
      })
    );
  };

  const FieldRoleCode = (key) => (
    <TextField
      disabled
      required
      fullWidth
      error={error.code}
      name='code'
      label={t('VIEWROLEPERMISSION005')}
      value={state.code}
      size='small'
      variant='outlined'
      onChange={handleChange}
      helperText={error.name}
    />
  );
  const FieldRoleName = (key) => (
    <TextField
      disabled={state.code === 'GENERAL PERSON'}
      fullWidth
      required
      value={state.name}
      helperText={error.name}
      size='small'
      name='name'
      label={t('VIEWROLEPERMISSION004')}
      variant='outlined'
      onChange={handleChange}
      error={error.name}
    />
  );

  return (
    <Grid>
      <TitleBar
        title={
          <Typography marginLeft={'10px'} fontSize={'20px'} fontWeight={500}>
            {t('Edit user role details')}
          </Typography>
        }
        path={true}
        onClick={() => history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 })}
      />
      <Typography fontSize={'16px'} color={'rgba(48,70,105,0.8)'}>
        {t('VIEWROLEPERMISSION002')}
      </Typography>
      <Box item flexGrow={1} paddingTop={2}>
        <Typography variant='title' gutterBottom component='div' fontWeight={500} fontSize={'18px'} color={'#3D4977'}>
          {t('VIEWROLEPERMISSION003')}
        </Typography>
      </Box>
      <Grid style={{ backgroundColor: '#ffffff', borderRadius: '8px', overflow: 'hidden' }}>
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
          <Box item width={'30%'} padding={'24px'}>
            {FieldRoleName()}
          </Box>
          <Box item width={'30%'} padding={'24px'}>
            {FieldRoleCode()}
          </Box>
        </Grid>
      </Grid>
      <Box item flexGrow={1} paddingTop={2}>
        <Typography variant='title' gutterBottom component='div' fontWeight={500} fontSize={'18px'} color={'#3D4977'}>
          {t('VIEWROLEPERMISSION006')}
        </Typography>
      </Box>
      <PermissionList permissions={permissions} setPermissions={(v) => setPermissions(v)} disabled={false} role={location.state} />
      <Grid container lg={12} xs={12} direction='row' justifyContent='flex-end' alignItems='center' p={1} paddingRight={3}>
        <Box display='flex' p={1}>
          <Button size='medium' variant='contained' color='secondary' onClick={() => handleSubmit()} sx={{ width: { xs: '120px', md: '180px' } }}>
            {t('COMMON224')}
          </Button>
        </Box>
        <Box display='flex' p={1}>
          <Button
            size='medium'
            variant='outlined'
            onClick={() => history.push({ pathname: REACT_VIEW_ROLE_LIST_V4 })}
            sx={{ width: { xs: '120px', md: '180px' }, background: '#FFFF', color:'#3d4977 !important', borderBlockColor:'#3d4977 !important'}}
          >
            {t('COMMON008')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
