import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';

export default function EventGraph() {
  const { t } = useTranslation();
  const [series, setSeries] = useState([
    {
      name: t('COMMON087'),
      data: [],
    },
    {
      name: t('COMMON085'),
      data: [],
    },
    {
      name: t('Critical'),
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'chartArea',
      toolbar: {
        show: false,
        autoSelected: 'none',
      },
      zoom: {
        enabled: true,
      },
    },
    legend: {
      horizontalAlign: 'right',
      position: 'top',
    },
    xaxis: {
      type: 'time',
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Events',
      },
    },
    xaxis: {
      title: {
        text: 'Hours',
      },
    },
    dataLabels: {
      enabled: false,
    },

    tooltip: {
      x: {
        formatter: function(val) {
          return t('time') + ' : ' + val;
        },
      },

      y: {
        formatter: function(val) {
          return t('time') + ' : ' + val;
        },
      },
    },
    colors: ['#36C96D', '#F1AC24', '#F66669'],
  });

  const [timeRange, setTimeRange] = useState(2);
  useEffect(() => {
    var normal = [];
    var alarm = [];
    var warning = [];
    var categories = [];

    axios.get('/web/device/events/graph?hours=' + timeRange).then((res) => {
      res.data.data.map((event) => {
        if (!CommonUtil.isEmpty(event)) {
          categories.push(event.time);
          normal.push(event.normal);
          alarm.push(event.error);
          warning.push(event.warn);
        }
      });
      setSeries([
        {
          name: t('COMMON087'),
          data: normal,
        },
        {
          name: t('COMMON085'),
          data: warning,
        },
        {
          name: t('Critical'),
          data: alarm,
        },
      ]);
      setOptions({
        ...options,
        xaxis: {
          type: 'time',
          categories: categories,
        },
      });
    });
  }, [timeRange]);
  return (
    <div>
      <Box display='flex' flexDirection='row'>
        <Box flexGrow={1}>
          <Typography gutterBottom variant='h4' component='h2' color='secondary' style={{ margin: '18px' }}>
            {t('COMMON182')}
          </Typography>
        </Box>
        <Box p={1} width='150px'>
          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>{t('COMMON207')}</InputLabel>
            <Select
              value={timeRange}
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              label={t('COMMON207')}
              onChange={(e) => setTimeRange(e.target.value)}
            >
              <MenuItem value=''>
                <em>{t('none')}</em>
              </MenuItem>
              <MenuItem value={2}>{t('COMMON194')}</MenuItem>
              <MenuItem value={4}>{t('COMMON195')}</MenuItem>
              <MenuItem value={8}>{t('COMMON196')}</MenuItem>
              <MenuItem value={12}>{t('COMMON197')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <ReactApexChart options={options} series={series} type='area' height={350} />
    </div>
  );
}
