import axios from 'axios';
import { JAVA_API_REGISTER_USER, JAVA_API_FORGOT_PASSWORD } from './ApiEndPoints';
import { SET_CURRENT_USER } from './types';

export const registerUser = (payload, callBack) => async (dispatch) => {
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('tempOrmToken'),
      },
    };

    const res = await axios.post('http://192.168.11.13:9095/api/v2.0/user', payload, config);
    callBack(res.data);
  } catch {}
};

export const forgotPassword = (payload, callBack) => async (dispatch) => {
  try {
    //const res = await axios.post(JAVA_API_FORGOT_PASSWORD, payload);
    const res = await axios.post('http://192.168.11.13:9095/api/v2.0/users/password', payload);
    callBack(res.data);
  } catch {}
};

export const getUserV3 = (userId) => async (dispatch) => {
  try {
    const res = await axios.get('/web/user/'+userId+'?toggleKeyToURL='+true);
    if (res.status === 200 && res.data.code == 'UASI0033') {
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data.data,
      });
    }
  } catch {}
};
