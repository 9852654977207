import { INTERLOCK_LIST , INTERLOCK_Device_LIST,INTERLOCK_DEVICE_RULE} from "../actions/types";

const initialState = {
    interlocks: [],
    interlockDevices:[],
    interlockDeviceRule: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INTERLOCK_LIST:
            return {
                ...state,
                interlocks: action.payload
            }
        case INTERLOCK_Device_LIST:
            return {
                ...state,
                interlockDevices: action.payload
            }
         case INTERLOCK_DEVICE_RULE:
            return {
                ...state,
                interlockDeviceRule: action.payload
            }
        default:
            return state;
    }
}