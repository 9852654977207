import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton, Tooltip, Fab } from '@material-ui/core';
import { REACT_URL_TIMEPERIOD, REACT_URL_TIMEPERIOD_ADD } from '../../actions/EndPoints';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Toolbar from '../../components/Toolbar';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterTimePeriod from './FilterTimePeriod';
import DataTable from '../../components/DataTable';
import { tooltip } from 'leaflet';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeZoneIntervalList, deleteScheduleTimeZoneInterval } from './SchedulesService';
import CommonUtil from '../../components/Util/CommonUtils';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddSchedules from './AddSchedules';

export default function Schedules() {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [updateSchedule, setUpdateSchedule] = useState({});
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedData, onSelectionChange] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [schedules, setSchedules] = useState([]);
  const [name, setName] = useState('');

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const breadcrumb = [{ path: '/acc/z/schedules', name: t('SCHEDULES004') }];

  const columns = [
    {
      field: 'operationName',
      title: t('TIMEP013'),
      tooltip: t('tableHeaderTimePeriodName'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
  ];

  const [filter, setFilter] = useState({
    operationName: '',
  });

  const defaultFilter = {
    operationName: '',
  };
  const applyFilter = (filterData) => {
    setName(filterData.name);
    const query = {
      operationName: filterData.operationName,
    };
    setFilter({
      ...filter,
      ...query,
    });
    loadSchedules(0, rowsPerPage, filterData.name);
  };

  const resetFilter = () => {
    setName('');
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadSchedules(0, rowsPerPage, name);
  }, [filter]);

  const actions = [
    (rowData) => ({
      icon: () => <EditOutlinedIcon style={{ color: 'secondary' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        openEditPage(rowData);
      },
      disabled: rowData.operationName === '24-Hours General',
    }),

    (rowData) => ({
      icon: () => <DeleteOutlineIcon color={rowData.operationName === '24-Hours General'?"disable":'secondary'} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
      disabled: rowData.operationName === '24-Hours General',
    }),
  ];

  const openEditPage = (data) => {
    history.push({
      pathname: '/acc/z/schedules-update',
      state: { ...data },
    });
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '60vh',
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const handleChangePage = (page) => {
    setPage(page);
    loadSchedules(page, rowsPerPage, '');
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadSchedules(0, pageSize, '');
  };

  const loadSchedules = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name, (res) => {
      if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.timeZone instanceof Array) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
      } else {
        setTotalRecords(0);
        setPage(0);
      }
      setSchedules(res.data.data.timeZone);
    });
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
    }

    var ids = '';
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ',';
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteScheduleTimeZoneInterval(ids, (res) => {
                  if (res.data.code === 'DMSI0009') {
                    toast.success(t('SCHEDULES011'));
                    loadSchedules(page, rowsPerPage, name);
                  } else {
                    toast.error(res.data.msg);
                  }
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <Button
            size='small'
            variant='contained'
            color='secondary'
            aria-label={t('COMMON002')}
            component={Link}
            to={{
              pathname: '/acc/z/add/schedules',
              page: 'add',
            }}
          >
            {' '}
            {t('SCHEDULES003')}
          </Button>
        </Tooltip>
      </Breadcrumb>

      <Toolbar>
        <Box flexGrow={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>

        {/* <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box> */}

        <Box p={1}>
          <FilterTimePeriod page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={schedules}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
