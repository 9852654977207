import { Box, Grid, Tooltip, IconButton, Fab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { deleteZone, getZones, filterZone } from '../../actions/organization/ZoneApiCalls';
//import EditIcon from '@material-ui/icons/Edit';

import AddZone from './AddZone';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterZone from './FilterZone';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import Breadcrumb from '../../components/Breadcrumb';
import ConfirmModal from '../../components/ConfirmModal';

//import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
// import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';

import { ReactComponent as ViewIcon } from '../../assets/icons/ViewIcon.svg';
import { filterZoneV3 } from './ZoneService';
import { client } from 'stompjs';

export default function Zone() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const isZlink = window.location.toString();
  const zlink = isZlink.includes('/z/');

  const [zoneState, setZoneState] = React.useState([]);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    parentId: '',
    description: '',
    siteId: '',
  });

  const [filter, setFilter] = React.useState({
    name: '',
    parentId: '',
  });

  const defaultFilter = {
    name: '',
    parentId: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      parentId: filterData.parentId,
    };
    setFilter({
      ...filter,
      ...query,
    });
  };
  const resetFilter = () => {
    setPage(0);
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadZones(page, rowsPerPage);
  }, [filter]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.zone.zoneList)) {
      setTotalRecords(store.zone.zoneList.totalCount);
      setPage(store.zone.zoneList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.zone.zoneList.zone);
  }, [store.zone.zoneList]);

  const loadZones = (page, rowsPerPage) => {
    let payload = {
      name: filter.name,
      parentId: filter.parentId,
    };

    filterZoneV3(page + 1, rowsPerPage, payload, (res) => {
      setTotalRecords(res.data.data.totalCount);
      setZoneState(res.data.data.zones);
    });
    onSelectionChange([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadZones(newPage, rowsPerPage);
  };
  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadZones(0, rowsPerPage);
  };

  const openAddZoneDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteZone(idsToDelete, (data) => {
                  handleError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'LMSI0019':
        toast.success(data.message);
        loadZones(page, rowsPerPage);
        break;
      case 'LMSW0001':
        if (data.data.successCount !== 0) {
          toast.success(data.data.successCount + ' ' + t('COMMON048'));
        }
        data = data.data.error;
        data.map(function(name, index) {
          toast.error(data[index].message);
        });
        loadZones(page, rowsPerPage);
        break;
      default:
        toast.error(data.message);
        loadZones(page, rowsPerPage);
        break;
    }
  };

  const breadcrumb = [{ path: '', name: t('ZONE001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },

  ];

  const options = { 
    actionsColumnIndex: -1,
     search: false,
     selection: false, 
     toolbar: false, 
     paging: true, 
     initialPage: 1, 
     pageSize: rowsPerPage ,
     maxBodyHeight: "60vh"
    };

  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  const actionsZlink = [
    {
      icon: () => <ViewIcon color='secondary' />,
      tooltip: t('COMMON472'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        {!zlink && (
          <Tooltip title={t('COMMON009')}>
            <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddZoneDialog}>
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
      </Breadcrumb>

      <Toolbar>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={resetFilter}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box p={1}>
          <FilterZone props={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={zoneState}
        actions={zlink ? actionsZlink : actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      {openEditDialog && (
        <AddZone
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadZones(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
