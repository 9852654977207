import React, { useEffect } from 'react';
import { permissionTree } from './PermissionTree';
import { Box, Checkbox, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as LeftArrowIcon } from '../../assets/RoleAndPermissionIcon/leftArrow.svg';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderRadius: '8px',
  display: 'contents',
  margin: '0px 24px',
  '& .MuiPaper-root-MuiAccordion-root.Mui-expanded(:first-of-type)': { marginTop: '12px !importent' },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#FFFF',
  flexDirection: 'row-reverse',
  borderRadius: '8px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    paddingLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: '#FFFF',
  padding: '0px !importent',
}));
export const PermissionList = (props) => {
  const [expanded, setExpanded] = React.useState('');

  const isChecked = (codes) => {
    var checked = [];
    codes.map((code) => {
      if (props.permissions[code]) checked.push(code);
    });
    return checked.length === codes.length - 1;
  };
  const handleChange = (codes, e) => {
    var newPermissions = { ...props.permissions };
    codes.map((code) => {
      newPermissions[code] = e.target.checked;
    });

    props.setPermissions({
      ...props.permissions,
      ...newPermissions,
    });
  };
  const isExpaded = (title) => {
    return expanded === title ? true : false;
  };
  const accordionTree = () => {
    return (
      <>
        {permissionTree.map((e) => {
          return (
            <Accordion
              expanded={isExpaded(e.parent.title)}
              onChange={() => {
                setExpanded(e.parent.title);
              }}
            >
              <AccordionSummary
                expandIcon={
                  <LeftArrowIcon
                    style={{ transform: 'rotate(180deg)', margin: '0px 8px' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (expanded === e.parent.title) {
                        setExpanded(!expanded);
                      } else {
                        setExpanded(e.parent.title);
                      }
                    }}
                  />
                }
              >
                <FormControlLabel
                  label={e.parent.title}
                  control={
                    <Checkbox
                      disabled={props.disabled}
                      checked={props.role?.code === 'OWNER' ? true : props.permissions[e.parent.codes[0]] || isChecked(e.parent.codes)}
                      onChange={(event) => {
                        handleChange(e.parent.codes, event);
                      }}
                    />
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid ml={4}>
                  {e.child.map((c) => {
                    return (
                      <FormControlLabel
                        label={c.title}
                        control={<Checkbox disabled={props.disabled} value={props.permissions[c.code]} checked={props.role?.code === 'OWNER' ? true : props.permissions[c.code]} />}
                        onChange={(e) => {
                          props.setPermissions({ ...props.permissions, [c.code]: e.target.checked });
                        }}
                      />
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  };

  return <Grid style={{ margin: '12px', padding: '12px 0px' }}>{accordionTree()}</Grid>;
};
