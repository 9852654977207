import { GET_ACCESS_EMPLOYEE_LIST, SUCCESS,GET_PERSON_PRIMARY_LIST} from "../actions/types";

const initialState = {
    employeeList: [],
    isSuccess: false,
    employeePrimaryList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ACCESS_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: action.payload
            };
            case GET_PERSON_PRIMARY_LIST:
            return {
                ...state,
                employeePrimaryList: action.payload
            };
        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

        default:
            return state;
    }
}