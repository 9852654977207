import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import {useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import CommonUtil from '../../components/Util/CommonUtils';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { updateReader } from './ReaderService';
import { toast } from 'react-toastify';

export default function EditReader(props) {
  
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);
  const [reader, setReader] = React.useState(props.reader);
  const [state, setState] = React.useState({
    id: props.reader.readerId,
    name: props.reader.name,
    readerState: '',
  });

  const [error, setError] = React.useState({
    name: false,
  });

  useEffect(() => {
    setOpen(props.open);
    setReader(props.reader);
    setState({
      id: props.reader.id,
      name: props.reader.name,
      readerState: props.reader.readerState,
    });
  }, [props.open, props.reader]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    let updateObject={
      commAddress: props.reader.commAddress,
      commType : props.reader.commType,
      extDevId: props.reader.extDevId,
      ip: props.reader.ip,
      mac:props.reader.mac,
      multicast:props.reader.multicast,
      name:state.name,
      port:props.reader.port,
      readerEncrypt:props.reader.readerEncrypt,
      readerNo:props.reader.readerNo,
      readerState: props.reader.readerState,
      doorId: props.reader.doorId,
      readerId: props.reader.readerId,
      address: props.reader.address,
      devId: props.reader.devId,
      value: props.reader.value,
      offlineRefuse: props.reader.offlineRefuse,
      disable:props.reader.disable,
      doorNum:props.reader.doorNum,
    }
    let id=props.reader.readerId;
      updateReader(id,updateObject, (data) => {
       if(data.data.code==='DMSI0000'){
        toast.success(data.data.msg);
        handleClose();
       }
      })
  };

  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('READER002')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.name}
                    name='name'
                    label={t('COMMON014') + ' *'}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={reader.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('DOORI003') + '*'} helperText='' variant='outlined' size='small' defaultValue={reader.doorId} />
                </Box>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('COMMON011')} helperText='' variant='outlined' size='small' defaultValue={reader.readerNo} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('READER003')} variant='outlined' size='small' defaultValue={state.readerState === 0 ? 'In' : 'Out'} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
