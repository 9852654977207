import React, { useState, useEffect } from 'react';
import { activateEmployee, deletePerson, filterPerson, getPersons } from '../../actions/organization/PersonApiCalls';
import { useDispatch } from 'react-redux';
import Headerbar from '../../components/HeaderBar';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import DataTable from '../../components/DataTable';
import Pagination from '../../components/Pagination';
import UpdatePerson from './UpdatePerson';
import CommonUtil from '../../components/Util/CommonUtils';
import { useSelector } from 'react-redux';
import { Box, Fab, Grid, IconButton, Link, styled, Typography } from '@material-ui/core';
import Toolbar from '../../components/Toolbar';
import AddPerson from './AddPerson';
import Avatar from '@mui/material/Avatar';
import FilterPerson from './FilterPerson';
import Breadcrumb from '../../components/Breadcrumb';
// import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
// import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import { ReactComponent as ViewIcon } from '../../assets/icons/ViewIcon.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { REACT_URL_PERSON_UPDATE } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';
import { loadOrgPersons } from './PersonService';
import { CardHeader } from '@mui/material';
import AvatarCustom from '../../utils/AvatarCustom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


export default function Person() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const breadcrumb = [{ path: '', name: t('PERSON001') }];
  const isZlink = window.location.toString();
  const zlink = isZlink.includes('/z/');
  const [state, setState] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    code: '',
    email: '',
    mobile: '',
    personName: '',
    designationName: '',
  });

  const [filter, setFilter] = useState({
    firstName: '',
    lastName: '',
    // employeeCode: '',
    code:'',
    email: '',
    phone: '',
  });

  const defaultFilter = {
    firstName: '',
    lastName: '',
    //employeeCode: '',
    code:'',
    email: '',
    phone: '',
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:'#cad0d9',
      color:'#000000',
      maxWidth: 500,
    },
  });
  const [personState,setPersonState] = React.useState([])

  const applyFilter = (filterData) => {
    if (!CommonUtil.isEmptyString(filterData.phone)) {
      filterData.phone = filterData.phone.replace('+', '');
      filterData.phone = '%2B' + filterData.phone;
    }
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      // employeeCode: filterData.employeeCode,
      code: filterData.code,
      email: filterData.email,
      phone: filterData.phone,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setPage(0);
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadPersons(page,rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  // useEffect(() => {
  //   if (!CommonUtil.isEmpty(store.person.personList)) {
  //     setTotalRecords(store.person.personList.totalCount);
  //     setPage(store.person.personList.currentPage - 1);
  //   } else {
  //     setTotalRecords(0);
  //     setPage(0);
  //   }
  //   setData(store.person.personList.employee);
  // }, [store.person.personList]);

  const loadPersons = (page,rowsPerPage) => {

    let payload = {
      firstName: filter.firstName,
      lastName: filter.lastName,
      code: filter.code,
      email: filter.email,
      phone: filter.phone

    }
    // dispatch(filterPerson(filter.firstName, filter.lastName, filter.employeeCode, filter.email, filter.phone, page + 1, rowsPerPage));
    loadOrgPersons(page + 1, rowsPerPage, payload ,(res)=>{
      setTotalRecords(res.data.data.totalCount)
      setPersonState(res.data.data.person)
    })

    onSelectionChange([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadPersons(newPage,rowsPerPage)
    //dispatch(filterPerson(filter.firstName, filter.lastName, filter.employeeCode, filter.email, filter.phone, newPage + 1, rowsPerPage));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadPersons(0,pageSize)
    //dispatch(filterPerson(filter.firstName, filter.lastName, filter.employeeCode, filter.email, filter.phone, 1, pageSize));
  };

  const openAddEmployeeDialog = () => {
    setOpenAddDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var error = '';
    var idsToDelete = [];
    data.map((record, index) => {
      if (!CommonUtil.isEmptyString(record.userId)) {
        // error = error + record.employeeCode;
        error = error + record.code;
      } else {
        idsToDelete[index] = record.id;
      }
    });
    if (data.length === 1 && !CommonUtil.isEmptyString(error)) {
      toast.error(t('PERSON044'));
      return;
    }
    if (data.length > 1 && !CommonUtil.isEmptyString(error)) {
      toast.warning(t('PERSON045'));
    }
    var str = idsToDelete.join(',');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deletePerson(str, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    if (data.code == 'OMSI0008' || data.code == 'OMSW0014') {
      toast.success(t('COMMON198'));
      loadPersons(page,rowsPerPage);
    }
    // switch (data.code) {
    //   case 'OMSI0008':
    //     toast.success(data.message);
    //     loadPersons();
    //     break;
    //   default:
    //     toast.error(data.message);
    //     break;
    // }
  };

  const activatePerson = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var personsToActivate = [];
    data.map((record, index) => {
      let person = {
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        phone: record.phone,
        userId: record.userId,
        companyCode: store.security.user.companyCode,
        // employeeCode: record.employeeCode,
        code: record.code,
      };
      personsToActivate[index] = person;
    });

    dispatch(
      activateEmployee(personsToActivate, (successData) => {
        if (successData.code === 'CAAI3003') {
          toast.success(successData.message);
        } else if (successData.code === 'CAAW0001') {
          toast.warning(successData.message);
        } else {
          toast.error(successData.message);
        }
        loadPersons(page,rowsPerPage);
      })
    );
  };

  const handlePersonName = (client) => {
    let person = {
      ...client,
      disable: true,
    };
    setUpdateObject(person);

    history.push({
      pathname: '/acc/z/view-person',
      state: { selectedObject: person, open: true },
    });
  };

  const columns = [
   

    {
      render: (client) => {
        const firstName = client.firstName === undefined ? '' : client.firstName;
        const lastName = client.lastName === undefined ? '' : client.lastName;
        const code = client.code === undefined ? '' : client.code;
        const person = firstName + '\n' + lastName + ' (' + code + ')';
        return (
          <div onClick={(data) => handlePersonName(client)}>
            <CustomWidthTooltip title={client.firstName+" "+client.lastName+"("+code+")"} placement="bottom-start">
            <CardHeader
            style={{height:'10px'}}
            avatar={
              <Avatar sx={{ bgcolor: '#C0C0C0', marginRight: '8px' }} alt={client.firstNamee} src={client.profilePhotoURL}></Avatar>
          }
          title={<Link >
            <Typography style={{whiteSpace:'nowrap', width:'430px',overflow:'hidden',textOverflow:'ellipsis'}}>
            {person}
            </Typography>
          </Link>}
            />
            </CustomWidthTooltip>
            
          </div>
        );
      },
      title: t('personName'),
      sorting: false,
      editable: 'never',
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        openEditPage(rowData);
      },
    },
    {
      render: (client) => {
        const email = client.email === undefined ? '' : client.email;
        const phone = client.phone === undefined ? '' : client.phone;
        return email + '\n' + phone;
      },
      title: t('PERSON014'),
      editable: 'never',
      sorting: false,
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
    },
    // {
    //   field: 'departmentName',
    //   title: t('PERSON015'),
    //   sorting: false,
    //   cellStyle: {
    //     minWidth: 200,
    //     maxWidth: 200,
    //   },
     

    // },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const actions = [
    !zlink
      ? {
        icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
        tooltip: t('COMMON005'),
        position: 'row',
        onClick: (event, rowData) => {
          setUpdateObject(rowData);
          openEditPage(rowData);
        },
      }
      : {
        icon: () => <ViewIcon style={{ color: '#3d4977' }} />,
        tooltip: t('COMMON463'),
        position: 'row',
        onClick: (event, rowData) => {
          handlePersonName(rowData);
        },
      },

    // {
    //   icon: () => <MailOutlineIcon style={{ color: '#3d4977' }} />,
    //   tooltip: t('PERSON043'),
    //   position: 'row',
    //   onClick: (event, rowData) => {
    //     activatePerson([rowData]);
    //   },
    // },
    !zlink && {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  const openEditPage = (data) => {
    history.push({
      pathname: '/acc/edit-person',
      state: { selectedObject: data, open: openEditDialog },
    });
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        {(store.security.user.client_id === '38c42726ab234fcda88ee9dc418637a0' || totalRecords < 200) && !zlink && (
          <Tooltip title={t('COMMON009')}>
            <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddEmployeeDialog}>
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON009')} onClick={resetFilter}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1}>
          <Tooltip title={t('PERSON043')}>
            <IconButton color='secondary' aria-label={t('COMMON009')} onClick={() => activatePerson(selectedData)}>
              <MailOutlineIcon style={{ color: '#3d4977' }} />
            </IconButton>
          </Tooltip>
        </Box> */}

        <Box p={1} flexGrow={1}>
          {!zlink && (
            <Tooltip title={t('COMMON004')}>
              <IconButton color='secondary' aria-label={t('COMMON009')} onClick={() => deleteRecord(selectedData)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box p={1}>
          <FilterPerson props={state} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={personState}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openAddDialog && (
        <AddPerson
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false);
            loadPersons(page,rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
