import { Grid, Link, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Headerbar from '../../components/HeaderBar';
import CommonUtil from '../../components/Util/CommonUtils';
import Stripe from '../../assets/cloudaccess-png/StripeLogo.png';
import AllPay from '../../assets/cloudaccess-png/AllPay.png';
import truemoney from '../../assets/cloudaccess-png/เช็คการโอน-ทรูวอลเล็ต.png';
import paytm from '../../assets/cloudaccess-png/PaytmLogo.png';
import paypal from '../../assets/cloudaccess-png/PaypalLogo.png';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb';
import { crateSubscritpionApi } from './SubscriptionService';
import { useHistory, useLocation } from 'react-router-dom';
import { REACT_URL_SUBSCRIPTION_ORDERS } from '../../actions/EndPoints';
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import { Payment } from '@material-ui/icons';

export default function CreateSubscription(props) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const location = useLocation();
  const history = useHistory();
  const [payment, setPayment] = useState();
  const breadcrumb = [
    { path: '/acc/z/Subscription', name: t('Subscription and Service') },
    {
      path: '',
      name: t('Payment'),
    },
  ];
  const breadcrumb1 = [{ path: '', name: t('Subscription Details') }];

  const compId = localStorage.getItem('companyId');
  const [state, setState] = React.useState({
    type: 1,
    period: 3,
    price_strategy: location.state !== null && location.state?.id,
    company_id: compId,
  });

  const subscribe = () => {
    crateSubscritpionApi(state, (res) => {
      if (res.data.code === '201' && !CommonUtil.isEmpty(res.data.data)) {
        gotoSubscriptionHistory();
      } else if (res.data.code === 'CACCZE005') {
        toast.error('Free trial already subscribed.');
      } else if (res.data.code === 'CACCZE006') {
        toast.error('This company had an unpaid subscription, Please continue to pay or cancel it before creating a new one.');
      } else {
        setData([]);
      }
    });
  };

  const gotoSubscriptionHistory = () => {
    history.push({ pathname: REACT_URL_SUBSCRIPTION_ORDERS });
  };
  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
  };
  return (
    <>
      <Breadcrumb links={breadcrumb} />
      <Grid>
        <Paper style={{ backgroundColor: '#FFFF' }}>
          <Breadcrumb links={breadcrumb1} />
          <Box justifyContent={'space-between'} display={'flex'} paddingBottom={'1%'}>
            <ul>
              <Box display={'flex'}>
                <Typography color={'#C0C0C0'} marginRight='10px'>
                  Feature package :{' '}
                </Typography>
                <Typography marginLeft={'60px'} fontWeight={500}>
                  {location.state !== null && location.state?.name}
                </Typography>
              </Box>
              <Box display={'flex'} margin='10px 0px'>
                <Typography color={'#C0C0C0'} marginRight='10px'>
                  Plan Period :{' '}
                </Typography>
                {location.state !== null && location.state?.name !== 'trail' && location.state !== null && location.state?.name !== 'Free' ? (
                  <>
                    <Typography marginLeft={'100px'} fontWeight={500}>
                      {location.state !== null && location.state?.feature[0].type_of_quota}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
                {location.state !== null && location.state?.name !== 'Pro plan' && location.state !== null && location.state?.name !== 'Plus plan' ? (
                  <>
                    <Typography marginLeft={'100px'} fontWeight={500}>
                      {3}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </Box>
              <Box display={'flex'}>
                <Typography color={'#C0C0C0'} marginRight='10px'>
                  Subscription Price :
                </Typography>
                <Typography marginLeft={'50px'} fontWeight={500}>
                  {location.state?.monthly_price ? location.state?.monthly_price : location.state?.annual_price}
                </Typography>
              </Box>
            </ul>
          </Box>
        </Paper>
        <Paper>
          <Box marginTop={'1.8%'} marginLeft={'10px'} padding={'2%'}>
            <Typography color={'#C0C0C0'}>You will get the services as below :</Typography>
            <Typography color={'#C0C0C0'}>cloud access</Typography>

            <Box marginTop={'1%'}>
              {location.state !== null && location.state?.name !== 'Pro plan' && location.state !== null && location.state?.name !== 'Plus plan' ? (
                <>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[3].name}
                  </Typography>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[1].type_of_quota}
                  </Typography>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[0].name}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Box>

            {location.state !== null && location.state?.name !== 'trail' && location.state !== null && location.state?.name !== 'Free' ? (
              <>
                <Box marginTop={'1%'}>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[0].name}
                  </Typography>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[1].name}
                  </Typography>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[2].name}
                  </Typography>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[3].name}
                  </Typography>
                  <Typography marginLeft={'35px'}>
                    <CheckIcon style={{ color: '#36C96D', fontSize: '20px' }} />
                    {location.state !== null && location.state?.feature[4].name}
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Paper>
        <Grid mt={2}>
          <Paper>
            <Grid padding={'1rem'}>
              <Typography fontSize={'16px'} color={'#304669'} mt={1}>
                You will pay with :
              </Typography>
              <Grid container direction='row' alignItems='center' mr={2} padding='12px'>
                <Box marginTop={'1%'} marginLeft={'10px'} width={500} justifyContent={'space-between'} display={'flex'} alignItems='center'>
                  <Box>
                    <Box
                      style={{ borderStyle: 'solid', borderColor: payment === 'trueMoney' ? '#36C96D' : '#DDDDDD', borderWidth: '2px', marginRight: '10px', marginTop: '3px' }}
                      onClick={() => setPayment('trueMoney')}
                    >
                      <img src={truemoney} />
                    </Box>
                    <Typography style={{ color: '#304669', fontWeight: 'bold' }} mt={1.5} pl={0.2}>
                      TrueMoney
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      style={{
                        borderStyle: 'solid',
                        borderColor: payment === 'stripe' ? '#36C96D' : '#DDDDDD',
                        borderWidth: '2px',
                        paddingBottom: '9px',
                        paddingTop: '7px',
                        marginRight: '10px',
                        marginTop: '10px',
                      }}
                      onClick={() => setPayment('stripe')}
                    >
                      <img src={Stripe} />
                    </Box>
                    <Typography style={{ color: '#304669', fontWeight: 'bold' }}>
                      Stripe
                      <br /> Payments
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      style={{ borderStyle: 'solid', borderColor: payment === 'paytm' ? '#36C96D' : '#DDDDDD', borderWidth: '2px', padding: '9px', marginRight: '10px' }}
                      onClick={() => setPayment('paytm')}
                    >
                      <img src={paytm} />
                    </Box>
                    <Typography style={{ color: '#304669', fontWeight: 'bold' }} mt={1.5} pl={0.2}>
                      Paytm
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      style={{ borderStyle: 'solid', borderColor: payment === 'paypal' ? '#36C96D' : '#DDDDDD', borderWidth: '2px', paddingBottom: '20px', paddingTop: '20px' }}
                      onClick={() => setPayment('paypal')}
                    >
                      <img src={paypal} style={{ width: '56px' }} />
                    </Box>
                    <Typography style={{ color: '#304669', fontWeight: 'bold' }} mt={1.5} pl={0.2}>
                      Paypal
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'end', margin: '12px 0px', alignItems: 'center' }}>
          <Link marginTop={'2%'} style={{ margin: '0px 10px', borderBlockColor: '#36C96D', color: '#36C96D' }} onClick={() => subscribe()}>
            Save order List
          </Link>
          <Button onClick={() => subscribe()} style={{ width: '240px', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF', height: '50px', marginRight: '24px' }}>
            Confirm
          </Button>
          <Button
            variant='outlined'
            onClick={() => history.goBack()}
            style={{ width: '240px', backgroundColor: 'white', color: 'rgb(54, 201, 109)', borderColor: 'rgb(54, 201, 109)', height: '50px', marginRight: '16px' }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
