import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import Button from '@mui/material/Button';
import { updateReader } from '../reader/ReaderService';
import { toast } from 'react-toastify';

export default function DeviceTopologyReaderInformartion(props) {
  const { t } = useTranslation();
  const selectedSite = useSelector((state) => state.deviceTopology.selectedSite);
  const selectedZone = useSelector((state) => state.deviceTopology.selectedZone);
  const selectedDevice = useSelector((state) => state.deviceTopology.selectedDevice);
  const selectedSubDevice = useSelector((state) => state.deviceTopology.selectedSubDevice);

  const selectedReader = useSelector((state) => state.deviceTopology.selectedDeviceReader);
  const dispatch = useDispatch();
  const defaultState = {
    name: '',
    door: '',
    number: '',
    commType: '',
    commAddress: '',
    readerState: '',
  };
  const [state, setState] = React.useState({ name: '', ...selectedReader });
  const [open, setOpen] = React.useState(true);

  const [update, setUpdate] = React.useState(false);

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedReader)) {
      return loadReaderInformation();
    }

    if (!CommonUtil.isEmpty(selectedSite) || !CommonUtil.isEmpty(selectedZone) || !CommonUtil.isEmpty(selectedDevice)) {
      setOpen(false);
      return;
    } else {
      setOpen(false);
      dispatch({ type: 'RESET_TOPOLOGY', payload: {} });
    }
  }, [selectedSite, selectedZone, selectedDevice]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedReader)) {
      setState({
        ...selectedReader,
      });
    }
  }, [selectedReader]);

  useEffect(() => {
    setUpdate(props.open);
  }, [props.open]);

  const loadReaderInformation = () => {
    setState('');
    setOpen(true);
  };

  const handleSubmit = () => {
    let updateObject = {
      commAddress: selectedReader.commAddress,
      commType: selectedReader.commType,
      extDevId: selectedReader.extDevId,
      ip: selectedReader.ip,
      mac: selectedReader.mac,
      multicast: selectedReader.multicast,
      name: state.name,
      port: selectedReader.port,
      readerEncrypt: selectedReader.readerEncrypt,
      readerNo: selectedReader.readerNo,
      readerState: selectedReader.readerState,
      doorId: selectedReader.doorId,
      readerId: selectedReader.readerId,
      address: selectedReader.address,
      devId: selectedReader.devId,
      value: selectedReader.value,
      offlineRefuse: selectedReader.offlineRefuse,
      disable: selectedReader.disable,
      doorNum: selectedReader.doorNum,
    };
    let id = selectedReader.readerId;
    updateReader(id, updateObject, (data) => {
      if (data.data.code === 'DMSI0000') {
        toast.success(data.data.msg);
        handleClose();
        setUpdate(true);
        props.applyUpdate(true);
      } else {
        toast.error(data.data.msg);
      }
    });
  };

  const handleClose = () => {
    props.toggleUpdate();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      {open && (
        <Box sx={{ flexWrap: 'wrap' }}>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={4}>
              <Box mb={1} mt={2} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('COMMON044')}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
              <Typography>{state.name}</Typography>
                {/* <TextField autoComplete='off' fullWidth name='name' disabled={update} value={state.name} onChange={handleChange} size='small' /> */}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DOORI000')}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedReader.doorName}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('COMMON060')}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedReader.doorNum}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('COMMON012')}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedReader.commType}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('COMMON013')}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedReader.commAddress}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4} mb={3}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('COMMON063')}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8} mb={3}>
              <Box>
                <Typography>{selectedReader.readerState == 1 ? 'Out' : 'In'}</Typography>
              </Box>
            </Grid>

            {/* <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
              <Box component={'span'}>
                <Button variant='contained' fullWidth={true} size='small' sx={{ backgroundColor: 'rgb(54, 201, 109)' }} disabled={update} onClick={handleSubmit}>
                  {t('save')}
                </Button>
              </Box>
              <Box component={'span'}>
                <Button
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  disabled={update}
                  sx={{ border: '2px solid rgb(54, 201, 109)', color: 'rgb(54, 201, 109)', marginLeft: '2px' }}
                  onClick={handleClose}
                >
                  {' '}
                  {t('COMMON008')}
                </Button>
              </Box>
            </Box> */}
          </Grid>
        </Box>
      )}
    </>
  );
}
