import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    const { loading } = this.props;
    if (!loading) return null;
    return (
      <div className='loader-container'>
        <div className='loader'>
          <CircularProgress>{this.props.children}</CircularProgress>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loader.loading,
});

export default connect(mapStateToProps)(Loader);
