import axios from 'axios';
import { JAVA_API_ZONE, JAVA_API_ZONE_SEARCH } from './ApiEndPoints';
import { ZONE_LIST } from './types';

export const getZones = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_ZONE  + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      if (isPageable) {
        dispatch({
          type: ZONE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: ZONE_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const updateZone = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_ZONE, [payload]);
    } else {
      res = await axios.put(JAVA_API_ZONE + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch {}
};

export const deleteZone = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.ids = ids;

    const res = await axios.delete(JAVA_API_ZONE, { data: deleteObj });
    callBack(res.data);
  } catch {}
};

export const filterZone = (name, parentId, page, size) => async (dispatch) => {

  try {
    const res = await axios.get(JAVA_API_ZONE_SEARCH + '?page=' + page + '&size=' + size + '&name=' + name + '&parentId=' + parentId);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      dispatch({
        type: ZONE_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: ZONE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const loadZoneBySite = (siteId, callback) => async (dispatch) => {
  try {
    const query = '&siteId=' + siteId;
    const res = await axios.get(JAVA_API_ZONE_SEARCH + '?page=' + 0 + '&size=' + 50 + query);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      callback(res.data.data);
    }
  } catch {}
};
