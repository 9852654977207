import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DataTable from '../../components/DataTable';
import { filterPerson } from '../../actions/organization/PersonApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { addPersonsToAccessGroup } from './AccessGroupService';
import { toast } from 'react-toastify';
import FilterPerson from '../Person/FilterPerson';
import Toolbar from '../../components/Toolbar';
import CachedIcon from '@material-ui/icons/Cached';
import { loadOrgPersons } from '../Person/PersonService';

export default function AddAccessGroupPerson() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRecords, onSelectionChange] = React.useState([]);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const breadcrumb = [
    { path: '/acc/z/access-group', name: t('ACCESSGROUP005') },
    { path: '/acc/z/access-group-add-person', name: t('PERSON002') },
  ];

  const columns = [
    {
      field: 'firstName',
      title: t('COMMON020'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'white',
      },
    },
    { field: 'lastName', title: t('COMMON021'), editable: 'never' },
    // { field: 'employeeCode', title: t('COMMON045'), editable: 'never' },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        alert([rowData]);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadPersons(newPage, rowsPerPage)
   // dispatch(filterPerson(filter.firstName, filter.lastName, filter.employeeCode, filter.email, filter.phone, newPage + 1, rowsPerPage));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadPersons(0, pageSize)
    //dispatch(filterPerson(filter.firstName, filter.lastName, filter.employeeCode, filter.email, filter.phone, 1, pageSize));
  };

  const [state, setState] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    code: '',
    email: '',
    mobile: '',
    personName: '',
    designationName: '',
  });

  const [filter, setFilter] = useState({
    firstName: '',
    lastName: '',
    // employeeCode: '',
    code: '',
    email: '',
    phone: '',
  });

  const defaultFilter = {
    firstName: '',
    lastName: '',
    // employeeCode: '',
    code: '',
    email: '',
    phone: '',
  };

  const [personState, setPersonState] = React.useState([])


  const applyFilter = (filterData) => {
    if (!CommonUtil.isEmptyString(filterData.phone)) {
      filterData.phone = filterData.phone.replace('+', '');
      filterData.phone = '%2B' + filterData.phone;
    }
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      // employeeCode: filterData.employeeCode,
      code: filterData.code,
      email: filterData.email,
      phone: filterData.phone,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setPage(0);
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadPersons(page,rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  // useEffect(() => {
  //   if (!CommonUtil.isEmpty(store.person.personList)) {
  //     setTotalRecords(store.person.personList.totalCount);
  //     setPage(store.person.personList.currentPage - 1);
  //   } else {
  //     setTotalRecords(0);
  //     setPage(0);
  //   }
  //   setData(store.person.personList.employee);
  // }, [store.person.personList]);

  const loadPersons = (page, rowsPerPage) => {

    let payload = {
      firstName: filter.firstName,
      lastName: filter.lastName,
      code: filter.code,
      email: filter.email,
      phone: filter.phone

    }
    // dispatch(filterPerson(filter.firstName, filter.lastName, filter.employeeCode, filter.email, filter.phone, page + 1, rowsPerPage));
    loadOrgPersons(page + 1, rowsPerPage, payload, (res) => {
      setTotalRecords(res.data.data.totalCount)
      // setPage(res.data.data.currentPage);
      setPersonState(res.data.data.person)
    })

    onSelectionChange([]);
  };

  const handleAddPersonToAccessGroup = () => {
    if (selectedRecords.length < 1) {
      toast.error('Please select the persons to add');
      return;
    } else {
      var persons = [];
      selectedRecords.map((person) => {
        persons.push(person.id);
      });
      var payload = {
        persons: persons,
      };
      addPersonsToAccessGroup(location.state.id, payload, (res) => {
        if (res.data.code === 'DMSI0000') {
          toast.success(t('ACCESSGROUP013'));
          history.goBack();
        } else {
          if (res.data.data.error && res.data.data.error instanceof Array && res.data.data.error.length > 0 && res.data.data.error.length === selectedRecords.length) {
            toast.error(res.data.data.error[0].msg);
          } else {
            toast.warning(res.data.msg);
            history.goBack();
          }
        }
      });
    }
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Box p={1}>
          <FilterPerson props={state} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON009')} onClick={resetFilter}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={personState}
        actions={[]}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      <Grid container direction='row' justifyContent='flex-end' alignItems='flex-start'>
        <Box pt={1} pr={1}>
          <SubmitButton onClick={() => handleAddPersonToAccessGroup(selectedRecords)} color='primary' variant='contained' size='large'>
            {t('COMMON007')}
          </SubmitButton>
        </Box>
        <Box pt={1}>
          <CancelButton
            onClick={() => {
              history.goBack();
            }}
            color='primary'
            variant='contained'
            size='large'
          >
            {t('COMMON008')}
          </CancelButton>
        </Box>
      </Grid>
    </Grid>
  );
}
