import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, MenuItem, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { REACT_URI_SITE } from '../../actions/organization/EndPoints';
import Breadcrumb from '../../components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CommonUtil from '../../components/Util/CommonUtils';
import { countryList } from '../../components/Util/CountryList';
import { cityData, stateData } from './AddressFormData';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { getCompanyProfile } from '../../actions/ProfileApiCalls';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { siteBySiteIdV2 } from './SiteService';
import { timeZoneList } from './TimeZone';
import { DialogContent, Paper } from '@mui/material';
import TitleBar from '../../components/v4/TitleBar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import OverFlowText from '../accessgroup/OverFlowText';

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewSite() {
  const store = useSelector((state) => state);
  const location = useLocation();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState(cityVal);
  const [timeZones, setTimeZones] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [addressType, setAddressTypes] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const history = useHistory();

  const cityVal = [
    { id: 1, name: 'Bangalore' },
    { id: 2, name: 'Mysore' },
  ];
  const breadcrumb = [
    { path: REACT_URI_SITE, name: t('SITE001') },
    { path: '/acc/z/site-form', name: location.state.disable ? t('SITE001') : t('SITE008') },
  ];

  const [sitePayload, setSitePayload] = useState({
    id: '',
    name: '',
    description: '',
    parentId: '',
    siteTypeId: '',
  });
  const [addressPayload, setAddressPayload] = useState({
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    employeeId: '',
    userId: '',
    siteId: '',
  });
  const [error, setError] = React.useState({
    name: false,
    description: false,
    parentId: false,
    siteTypeId: false,
    addressLine1: false,
    country: false,
    state: false,
    city: false,
    zipCode: false,
    latitude: false,
    longitude: false,
    radius: false,
    timezone: false,
    addressTypeId: false,
  });
  useEffect(() => {
    if (!CommonUtil.isEmpty(location.state)) {
      setSitePayload({
        ...sitePayload,
        id: location.state.id,
        name: location.state.name,
        description: location.state.description,
        parentId: location.state.parentId,
        siteTypeId: location.state.siteTypeId,
      });
      if (!CommonUtil.isEmpty(location.state.address)) {
        var address = location.state.address;
        if (CommonUtil.isEmptyString(address.country)) {
          dispatch(
            getCompanyProfile((data) => {
              if (!CommonUtil.isEmptyString(data.country)) {
                setAddressPayload({
                  ...address,
                  country: data.country,
                });
              } else {
                address.country = CommonUtil.getLocationDetails('country_name');
              }
            })
          );
        }
        setAddressPayload({
          ...address,
        });
      } else {
        if (!CommonUtil.isEmpty(location.state.id)) {
          siteBySiteIdV2(location.state.id, (data) => {
            if (!CommonUtil.isEmpty(data.data.code) && !CommonUtil.isEmpty(data.data.data.address)) {
              setAddressPayload(data.data.data.address);
            }
          });
        }
      }
    }
  }, [location.state]);

  useEffect(() => {
    setCountries(countryList);
    setSelectStates(stateData);
    setCities(cityData);
  }, []);
  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.siteError)) {
      const { siteError } = store.errors;
      switch (siteError.code) {
        case 'LMSE0025':
          setError({ name: true });
          break;
        case 'LMSE0026':
          setError({ siteTypeId: true });
          break;
        case 'LMSE0037':
          setError({ city: true });
          break;
        default:
      }
    }
  }, [store.errors.siteError]);

  const divStyle = {
    minHeight: '70vh',
    paddingTop: 20,
    paddingLeft: 30,
    backgroundColor: '#cccc', // camel cased
    height: '100px',
    // color: 'yellow'
  };

  const getTimezoneName = (value) => {
    var name = '';
    timeZoneList.map((t) => {
      if (value == t.value) {
        name = t.name;
      }
    });
    return name;
  };

  return (
    <>
      <TitleBar title='View Site Details' path={true} onClick={() => history.push({ pathname: REACT_URI_SITE, state: { index: 0 } })}></TitleBar>

      <Paper
        elevation={0}
        style={{
          minHeight: '230px',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Grid
          container
          direction='row'
          style={{
            marginRight: 10,
            paddingLeft: 50,
          }}
          // justifyContent='space-evenly'
          alignItems='flex-start'
        >
          <Grid item lg={3.5} padding={1} style={{ paddingRight: 50 }}>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('COMMON044')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={sitePayload.name}>
                  {sitePayload.name}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('COMMON189')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.country}>
                  {addressPayload.country}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('ADD004')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.state}>
                  {addressPayload.state}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('ADD005')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.city}>
                  {addressPayload.city}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('ADD006')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.areaCode}>
                  {' '}
                  {addressPayload.areaCode}
                </OverFlowText>
              </Box>
            </Box>
          </Grid>
          <Grid item paddingLeft='15px'>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('ADD010')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={getTimezoneName(addressPayload.timeZone)}>
                  {' '}
                  {getTimezoneName(addressPayload.timeZone)}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('ADD001')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.addressLine1}>
                  {' '}
                  {addressPayload.addressLine1}
                </OverFlowText>
              </Box>
            </Box>

            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('ADD007')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.latitude == '0.0' ? '' : addressPayload.latitude}>
                  {addressPayload.latitude == '0.0' ? '' : addressPayload.latitude}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('ADD008')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.longitude == '0.0' ? '' : addressPayload.longitude}>
                  {addressPayload.longitude == '0.0' ? '' : addressPayload.longitude}
                </OverFlowText>
              </Box>
            </Box>

            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('ADD009')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressPayload.radius == '0.0' ? '' : addressPayload.radius}>
                  {' '}
                  {addressPayload.radius == '0.0' ? '' : addressPayload.radius}
                </OverFlowText>
              </Box>
            </Box>

            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'> {t('description')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={sitePayload.description}>
                  {sitePayload.description}
                </OverFlowText>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
