import { Box } from '@material-ui/core';
import React from 'react';


export default function Toolbar(props) {

    return (
        <div style={{ width: '100%' }}>
            <Box display="flex" bgcolor="background.paper">
                {props.children}
            </Box>
        </div>
    )
}