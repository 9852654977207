export const PERSONNEL_MANAGEMENT_UI_URL = process.env.REACT_APP_ORG_UI_URI;
//export const CLOUD_ACCESS_UI_URL = process.env.REACT_APP_ACCESS_SERVICE_URI;
// export const PERSONNEL_MANAGEMENT_UI_URL = 'http://localhost:9091';

export const JAVA_API_LOGIN = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/authenticate';

export const JAVA_API_DEPARTMENT = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/department';
export const JAVA_API_DEPARTMENT_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/department/search';

export const JAVA_API_DESIGNATION = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/designation';
export const JAVA_API_DESIGNATION_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/designation/search';

export const JAVA_API_REGISTER_USER = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/user';
export const JAVA_API_MEMBERSHIP = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/membership';

export const JAVA_API_FORGOT_PASSWORD = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/users/password';

//export const JAVA_API_COMPANY = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/company';
export const JAVA_API_COMPANY = '/onboard/company';

export const JAVA_API_SITE_TYPE = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/siteType';
export const JAVA_API_SITE_TYPE_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/siteType/search';

export const JAVA_API_ADDRESS_TYPE = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/addressType';
export const JAVA_API_ADDRESS_TYPE_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/addressType/search';

export const JAVA_API_ZONE = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/zone';
export const JAVA_API_ZONE_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/zone/search';

export const JAVA_API_SITE = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/site';
export const JAVA_API_SITE_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/site/search';

export const JAVA_API_PERSON = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/employee';
export const JAVA_API_PERSON_SEARCH = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/emp';

export const JAVA_API_ADDRESS = PERSONNEL_MANAGEMENT_UI_URL + '/api/v2.0/address';

export const JAVA_API_PERSON_ACTIVATION = '/api/v2.0/user/activate';

export const JAVA_API_PERSON_DELETE = '/api/v2.0/emp';

export const JAVA_API_SITE_DELETE = '/api/v2.0/level_device/site';

//Site V3 API's

export const JAVA_API_SITE_LIST_V3 = '/web/location/site/filter';

export const JAVA_API_ADDRESS_V2 = '/api/v2.0/address/';

export const JAVA_API_DEPARTMENT_FILTER_LIST_V3='/web/organization/department/filter';

export const JAVA_API_DESIGNATION_FILTER_LIST_V3='/web/organization/designation/filter';

