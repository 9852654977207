import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import DeviceTopologySiteList from './DeviceTopologySiteList';
import DeviceTopologyZoneList from './DeviceTopologyZoneList';
import DeviceTopologyDeviceList from './DeviceTopologyDeviceList';
import { Typography, Divider, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeviceTopologySubDeviceList from './DeviceTopologySubDeviceList';
import DeviceTopologyAuxInInformartion from './DeviceTopologyAuxInInformartion';
import DeviceTopologyAuxOutInformartion from './DeviceTopologyAuxOutInformartion';
import DeviceTopologyDoorInformations from './DeviceTopologyDoorInformations';
import DeviceTopologyReaderInformartion from './DeviceTopologyReaderInformartion';
import { ReactComponent as EditIcon } from '../../assets/v4/Editicon.svg';
import { useDispatch, useSelector } from 'react-redux';
export default function DeviceTopologyMainPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [update, setUpdate] = React.useState(true);
  const [render, setRender] = React.useState('');
  const [siteCount, setSiteCount] = React.useState('');
  const [zoneCount, setZoneCount] = React.useState('');
  const deviceCount = useSelector((state) => state.deviceTopology.deviceCount);
  const doorCount = useSelector((state) => state.deviceTopology.doorCount);
  const store = useSelector((state) => state.deviceTopology);

  const [updatedObject, setUpdateObject] = React.useState('');

  useEffect(() => {
    setRender('');
  }, [store.selectedSite, store.selectedZone, store.selectedDevice]);

  useEffect(() => () => dispatch({ type: 'RESET_TOPOLOGY', payload: {} }), []);

  const handleUpdate = () => {
    setUpdate(!update);
  };

  const renderBox = (value) => {
    setRender(value);
  };

  const siteTotalCount = (value) => {
    if (value == 0) {
      setSiteCount(value);
    }

    if (value >= 1 && value < 10) {
      setSiteCount('0' + value);
    } else {
      setSiteCount(value);
    }
  };

  const zoneTotalCount = (value) => {
    if (value == 0) {
      setZoneCount(value);
    } else if (value >= 1 && value < 10) {
      setZoneCount('0' + value);
    } else {
      setZoneCount(value);
    }
  };

  return (
    <>
      <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
        <Grid item md={1.9} style={{ backgroundColor: '#ffffff' }}>
          <Box>
            <Box py={1.5} display='flex' justifyContent='space-between'>
              <Typography textAlign={'left'} fontWeight='bold' fontSize={'14px'} ml={2}>
                {' '}
                {t('DEVICETOPOLOGY002')}
              </Typography>
              <Typography display='flex' fontWeight='bold' fontSize={'14px'} mr={3} sx={{ color: '#36C96D' }}>
                {siteCount !== 0 ? siteCount : ' '}
                {t('DEVICETOPOLOGY003')}
              </Typography>
            </Box>
            <Divider />
            <DeviceTopologySiteList renderSiteCount={(value) => siteTotalCount(value)} />
          </Box>
        </Grid>

        <Grid item md={1.9} style={{ background: '#ffffff', margin: '0px 3px' }}>
          <Box>
            <Box py={1.5} display='flex' justifyContent={'space-between'}>
              <Typography textAlign={'center'} fontWeight='bold' fontSize={'14px'} ml={2}>
                {' '}
                {t('DEVICETOPOLOGY004')}
              </Typography>
              <Typography display='flex' fontWeight='bold' fontSize={'14px'} mr={3} sx={{ color: '#36C96D' }}>
                {zoneCount !== 0 ? zoneCount : ' '}
                {t('DEVICETOPOLOGY005')}
              </Typography>
            </Box>
            <Divider />
            <DeviceTopologyZoneList renderZoneCount={(value) => zoneTotalCount(value)} />
          </Box>
        </Grid>

        <Grid item md={1.9} style={{ background: '#ffffff' }}>
          <Box>
            <Box py={1.5} display='flex' justifyContent='space-between'>
              <Typography textAlign={'left'} fontWeight='bold' fontSize={'14px'} ml={2}>
                {' '}
                {t('DEVICETOPOLOGY006')}
              </Typography>
              <Typography display='flex' fontWeight='bold' fontSize={'14px'} mr={1} sx={{ color: '#36C96D' }}>
                {deviceCount !== 0 && deviceCount >= 1 && deviceCount < 10 ? '0' + deviceCount : deviceCount}
                {t('DEVICETOPOLOGY007')}
              </Typography>
            </Box>
            <Divider />
            <DeviceTopologyDeviceList />
          </Box>
        </Grid>

        <Grid item md={1.9} style={{ background: '#ffffff', margin: '0px 3px' }}>
          <Box>
            <Box py={1.5} display='flex' justifyContent='space-between'>
              <Typography textAlign={'left'} fontWeight='bold' fontSize={'14px'} ml={1}>
                {' '}
                {t('DEVICETOPOLOGY008')}
              </Typography>
              <Typography display='flex' fontWeight='bold' fontSize={'14px'} mr={0.5} sx={{ color: '#36C96D' }}>
                {doorCount !== 0 && doorCount >= 1 && doorCount < 10 ? '0' + doorCount : doorCount}
                {t('DEVICETOPOLOGY009')}
              </Typography>
            </Box>
            <Divider />
            <DeviceTopologySubDeviceList render={(value) => setRender(value)} updatedObject={updatedObject} setUpdate={() => setUpdateObject('')} />
          </Box>
        </Grid>

        <Grid item md={3.9} style={{ background: '#ffffff' }}>
          <Box>
            <Box py={1.5}>
              <Typography textAlign={'center'} fontWeight='bold' fontSize={'14px'} ml={2}>
                {' '}
                {t('DEVICETOPOLOGY010')}
                {/* <EditIcon onClick={() => handleUpdate()} style={{ float: 'right' }} /> */}
              </Typography>
            </Box>
            <Divider />
            {render === 'door' && (
              <DeviceTopologyDoorInformations
                open={update}
                toggleUpdate={() => {
                  //setUpdate(false);
                  setUpdateObject('door');
                }}
                applyUpdate={(data) => setUpdate(data)}
              />
            )}
            {render === 'auxIn' && (
              <DeviceTopologyAuxInInformartion
                open={update}
                toggleUpdate={() => {
                  //setUpdate(false);
                  setUpdateObject('auxIn');
                }}
                applyUpdate={(data) => setUpdate(data)}
              />
            )}
            {render === 'auxOut' && (
              <DeviceTopologyAuxOutInformartion
                open={update}
                toggleUpdate={() => {
                  //setUpdate(false);
                  setUpdateObject('auxOut');
                }}
                applyUpdate={(data) => setUpdate(data)}
              />
            )}
            {render === 'reader' && (
              <DeviceTopologyReaderInformartion
                open={update}
                toggleUpdate={() => {
                  //setUpdate(false);
                  setUpdateObject('reader');
                }}
                applyUpdate={(data) => setUpdate(data)}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
