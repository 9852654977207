import React from 'react';
import { Grid, IconButton, Typography, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ReactComponent as Close } from '../../assets/close.svg';


export default function SimpleDilogBox(props) {
  const styleDialog = {
    '& .MuiDialog-paper': {
      position: 'absolute',
      boxShadow: 1,
      minWidth: { xs: '80%', md: props.customWidth ? props.customWidth : '60%' },
      borderRadius: '12px',
      padding: '1rem',
      top: props.customWidth ? 'none' : '4rem',
    },
  };
  return (
    <Dialog sx={styleDialog} open={props.open} onClose={() => props.onClose()}>
      <Box>
        <IconButton disableRipple onClick={props.onClose}
          style={{ width: '100%', justifyContent: 'end' }}>
          <Close />
        </IconButton>
        <Typography variant='h5' component='div' gutterBottom
          style={{ color: '#474A4E', fontWeight: 'bold' }}>
          {props.title}
        </Typography>
        {props.children}
      </Box>
    </Dialog>
  );
}
SimpleDilogBox.defaultProps = {
  customWidth:'0px',
  open:true,
  onClose:()=>{
    console.log('defaultProps')
  },
  title:'title',
  children:{}
}