import axios from 'axios';
import { toast } from 'react-toastify';
export const saveScheduleTimeZoneInterval = async (payload, callBack) => {
  try {
    const res = await axios.post('/web/schedule', payload);
    callBack(res);
  } catch (error) {}
};

export const updateSchedule = async (id, payload, callBack) => {
  try {
    const res = await axios.put('/web/schedules/' + id, payload);
    callBack(res);
  } catch (error) {}
};

export const getTimeZoneIntervalList = async (page, size, name, callBack) => {
  try {
    const res = await axios.post('/web/schedules/list?page=' + page + '&size=' + size + '&name=' + name);
    callBack(res);
  } catch (error) {}
};

export const deleteScheduleTimeZoneInterval = (id, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete('/web/schedules/' + id);
    callBack(res);
  } catch (error) {}
};

export const getScheduleById = async (id, callBack) => {
  try {
    const res = await axios.get('/web/schedules/' + id);
    callBack(res);
  } catch (error) {}
};
