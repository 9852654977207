export const GET_ERRORS = 'GET_ERRORS';
export const SUCCESS = 'SUCCESS';

//OAuth-client
export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const DASHBOARD_DATA = 'DASHBOARD_DATA';

export const PERSON_LIST = 'PERSON_LIST';
export const PERSON_ERRORS = 'PERSON_ERRORS';

export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_ERRORS = 'DEPARTMENT_ERRORS';

export const DESIGNATION_LIST = 'DESIGNATION_LIST';
export const DESIGNATION_ERRORS = 'DESIGNATION_ERRORS';

export const ADDRESS_TYPE_LIST = 'ADDRESS_TYPE_LIST';
export const ADDRESS_TYPE_ERRORS = 'ADDRESS_TYPE_ERRORS';

export const SITE_TYPE_LIST = 'SITE_TYPE_LIST';
export const SITE_TYPE_ERRORS = 'SITE_TYPE_ERRORS';

export const SITE_LIST = 'SITE_LIST';
export const SITE_ERRORS = 'SITE_ERRORS';

export const ZONE_LIST = 'ZONE_LIST';
export const ZONE_ERRORS = 'ZONE_ERRORS';

export const ADDRESS_LIST = 'ADDRESS_LIST';

export const USER_ROLE_LIST = 'USER_ROLE_LIST';

export const USER_FILTER_LIST = 'USER_FILTER_LIST';
