import React, { useEffect } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Avatar, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import CredentialCount from './CredentialCount';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectRole from './SelectRole';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import { ReactComponent as DevcieIcon } from '../../assets/DeviceIcon.svg';
import { ReactComponent as Refresh } from '../../assets/icons/RefreshIcon.svg';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { deletePersonCredentialsFromCMS, deletePersonFromDevice, getDeviceSpecs, personsFromDevice } from './DeviceService';
import { toast } from 'react-toastify';
import { siteBySiteId } from '../Site/SiteService';
import { zoneByZoneId } from '../Zone/ZoneService';
import { ReactComponent as LeftArrow } from '../../assets/LeftArrow.svg';
import AvatarCustom from '../../utils/AvatarCustom';
import { ReactComponent as UnionReload } from '../../assets/UnionReload.svg';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import CommonUtil from '../../components/Util/CommonUtils';
import OverFlowText from '../accessgroup/OverFlowText';
export const CredentialList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedPersons, onSelectionChange] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [zoneName, setZoneName] = useState('');
  const [deviceSupports, setDeviceSupports] = useState('');
  const location = useLocation();
  const [selectedDevice, setSelectedDevice] = useState(location.state.selectedDevice[0]);

  useEffect(() => {
    loadCredentials(page.rowsPerPage);
  }, []);

  const loadCredentials = (newPage, rowsPerPage) => {
    personsFromDevice(selectedDevice.id, newPage + 1, rowsPerPage, (data) => {
      if (data.data.code === 'CMSI0000') {
        setTotalRecords(data.data.data.totalCount);
        setPage(data.data.data.currentPage - 1);
        setData(data.data.data.employees);
      } else {
        setTotalRecords(0);
        setPage(0);
        setData([]);
      }
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadCredentials(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadCredentials(0, pageSize);
  };
  useEffect(() => {
    siteBySiteId(selectedDevice.siteId, (data) => {
      if (data.data.code === 'LMSI6000') {
        setSiteName(data.data.data.name);
      }
    });
    zoneByZoneId(selectedDevice.zoneId, (data) => {
      if (data.data.code === 'LMSI6000') {
        setZoneName(data.data.data.name);
      }
    });
    getDeviceSpecs(selectedDevice.id, selectedDevice.sn, (data) => {
      if (data.data.code === 'DMSI0000') {
        setDeviceSupports(data.data.data);
      }
    });
  }, []);

  const deviceDelete = (employeeId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON478')}
            onConfirm={() => {
              deletePersonCredentialsFromCMS(employeeId, (data) => {
                if (data.data.code === 'CMSI0012') {
                  toast.success(data.data.message);
                  loadCredentials(0, 5);
                } else {
                  toast.error(data.data.message);
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: 'firstName',
      title: t('Person Info'),
      width: '10%',
      render: (e) => (
        <Box display={'flex'}>
          {' '}
          {/* <CardHeader style={{ height: '10px' }} avatar={<AvatarCustom name={e.firstName} />} /> */}
          <Box display={'block'} p={0} m={0} mt={-1}>
            <OverFlowText title={e.firstName + ' ' + e.lastName}>{CommonUtil.handleTextOverflow(e.firstName + ' ' + e.lastName)}</OverFlowText>
            <Typography>{' (' + e.employeeCode + ')'}</Typography>
          </Box>
        </Box>
      ),
    },

    {
      field: 'select role drop down',
      title: t('User role in this device'),
      width: '5%',
      render: (e) => <SelectRole person={e} device={selectedDevice} selectedPerson={{ devId: selectedDevice.id, pin: e.employeeId, role: e.role ? '0' : e.role }} />,
    },

    {
      field: 'credentialCount',
      title: t('Person credentials'),
      width: '20%',
      render: (e) => <CredentialCount credentialCount={e.credentialCount} credential={e} deviceSupports={ deviceSupports} devId={selectedDevice.id} />,
    },
  ];

  const renderPersonId = (employeeId) => {
    const payload = {
      device: selectedDevice,
      personId: employeeId,
    };
    history.push({ pathname: '/acc/z/credential/device', state: { selectedId: payload } });
  };

  const actions = [
    // {
    //   icon: () => <UnionReload style={{ color: '#304669' }} />,
    //   tooltip: t('sync'),
    //   position: 'row',
    //   onClick: (event, rowData) => {
    //     renderPersonId(rowData.employeeId);
    //   },
    // },

    {
      icon: () => <DeleteOutlineIcon style={{ color: '#304669' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deviceDelete(rowData.employeeId);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: '60vh',
  };

  return (
    <Grid container>
      <Grid item lg={12}>
        <Box display='flex' alignItems='center' paddingBottom={'10px'}>
          <Box item>
            <IconButton
              onClick={() => {
                history.push({ pathname: '/acc/z/device' });
              }}
            >
              <LeftArrow />
            </IconButton>
          </Box>
          <Box item paddingLeft={1.5}>
            <Typography color='#304669'>Person in this device</Typography>
          </Box>
        </Box>
        <Paper
          elevation={0}
          style={{
            minHeight: '40px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            paddingTop: '16px',
            paddingBottom: '16px',
            marginBottom: '20px',
          }}
        >
          <Box container lg={12} display='flex'>
            <Box item lg={6}>
              <IconButton style={{ alignSelf: 'center', borderRadius: '50px', backgroundColor: '#a8b8d126', height: '60px', width: '60px', left: '20px', top: '0px' }}>
                <DevcieIcon style={{ height: '30px', width: '35px' }} />
              </IconButton>
            </Box>
            <Box item lg={6} ml={5}>
              <Typography>{selectedDevice.sn}</Typography>
              <Typography>
                {'Site:  '}
                {siteName}
              </Typography>
              <Typography>
                {'Zone:  '}
                {zoneName}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid container display='flex' lg={12} justifyContent='space-between'>
        <Grid item display='flex'>
          <Typography>
            Person & verification mode in this device
            <Tooltip
              placement='right-end'
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#fff',
                    '& .MuiTooltip-arrow': {
                      color: '#fff',
                    },
                    color: '#36C96D',
                  },
                },
              }}
              title={'Click verification button to register corresponding biological template.'}
            >
              <IconButton style={{ color: '#36C96D', borderRadius: '50px', backgroundColor: '#fff', cursor: 'pointer', borderColor: '#fff', height: '26px' }}>?</IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item display='flex'>
          <IconButton onClick={() => loadCredentials(0, 5)}>
            <Refresh />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <DataTable
          columns={columns}
          data={data}
          actions={actions}
          onSelectedRoles={(rows) => onSelectionChange(rows)}
          options={options}
          count={totalRecords}
          page={page}
          onChangePage={(newPage) => handleChangePage(newPage)}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
        />
      </Grid>
    </Grid>
  );
};
