import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Breadcrumb from '../../components/Breadcrumb';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DataTable from '../../components/DataTable';
import { addPerson } from '../../actions/organization/PersonApiCalls';
import { deleteAcccessGroupDoorById, getAccessGroupDoors } from './AccessGroupService';
import CommonUtil from '../../components/Util/CommonUtils';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { filterDoors } from '../door/DoorService';

export default function AccessGroupDoor() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRecords, setSelectRecords] = React.useState([]);
  const [data, setData] = useState([]);
  const [doors, setDoors] = useState([]);
  const breadcrumb = [
    { path: '/acc/z/access-group', name: t('ACCESSGROUP005') },
    { path: '/acc/z/access-group-door', name: t('DEVI0059') },
  ];

  const loadData = (pageNumber, pageSize) => {
    getAccessGroupDoors(pageNumber, pageSize, location.state.id, (res) => {
      var doorList = [];
      if (res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.accessGroupDoors instanceof Array) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage);

        filterDoors(1, 1000, {}, (doorRes) => {
          if (doorRes.data.code === 'DMSI0000') {
            res.data.data.accessGroupDoors.map((agd) => {
              doorRes.data.data.doors.map((d) => {
                if (agd.doorId === d.doorId) {
                  doorList.push(d);
                }
              });
            });
            setData(doorList);
          }
        });
      } else {
        setTotalRecords(0);
        setPage(0);
        setData([]);
      }
    });
  };

  const getDoorByDoorId = (doorId) => {
    const door = {};
    doors.map((e) => {
      if (doorId === e.doorId) {
        door = e;
      }
    });
    return door;
  };

  useState(() => {
    loadData(page, rowsPerPage);
  }, []);

  useState(() => {
    if (doors.length > 0) loadData(page, rowsPerPage);
  }, [doors, page]);

  const deleteData = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
    }

    var doors = [];
    data.map((door) => {
      doors.push(door.doorId);
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              deleteAcccessGroupDoorById(location.state.id, data[0].doorId, (res) => {
                if (res.data.code === 'DMSI0011') {
                  toast.success(t('ACCESSGROUP014'));
                  loadData(page, rowsPerPage);
                } else {
                  toast.error(res.data.msg);
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: 'doorParameters.name',
      title: t('COMMON014'),
      tooltip: t('DOORI003'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
      cellStyle: {
        innerWidth: '100%',
      },
    },
    { field: 'deviceName', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'doorParameters.doorNo', title: t('COMMON060'), tooltip: t('DOORI002'), editable: 'never' },

    { field: 'doorParameters.verifyMode', title: t('DOORI005'), tooltip: t('DOORI030'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteData([rowData]);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const handleChangePage = (newPage, id, name) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize, id, name) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const addDoorsToLevel = () => {
    history.push({
      pathname: '/acc/z/access-group-add-door',
      state: { ...location.state },
    });
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Button
          size='small'
          variant='contained'
          color='secondary'
          aria-label={t('LEVEL003')}
          onClick={() => {
            addDoorsToLevel();
          }}
        >
          {' + '}
          {t('LEVEL003')}
        </Button>
      </Breadcrumb>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => setSelectRecords(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
