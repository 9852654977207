import axios from "axios";

export const filterReader = async (page, rowsPerPage, callBack) => {
    try {
      const res = await axios.get('/web/devices/readers?page=' + page + '&size=' + rowsPerPage);
      callBack(res);
    } catch (error) {
    }
  };

  export const updateReader = async (id,updateObject, callBack) => {
    try {
      const res = await axios.put('/web/readers/' + id , updateObject);
      callBack(res);
    } catch (error) {
    }
  };