import axios from 'axios';
import { JAVA_API_COMPANY } from './ApiEndPoints';
import { GET_COMPANIES, SET_CURRENT_USER } from './types';
import jwt_decode from 'jwt-decode';
import setJWTToken from '../../oauth-client/setJWTToken';
import { SET_COMPANY } from '../types';

export const createNewCompany = (payload, callBack) => async (dispatch) => {
  try {
    let config = {
      headers: {
        Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
      },
    };
    const res = await axios.post(JAVA_API_COMPANY, payload, config);
    alert('UASI0011' + res.data.code);
    if (res.data.code === 'UASI0011') {
      alert('UASI0011' + res.data.code);
      const accessToken = res.data.data.access_token;
      // const refreshToken = res.data.data.refresh_token;
      setJWTToken('Bearer ' + res.data.data.access_token);
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
      localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
      localStorage.setItem('companyId', decoded.companyId);
      localStorage.setItem('companyName', decoded.companyName);
      localStorage.setItem('companyCode', payload.code);

      const decoded = jwt_decode(accessToken);
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
    }
    callBack(res.data);
  } catch {}
};

export const switchCompany = (code, callBack) => async (dispatch) => {
  const payload = {
    companyCode: code,
    companyId: '',
  };
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('tempOrmToken'),
      },
    };
    const res = await axios.put(JAVA_API_COMPANY, payload, config);
    if (res.data.code !== 'UASI0011') {
      callBack(res.data);
    } else {
      const accessToken = res.data.data.access_token;
      // const refreshToken = res.data.data.refresh_token;

      const decoded = jwt_decode(accessToken);
      localStorage.setItem('ormToken', accessToken);
      // localStorage.setItem('ormRToken', refreshToken);
      localStorage.removeItem('tempOrmToken');
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });

      callBack(res.data);
    }
  } catch {}
};

export const getCompanyList = () => async (dispatch) => {
  let config = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('tempOrmToken'),
    },
  };
  const res = await axios.get(JAVA_API_COMPANY + '?page=0&zise=100', config);
  if (res.data.code === 'UASI0000') {
    dispatch({
      type: GET_COMPANIES,
      payload: res.data.data.company,
    });
  }
};

export const getCompany = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/company/profile');
    if (res.status === 200 && res.data.code == 'UASI0000') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: SET_COMPANY,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch {}
};

export const getCompanyV3 = (companyId) => async (dispatch) => {
  try {
    const res = await axios.get('/web/company/'+companyId+'?toggleKeyToURL='+true);
    if (res.status === 200 && res.data.code == 'UASI0026') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
    }
  } catch {}
};

