import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Breadcrumb from '../../components/Breadcrumb';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DataTable from '../../components/DataTable';
import { addPerson } from '../../actions/organization/PersonApiCalls';
import { deleteAcccessGroupPersonById, getAccessGroupDoors, getAccessGroupPersons } from './AccessGroupService';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';

export default function AccessGroupPerson() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRecords, SetSelectRecords] = React.useState([]);
  const [data, setData] = useState([]);
  const breadcrumb = [
    { path: '/acc/z/access-group', name: t('ACCESSGROUP005') },
    { path: '/acc/z/access-group-person', name: t('PERSON001') },
  ];

  const deleteData = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
    }

    var persons = [];
    data.map((e) => {
      persons.push(e.id);
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              deleteAcccessGroupPersonById(location.state.id, data[0].id, (res) => {
                if (res.data.code === 'DMSI0000') {
                  toast.success(t('ACCESSGROUP015'));
                  loadData(page, rowsPerPage);
                } else {
                  toast.error(res.data.msg);
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: 'firstName',
      title: t('COMMON020'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'white',
      },
      render: (rowData) =>
        rowData.firstName ? (
          rowData.firstName
        ) : (
          <span style={{ color: 'red' }} className='refresh-btn'>
            Person already deleted in ZLink, Please delete them from the Access group
          </span>
        ),
    },
    { field: 'lastName', title: t('COMMON021'), editable: 'never' },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteData([rowData]);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const handleChangePage = (newPage, id, name) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize, id, name) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const addPersonToLevel = () => {
    history.push({
      pathname: '/acc/z/access-group-add-person',
      state: { ...location.state },
    });
  };

  const loadData = (pageNumber, pageSize) => {
    getAccessGroupPersons(pageNumber + 1, pageSize, location.state.id, (res) => {
      if (res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data)) {
        setTotalRecords(res.data.data.totalCount);
        setData(res.data.data.levelPersons);
      } else {
        setData([]);
        setPage(0);
        setTotalRecords(0);
      }
    });
  };

  useEffect(() => {
    loadData(page, rowsPerPage);
  }, []);

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Button
          size='small'
          variant='contained'
          color='secondary'
          aria-label={t('COMMON002')}
          onClick={() => {
            addPersonToLevel();
          }}
        >
          {' + '}
          {t('PERSON002')}
        </Button>
      </Breadcrumb>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => SetSelectRecords(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
