import axios from 'axios';
import { GET_APP_CONFIGS, GET_COMPANIES, REFRESH_TOKEN_TIMER, SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from '../actions/types';
import CommonUtil from '../components/Util/CommonUtils';
import setJWTToken from './setJWTToken';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';

export const authorize = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.post(`/onboard/user/authorize`, payload);
    if (res.data.code === 'CAAI0001') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const listCompanies = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get(`/onboard/company?pageNumber=0&pageSize=1000`);
    if (res.data.code === 'UASI0000') {
      dispatch({
        type: GET_COMPANIES,
        payload: CommonUtil.isEmpty(res.data.data) ? [] : res.data.data.company,
      });
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const switchCompany = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put(`/onboard/company`, payload);
    if (res.data.code === 'UASI0011') {
      localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
      setJWTToken('Bearer ' + res.data.data.access_token);
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
      localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
      const decoded = jwt_decode(res.data.data.access_token);
      localStorage.setItem('companyId', decoded.companyId);
      localStorage.setItem('companyName', decoded.companyName);
      localStorage.setItem('companyCode', decoded.companyCode);

      var currentSeconds = Math.floor(Date.now() / 1000);
      var secondsLeft = decoded.exp - currentSeconds;
      var timer = Math.floor(secondsLeft / 60) - 5;
      timer = timer * 60 * 1000;
      console.log('Set refresh token timer', timer);
      console.log('Time left to call refresh token', secondsLeft / 60);
      //alert((new Date(decoded.expiryAt) - new Date()) / 60000);
      //console.log('Verified token :' + JSON.stringify(decoded));
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      dispatch({
        type: SET_REFRESH_TOKEN,
        payload: 'Bearer ' + res.data.data.refresh_token,
      });
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: 'Bearer ' + res.data.data.access_token,
      });
      dispatch({
        type: REFRESH_TOKEN_TIMER,
        payload: timer,
      });
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const registerUser = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.post(`/onboard/user`, payload);
    if (res.data.code === 'UASI0001') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const forgotPassword = (emailId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.post('/onboard/user/' + emailId + '/forgot_password');
    if (res.status === 200 && res.data.code === 'CASI0001') {
      onSuccess(res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch {}
};

export const resetPwd = (code, payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/onboard/user/' + code + '/reset_password', payload);
    if (res.status === 200 && res.data.code === 'CASI0601') {
      onSuccess(res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch {}
};

export const validateActivationCode = (code, payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    let res = await axios.put('/onboard/user/' + code + '/activate', payload);
    if (res.data.code === 'CVAI3003') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {}
};

export const applicationConfigsApi=()=>async (dispatch)=>{
  try{
    let res=await axios.get('/api/v2.0/getConfigs');
    if(res.data.code==='CASI0001'){
      dispatch({
          type: GET_APP_CONFIGS,
          payload: res.data.data,
        })
    }
  }catch(error){}

};