import { Box, Grid, Paper, Typography, Button, Autocomplete, TextField, styled } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import { ReactComponent as InformationICon } from '../../assets/inforamationIcon.svg';
import { ReactComponent as TimeSlot } from '../../assets/timeSlot.svg'
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import { getTimeZoneIntervalList } from '../schedules/SchedulesService';
import {getDeviceVerificationMode } from '../device/DeviceService';
import CustomInput from '../../components/CustomInput';
import { toast } from 'react-toastify';
import { updateDoor } from './DoorService';

const EditDoorPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history=useHistory();
  const [timeSlot, setTimeSlot] = useState([]);
  const[verificationMode,setVerificationMode]=useState([]);
  const [error, setError] = useState(defaultValue);
  const [state, setState] = useState(() => {
    if (!CommonUtil.isEmpty(location.state)) {
      return {
        id: location.state.doorId,
        name: location.state.doorParameters.name,
        deviceName: location.state.deviceName,
        doorNo: location.state.doorParameters.doorNo,
        verifyMode: location.state.doorParameters.verifyMode,
        activeTimeSegBusinessId: location.state.doorParameters.activeTimeSegBusinessId,
        lockDelay: location.state.doorParameters.lockDelay,  // lockDelay is same as lock open duration
        sensorDelay: location.state.doorParameters.sensorDelay,
        /**
         * doorSensorStatus is sensor Type.
         * doorSensorStatus: 0-None, 1-Normal open, 2- Normal closed
         */
        doorSensorStatus: location.state.doorParameters.doorSensorStatus,
        actionInterval:location.state.doorParameters.actionInterval
      }
    }
    else {
      return defaultValue;
    }
  });

  useEffect(() => {
    loadSchedules(1, 1000, '');
    loadDeviceSpec();
  }, []);

  const handleChange=(event)=>{
    var name=event.target.name;
    setState({
      ...state,
      [name]:event.target.value
    })
    setError({
      ...error,
      [name]:''
    })
  }; 

  const loadDeviceSpec = () => {
    getDeviceVerificationMode(location.state.deviceId, (data) => {
      if (data.data.code === 'DMSI0000') {
        setVerificationMode(data.data.data);
      }
    });
  }

  const loadSchedules = (page, size, name) => {
    getTimeZoneIntervalList(page, size, name, (res) => {
      if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.timeZone instanceof Array) {
        if (state.activeTimeSegBusinessId === '9999') {
          res.data.data.timeZone.map((data) => {
            if (data.operationName === '24-Hours General') {
              setState({ ...state, activeTimeSegBusinessId: data.id })
            }
          })
        }
        setTimeSlot(res.data.data.timeZone);
      } else {
        setTimeSlot([]);
      }
    });
  };
  
  const findTimeSlot = (activeTimeSegBusinessId) => {
    var timeSt = { activeTimeSegBusinessId: '' };
    timeSlot.map((value) => {
      if (value.id === activeTimeSegBusinessId) {
        timeSt = value;
      }
    });
    return timeSt;
  }

  const findSensorType = useCallback((doorSensorStatus) => {
    var sensor = { id: '' };
    doorSensorType.map((value) => {
      if (value.id === doorSensorStatus) {
        sensor = value;
      }
    });
    return sensor;
  }, []);

  const findVerificationMode =(verifyMode) => {
    var varification = { name: '' };
    verificationMode.map((mode) => {
      if (mode.value == verifyMode) {
        varification = mode;
      }
    });
    return varification;
  };

  const breadcrumb = [
    { path: '/acc/z/door', name: t('DOORI001') },
    { path: '', name: t('DOORI040') }
  ];

  const handleSubmit = () => {
    
    if (CommonUtil.isEmptyString(state.name)) {
      toast.error(t('COMMON487'));
      setError({...error,name:true})
      return;
    }
    if (CommonUtil.isEmptyString(state.lockDelay)) {
      setError({...error,lockDelay:true})
      toast.error(t('COMMON210'));
      return;
    }
    if (CommonUtil.isEmptyString(state.activeTimeSegBusinessId)) {
      toast.error(t('DOORI036'));
      setError({...error,activeTimeSegBusinessId:true});
      return;
    }
    if (state.lockDelay < 1 || state.lockDelay > 254) {
      toast.error(t('COMMON177'));
      setError({...error,lockDelay:true})
      return;
    }
    if (CommonUtil.isEmptyString(state.sensorDelay)) {
      setError({...error,sensorDelay:true});
      toast.error(t('COMMON482'));
      return;
    }
    if (state.sensorDelay < 1 || state.sensorDelay > 254) {
      setError({...error,sensorDelay:true});
      toast.error(t('COMMON483'));
      return;
    }
    if (CommonUtil.isEmptyString(state.actionInterval)) {
      toast.error(t('COMMON485'));
      setError({...error,actionInterval:true});
      return;
    }
    if (state.actionInterval < 0 || state.actionInterval > 254) {
      toast.error(t('COMMON486')); 
      setError({...error,actionInterval:true});
      return;
    }

    if (CommonUtil.isEmptyString(state.verifyMode)) {
      toast.error(t('COMMON484'));
      setError({...error,verifyMode:true});
      return;
    }
    let payloadObj = {};
    payloadObj.parameters = state;
    updateDoor(state.id, payloadObj, (res) => {
      if (res.data.code === 'DMSI0000') {
        toast.success(res.data.msg);
        history.push("/acc/z/door")
      } else {
        toast.error(res.data.msg);
      }
    });
  };
  const TimeSlotAutocomplete = () => {
    return (
      <Autocomplete
        fullWidth
        size='small'
        value={state.activeTimeSegBusinessId ? findTimeSlot(state.activeTimeSegBusinessId) : ''}
        options={CommonUtil.isEmpty(timeSlot) ? [] : timeSlot}
        getOptionLabel={(option) => { return option.operationName }}
        renderInput={(params) => <TextField {...params}
        helperText={error.activeTimeSegBusinessId}
        error={error.activeTimeSegBusinessId}
        />}
        forcePopupIcon={true}
        sx={{[`& .MuiAutocomplete-popupIndicator`]: {transform: ' none !important'}}}
        popupIcon={<Box bgcolor={'#FFF'}><TimeSlot /></Box>}
        onChange={(event, newValue) => {
          // alert(JSON.stringify(newValue))
          if(newValue !==null){
            setState({...state,activeTimeSegBusinessId:newValue.id});
            setError({...error,activeTimeSegBusinessId:''});
          }
        }}
      />

    )
  }
  const DoorSensorTypeAutocomplete = () => {
    return (
      <Autocomplete
        fullWidth
        size='small'
        value={state.doorSensorStatus ? findSensorType(state.doorSensorStatus) : null}
        options={CommonUtil.isEmpty(doorSensorType) ? [] : doorSensorType}
        getOptionLabel={(option) => { return option.name }}
        renderInput={(params) => <TextField {...params}
        helperText={error.doorSensorStatus}
        error={error.doorSensorStatus}
        />}
        onChange={(event, newValue) => {
          if(newValue !==null){
            setState({...state,doorSensorStatus:newValue.id});
            setError({...error,doorSensorStatus:''});
          }
        }}

      />

    )
  };

  const LockSensorTypeAutocomplete = () => {
    return (
      <Autocomplete
        fullWidth
        disabled
        size='small'
        // value={state.doorSensorStatus ? findSensorType(state.doorSensorStatus) : null}
        options={CommonUtil.isEmpty(lockSensorType) ? [] : lockSensorType}
        getOptionLabel={(option) => { return option.name }}
        renderInput={(params) => <TextField {...params}
        helperText={error.doorSensorStatus}
        error={error.doorSensorStatus}
        />}
        onChange={(event, newValue) => {
          if(newValue !==null){
            setState({...state,doorSensorStatus:newValue.id});
            setError({...error,doorSensorStatus:''});
          }
        }}

      />

    )
  }

  const VerificationModeAutocomplete = () => {
    return (
      <Autocomplete
        fullWidth
        disabled
        size='small'
        value={state.verifyMode ? findVerificationMode(state.verifyMode) : null}
        options={CommonUtil.isEmpty(verificationMode)? [] : verificationMode}
        getOptionLabel={(option) => { return option.name!==undefined?option.name:'' }}
        renderInput={(params) => <TextField {...params}
        helperText={error.verifyMode}
        error={error.verifyMode}
        />}
        onChange={(event, newValue) => {
          if(newValue !==null){
            setState({...state,verifyMode:newValue.value});
            setError({...error,verifyMode:''});
          }
        }}

      />

    )
  }

  return (
    <>
      <Breadcrumb links={breadcrumb} />
      <Paper>
        <Grid container spacing={2}>
          <Grid item sm={11.8} xs={11.8} >
            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI003')} *</Typography>
                <TextField
                  size='small'
                  fullWidth
                  required
                  variant='outlined'
                  name='name'
                  error={error.name}
                  value={state.name}
                  helperText={error.name}
                  onChange={(event) => handleChange(event)}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[0].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DEVI0002')}</Typography>
                <TextField 
                size='small' 
                fullWidth 
                variant='outlined' 
                name='deviceName' 
                disabled
                value={state.deviceName}
                />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[1].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI002')}</Typography>
                <TextField
                  fullWidth
                  error={error.doorNo}
                  name="doorNo"
                  variant="outlined"
                  size="small"
                  value={state.doorNo}
                  required
                  disabled
                />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[2].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI041')} *</Typography>
                <TimeSlotAutocomplete />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[3].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI042')} *</Typography>
                <VerificationModeAutocomplete/>
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[4].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI043')} *</Typography>
                <CustomInput
                  fullWidth
                  error={error.lockDelay}
                  name="lockDelay"
                  variant="outlined"
                  size="small"
                  required
                  value={state.lockDelay}
                  validation={'numericWithoutDot'}
                  handleChange={(e)=>{setState({...state,lockDelay:e});setError({...error,lockDelay:''})}}
                  helperText={error.lockDelay}
                />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[5].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI047')} *</Typography>
                <CustomInput
                  fullWidth
                  error={error.actionInterval}
                  name="actionInterval"
                  variant="outlined"
                  size="small"
                  required
                  value={state.actionInterval}
                  validation={'numericWithoutDot'}
                  handleChange={(e)=>{setState({...state,actionInterval:e});setError({...error,actionInterval:''})}}
                  helperText={error.actionInterval}
                />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[6].InfoText}</Typography>
              </Box>
            </Box>
            
            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI044')} *</Typography>
                <DoorSensorTypeAutocomplete />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[7].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI048')} *</Typography>
                <LockSensorTypeAutocomplete />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[8].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI045')} *</Typography>
                <CustomInput
                  fullWidth
                  error={error.sensorDelay  }
                  name="sensorDelay"
                  variant="outlined"
                  size="small"
                  required
                  value={state.sensorDelay}
                  validation={'numericWithoutDot'}
                  handleChange={(e)=>{setState({...state,sensorDelay:e});setError({...error,sensorDelay:''});}}
                  helperText={error.sensorDelay}
                />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[9].InfoText}</Typography>
              </Box>
            </Box>

            <Box display={'flex'}>
              <Box padding={1} minWidth={{ xl: '40%', lg: '40%', md: '30%', sm: '97%', xs: '97%' }}>
                <Typography variant='h5' fontSize={'small'}>{t('DOORI046')} *</Typography>
                <TextField size='small' fullWidth variant='outlined' disabled={true} />
              </Box>
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                  alignItems: 'center',
                  paddingLeft: '15px',
                  paddingTop: '20px'
                }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{informationMessage[10].InfoText}</Typography>
              </Box>
            </Box>

            <Box padding={1} display='flex' justifyContent={'flex-start'} margin={'10px 25px'}>
              <Button variant='contained' size='large'
              onClick={()=>handleSubmit()}
              >Save</Button>
              <Button variant='outlined' size='large' style={{ marginLeft: '20px' }}
              onClick={()=> history.push("/acc/z/door")}
              >Cancel</Button>
            </Box>

          </Grid>
          <Grid item display={{ xl: 'none', lg: 'none', md: 'none', sm: 'inline', sx: 'flex' }} >
            {informationMessage.map((data) =>
              <Box padding={1} sx={{ display: 'flex', alignContent: 'center' }}>
                <Box marginRight={1}>
                  <InformationICon />
                </Box>
                <Typography variant='h5' fontSize={'small'} textAlign="justify">{data.InfoText}</Typography>
              </Box>)}

          </Grid>

        </Grid>
      </Paper>
    </>
  )
}

export default EditDoorPage


const informationMessage = [
  {
    InfoText: 'Usually, a Lock relay open one Door Lock. There will be a Lock # on the device to indicate these relays. Choose a memorable name (usually with a location) as the name of the door.'
  },
  {
    InfoText: 'This door physically wired to one lock relay of this device. One device may support 1-4 doors.'
  },
  {
    InfoText: 'Device mark a lock relay number for door lock, so that it can know what action happen to which door lock.'
  },
  {
    InfoText: 'The default value is 24 Hour active, it means all the person sync to this device can pass the door after verification. If you want this door working in limited time slots, choose one active time for this door, then only during this time slots, this door lock will open.'
  },
  {
    InfoText: 'All person in this device have to use the combination verification way.'
  },
  {
    InfoText: 'By default, the COM and NC of the lock relay are connected. "Open" means the COM and NO of the lock relay are connected. According to the type of door lock, some door lock is closed when the power is on, and some door lock is closed when the power is off. Please check door lock is correctly connected to the lock relay.'
  },
  {
    InfoText: 'This is the time interval between the current and the next verification.'
  },
  {
    InfoText: 'There are NC and NO Sensor, please set this paremeter according the Sensor Type.'
  },
  {
    InfoText:'The Door Lock Sensor type defines the default operation that the Lock  is set.'
  },
  {
    InfoText: 'Door Sensor will detect Door state after a few seconds. If Door\'s state is different from the Door Sensor Type (NC/NO Door), Device will give a signal to Auxiliary Output.'
  },
  {
    InfoText: 'Device or other alarm output will alarm after Lock Open Duration + Door Sensor Delay + Door Sensor Alarm Delay seconds.'
  },
];

const doorSensorType = [
  {
    id: '0',
    name: 'None'
  },
  {
    id: '1',
    name: 'Normal open'
  },
  {
    id: '2',
    name: 'Normal close'
  }
];

const lockSensorType = [
  {
    id: '0',
    name: 'None'
  },
  {
    id: '1',
    name: 'Normal open'
  },
  {
    id: '2',
    name: 'Normal close'
  }
];

const defaultValue = {
  id: '',
  name: '',
  deviceName: '',
  doorNo: '',
  verifyMode: '',
  activeTimeSegBusinessId: '',
  lockDelay: '',
  sensorDelay: '',
  doorSensorStatus: '',
  actionInterval:''
}
const val={
  "id": "",
  "name": "",
  "deviceName": "",
  "doorNo": "",
  "verifyMode": "",
  "activeTimeSegBusinessId": "",
  "lockDelay": "",
  "sensorDelay": "",
  "doorSensorStatus": "",
  "actionInterval": ""
}