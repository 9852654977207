import { JAVA_URL_SITE_LIST, REACT_GET_SITE_BY_ID } from '../../actions/EndPoints';
import axios from 'axios';
export const listAllSites = async (callBack) => {
    try {
        const res = await axios.get(JAVA_URL_SITE_LIST + '?isPageable=false')
        callBack(res)
    }
    catch (error) { }
}

export const siteBySiteId = async (id,callBack) => {
    try {
        const res = await axios.get(REACT_GET_SITE_BY_ID + "/" + id);
        callBack(res)
    }
    catch (error) { }
}

export const siteBySiteIdV2 = async (id,callBack) => {
    try {
        const res = await axios.get('/api/v2.0/site/' + id);
        callBack(res)
    }
    catch (error) { }
}

