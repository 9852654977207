import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import CommonUtil from '../../components/Util/CommonUtils';

export default function OverFlowText(props) {
  const { title, marginTop } = props;
  return (
    <Tooltip title={title} style={{ marginTop: marginTop }}>
      <Typography variant={props.variant} {...props}>
        {CommonUtil.handleTextOverflow(props.children)}
      </Typography>
    </Tooltip>
  );
}
