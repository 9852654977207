import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getAuxins } from '../../actions/AuxinApiCalls';
import { REACT_URL_AUXIN } from '../../actions/EndPoints';
import EditAuxin from '../auxin/EditAuxIn';
// import FilterAuxin from './FilterAuxin';
import CommonUtil from '../../components/Util/CommonUtils';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { filterAuxIn } from './AuxInService';

export default function AuxinList() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [selectedData, onSelectionChange] = useState([]);

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [updateObject, setupdateObject] = useState({});

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    name: '',
    deviceAlias: '',
  });

  const [filter, setFilter] = useState({
    name: '',
    deviceAlias: '',
  });

  const defaultFilter = {
    name: '',
    deviceAlias: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      deviceAlias: filterData.deviceAlias,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.auxin.auxins)) {
      setTotalRecords(store.auxin.auxins.totalConunt);
      setPage(store.auxin.auxins.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.auxin.auxins.auxIns);
    // eslint-disable-next-line
  }, [store.auxin.auxins]);

  // Called every time there is change on "store.auxin.auxins" to keep the table updated
  useEffect(() => {
    setData(store.auxin.auxins.auxIns);
  }, [store.auxin.auxins]);

  // const loadData = (page, rowsPerPage) => {
  //   dispatch(getAuxins(filter.name, filter.deviceAlias, page + 1, rowsPerPage));
  // };

  const loadData = (newPage, rowsPerPage) => {
    filterAuxIn(newPage + 1, rowsPerPage, (res) => {
      if (res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data)) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
        setData(res.data.data.doors);
      } else {
        setTotalRecords(0);
        setPage(0);
        setData([]);
      }
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const breadcrumb = [{ path: REACT_URL_AUXIN, name: t('AUXIN001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON474'),
      tooltip: t('COMMON064'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
    { field: 'deviceName', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'aux_no', title: t('COMMON011'), tooltip: t('COMMON065'), editable: 'never' },
    { field: 'printer_number', title: t('COMMON067'), tooltip: t('COMMON066'), editable: 'never' },
    { field: 'remark', title: t('COMMON016'), editable: 'never' },
  ];

  // passed to the DataTable component to create action buttons
  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setupdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  // eslint-disable-next-line
  const onPageSizeChange = (pageSize) => {
    setQuery({
      ...query,
      page: 0,
      pageSize: pageSize,
    });
    loadData();
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t("AUXIN001")}/> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          {/* <FilterAuxin page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} /> */}
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditAuxin
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
