import axios from 'axios';

export const filterPlans = async (page, rowsPerPage, callBack) => {
  try {
    const res = await axios.get('/web/subscription/price_strategies?pageNumber=' + page + '&pageSize=' + rowsPerPage);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const FilterSubscrptionList = async (page, rowsPerPage, callBack) => {
  try {
    const res = await axios.get('/web/subscription/list?pageNumber=' + page + '&pageSize=' + rowsPerPage);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const crateSubscritpionApi = async (payload, callBack) => {
  try {
    const res = await axios.post('/web/subscription', payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const paytmPayment = async (payload, callBack) => {
  try {
    const res = await axios.post('/web/subscription/paytm/transaction' + payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const stripePayment = async (payload, callBack) => {
  try {
    const res = await axios.post('/web/subscription/stripe/transaction' + payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};
