import React from 'react';
import { IconButton, Tooltip, MenuItem, Menu } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Setup } from '../../assets/icons/SetupIcon.svg';
import SetRegistrationDevice from './SetRegistrationDevice';
export default function DeviceSetupOperations(props) {
    const { t } = useTranslation()
    const reload=(val)=>{
        props.setReload(val);
    }
    return (
        <PopupState variant="popper" popupId='demo-popup-menu'>
            {(popupState) => (
                <React.Fragment>
                    <Tooltip title={t('DEVI0036')}>
                        <IconButton color='secondary' aria-label={t('DEVI0036')} {...bindTrigger(popupState)}>
                            <Setup style={{ height: 30, width: 30 }} />
                        </IconButton>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                        <SetRegistrationDevice {...props} popupState={popupState} setReload={reload}/>
                    </Menu>

                </React.Fragment>
            )}

        </PopupState>
    )
}