import axios from 'axios';
import { JAVA_API_DEVICE, JAVA_API_DEVICE_OPERATIONS } from './ApiEndPoints';
import { toast } from 'react-toastify';
import { JAVA_URL_TIME_ZONE_LIST, REACT_URL_DEVICE_LIST } from './EndPoints';
import { DEVICE_ERRORS, ANTIPASSBACK_Device_LIST, INTERLOCK_Device_LIST, GET_ACCDEVICE_READERSTATE } from './types';

export const getSdmsDevices = () => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DEVICE + '/' + localStorage.getItem('companyCode'));
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: 'GET_ADMSACCDEVICES',
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: 'GET_ADMSACCDEVICES',
        payload: [],
      });
    }
  } catch (error) {}
};

export const getDevices = (alias, sn, deviceName, ip, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(
      JAVA_API_DEVICE + '?alias=' + alias + '&sn=' + sn + '&deviceName=' + encodeURIComponent(deviceName) + '&ipAddress=' + ip + '&page=' + page + '&size=' + size
    );
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: 'GET_ACCDEVICES',
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: 'GET_ACCDEVICES',
        payload: [],
      });
    }
  } catch (error) {}
};

export const addDevice = (sn, payload, history,callBack) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_DEVICE + '/' + sn, payload);
    if (res.status === 200 && (res.data.code === 'CASI0002' || res.data.code === 'CASI0273')) {
      toast.success(res.data.message);
      history.push(REACT_URL_DEVICE_LIST);
    } else {
      toast.error(res.data.message);
      callBack(res.data.code);
    }
  } catch (error) {}
};

export function listTimeZones() {
  try {
    axios.get(JAVA_URL_TIME_ZONE_LIST).then((response) => {
      return response.data.data;
    });
  } catch (error) {}
}

export const updateDevice = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE, payload);
    if (res.status === 200 && res.data.code === 'CASI0004') {
      toast.success(res.data.message);
      callBack(res.data);
      dispatch({
        type: DEVICE_ERRORS,
        payload: {},
      });
    } else {
      toast.error(res.data.message);
      dispatch({
        type: DEVICE_ERRORS,
        payload: res.data,
      });
    }
  } catch {}
};

export const removeDevice = (ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_DEVICE + '?ids=' + ids);
    if (res.status === 200 && res.data.code === 'CASI0003') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch {}
};

export const performDeviceOperations = (ids, operation, callback) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '?deviceIdList=' + ids + '&deviceOperation=' + operation);
    if (res.status === 200 && (res.data.code === 'CASI0007' || res.data.code === 'CASI0006' || res.data.code === 'CASI0005')) {
      toast.success(res.data.message);
    } else if (res.status === 200 && res.data.code === 'CASW0004') {
      toast.warn(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    callback();
  } catch {
    toast.error('Something went wrong');
  }
};

export const resetErrors = () => async (dispatch) => {
  dispatch({
    type: 'RESET_ERRORS',
    payload: {},
  });
};

export const getSyncType = (deviceIdList, callBack) => async (dispath) => {
  try {
    const res = await axios.get(JAVA_API_DEVICE + '/sync_types?deviceIdList=' + deviceIdList);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      callBack(res.data.data);
    } else {
      callBack([]);
    }
  } catch (error) {}
};

export const syncData = (deviceIdList, syncTypes, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE + '/sync_data?deviceIdList=' + deviceIdList + '&syncTypes=' + syncTypes+ '&cloudAccess='+true);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
      callBack();
    }
  } catch {
    toast.error('Something went wrong');
  }
};

export const getDevicesForInterlock = () => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.get(JAVA_API_DEVICE + '/' + 'interlock-device');
    if (res.status === 200 && res.data.code === 'CASI0200') {
      dispatch({
        type: INTERLOCK_Device_LIST,
        payload: res.data.data.listDevices,
      });
    } else {
      dispatch({
        type: INTERLOCK_Device_LIST,
        payload: [],
      });
    }
  } catch (error) {}
};

export const getDevicesForAntipassback = () => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.get(JAVA_API_DEVICE + '/' + 'antipassback-device');
    if (res.status === 200 && res.data.code === 'CASI0205') {
      dispatch({
        type: ANTIPASSBACK_Device_LIST,
        payload: res.data.data.listDevices,
      });
    } else {
      dispatch({
        type: ANTIPASSBACK_Device_LIST,
        payload: [],
      });
    }
  } catch (error) {}
};

export const getDeviceReaderState = (deviceId, callback) => async (dispatch) => {
  const res = await axios.get(JAVA_API_DEVICE + '/' + 'readerState' + '/' + deviceId);
  if (res.status === 200 && res.data.code === 'CASI0001') {
    callback(res.data.Reader1IOState);
    dispatch({
      type: GET_ACCDEVICE_READERSTATE,
      payload: res.data.Reader1IOState,
    });
  }
};

export const obtailPersonnelDetails = (sn, callBack) => async (dispath) => {
  try {
    const res = await axios.get('/api/v2.0/person/fetch?sn=' + sn);
    if (res.status === 200 && res.data.code === 'CASI0213') {
      toast.success(res.data.message);
      callBack(true);
    } else {
      toast.error(res.data.message);
      callBack(false);
    }
  } catch (error) {}
};

export const obtailPersonnelBioDetails = (sn, type, callBack) => async (dispath) => {
  try {
    const res = await axios.get('/api/v2.0/person/biotemplate/fetch?sn=' + sn + '&type=' + type);
    if (res.status === 200 && res.data.code === 'CASI0214') {
      toast.success(res.data.message);
      callBack(true);
    } else {
      toast.error(res.data.message);
      callBack(false);
    }
  } catch (error) {}
};

export const clearAdministrator = (id) => async (dispatch) => {
  try {
    const res = await axios.post('/api/v2.0/device/clearAdministrator?deviceIdList='+id);
    if (res.data.code === 'CASI0001') {
      toast.success(res.data.message);
    }
    if (res.data.code === 'CASW0004') {
      toast.warning(res.data.message);
    }
  } catch {
    toast.error('Something went wrong');
  }
};