import { DEPARTMENT_LIST } from '../../actions/organization/types';

const initialState = {
  departmentList: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload,
      };

    default:
      return state;
  }
}
