import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EventGraph from './EventGraph';
import GlobalMapContainer from './GlobalMapContainer';
import SiteDashboardCards from './SiteDashboardCards';
import './DashBoard.css';

export default function DashBoard() {
  const { t } = useTranslation(0);

  return (
    <Grid container spacing={0}>
      <Typography gutterBottom variant='h3' component='h2' color='secondary' style={{ margin: '18px' }}>
        {t('COMMON180')}
      </Typography>

      <Grid item xs={12} sm={12}>
        <Box width={'100%'}>
          <SiteDashboardCards />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'}>
          <EventGraph />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box width={'100%'}>
          <GlobalMapContainer />
        </Box>
      </Grid>
    </Grid>
  );
}
