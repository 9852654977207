import { GET_APP_CONFIGS } from "../actions/types";

const initialState={
    appConfig:{}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_APP_CONFIGS:
			return {
				...state,
				appConfig: action.payload
			};
		default:
			return state;
	}
}