import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { REACT_URL_READER_LIST } from '../../actions/EndPoints';
import EditReader from '../reader/EditReader';
// import FilterReader from './FilterReader';
import CommonUtil from '../../components/Util/CommonUtils';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { filterReader } from './ReaderService';

export default function ReaderList() {
  const { t } = useTranslation();
  const [readers, setReaders] = useState([]);
  // eslint-disable-next-line
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateReader, setUpdateReader] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    name: '',
    doorName: '',
  });

  const [filter, setFilter] = useState({
    name: '',
    doorName: '',
  });

  const defaultFilter = {
    name: '',
    doorName: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      doorName: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadReaders(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  const loadReaders = (page, rowsPerPage) => {
    filterReader(page + 1, rowsPerPage, (res) => {
      if (res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data)) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
        setReaders(res.data.data.Readers);
      } else {
        setTotalRecords(0);
        setPage(0);
        setReaders([]);
      }
    });
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadReaders(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadReaders(0, pageSize);
  };

  const breadcrumb = [{ path: REACT_URL_READER_LIST, name: t('READER001') }];

  const columns = [
    {
      field: 'name',
      title: t('READER004'),
      tooltip: t('READER004'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
    { field: 'doorName', title: t('DOORI001'), tooltip: t('DOORI003'), editable: 'never' },
    { field: 'deviceName', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'readerNo', title: t('COMMON011'), tooltip: t('COMMON131'), editable: 'never' },
    { field: 'commType', title: t('COMMON061'), tooltip: t('COMMON012'), editable: 'never' },
    { field: 'commAddress', title: t('COMMON062'), tooltip: t('COMMON013'), editable: 'never' },
    //{ field: 'readerState', title: t('READER003'), editable: 'never' },
    {
      field: 'readerState',
      title: t('COMMON476'),
      tooltip: t('COMMON132'),
      editable: 'never',
      render: (rowData) => {
        return rowData.readerState == 1 ? 'Out' : 'In';
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateReader(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t('READER001')} /> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          {/* <FilterReader page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} /> */}
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={readers}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditReader
          reader={updateReader}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadReaders(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
