 
import { GET_ACCESS_DOOR_DETAILS_LIST} from "../actions/types";
const initialState = {
    doorOperationDetails: [], 
};
export default function (state = initialState, action) {
     
    switch (action.type) {
        case GET_ACCESS_DOOR_DETAILS_LIST :
            return {
                ...state,
                doorOperationDetails : action.payload
            
            }
             
        default:
             return state;
    }

}