import { GET_ACCESS_DEPARTMENT_LIST,GET_ACCESS_DEPT_LIST,GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID,GET_ACCESS_LEVEL_DEPT_LIST, SUCCESS} from "../actions/types";

const initialState = {
    departmentList: [],
    departmentListByDeptId: [],
    deptList: [],
    isSuccess: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ACCESS_DEPARTMENT_LIST:
            return {
                ...state,
                departmentList: action.payload
            };
            case GET_ACCESS_DEPT_LIST:
                return {
                    ...state,
                    deptList: action.payload
                };
            case GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID:
                return {
                    ...state,
                    departmentListByDeptId: action.payload
                };
                case GET_ACCESS_LEVEL_DEPT_LIST:
                return {
                    ...state,
                    departmentListByDeptId: action.payload
                };

        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

        default:
            return state;
    }
}