import { GET_ACCREADER_LIST, UPDATE_ACCREADER } from "../actions/types";

const initialState = {
	accReaderList: [],
	updateAccReader: []
};

export default function (state = initialState, action) {
	switch (action.type) {

		case GET_ACCREADER_LIST:
			return {
				...state,
				accReaderList: action.payload
			};
		case UPDATE_ACCREADER:
			return {
				...state,
				updateAccReader: action.payload
			};

		default:
			return state;
	}
}