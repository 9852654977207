import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { listAllSites } from '../Site/SiteService';
import CommonUtil from '../../components/Util/CommonUtils';
import { updateDevice } from './DeviceService';
import { toast } from 'react-toastify';


export default function BioSpecificNumber(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);

  const[state,setState]=useState({
    selectHand:'',
    fid:''
  });

  const[error,setError]=useState({
    selectHand:'',
    fid:''
  })
  

  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 260,
      },
    },
  };
  
  const handleClose = () => {
    props.onClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.selectHand)) {
      setError({
        ...error,
        selectHand: true,
      });
      toast.error('Select your hand');
      return;
    }

    if (CommonUtil.isEmptyString(state.fid) && props.needFinger===true) {
      setError({
        ...error,
        fid: true,
      });
      toast.error('Choose your finger');
      return;
    }
      if (props.needFinger === true) {

          const payload = {
              fid: state.fid
          };
          props.handleClick(payload);
      }
   else {
       const payload = {
           fid: state.selectHand === 'right' ? 1 : 0
       };
       props.handleClick(payload);
   }
   
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex' style={{minWidth:'350px'}}>
            <Box flexGrow={2}>{'Set BioMetric Details'}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'> 
            <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' fullWidth m={1} p={1}>
                  <FormControl variant='outlined' fullWidth size='small'>
                    <InputLabel>{t('Select your hand')}</InputLabel>
                    <Select name='selectHand' label='Select your hand' onChange={handleChange}>
                      <MenuItem value={'left'}>Left hand</MenuItem>
                      <MenuItem value={'right'}>Right hand</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid> 
              {props.needFinger===true&&state.selectHand &&(<Grid item xs={12} sm={12} lg={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel>{t('Select your finger')}</InputLabel>
                    <Select
                      MenuProps={MenuProps}
                      error={error.fid}
                      fullWidth
                      label={t('Select your finger')}
                      name='fid'
                      onChange={handleChange}
                    >
                      {state.selectHand==='right' ? (
                        rightHandFinger.map((finger, index) => (
                          <MenuItem key={index} value={finger.value} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {finger.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                       leftHandFinger.map((finger, index) => (
                          <MenuItem key={index} value={finger.value} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {finger.name}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>)}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const leftHandFinger = [
    {
        name: 'Left little finger',
        value: 0
    },
    {
        name: 'Left ring  finger',
        value: 1
    },
    {
        name: 'Left middle  finger',
        value: 2
    },
    {
        name: 'Left index finger',
        value: 3
    },
    {
        name: 'Left thumb',
        value: 4
    },
];

const rightHandFinger = [
    {
        name: 'Right little finger',
        value: 9
    },
    {
        name: 'Right ring  finger',
        value: 8
    },
    {
        name: 'Right middle  finger',
        value: 7
    },
    {
        name: 'Right index finger',
        value: 6
    },
    {
        name: 'Right thumb',
        value: 5
    },
]