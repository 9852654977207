import { ANTIPASSBACK_LIST , ANTIPASSBACK_Device_LIST,ANTIPASSBACK_DEVICE_RULE} from "../actions/types";

const initialState = {
    antipassbacks: [],
    antipassbackDevices:[],
   	antipassbackDeviceRule: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ANTIPASSBACK_LIST:
            return {
                ...state,
                antipassbacks: action.payload
            }
        case ANTIPASSBACK_Device_LIST:
            return {
                ...state,
                antipassbackDevices: action.payload
            }
         case ANTIPASSBACK_DEVICE_RULE:
            return {
                ...state,
                antipassbackDeviceRule: action.payload
            }
        default:
            return state;
    }
}