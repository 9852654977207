import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import CommonUtil from '../../components/Util/CommonUtils';
import { updateDevicePasscode } from './DeviceService';
import { toast } from 'react-toastify';
import { data } from 'jquery';


export default function BioSpecificPassCode(props) {
  const [person, setPerson] = useState({ passcode: '1' });
  const [open, setOpen] = React.useState(props.open);
  const { t } = useTranslation();
  const [state, setState] = useState({
    devId: props.data.devId,
    personId: props.data.credential.employeeId,
    passcode: '',
  });

  useEffect(() => {
    console.log(state.devId);
  }, [data])
  const [error, setError] = useState({
    devId: false,
    personId: false,
    passcode: false,
  })

  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 260,
      },
    },
  };

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    setState({
      ...state,
    });
  }, [props.person, props.device]);

  const handleChangepasscode = () => {
    if (CommonUtil.isEmptyString(state.passcode)) {
      setError({
        ...error,
        passcode: true,
      });
      toast.error(t('Passcode is required'));
      return;
    }
    updateDevicePasscode(state.devId, state.personId, state.passcode, (data) => {
      if (data.data.code === 'CMSI0005') {
        toast.success(data.data.message);
        setOpen(false);
        setState({
          ...state,
        });
      }
      else if (data.data.code === 'CMSI0004') {
        toast.success(data.data.message);
        setOpen(false);
        setState({
          ...state,
        });
      }
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };



  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex' style={{ minWidth: '350px' }}>
            <Box flexGrow={2}>{'Set BioMetric Details'}</Box>
            <CancelIcon onClick={() => setOpen(false)} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Box display='flex' justifyContent='center' fullWidth m={1} p={1}>
              <TextField
                fullWidth
                name='passcode'
                required
                error={error.passcode}
                label={t('Passcode')}
                helperText=''
                variant='outlined'
                size='small'
                onChange={handleChange}
                value={state.passcode}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={() => handleChangepasscode()} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={() => setOpen(false)} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

