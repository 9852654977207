import BrowserLanguage from '../components/Util/BrowserLanguage';

let language = BrowserLanguage.getDefaultLanguage();
const countryData = [
  {
    name: 'Afghanistan',
    value: '+93',
  },
  {
    name: 'Aland Islands',
    value: '+358',
  },
  {
    name: 'Albania',
    value: '+355',
  },
  {
    name: 'Algeria',
    value: '+213',
  },
  {
    name: 'AmericanSamoa',
    value: '+1684',
  },
  {
    name: 'Andorra',
    value: '+376',
  },
  {
    name: 'Angola',
    value: '+244',
  },
  {
    name: 'Anguilla',
    value: '+1264',
  },
  {
    name: 'Antarctica',
    value: '+672',
  },
  {
    name: 'Antigua and Barbuda',
    value: '+1268',
  },
  {
    name: 'Argentina',
    value: '+54',
  },
  {
    name: 'Armenia',
    value: '+374',
  },
  {
    name: 'Aruba',
    value: '+297',
  },
  {
    name: 'Australia',
    value: '+61',
  },
  {
    name: 'Austria',
    value: '+43',
  },
  {
    name: 'Azerbaijan',
    value: '+994',
  },
  {
    name: 'Bahamas',
    value: '+1242',
  },
  {
    name: 'Bahrain',
    value: '+973',
  },
  {
    name: 'Bangladesh',
    value: '+880',
  },
  {
    name: 'Barbados',
    value: '+1246',
  },
  {
    name: 'Belarus',
    value: '+375',
  },
  {
    name: 'Belgium',
    value: '+32',
  },
  {
    name: 'Belize',
    value: '+501',
  },
  {
    name: 'Benin',
    value: '+229',
  },
  {
    name: 'Bermuda',
    value: '+1441',
  },
  {
    name: 'Bhutan',
    value: '+975',
  },
  {
    name: 'Bolivia, Plurinational State of',
    value: '+591',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: '+387',
  },
  {
    name: 'Botswana',
    value: '+267',
  },
  {
    name: 'Brazil',
    value: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    value: '+246',
  },
  {
    name: 'Brunei Darussalam',
    value: '+673',
  },
  {
    name: 'Bulgaria',
    value: '+359',
  },
  {
    name: 'Burkina Faso',
    value: '+226',
  },
  {
    name: 'Burundi',
    value: '+257',
  },
  {
    name: 'Cambodia',
    value: '+855',
  },
  {
    name: 'Cameroon',
    value: '+237',
  },
  {
    name: 'Canada',
    value: '+1',
  },
  {
    name: 'Cape Verde',
    value: '+238',
  },
  {
    name: 'Cayman Islands',
    value: '+ 345',
  },
  {
    name: 'Central African Republic',
    value: '+236',
  },
  {
    name: 'Chad',
    value: '+235',
  },
  {
    name: 'Chile',
    value: '+56',
  },
  {
    name: 'China',
    value: '+86',
  },
  {
    name: 'Christmas Island',
    value: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    value: '+61',
  },
  {
    name: 'Colombia',
    value: '+57',
  },
  {
    name: 'Comoros',
    value: '+269',
  },
  {
    name: 'Congo',
    value: '+242',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    value: '+243',
  },
  {
    name: 'Cook Islands',
    value: '+682',
  },
  {
    name: 'Costa Rica',
    value: '+506',
  },
  {
    name: 'Cote d Ivoire',
    value: '+225',
  },
  {
    name: 'Croatia',
    value: '+385',
  },
  {
    name: 'Cuba',
    value: '+53',
  },
  {
    name: 'Cyprus',
    value: '+357',
  },
  {
    name: 'Czech Republic',
    value: '+420',
  },
  {
    name: 'Denmark',
    value: '+45',
  },
  {
    name: 'Djibouti',
    value: '+253',
  },
  {
    name: 'Dominica',
    value: '+1767',
  },
  {
    name: 'Dominican Republic',
    value: '+1849',
  },
  {
    name: 'Ecuador',
    value: '+593',
  },
  {
    name: 'Egypt',
    value: '+20',
  },
  {
    name: 'El Salvador',
    value: '+503',
  },
  {
    name: 'Equatorial Guinea',
    value: '+240',
  },
  {
    name: 'Eritrea',
    value: '+291',
  },
  {
    name: 'Estonia',
    value: '+372',
  },
  {
    name: 'Ethiopia',
    value: '+251',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    value: '+500',
  },
  {
    name: 'Faroe Islands',
    value: '+298',
  },
  {
    name: 'Fiji',
    value: '+679',
  },
  {
    name: 'Finland',
    value: '+358',
  },
  {
    name: 'France',
    value: '+33',
  },
  {
    name: 'French Guiana',
    value: '+594',
  },
  {
    name: 'French Polynesia',
    value: '+689',
  },
  {
    name: 'Gabon',
    value: '+241',
  },
  {
    name: 'Gambia',
    value: '+220',
  },
  {
    name: 'Georgia',
    value: '+995',
  },
  {
    name: 'Germany',
    value: '+49',
  },
  {
    name: 'Ghana',
    value: '+233',
  },
  {
    name: 'Gibraltar',
    value: '+350',
  },
  {
    name: 'Greece',
    value: '+30',
  },
  {
    name: 'Greenland',
    value: '+299',
  },
  {
    name: 'Grenada',
    value: '+1473',
  },
  {
    name: 'Guadeloupe',
    value: '+590',
  },
  {
    name: 'Guam',
    value: '+1671',
  },
  {
    name: 'Guatemala',
    value: '+502',
  },
  {
    name: 'Guernsey',
    value: '+44',
  },
  {
    name: 'Guinea',
    value: '+224',
  },
  {
    name: 'Guinea-Bissau',
    value: '+245',
  },
  {
    name: 'Guyana',
    value: '+595',
  },
  {
    name: 'Haiti',
    value: '+509',
  },
  {
    name: 'Holy See (Vatican City State)',
    value: '+379',
  },
  {
    name: 'Honduras',
    value: '+504',
  },
  {
    name: 'Hong Kong',
    value: '+852',
  },
  {
    name: 'Hungary',
    value: '+36',
  },
  {
    name: 'Iceland',
    value: '+354',
  },
  {
    name: 'India',
    value: '+91',
  },
  {
    name: 'Indonesia',
    value: '+62',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    value: '+98',
  },
  {
    name: 'Iraq',
    value: '+964',
  },
  {
    name: 'Ireland',
    value: '+353',
  },
  {
    name: 'Isle of Man',
    value: '+44',
  },
  {
    name: 'Israel',
    value: '+972',
  },
  {
    name: 'Italy',
    value: '+39',
  },
  {
    name: 'Jamaica',
    value: '+1876',
  },
  {
    name: 'Japan',
    value: '+81',
  },
  {
    name: 'Jersey',
    value: '+44',
  },
  {
    name: 'Jordan',
    value: '+962',
  },
  {
    name: 'Kazakhstan',
    value: '+77',
  },
  {
    name: 'Kenya',
    value: '+254',
  },
  {
    name: 'Kiribati',
    value: '+686',
  },
  {
    name: 'Korea, Democratic People s Republic of Korea',
    value: '+850',
  },
  {
    name: 'Korea, Republic of South Korea',
    value: '+82',
  },
  {
    name: 'Kuwait',
    value: '+965',
  },
  {
    name: 'Kyrgyzstan',
    value: '+996',
  },
  {
    name: 'Laos',
    value: '+856',
  },
  {
    name: 'Latvia',
    value: '+371',
  },
  {
    name: 'Lebanon',
    value: '+961',
  },
  {
    name: 'Lesotho',
    value: '+266',
  },
  {
    name: 'Liberia',
    value: '+231',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    value: '+218',
  },
  {
    name: 'Liechtenstein',
    value: '+423',
  },
  {
    name: 'Lithuania',
    value: '+370',
  },
  {
    name: 'Luxembourg',
    value: '+352',
  },
  {
    name: 'Macao',
    value: '+853',
  },
  {
    name: 'Macedonia',
    value: '+389',
  },
  {
    name: 'Madagascar',
    value: '+261',
  },
  {
    name: 'Malawi',
    value: '+265',
  },
  {
    name: 'Malaysia',
    value: '+60',
  },
  {
    name: 'Maldives',
    value: '+960',
  },
  {
    name: 'Mali',
    value: '+223',
  },
  {
    name: 'Malta',
    value: '+356',
  },
  {
    name: 'Marshall Islands',
    value: '+692',
  },
  {
    name: 'Martinique',
    value: '+596',
  },
  {
    name: 'Mauritania',
    value: '+222',
  },
  {
    name: 'Mauritius',
    value: '+230',
  },
  {
    name: 'Mayotte',
    value: '+262',
  },
  {
    name: 'Mexico',
    value: '+52',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    value: '+691',
  },
  {
    name: 'Moldova',
    value: '+373',
  },
  {
    name: 'Monaco',
    value: '+377',
  },
  {
    name: 'Mongolia',
    value: '+976',
  },
  {
    name: 'Montenegro',
    value: '+382',
  },
  {
    name: 'Montserrat',
    value: '+1664',
  },
  {
    name: 'Morocco',
    value: '+212',
  },
  {
    name: 'Mozambique',
    value: '+258',
  },
  {
    name: 'Myanmar',
    value: '+95',
  },
  {
    name: 'Namibia',
    value: '+264',
  },
  {
    name: 'Nauru',
    value: '+674',
  },
  {
    name: 'Nepal',
    value: '+977',
  },
  {
    name: 'Netherlands',
    value: '+31',
  },
  {
    name: 'Netherlands Antilles',
    value: '+599',
  },
  {
    name: 'New Caledonia',
    value: '+687',
  },
  {
    name: 'New Zealand',
    value: '+64',
  },
  {
    name: 'Nicaragua',
    value: '+505',
  },
  {
    name: 'Niger',
    value: '+227',
  },
  {
    name: 'Nigeria',
    value: '+234',
  },
  {
    name: 'Niue',
    value: '+683',
  },
  {
    name: 'Norfolk Island',
    value: '+672',
  },
  {
    name: 'Northern Mariana Islands',
    value: '+1670',
  },
  {
    name: 'Norway',
    value: '+47',
  },
  {
    name: 'Oman',
    value: '+968',
  },
  {
    name: 'Pakistan',
    value: '+92',
  },
  {
    name: 'Palau',
    value: '+680',
  },
  {
    name: 'Palestinian Territory, Occupied',
    value: '+970',
  },
  {
    name: 'Panama',
    value: '+507',
  },
  {
    name: 'Papua New Guinea',
    value: '+675',
  },
  {
    name: 'Paraguay',
    value: '+595',
  },
  {
    name: 'Peru',
    value: '+51',
  },
  {
    name: 'Philippines',
    value: '+63',
  },
  {
    name: 'Pitcairn',
    value: '+872',
  },
  {
    name: 'Poland',
    value: '+48',
  },
  {
    name: 'Portugal',
    value: '+351',
  },
  {
    name: 'Puerto Rico',
    value: '+1939',
  },
  {
    name: 'Qatar',
    value: '+974',
  },
  {
    name: 'Romania',
    value: '+40',
  },
  {
    name: 'Russia',
    value: '+7',
  },
  {
    name: 'Rwanda',
    value: '+250',
  },
  {
    name: 'Reunion',
    value: '+262',
  },
  {
    name: 'Saint Barthelemy',
    value: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: '+1869',
  },
  {
    name: 'Saint Lucia',
    value: '+1758',
  },
  {
    name: 'Saint Martin',
    value: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: '+1784',
  },
  {
    name: 'Samoa',
    value: '+685',
  },
  {
    name: 'San Marino',
    value: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    value: '+239',
  },
  {
    name: 'Saudi Arabia',
    value: '+966',
  },
  {
    name: 'Senegal',
    value: '+221',
  },
  {
    name: 'Serbia',
    value: '+381',
  },
  {
    name: 'Seychelles',
    value: '+248',
  },
  {
    name: 'Sierra Leone',
    value: '+232',
  },
  {
    name: 'Singapore',
    value: '+65',
  },
  {
    name: 'Slovakia',
    value: '+421',
  },
  {
    name: 'Slovenia',
    value: '+386',
  },
  {
    name: 'Solomon Islands',
    value: '+677',
  },
  {
    name: 'Somalia',
    value: '+252',
  },
  {
    name: 'South Africa',
    value: '+27',
  },
  {
    name: 'South Sudan',
    value: '+211',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: '+500',
  },
  {
    name: 'Spain',
    value: '+34',
  },
  {
    name: 'Sri Lanka',
    value: '+94',
  },
  {
    name: 'Sudan',
    value: '+249',
  },
  {
    name: 'Suriname',
    value: '+597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: '+47',
  },
  {
    name: 'Swaziland',
    value: '+268',
  },
  {
    name: 'Sweden',
    value: '+46',
  },
  {
    name: 'Switzerland',
    value: '+41',
  },
  {
    name: 'Syrian Arab Republic',
    value: '+963',
  },
  {
    name: 'Taiwan',
    value: '+886',
  },
  {
    name: 'Tajikistan',
    value: '+992',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    value: '+255',
  },
  {
    name: 'Thailand',
    value: '+66',
  },
  {
    name: 'Timor-Leste',
    value: '+670',
  },
  {
    name: 'Togo',
    value: '+228',
  },
  {
    name: 'Tokelau',
    value: '+690',
  },
  {
    name: 'Tonga',
    value: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    value: '+1868',
  },
  {
    name: 'Tunisia',
    value: '+216',
  },
  {
    name: 'Turkey',
    value: '+90',
  },
  {
    name: 'Turkmenistan',
    value: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    value: '+1649',
  },
  {
    name: 'Tuvalu',
    value: '+688',
  },
  {
    name: 'Uganda',
    value: '+256',
  },
  {
    name: 'Ukraine',
    value: '+380',
  },
  {
    name: 'United Arab Emirates',
    value: '+971',
  },
  {
    name: 'United Kingdom',
    value: '+44',
  },
  {
    name: 'United States',
    value: '+1',
  },
  {
    name: 'Uruguay',
    value: '+598',
  },
  {
    name: 'Uzbekistan',
    value: '+998',
  },
  {
    name: 'Vanuatu',
    value: '+678',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    value: '+58',
  },
  {
    name: 'Vietnam',
    value: '+84',
  },
  {
    name: 'Virgin Islands, British',
    value: '+1284',
  },
  {
    name: 'Virgin Islands, U.S.',
    value: '+1340',
  },
  {
    name: 'Wallis and Futuna',
    value: '+681',
  },
  {
    name: 'Yemen',
    value: '+967',
  },
  {
    name: 'Zambia',
    value: '+260',
  },
  {
    name: 'Zimbabwe',
    value: '+263',
  },
];

const stateData = [
  {
    id: '1',
    name: 'Karnataka',
  },
];

const cityData = [
  {
    id: '1',
    name: 'Bengaluru',
  },
  {
    id: '2',
    name: 'Mysore',
  },
];

const timeZones = [
  {
    value: '-1200',
    name: '(GMT-12:00) International Date Line West',
  },
  {
    value: '-1100',
    name: '(UTC-11)Coordinated Universal Time-11',
  },
  {
    value: '-1000',
    name: '(UTC-10)Hawaii',
  },
  {
    value: '-0900',
    name: '(UTC-9)Alaska',
  },
  {
    value: '-0800',
    name: '(UTC-8)Pacific time (American and Canada) Baja California',
  },
  {
    value: '-0700',
    name: '(UTC-7)La Paz, The mountain time (American and Canada), Arizona',
  },
  {
    value: '-0600',
    name: '(UTC-6)Saskatchewan, Central time, Central America',
  },
  {
    value: '-0500',
    name: '(UTC-5)Bogota, Lima, Quito, Rio Branco, Eastern time, Indiana(East)',
  },
  {
    value: '-0430',
    name: '(UTC-4:30)Caracas',
  },
  {
    value: '-0400',
    name: '(UTC-4)Atlantic time, Cuiaba, Georgetown, La Paz, Santiago',
  },
  {
    value: '-0330',
    name: '(UTC-3:30)Newfoundland',
  },
  {
    value: '-0300',
    name: '(UTC-3)Brasilia, Buenos Aires, Greenland, Cayenne',
  },
  {
    value: '-0200',
    name: '(UTC-2)The International Date Line West-02',
  },
  {
    value: '-0100',
    name: '(UTC-1)Cape Verde Islands, Azores',
  },
  {
    value: '-0000',
    name: '(UTC)Dublin, Edinburgh, Lisbon, London, The International Date Line West',
  },
  {
    value: '%2B0100',
    name: '(UTC+1)Amsterdam, Brussels, Sarajevo',
  },
  {
    value: '%2B0200',
    name: '(UTC+2)Beirut, Damascus, Eastern Europe, Cairo,Athens, Jerusalem',
  },
  {
    value: '%2B0300',
    name: '(UTC+3)Baghdad, Kuwait, Moscow, St Petersburg,Nairobi',
  },
  {
    value: '%2B0330',
    name: '(UTC+3:30)Teheran or Tehran',
  },
  {
    value: '%2B0400',
    name: '(UTC+4)Abu Dhabi, Yerevan, Baku, Port Louis, Samarra',
  },
  {
    value: '%2B0430',
    name: '(UTC+4:30)Kabul',
  },
  {
    value: '%2B0500',
    name: '(UTC+5)Ashgabat, Islamabad, Karachi',
  },
  {
    value: '%2B0530',
    name: '(UTC+5:30)Chennai, Calcutta Mumbai, New Delhi',
  },
  {
    value: '%2B0545',
    name: '(UTC+5:45)Kathmandu',
  },
  {
    value: '%2B0600',
    name: '(UTC+6)Astana, Dhaka, Novosibirsk',
  },
  {
    value: '%2B0630',
    name: '(UTC+6:30)Yangon',
  },
  {
    value: '%2B0700',
    name: '(UTC+7)Bangkok, Hanoi, Jakarta',
  },
  {
    value: '%2B0800',
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi,Kuala Lumpur, Singapore',
  },
  {
    value: '%2B0900',
    name: '(UTC+9)Osaka, Tokyo, Seoul, Yakutsk',
  },
  {
    value: '%2B0930',
    name: '(UTC+9:30)Adelaide, Darwin',
  },
  {
    value: '%2B1000',
    name: '(UTC+10)Brisbane, Vladivostok, Guam, Canberra',
  },
  {
    value: '%2B1100',
    name: '(UTC+11)Solomon Islands, New Caledonia',
  },
  {
    value: '%2B1200',
    name: '(UTC+12)Anadyr, Oakland, Wellington, Fiji',
  },
  {
    value: '%2B1300',
    name: "(UTC+13)Nuku'alofa, The Samoa Islands",
  },
  {
    value: '%2B1400',
    name: '(UTC+14)Christmas Island',
  },
];

const timeZoneDataEN = [
  {
    id: '1',
    name: '(GMT-12:00) International Date Line West',
  },
  {
    id: '2',
    name: '(GMT-11:00) Midway Island, Samoa',
  },
  {
    id: '3',
    name: '(GMT-10:00) Hawaii',
  },
  {
    id: '4',
    name: '(GMT-09:00) Alaska',
  },
  {
    id: '5',
    name: '(GMT-08:00) Pacific Time (US & Canada)',
  },
  {
    id: '6',
    name: '(GMT-08:00) Tijuana, Baja California',
  },
  {
    id: '7',
    name: '(GMT-07:00) Arizona',
  },
  {
    id: '8',
    name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    id: '9',
    name: '(GMT-07:00) Mountain Time (US & Canada)',
  },
  {
    id: '10',
    name: '(GMT-06:00) Central America',
  },
  {
    id: '11',
    name: '(GMT-06:00) Central Time (US & Canada)',
  },
  {
    id: '12',
    name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
  },
  {
    id: '13',
    name: '(GMT-06:00) Saskatchewan',
  },
  {
    id: '14',
    name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
  },
  {
    id: '15',
    name: '(GMT-05:00) Eastern Time (US & Canada)',
  },
  {
    id: '16',
    name: '(GMT-05:00) Indiana (East)',
  },
  {
    id: '17',
    name: '(GMT-04:00) Atlantic Time (Canada)',
  },
  {
    id: '18',
    name: '(GMT-04:00) Caracas, La Paz',
  },
  {
    id: '19',
    name: '(GMT-04:00) Manaus',
  },
  {
    id: '20',
    name: '(GMT-04:00) Santiago',
  },
  {
    id: '21',
    name: '(GMT-03:30) Newfoundland',
  },
  {
    id: '22',
    name: '(GMT-03:00) Brasilia',
  },
  {
    id: '23',
    name: '(GMT-03:00) Buenos Aires, Georgetown',
  },
  {
    id: '24',
    name: '(GMT-03:00) Greenland',
  },
  {
    id: '25',
    name: '(GMT-03:00) Montevideo',
  },
  {
    id: '26',
    name: '(GMT-02:00) Mid-Atlantic',
  },
  {
    id: '27',
    name: '(GMT-01:00) Cape Verde Is.',
  },
  {
    id: '28',
    name: '(GMT-01:00) Azores',
  },
  {
    id: '29',
    name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
  },
  {
    id: '30',
    name: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
  },
  {
    id: '31',
    name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    id: '32',
    name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    id: '33',
    name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    id: '34',
    name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    id: '35',
    name: '(GMT+01:00) West Central Africa',
  },
  {
    id: '36',
    name: '(GMT+02:00) Amman',
  },
  {
    id: '37',
    name: '(GMT+02:00) Athens, Bucharest, Istanbul',
  },
  {
    id: '38',
    name: '(GMT+02:00) Beirut',
  },
  {
    id: '39',
    name: '(GMT+02:00) Cairo',
  },
  {
    id: '40',
    name: '(GMT+02:00) Harare, Pretoria',
  },
  {
    id: '41',
    name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    id: '42',
    name: '(GMT+02:00) Jerusalem',
  },
  {
    id: '43',
    name: '(GMT+02:00) Minsk',
  },
  {
    id: '44',
    name: '(GMT+02:00) Windhoek',
  },
  {
    id: '45',
    name: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
  },
  {
    id: '46',
    name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
  },
  {
    id: '47',
    name: '(GMT+03:00) Nairobi',
  },
  {
    id: '48',
    name: '(GMT+03:00) Tbilisi',
  },
  {
    id: '49',
    name: '(GMT+03:30) Tehran',
  },
  {
    id: '50',
    name: '(GMT+04:00) Abu Dhabi, Muscat',
  },
  {
    id: '51',
    name: '(GMT+04:00) Baku',
  },
  {
    id: '52',
    name: '(GMT+04:00) Yerevan',
  },
  {
    id: '53',
    name: '(GMT+04:30) Kabul',
  },
  {
    id: '54',
    name: '(GMT+05:00) Yekaterinburg',
  },
  {
    id: '55',
    name: '(GMT+05:00) Islamabad, Karachi, Tashkent',
  },
  {
    id: '56',
    name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    id: '57',
    name: '(GMT+05:30) Sri Jayawardenapura',
  },
  {
    id: '58',
    name: '(GMT+05:45) Kathmandu',
  },
  {
    id: '59',
    name: '(GMT+06:00) Almaty, Novosibirsk',
  },
  {
    id: '60',
    name: '(GMT+06:00) Astana, Dhaka',
  },
  {
    id: '61',
    name: '(GMT+06:30) Yangon (Rangoon)',
  },
  {
    id: '62',
    name: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    id: '63',
    name: '(GMT+07:00) Krasnoyarsk',
  },
  {
    id: '64',
    name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    id: '65',
    name: '(GMT+08:00) Kuala Lumpur, Singapore',
  },
  {
    id: '66',
    name: '(GMT+08:00) Irkutsk, Ulaan Bataar',
  },
  {
    id: '67',
    name: '(GMT+08:00) Perth',
  },
  {
    id: '68',
    name: '(GMT+08:00) Taipei',
  },
  {
    id: '69',
    name: '(GMT+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    id: '70',
    name: '(GMT+09:00) Seoul',
  },
  {
    id: '71',
    name: '(GMT+09:00) Yakutsk',
  },
  {
    id: '72',
    name: '(GMT+09:30) Adelaide',
  },
  {
    id: '73',
    name: '(GMT+09:30) Darwin',
  },
  {
    id: '74',
    name: '(GMT+10:00) Brisbane',
  },
  {
    id: '75',
    name: '(GMT+10:00) Canberra, Melbourne, Sydney',
  },
  {
    id: '76',
    name: '(GMT+10:00) Hobart',
  },
  {
    id: '77',
    name: '(GMT+10:00) Guam, Port Moresby',
  },
  {
    id: '78',
    name: '(GMT+10:00) Vladivostok',
  },
  {
    id: '79',
    name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
  },
  {
    id: '80',
    name: '(GMT+12:00) Auckland, Wellington',
  },
  {
    id: '81',
    name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
  },
  {
    id: '82',
    name: "(GMT+13:00) Nuku'alofa",
  },
];
const timeZoneDataES = [
  {
    id: '1',
    name: '(GMT-12: 00) Línea de fecha internacional Oeste',
  },
  {
    id: '2',
    name: '(GMT-11: 00) Isla Midway, Samoa',
  },
  {
    id: '3',
    name: '(GMT-10: 00) Hawai',
  },
  {
    id: '4',
    name: '(GMT-09: 00) ',
  },
  {
    id: '5',
    name: '(GMT-08: 00) Hora del Pacífico (EE. UU. y Canadá)',
  },
  {
    id: '6',
    name: '(GMT-08: 00) Tijuana, Baja California',
  },
  {
    id: '7',
    name: '(GMT-07: 00) Arizona',
  },
  {
    id: '8',
    name: '(GMT-07: 00) Chihuahua, La Paz, Mazatlán',
  },
  {
    id: '9',
    name: '(GMT-07: 00) Hora de la montaña (EE. UU. y Canadá)',
  },
  {
    id: '10',
    name: '(GMT-06: 00) Centroamérica',
  },
  {
    id: '11',
    name: '(GMT-06: 00) Hora central (EE. UU. y Canadá)',
  },
  {
    id: '12',
    name: '(GMT-06: 00) Guadalajara, Ciudad de México, Monterrey',
  },
  {
    id: '13',
    name: '(GMT-06: 00) Saskatchewan',
  },
  {
    id: '14',
    name: '(GMT-05: 00) Bogotá, Lima, Quito, Rio Branco',
  },
  {
    id: '15',
    name: '(GMT-05: 00) Hora del Este (EE. UU. y Canadá)',
  },
  {
    id: '16',
    name: '(GMT-05: 00) Indiana (Este)',
  },
  {
    id: '17',
    name: '(GMT-04: 00) Hora del Atlántico (Canadá)',
  },
  {
    id: '18',
    name: '(GMT-04: 00) Caracas, La Paz',
  },
  {
    id: '19',
    name: '(GMT-04: 00) Manaus',
  },
  {
    id: '20',
    name: '(GMT-04: 00) Santiago',
  },
  {
    id: '21',
    name: '(GMT-03: 30) Terranova',
  },
  {
    id: '22',
    name: '(GMT-03: 00) Brasilia',
  },
  {
    id: '23',
    name: '(GMT-03: 00) Buenos Aires, Georgetown',
  },
  {
    id: '24',
    name: '(GMT-03: 00) Groenlandia',
  },
  {
    id: '25',
    name: '(GMT-03: 00) Montevideo',
  },
  {
    id: '26',
    name: '(GMT-02: 00) Mid-Atlantic',
  },
  {
    id: '27',
    name: '(GMT-01: 00) Islas de Cabo Verde.',
  },
  {
    id: '28',
    name: '(GMT-01: 00) Azores',
  },
  {
    id: '29',
    name: '(GMT + 00: 00) Casablanca, Monrovia, Reykjavik',
  },
  {
    id: '30',
    name: '(GMT + 00: 00) Hora media de Greenwich: Dublín, Edimburgo, Lisboa, Londres',
  },
  {
    id: '31',
    name: '(GMT + 01: 00) Ámsterdam, Berlín, Berna, Roma, Estocolmo, Viena',
  },
  {
    id: '32',
    name: '(GMT + 01: 00) Belgrado, Bratislava, Budapest, Ljubljana, Praga',
  },
  {
    id: '33',
    name: '(GMT + 01: 00) Bruselas, Copenhague, Madrid, París',
  },
  {
    id: '34',
    name: '(GMT + 01: 00) Sarajevo, Skopje, Varsovia, Zagreb',
  },
  {
    id: '35',
    name: '(GMT + 01: 00) África Central Occidental',
  },
  {
    id: '36',
    name: '(GMT + 02: 00) Amman',
  },
  {
    id: '37',
    name: '(GMT + 02: 00) Atenas, Bucarest, Estambul',
  },
  {
    id: '38',
    name: '(GMT + 02: 00) Beirut',
  },
  {
    id: '39',
    name: '(GMT + 02: 00) Cairo',
  },
  {
    id: '40',
    name: '(GMT + 02: 00) Harare, Pretoria',
  },
  {
    id: '41',
    name: '(GMT + 02: 00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    id: '42',
    name: '(GMT + 02: 00) Jerusalén',
  },
  {
    id: '43',
    name: '(GMT + 02: 00) Minsk',
  },
  {
    id: '44',
    name: '(GMT + 02: 00) Windhoek',
  },
  {
    id: '45',
    name: '(GMT + 03: 00) Kuwait, Riyadh, Bagdad',
  },
  {
    id: '46',
    name: '(GMT + 03: 00) Moscú, San Petersburgo, Volgogrado',
  },
  {
    id: '47',
    name: '(GMT + 03: 00) Nairobi',
  },
  {
    id: '48',
    name: '(GMT + 03: 00) Tbilisi',
  },
  {
    id: '49',
    name: '(GMT + 03: 30) Teherán',
  },
  {
    id: '50',
    name: '(GMT + 04: 00) Abu Dhabi, Muscat',
  },
  {
    id: '51',
    name: '(GMT + 04: 00) Bakú',
  },
  {
    id: '52',
    name: '(GMT + 04: 00) Ereván',
  },
  {
    id: '53',
    name: '(GMT + 04: 30) Kabul',
  },
  {
    id: '54',
    name: '(GMT + 05: 00) Ekaterimburgo',
  },
  {
    id: '55',
    name: '(GMT + 05: 00) Islamabad, Karachi, Tashkent',
  },
  {
    id: '56',
    name: '(GMT + 05: 30) Chennai, Kolkata, Mumbai, Nueva Delhi',
  },
  {
    id: '57',
    name: '(GMT + 05: 30) Sri Jayawardenapura',
  },
  {
    id: '58',
    name: '(GMT + 05: 45) Katmandú',
  },
  {
    id: '59',
    name: '(GMT + 06: 00) Almaty, Novosibirsk',
  },
  {
    id: '60',
    name: '(GMT + 06: 00) Astana, Dhaka',
  },
  {
    id: '61',
    name: '(GMT + 06: 30) Yangon (Rangoon)',
  },
  {
    id: '62',
    name: '(GMT + 07: 00) Bangkok, Hanoi, Yakarta',
  },
  {
    id: '63',
    name: '(GMT + 07: 00) Krasnoyarsk',
  },
  {
    id: '64',
    name: '(GMT + 08: 00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    id: '65',
    name: '(GMT + 08: 00) Kuala Lumpur, Singapur',
  },
  {
    id: '66',
    name: '(GMT + 08: 00) Irkutsk, Ulaan Bataar',
  },
  {
    id: '67',
    name: '(GMT + 08: 00) Perth',
  },
  {
    id: '68',
    name: '(GMT + 08: 00) Taipei',
  },
  {
    id: '69',
    name: '(GMT + 09: 00) Osaka, Sapporo, Tokio',
  },
  {
    id: '70',
    name: '(GMT + 09: 00) Seúl',
  },
  {
    id: '71',
    name: '(GMT + 09: 00) Yakutsk',
  },
  {
    id: '72',
    name: '(GMT + 09: 30) Adelaide',
  },
  {
    id: '73',
    name: '(GMT + 09: 30) Darwin',
  },
  {
    id: '74',
    name: '(GMT + 10: 00) Brisbane',
  },
  {
    id: '75',
    name: '(GMT + 10: 00) Canberra, Melbourne, Sydney',
  },
  {
    id: '76',
    name: '(GMT + 10: 00) Hobart',
  },
  {
    id: '77',
    name: '(GMT + 10: 00) Guam, Port Moresby',
  },
  {
    id: '78',
    name: '(GMT + 10: 00) Vladivostok',
  },
  {
    id: '79',
    name: '(GMT + 11: 00) Magadan, Islas Salomón, Nueva Caledonia',
  },
  {
    id: '80',
    name: '(GMT + 12: 00) Auckland, Wellington',
  },
  {
    id: '81',
    name: '(GMT + 12: 00) Fiji, Kamchatka, Marshall Is.',
  },
  {
    id: '82',
    name: "(GMT + 13: 00) Nuku'alofa",
  },
];
const timeZoneDataZH = [
  {
    id: '1',
    name: '(GMT-12:00) 国际日期变更线西',
  },
  {
    id: '2',
    name: '(GMT-11:00) 萨摩亚中途岛',
  },
  {
    id: '3',
    name: '(GMT-10:00) 夏威夷',
  },
  {
    id: '4',
    name: '(GMT-09:00) 阿拉斯加',
  },
  {
    id: '5',
    name: '(GMT-08:00) 太平洋时间（美国和加拿大）',
  },
  {
    id: '6',
    name: '(GMT-08:00) 下加利福尼亚州蒂华纳',
  },
  {
    id: '7',
    name: '(GMT-07:00) 亚利桑那',
  },
  {
    id: '8',
    name: '(GMT-07:00) 奇瓦瓦州、拉巴斯、马萨特兰',
  },
  {
    id: '9',
    name: '(GMT-07:00) 山地时间（美国和加拿大）',
  },
  {
    id: '10',
    name: '(GMT-06:00) 中美洲',
  },
  {
    id: '11',
    name: '(GMT-06:00) 中部时间（美国和加拿大）',
  },
  {
    id: '12',
    name: '(GMT-06:00) 瓜达拉哈拉、墨西哥城、蒙特雷',
  },
  {
    id: '13',
    name: '(GMT-06:00) 萨斯喀彻温省',
  },
  {
    id: '14',
    name: '(GMT-05:00) 波哥大、利马、基多、里奥布兰科',
  },
  {
    id: '15',
    name: '(GMT-05:00) 东部时间（美国和加拿大）',
  },
  {
    id: '16',
    name: '(GMT-05:00) 印第安纳州（东部）',
  },
  {
    id: '17',
    name: '(GMT-04:00) 大西洋时间（加拿大）',
  },
  {
    id: '18',
    name: '(GMT-04:00) 拉巴斯加拉加斯',
  },
  {
    id: '19',
    name: '(GMT-04:00) 马瑙斯',
  },
  {
    id: '20',
    name: '(GMT-04:00) 圣地亚哥',
  },
  {
    id: '21',
    name: '(GMT-03:30) 纽芬兰',
  },
  {
    id: '22',
    name: '(GMT-03:00) 巴西利亚',
  },
  {
    id: '23',
    name: '(GMT-03:00) 布宜诺斯艾利斯,乔治城',
  },
  {
    id: '24',
    name: '(GMT-03:00) 格陵兰',
  },
  {
    id: '25',
    name: '(GMT-03:00) 蒙得维的亚',
  },
  {
    id: '26',
    name: '(GMT-02:00) 中大西洋',
  },
  {
    id: '27',
    name: '(GMT-01:00) 佛得角群岛。',
  },
  {
    id: '28',
    name: '(GMT-01:00) 亚速尔群岛',
  },
  {
    id: '29',
    name: '(GMT+00:00) 卡萨布兰卡,蒙罗维亚,雷克雅未克',
  },
  {
    id: '30',
    name: '(GMT+00:00) 格林威治标准时间：都柏林、爱丁堡、里斯本、伦敦',
  },
  {
    id: '31',
    name: '(GMT+01:00) 阿姆斯特丹、柏林、伯尔尼、罗马、斯德哥尔摩、维也纳',
  },
  {
    id: '32',
    name: '(GMT+01:00) 贝尔格莱德、布拉迪斯拉发、布达佩斯、卢布尔雅那、布拉格',
  },
  {
    id: '33',
    name: '(GMT+01:00) 布鲁塞尔、哥本哈根、马德里、巴黎',
  },
  {
    id: '34',
    name: '(GMT+01:00) 萨拉热窝、斯科普里、华沙、萨格勒布',
  },
  {
    id: '35',
    name: '(GMT+01:00) 中西非',
  },
  {
    id: '36',
    name: '(GMT+02:00) 安曼',
  },
  {
    id: '37',
    name: '(GMT+02:00) 雅典、布加勒斯特、伊斯坦布尔',
  },
  {
    id: '38',
    name: '(GMT+02:00) 贝鲁特',
  },
  {
    id: '39',
    name: '(GMT+02:00) 开罗',
  },
  {
    id: '40',
    name: '(GMT+02:00) 哈拉雷,比勒陀利亚',
  },
  {
    id: '41',
    name: '(GMT+02:00) 赫尔辛基、基辅、里加、索非亚、塔林、维尔纽斯',
  },
  {
    id: '42',
    name: '(GMT+02:00) 耶路撒冷',
  },
  {
    id: '43',
    name: '(GMT+02:00) 明斯克',
  },
  {
    id: '44',
    name: '(GMT+02:00) 温得和克',
  },
  {
    id: '45',
    name: '(GMT+03:00) 科威特、利雅得、巴格达',
  },
  {
    id: '46',
    name: '(GMT+03:00) 莫斯科、圣彼得堡、伏尔加格勒',
  },
  {
    id: '47',
    name: '(GMT+03:00) 内罗毕',
  },
  {
    id: '48',
    name: '(GMT+03:00) 第比利斯',
  },
  {
    id: '49',
    name: '(GMT+03:30) 德黑兰',
  },
  {
    id: '50',
    name: '(GMT+04:00) 阿布扎比,马斯喀特',
  },
  {
    id: '51',
    name: '(GMT+04:00) 巴库',
  },
  {
    id: '52',
    name: '(GMT+04:00) 埃里温',
  },
  {
    id: '53',
    name: '(GMT+04:30) 喀布尔',
  },
  {
    id: '54',
    name: '(GMT+05:00) 叶卡捷琳堡',
  },
  {
    id: '55',
    name: '(GMT+05:00) 伊斯兰堡、卡拉奇、塔什干',
  },
  {
    id: '56',
    name: '(GMT+05:30) 钦奈、加尔各答、孟买、新德里',
  },
  {
    id: '57',
    name: '(GMT+05:30) 斯里贾亚瓦德纳普拉',
  },
  {
    id: '58',
    name: '(GMT+05:45) 加德满都',
  },
  {
    id: '59',
    name: '(GMT+06:00) 阿拉木图,新西伯利亚',
  },
  {
    id: '60',
    name: '(GMT+06:00) 阿斯塔纳,达卡',
  },
  {
    id: '61',
    name: '(GMT+06:30) 仰光 (Rangoon)',
  },
  {
    id: '62',
    name: '(GMT+07:00) 曼谷、河内、雅加达',
  },
  {
    id: '63',
    name: '(GMT+07:00) 克拉斯诺亚尔斯克',
  },
  {
    id: '64',
    name: '(GMT+08:00) 北京、重庆、香港、乌鲁木齐',
  },
  {
    id: '65',
    name: '(GMT+08:00) 吉隆坡,新加坡',
  },
  {
    id: '66',
    name: '(GMT+08:00) 伊尔库茨克,乌兰巴托',
  },
  {
    id: '67',
    name: '(GMT+08:00) 珀斯',
  },
  {
    id: '68',
    name: '(GMT+08:00) 台北',
  },
  {
    id: '69',
    name: '(GMT+09:00) 大阪、札幌、东京',
  },
  {
    id: '70',
    name: '(GMT+09:00) 首尔',
  },
  {
    id: '71',
    name: '(GMT+09:00) 雅库茨克',
  },
  {
    id: '72',
    name: '(GMT+09:30) 阿德莱德',
  },
  {
    id: '73',
    name: '(GMT+09:30) 达尔文',
  },
  {
    id: '74',
    name: '(GMT+10:00) 布里斯班',
  },
  {
    id: '75',
    name: '(GMT+10:00) 堪培拉、墨尔本、悉尼',
  },
  {
    id: '76',
    name: '(GMT+10:00) 霍巴特',
  },
  {
    id: '77',
    name: '(GMT+10:00) 关岛,莫尔兹比港',
  },
  {
    id: '78',
    name: '(GMT+10:00) 符拉迪沃斯托克',
  },
  {
    id: '79',
    name: '(GMT+11:00) 马加丹,所罗门群岛,新喀里多尼亚',
  },
  {
    id: '80',
    name: '(GMT+12:00) 奥克兰,惠灵顿',
  },
  {
    id: '81',
    name: '(GMT+12:00) 斐济,堪察加半岛,马绍尔群岛。',
  },
  {
    id: '82',
    name: '(GMT+13:00) 努库阿洛法',
  },
];

//const timeZoneData=language === 'en' ?timeZoneDataEN  :language === 'es'?timeZoneDataES:timeZoneDataZH;
const timeZoneData = timeZoneDataEN;
export { countryData, stateData, cityData, timeZoneData, timeZones };
