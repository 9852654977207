import React from 'react';
import { Box, Grid } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/ZkbioLogo.svg';
import { makeStyles } from '@material-ui/core/styles';

import cloudAccess from '../../assets/images/onboard/cloudaccess.png';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.8em',
      backgroundColor: '#CAD0D9',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFFF',
      outline: '2px solid #FFFF',
    },
  },
}));
export default function OnBordlayout(props) {
  return (
    <Grid style={{ height: '100%', width: '100%' }}>
      <Grid
        sx={{
          width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <Grid
          sx={{
            width: { xs: '0%', sm: '0%', md: '50%', lg: '50%' },
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Grid style={{ marginBottom: '-90px' }}>{props.Image}</Grid>
        </Grid>
        <Grid
          sx={{
            width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <Logo width={'220'} height={'120'} /> */}

          {/* <img src={cloudAccess} alt='logo' className='left_img' /> */}
          <Grid sx={{ width: { lg: '60%', xs: '90%', overflow: 'auto' } }}>
            <Box marginRight={'10px'}>{props.children}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
