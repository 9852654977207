import React, { useEffect } from 'react';
import { Grid, TableContainer, Table, TableRow, TableCell, IconButton, Box } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CommonUtil from '../../components/Util/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { filterZone } from '../../actions/ZoneApiCalls';
import OverFlowText from '../accessgroup/OverFlowText';
import { makeStyles } from '@material-ui/core/styles';
import { slice } from 'lodash';
import { filterZoneV3 } from '../Zone/ZoneService';
const useStyles = makeStyles({
  root: {
    '& td': {
      padding: '10px 6px 0px 5px',
      '& p': {
        fontSize: 14,
      },
    },
  },
});
export default function DeviceTopologyZoneList(props) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.deviceTopology.selectedSite);
  const [zone, setZone] = React.useState([]);
  const [activeZoneId, setActiveZoneId] = React.useState({});
  const [scroll, setScroll] = React.useState(false)


  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedSite)) {
      loadZoneBySiteId();


    }
  }, [selectedSite]);
  let payload = {
    name: '',
    parentId: '',
    siteId: selectedSite.id
  }
  let zoneCount = ''
  const loadZoneBySiteId = () => {
    filterZoneV3(1, 1000, payload, (res) => {
      zoneCount = res.data.data.totalCount
      setZone(res.data.data.zones);
      props.renderZoneCount(zoneCount);
      dispatch({ type: "DEVICE_COUNT", payload: '' })
      dispatch({ type: 'SELECTED_SUB_DEVICE', payload: {} })
    })
  };
  const SelectedZone = (data) => {
    setActiveZoneId(data.id);
    dispatch({ type: 'SELECTED_ZONE', payload: data });
  };
  const classes = useStyles();


  return (
    <>
      <Grid style={{
        overflowX: zone.length > 9 && 'auto', height: zone.length > 9 && '34em',
        '::-webkit-scrollbar': {
          width: '2.5px',
        }
      }} >
        <TableContainer>
          <Table className={classes.root}>
            {zone?.map((data, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: data.id == activeZoneId ? '#c5dbcb' : '',
                  cursor: 'default',
                }}
                onClick={() => {
                  SelectedZone(data);
                }}
              >
                <TableCell>
                  <Box display='flex' justifyContent='space-between'>
                    {data.name ? (
                      <OverFlowText title={data.name} marginTop={6}>
                        {data.name.length <= 20 ? data.name : data.name.slice(0, 10) + '...'}

                      </OverFlowText>
                    ) : null}
                    <IconButton>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
