import axios from "axios";

export const filterAuxIn = async (page, rowsPerPage, callBack) => {
    try {
      const res = await axios.get('/web/auxiliary_inputs?page=' + page + '&size=' + rowsPerPage);
      callBack(res);
    } catch (error) {
    }
  };

  export const updateAuxIn = async (updateObject, callBack) => {
    try {
      const res = await axios.put('/web/auxiliary-in', updateObject);
      callBack(res);
    } catch (error) {
    }
  };