//OAuth-client
export const REACT_URI_LANDING = '/acc/';
export const REACT_URI_REGISTER_USER = '/acc/register-user';
export const REACT_URI_FORGOT_PASSWORD = '/acc/forgot-password';
export const REACT_URI_CREATE_COMPANY = '/acc/create-company';
export const REACT_URI_SWITCH_COMPANY = '/acc/switch-company';
//Dashboard
export const REACT_URI_DASHBOARD = '/acc/z/dashboard';

export const REACT_URI_DEPARTMENT = '/acc/department';
export const REACT_URI_DEPARTMENT_DETAILS = '/acc/department-details';

export const REACT_URI_DESIGNATION = '/acc/designation';
export const REACT_URI_DESIGNATION_DETAILS = '/acc/designation-details';

//Personal details
export const REACT_URI_PERSON = '/acc/person';
export const REACT_URL_PERSON_UPDATE = '/acc/update-person';
export const REACT_URI_PERSON_DETAILS = '/acc/person-details';

//Site Management
export const REACT_URI_ADDRESS_TYPE = '/acc/address-type';
export const REACT_URI_ADD_ADDRESS_TYPE = '/acc/addAddresstype';
export const REACT_URI_ADDRESS_TYPE_DETAILS = '/acc/addresstype-details';
export const REACT_URI_SITE_TYPE = '/acc/sitetype';
export const REACT_URI_SITE_TYPE_DETAILS = '/acc/sitetype-details';
export const REACT_URI_SITE = '/acc/z/site';
export const REACT_URI_SITE_DETAILS = '/acc/site-details';
export const REACT_URI_ZONE = '/acc/zone';
export const REACT_URI_ZONE_DETAILS = '/acc/zone-details';

export const REACT_URI_APPLICATIONS = '/acc/user-role';
export const REACT_URI_APPLICATIONS_PERSON = '/acc/user-role';
