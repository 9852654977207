export const defaultPermissionCode={
    
    'ACC_DASHBOARD':false,
    'VIEW_ACC_DASHBOARD':false,
    'ACC_ORGANIZATION':false,
    'VIEW_PERSON':false,
    'ASSING_USER_ROLE':false,
    'ACC_ROLE_PERMISSION':false,
    'ACC_CREATE_ROLE_PERMISSION':false,
    'ACC_EDIT_ROLE_PERMISSION':false,
    'ACC_DELETE_ROLE_PERMISSION':false,
    'SITE_MANAGMENT':false, 
    'ACC_VIEW_SITE':false, 
    'ACC_VIEW_ZONE':false,
    'DEVICE_MANAGEMENT':false,
    'ADD_DEVICE':false,
    'MANAGE_DEVICE':false,
    'ACCESS_CONTROL':false, 
    'ADD_TIMESLOT':false, 
    'VIEW_TIMESLOT':false, 
    'EDIT_TIMESLOT':false, 
    'DELETE_TIMESLOT':false, 
    'MANAGE_ACCESS_GROUP':false, 
    'ADD_ACCESS_GROUP':false, 
    'VIEW_ACCESS_GROUP':false, 
    'EDIT_ACCESS_GROUP':false, 
    'DELETE_ACCESS_GROUP':false,
    'ACC_REPORT':false,
    'VIEW_ACC_REPORT':false
};