import { queryAllByAltText } from '@testing-library/react';
import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { JAVA_API_SITE, JAVA_API_SITE_DELETE, JAVA_API_SITE_LIST_V3, JAVA_API_SITE_SEARCH } from './ApiEndPoints';
import { SITE_LIST } from './types';

export const getSites = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_SITE + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      if (isPageable) {
        dispatch({
          type: SITE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: SITE_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};
export const filterSite = (id,name, parentId, page, size, callBack) => async (dispatch) => {
  try {
    // let query = '';
    // if (name !== '') {
    //   query += '&name=' + name;
    // }
    const res = await axios.get(JAVA_API_SITE_SEARCH + '?page=' + page + '&size=' + size + '&id='+ id+ '&name=' + name + '&parentId=' + parentId);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      dispatch({
        type: SITE_LIST,
        payload: res.data.data,
      });
      callBack(res)
    } else {
      dispatch({
        type: SITE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const filterSiteV3 = async (filter, page, size, callBack) => {
  try {
    const res = await axios.post(JAVA_API_SITE_LIST_V3 + '?page=' + page + '&size=' + size + buildQuery(filter));
    callBack(res);
  } catch (error) {

   }
};

export const deleteSites = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.ids = ids;
    // deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_SITE_DELETE, { data: deleteObj });
    callBack(res.data);
  } catch {}
};

export const updateSite = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
       res = await axios.post(JAVA_API_SITE, [payload]);
      // res = await axios.post('http://localhost:9099/api/v2.0/site', [payload]);
    } else {
      if(CommonUtil.isEmptyString(payload.address)){
         res = await axios.put(JAVA_API_SITE + '/' + payload.id, payload);
        // res = await axios.put('http://localhost:9099/api/v2.0/site' + '/' + payload.id, payload);
      }else{
      payload.address.siteId = payload.id;
       res = await axios.put(JAVA_API_SITE + '/' + payload.id, payload);
       // res = await axios.put('http://localhost:9099/api/v2.0/site' + '/' + payload.id, payload);
    }
    }
    callBack(res.data);
  } catch {}
};
export const getSiteList = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_SITE + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      callBack(res.data.data);
    }
  }
  catch{}
}

function buildQuery(filter) {

  let query = '';
  if (filter.name != '') {
    query = '&name=' + filter.name;
  } if (filter.parentId != '') {
    query = '&parentId=' + filter.parentId;
  }
  return query;
}

export const siteListWithAddressV2 = (page,size) => async (dispatch) =>{
  try {
      const res = await axios.get('/api/v2.0/site/addres?page='+page+'&size='+size );
      if (res.status === 200 && res.data.code === 'LMSI0000') {
        dispatch({
          type: SITE_LIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: SITE_LIST,
          payload: [],
        });
      }
  }
  catch (error) { }
}