import React, { useEffect, useState } from 'react';
import { Typography, Grid, Divider, Checkbox, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../components/v4/TitleBar';
import { ReactComponent as Step1 } from '../../assets/v4/Union228.svg';
import { ReactComponent as Step2 } from '../../assets/v4/Union230.svg';
import { ReactComponent as Step3 } from '../../assets/v4/Union229.svg';
import { useHistory } from 'react-router-dom';

export default function AddDeviceInfo() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isRead, setRead] = useState(false);

  const handleSubmit = () => {
    history.push('/acc/z/device-bind');
  };
  return (
    <>
      <TitleBar title={t('Device center')} />
      <Typography variant='title' gutterBottom component='div' style={{ marginBottom: '1rem', fontWeight: 'bold', color: '#304669' }}>
        Device network configuration steps
      </Typography>
      <Grid
        container
        style={{
          backgroundColor: '#FFFF',
          padding: '2rem',
          borderRadius: '8px',
        }}
      >
        <Grid style={{ width: '30%' }}>
          <Typography variant='title' gutterBottom component='div'>
            Step 1: Power up and turn on the device
          </Typography>
          <Typography variant='fieldLabel'>If the device has a network, it will automatically connect to the network and start working.</Typography>
          <br />
          <Step1 style={{ alignSelf: 'center', width: '100%', padding: '1rem 0rem' }} />
        </Grid>
        <Divider orientation='vertical' flexItem style={{ margin: '0px 1rem' }} />

        <Grid style={{ width: '30%' }}>
          <Typography variant='title' gutterBottom component='div'>
            Step 2: Configure the network
          </Typography>
          <Typography variant='fieldLabel'>You may use blue tooth to set up the network. Or some device has touch screen that has network setting in firmware.</Typography>
          <Step2 style={{ alignSelf: 'center', width: '100%', padding: '1rem 0rem' }} />
        </Grid>
        <Divider orientation='vertical' flexItem style={{ margin: '0px 1rem' }} />

        <Grid style={{ width: '30%' }}>
          <Typography variant='title' gutterBottom component='div'>
            Step 3: Restore the factory settings
          </Typography>
          <Typography variant='fieldLabel'>Some devices can not have network setting. You may try to Reset the device factory setting.</Typography>
          <Step3 style={{ alignSelf: 'center', width: '100%', padding: '1rem 0rem' }} />
        </Grid>
      </Grid>
      <Grid
        style={{
          fontFamily: 'Roboto',
          color: '#304669',
          cursor: 'default',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '2rem 0.4rem',
        }}
      >
        <Grid container style={{ alignSelf: 'center' }}>
          <Checkbox onChange={(e) => setRead(e.target.checked)} style={{ padding: '0px', fontSize: '16px' }} />{' '}
          <Typography style={{ fontWeight: 'bold' }}>{t('I have read these instructions')}</Typography>
        </Grid>
        <Grid>
          <Button
            variant='contained'
            disabled={!isRead}
            onClick={() => handleSubmit()}
            style={{
              width: '200px',
              height: '48px',
              backgroundColor: '#304669',
              color: 'white',
            }}
          >
            {t('Continue')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
