import axios from "axios"


export const loadOrgPersons = async (page, size, filter, callBack) => {
    let query = 'page=' + page + '&size=' + size + buildQuery(filter);
    try {
        const res = await axios.post('/web/organization/person/list?' + query);
        callBack(res)
        return
    } catch (error) {

    }

    function buildQuery(filter) {

        let query = ''

        if (filter.firstName != '') {
            query = query + `&firstName= ${filter.firstName}`
            return query.replace(" ", "")
        }
        if (filter.lastName != '') {
            query = query + `&lastName= ${filter.lastName}`
            return query.replace(" ", "")
        }

        if (filter.code != '') {
            query = query + `&code= ${filter.code}`
            return query.replace(" ", "")
        }

        if (filter.email != '') {
            query = query + `&email = ${filter.email}`
            return query.replace(" ", "")
        }

        if (filter.phone != '') {
            query = "&phone=" + filter.phone
        }
        return query

    }
}



