import React from 'react';
import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import OnboardLayout from './OnboardLayout';
import { ReactComponent as ForgotPasswordIMG } from '../../assets/loginImage.svg';
import { REACT_LOGIN } from '../../actions/EndPoints';

export default function SessionOut() {
  const history = useHistory();
  var location = useLocation();
  return (
    <OnboardLayout Image={<ForgotPasswordIMG />}>
      <Typography variant='h4' component='div' gutterBottom style={{ color: '#474A4E' }}>
        Session timeout,
      </Typography>

      <br></br>
      <Button
        style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }}
        onClick={() => {
          let zlinkUrl = '';
          if (window.location.href.includes('staging')) {
            zlinkUrl = 'https://zlink-staging.minervaiotdev.com';
          } else if (window.location.href.includes('10.10.10.142')) {
            zlinkUrl = 'http://10.10.10.142:8021';
          } else if (window.location.href.includes('dev')) {
            zlinkUrl = 'https://zlink-web.minervaiotdev.com';
          } else {
            zlinkUrl = 'https://zlink.minervaiot.com';
          }
          window.location = zlinkUrl;
        }}
      >
        Login with zlink
      </Button>
    </OnboardLayout>
  );
}
