import axios from 'axios';
import { toast } from 'react-toastify';

export const roleList = (name, code) => async (dispatch) => {
  try {
    const res = await axios.get('/web/role/list' + '?name=' + name + '&code=' + code);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: 'ROLE_LIST',
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: 'ROLE_LIST',
        payload: [],
      });
    }
  } catch {}
};

export const deleteRole = (roleId, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete('/web/role/' + roleId);
    callBack(res.data);
  } catch {
    console.log('There is some error');
  }
};

export const getPermissionsByRoleId = (roleId) => async (dispatch) => {
  try {
    const res = await axios.get(`/web/role/${roleId}/permissions`);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: 'ROLE_PERMISSION_LIST',
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: 'ROLE_PERMISSION_LIST',
        payload: [],
      });
    }
  } catch {}
};
export const resetRolePermission = (roleId) => async (dispatch) => {
  try {
    dispatch({
      type: 'ROLE_PERMISSION_LIST',
      payload: [],
    });
  } catch {}
};
export const getPermissionsList = () => async (dispatch) => {
  try {
    const res = await axios.get(`/web/role/permissionsList`);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: 'PERMISSION_LIST',
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: 'PERMISSION_LIST',
        payload: [],
      });
    }
  } catch {}
};
export const updateRole = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(`/web/role/${payload.id}`, payload);
    callBack(res.data);
  } catch {
    console.log('There is some error');
  }
};

export const createRole = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post('/web/role', payload);
    callBack(res.data);
  } catch {
    toast.error('There is some error in server');
  }
};
