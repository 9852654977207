import axios from "axios";

export const filterAuxOut = async (page, rowsPerPage, callBack) => {
    try {
      const res = await axios.get('/web/auxiliary_outputs?page=' + page + '&size=' + rowsPerPage);
      callBack(res);
    } catch (error) {
    }
  };

  export const updateAuxOut = async (updateObject, callBack) => {
    try {
      const res = await axios.put('/web/auxiliary-out', updateObject);
      callBack(res);
    } catch (error) {
    }
  };