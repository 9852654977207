import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as AddIcon } from '../../assets/RoleAndPermissionIcon/addIcon.svg';
import CachedIcon from '@material-ui/icons/Cached';
import { styled } from '@material-ui/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roleList, deleteRole } from '../../actions/organization/RoleApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import OverFlowText from '../../screens/accessgroup/OverFlowText';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ReactComponent as ViewIcon } from '../../assets/icons/ViewIcon.svg';
import { FilterRole } from './FilterRole';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PermissionList } from './PermissionList';
import { useHistory } from 'react-router-dom';
import { REACT_ADD_ROLE_PERMISSION_V4, REACT_EDIT_ROLE_PERMISSION_V4, REACT_VIEW_ROLE_PERMISSION_V4 } from '../../actions/EndPoints';

const ToolBar = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '8px',
});

const ActionLayout = styled(Grid)({
  backgroundColor: '#FFFF',
  width: '100%',
  padding: '12px',
  margin: '12px 0px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default function RoleAndPermissionList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roleStore = useSelector((state) => state.roleAndPermission.roles);
  const [roleData, setRoleData] = useState([]);
  const history = useHistory();
  const defaultFilter = {
    name: '',
    code: '',
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(roleStore)) setRoleData(roleStore.roles);
  }, [roleStore]);

  const loadData = (name, code) => {
    dispatch(roleList(name, code));
  };

  useEffect(() => {
    loadData(defaultFilter.name, defaultFilter.code);
  }, []);

  const applyFilter = (data) => {
    loadData(data.name, data.code);
  };

  const deleteRoleDialog = (roleId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(deleteRole(roleId, (data) => handlError(data)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'UASI0043':
        toast.success(data.message);
        loadData('', '');
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  return (
    <Box height={'100%'}>
      <ToolBar>
        <Typography style={{ color: '#3D4977', fontWeight: 500, fontSize: '16px' }}>{t('ROLEPERMISSION001')}</Typography>
        <Box display={'flex'}>
          <Tooltip title={t('COMMON002')}>
            <IconButton aria-label={t('COMMON009')} >
              <CachedIcon color='secondary' style={{ height: '25px', cursor: 'pointer' }} onClick={() => loadData('', '')} />
            </IconButton>
          </Tooltip>
          <FilterRole applyFilter={(data) => applyFilter(data)} />
          <Tooltip title={t('COMMON009')}>
            <AddIcon cursor='pointer' style={{ height: '30px', cursor: 'pointer', position: 'relative', top: '9px' }} onClick={() => history.push(REACT_ADD_ROLE_PERMISSION_V4)} />
          </Tooltip>

        </Box>
      </ToolBar>
      <Box>
        {!CommonUtil.isEmpty(roleData) ? (
          roleData.map(
            (item, index) =>
              (item.code === t('OWNER') || item.scope !== 'GLOBAL') && (
                <ActionLayout key={index}>
                  <OverFlowText children={item.name} fontSize='1.2rem' color='#3b3e66' />
                  <Box style={{ alignItems: 'center', display: 'flex' }}>
                    <Tooltip title={t('COMMON473')}>
                      <ViewIcon
                        color='secondary'
                        style={{ width: '1.7rem',  cursor: 'pointer' }}
                        onClick={() => history.push({ pathname: REACT_VIEW_ROLE_PERMISSION_V4, state: item })}
                      />
                    </Tooltip>

                    {item.name?.toLowerCase() !== 'owner' ? (
                      <>
                        <Tooltip title={t('COMMON005')}>
                          <EditOutlinedIcon
                            color='secondary'
                            style={{ margin: '0px 8px', width: '2rem', cursor: 'pointer' }}
                            onClick={() => history.push({ pathname: REACT_EDIT_ROLE_PERMISSION_V4, state: item })}
                          />
                        </Tooltip>

                        <Tooltip title={t('delete')}>
                          <DeleteOutlineIcon color='secondary' style={{ width: '1.7rem', cursor: 'pointer' }} onClick={() => deleteRoleDialog(item.id)} />
                        </Tooltip>


                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </ActionLayout>
              )
          )
        ) : (
          <Box marginTop={'200px'}>
            <Typography fontSize={'16px'} fontWeight={500} color={'#3D4977'} textAlign={'center'}>
              {t('ROLEPERMISSION002')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
