import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { REACT_URL_ACCESS_GROUP_FILTER_V4 } from '../../actions/EndPoints';

export const postAccessGroup = async (payload, callBack) => {
  try {
    const res = await axios.post('/web/access_group', payload);
    callBack(res);
  } catch (error) {}
};

export const updateAccessGroup = async (id, payload, callBack) => {
  try {
    const res = await axios.put('/web/access_groups?' + 'id=' + id, payload);
    callBack(res);
  } catch (error) {}
};

export const getAccessGroup = async (page, size, callBack) => {
  try {
    const res = await axios.get('/web/access_groups?pageNumber=' + page + '&pageSize=' + size);
    callBack(res);
  } catch (error) {}
};

export const deleteAcccessGroupById = (ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete('/web/access_groups/' + ids);
    callBack(res);
  } catch (error) {}
};

export const getFilterAccessGroup = async (page, size, filter, callBack) => {
  try {
    if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(size)) {
      const res = await axios.post(REACT_URL_ACCESS_GROUP_FILTER_V4 + '?page=' + page + '&size=' + size + buildQuery(filter));
      callBack(res);
      return;
    }
    if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(size)) {
      const res = await axios.post('/web/access_groups_filter?page=' + page + '&size=' + size);
      callBack(res);
      return;
    }
  } catch (error) {}
};

export const getAccessGroupDoors = async (page, size, accessGroupId, callBack) => {
  try {
    const res = await axios.get('/web/access_group_doors/' + accessGroupId + '/list?pageNumber=' + page + '&pageSize=' + size);
    callBack(res);
  } catch (error) {}
};

export const addDoorsToAccessGroup = async (accessGroupId, payload, callBack) => {
  try {
    const res = await axios.post('/web/access_group_doors/' + accessGroupId, payload);
    callBack(res);
  } catch (error) {}
};

export const deleteAcccessGroupDoorById = async (accessGroupId, doorId, callBack) => {
  try {
    const res = await axios.delete('/web/access_group_doors/' + accessGroupId + '/doors/' + doorId);
    callBack(res);
  } catch (error) {}
};

export const getAccessGroupPersons = async (page, size, accessGroupId, callBack) => {
  try {
    const res = await axios.get('/web/access_group_persons/' + accessGroupId + '?page=' + page + '&size=' + size);
    callBack(res);
  } catch (error) {}
};

export const addPersonsToAccessGroup = async (accessGroupId, payload, callBack) => {
  try {
    const res = await axios.post('/web/access_groups_persons/' + accessGroupId, payload);
    callBack(res);
  } catch (error) {}
};

export const deleteAcccessGroupPersonById = async (accessGroupId, personId, callBack) => {
  try {
    const res = await axios.delete('/web/access_group_persons/' + accessGroupId + '/persons/' + personId);
    callBack(res);
  } catch (error) {}
};

function buildQuery(filter) {
  let query = '';

  if (filter.name) {
    query = query + `&name=${filter.name}`;
  }

  if (filter.id) {
    query = query + `&id=${filter.id}`;
  }

  return query;
}
