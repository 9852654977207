import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import {
  REACT_LOGIN,
  REACT_URL_SUBSCRIPTION_ORDERS,
  REACT_URL_SUBSCRIPTION,
  REACT_VIEW_ROLE_LIST_V4,
  REACT_VIEW_ROLE_PERMISSION_V4,
  REACT_EDIT_ROLE_PERMISSION_V4,
  REACT_ADD_ROLE_PERMISSION_V4,
  REACT_URL_CREATESUBSCRIBE,
  REACT_GET_READERS_V4,
  REACT_GET_AUXIN_V4,
  REACT_GET_AUXOUT_V4,
  REACT_URL_EDIT_DOOR_V4,
} from './actions/EndPoints';
import LoginForm from './pages/onboard/LoginForm';
import jwt_decode from 'jwt-decode';
import setJWTToken from './oauth-client/setJWTToken';
import { SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from './actions/types';
import { logout } from './oauth-client/OauthClientActions';
import store from './config/configureStore';
import axios from 'axios';
import SecuredRoute from './oauth-client/SecureRoute';
import { hideLoader, showLoader } from './actions/LoaderActions';
import Zlink from './pages/onboard/Zlink';
import AddDeviceInfo from './screens/device/AddDeviceInfo';
import AddDevice from './screens/device/AddDevice';
import DeviceList from './screens/device';
import AccessGroup from './screens/accessgroup/Index';
import Schedules from './screens/schedules/Index';
import AddSchedules from './screens/schedules/AddSchedules';
import DoorList from './screens/door';
import AccessGroupPerson from './screens/accessgroup/AccessGroupPerson';
import AddAccessGroupPerson from './screens/accessgroup/AddAccessGroupPerson';

import PersonZ from './screens/Person/Person';
import UpdatePersonZ from './screens/Person/UpdatePerosns';
import SiteZ from './screens/Site/Site';
import ZoneZ from './screens/Zone/Zone';
import AccessGroupDoor from './screens/accessgroup/AccessGroupDoor';
import AddAccessGroupDoor from './screens/accessgroup/AddAccessGroupDoor';
import UpdateSchedules from './screens/schedules/UpdateSchedules';
import Events from './screens/event/Index';
import DeviceTopologyMainPage from './screens/device/DeviceTopologyMainPage';
import DeviceTopologyToggle from './screens/device/DeviceTopologyToggle';
import DashBoard from './screens/dashboard/DashBoard';
import RoleAndPermissionList from './pages/Role&PermissionZlink/RoleAndPermissionList';
import Subscription from './screens/Subscription/Subscription';
import { ViewRoleAndPermission } from './pages/Role&PermissionZlink/ViewRoleAndPermission';
import { UpdatePermission } from './pages/Role&PermissionZlink/UpdatePermission';
import { CreateRole } from './pages/Role&PermissionZlink/CreateRole';
import subscribe from './screens/Subscription/CreateSubscription';
import SubscriptionOrders from './screens/Subscription/SubscriptionOrders';
import ReaderList from './screens/reader';
import AuxinList from './screens/auxin';
import AuxoutList from './screens/auxout';
import MainLayout from './components/layout-componets/MainLayout';
import ViewSite from './screens/Site/ViewSite';
import { CredentialList } from './screens/device/CredentialList';
import DoorAccessTime from './screens/door-access-time/DoorAccessTime';
import ViewPerson from './screens/Person/ViewPerson';
import SessionOut from './components/layout-componets/SessionOut';
import CredentialDevice from './screens/device/CredentialDevice';
import EditDoorPage from './screens/door/EditDoorPage';

const jwtToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

if (jwtToken) {
  try {
    setJWTToken(jwtToken);
    const decoded_jwtToken = jwt_decode(jwtToken);
    store.dispatch({
      type: SET_CURRENT_USER,
      payload: decoded_jwtToken,
    });

    store.dispatch({
      type: SET_REFRESH_TOKEN,
      payload: localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN),
    });
    store.dispatch({
      type: SET_ACCESS_TOKEN,
      payload: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
    });

    const currentTime = Date.now() / 1000;
    if (decoded_jwtToken.exp < currentTime) {
      store.dispatch(logout());
      window.location.href = '/';
    }
  } catch (error) {
    localStorage.removeItem('companyCode');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyId');
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
    window.location.href = '/';
  }
} else if (localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN)) {
  setJWTToken(localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN));
} else {
  localStorage.removeItem('companyCode');
  localStorage.removeItem('companyName');
  localStorage.removeItem('companyId');
  localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
  localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
}

axios.interceptors.request.use(
  (config) => {
    store.dispatch(showLoader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (config) => {
    store.dispatch(hideLoader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Routes = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Redirect exact from='/' to={REACT_LOGIN} />
        <Route path={REACT_LOGIN} component={LoginForm} />
        <Route path={'/acc/error'} component={SessionOut} />
        <Route path='/acc/zlink' component={Zlink} />
        <MainLayout>
          <SecuredRoute path={'/acc/z/person'} component={PersonZ} />
          <SecuredRoute path={'/acc/z/view-person'} component={ViewPerson} />
          <SecuredRoute exact path={'/acc/z/site'} component={SiteZ} />
          <SecuredRoute exact path={'/acc/z/site-form'} component={ViewSite} />
          <SecuredRoute exact path={'/acc/z/zone'} component={ZoneZ} />
          <SecuredRoute path={'/acc/z/device-add-instruction'} component={AddDeviceInfo} />
          <SecuredRoute path={'/acc/z/device-bind'} component={AddDevice} />
          <SecuredRoute path={'/acc/z/device'} component={DeviceList} />
          <SecuredRoute path={'/acc/z/schedules'} component={Schedules} />
          <SecuredRoute path={'/acc/z/add/schedules'} component={AddSchedules} />
          <SecuredRoute path={'/acc/z/door'} component={DoorList} />
          <SecuredRoute path={'/acc/z/access-group'} component={AccessGroup} />
          <SecuredRoute path={'/acc/z/access-group-person'} component={AccessGroupPerson} />
          <SecuredRoute path={'/acc/z/access-group-add-person'} component={AddAccessGroupPerson} />
          <SecuredRoute path={'/acc/z/access-group-door'} component={AccessGroupDoor} />
          <SecuredRoute path={'/acc/z/access-group-add-door'} component={AddAccessGroupDoor} />
          <SecuredRoute path={'/acc/z/schedules-update'} component={UpdateSchedules} />
          <SecuredRoute path={'/acc/z/events'} component={Events} />
          <SecuredRoute path={'/acc/z/device-topology'} component={DeviceTopologyMainPage} />
          <SecuredRoute path={'/acc/z/device-topology-toggle'} component={DeviceTopologyToggle} />
          <SecuredRoute path={REACT_URL_EDIT_DOOR_V4} component={EditDoorPage} />
          
          <SecuredRoute path={REACT_URL_SUBSCRIPTION_ORDERS} component={SubscriptionOrders} />
          <SecuredRoute path={REACT_URL_SUBSCRIPTION} component={Subscription} />
          <SecuredRoute path={REACT_URL_CREATESUBSCRIBE} component={subscribe} />

          <SecuredRoute path={'/acc/z/dashboard'} component={DashBoard} />
          <SecuredRoute path={'/acc/z/persons-in-device'} component={CredentialList} />
          <SecuredRoute path={REACT_VIEW_ROLE_LIST_V4} component={RoleAndPermissionList} />
          <SecuredRoute path={REACT_VIEW_ROLE_PERMISSION_V4} component={ViewRoleAndPermission} />
          <SecuredRoute path={REACT_EDIT_ROLE_PERMISSION_V4} component={UpdatePermission} />
          <SecuredRoute path={REACT_ADD_ROLE_PERMISSION_V4} component={CreateRole} />
          <SecuredRoute path={REACT_GET_READERS_V4} component={ReaderList} />
          <SecuredRoute path={REACT_GET_AUXIN_V4} component={AuxinList} />
          <SecuredRoute path={REACT_GET_AUXOUT_V4} component={AuxoutList} />
          <SecuredRoute path={'/acc/z/door-access-time'} component={DoorAccessTime} />
          <SecuredRoute path={'/acc/z/credential/device'} component={CredentialDevice} />
        </MainLayout>
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
