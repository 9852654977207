import { Box, FormControl, MenuItem } from '@material-ui/core';
import { Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import { updateDeviceRole } from './DeviceService';
export default function SelectRole(props) {
  const [person, setPerson] = useState({ role: '0' });
  useEffect(() => {
    setPerson({
      ...props.person,
      role: props.person?.role ? props.person.role : '0',
    });
  }, [props.person, props.device]);

  const handleChangeRole = (event) => {
    const role = event.target.value;

    updateDeviceRole(props.selectedPerson.devId, props.selectedPerson.pin, role, (data) => {
      if (data.data.code === 'CMSI0005') {
        toast.success(data.data.message);
        setPerson({
          ...person,
          role: role,
        });
      } else {
        toast.error(data.data.message);
      }
    });
  };
  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select style={{ height: '25px' }} labelId='demo-simple-select-label' value={person?.role} defaultValue={person?.role} onChange={handleChangeRole}>
            {person.role === '0' && <MenuItem value={'0'}>Select User role</MenuItem>}
            <MenuItem value={'ADMINSTRATOR'}>Super admin</MenuItem>
            {/* <MenuItem value={'REGISTRAR'}>Enroller</MenuItem> */}
            <MenuItem value={'COMMONUSER'}>Normal user</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
