import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomInput from '../../components/CustomInput';
import { toast } from 'react-toastify';

export const FilterRole = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filterState, setFilterState] = useState({ name: '', code: '' });

  const handleClose = () => {
    setOpen(!open)
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setFilterState({
      ...filterState,
      [name]: event.target.value,
    });
  };

  const reset = () => {
    if (filterState.name.trim() === '' && filterState.code.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }

    setFilterState({
      name: '',
      code: '',
    });

  };

  const handleSubmit = () => {
    if (filterState.name.trim() === '' && filterState.code.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    props.applyFilter(filterState);
    setFilterState({
      name: '',
      code: '',
    });
    handleClose();
  };

  const FieldRoleCode = (key) => (
    <TextField
      fullWidth
      value={filterState.code}
      size='small'
      name='code'
      label={'code'}
      variant='outlined'
      onChange={handleChange}
    />
  );
  const FieldRoleName = (key) => (
    <TextField
      style={{ margin: '10px 0px' }}
      fullWidth
      value={filterState.name}
      size='small'
      name='name'
      label={'name'}
      variant='outlined'
      onChange={handleChange}
    />
  );
  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton aria-label='Reset filter'>
          <FilterListIcon
            color='secondary'
            style={{ height: '25px', cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='md' open={open} onClose={handleClose} className='filter-dialog'>
        <DialogTitle>
          <Box display='flex' justifyContent={'space-between'}>
            <Typography> {t('COMMON006')}</Typography>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent justifyContent='center'>
          {FieldRoleName()}
          {FieldRoleCode()}
        </DialogContent>
        <DialogActions className='mr-4' style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => handleSubmit()}
            color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON024')}
          </Button>
          <Button
            onClick={() => reset()}
            color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON025')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
