import React, { useState, useEffect } from 'react';
import { TableContainer, Table, Grid, TableRow, TableCell, IconButton, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { filterSiteV3 } from '../../actions/organization/SiteApiCalls';
import OverFlowText from '../accessgroup/OverFlowText';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { slice } from 'lodash';
import { RESET_TOPOLOGY } from './types';
const useStyles = makeStyles({
  root: {
    '& td': {
      padding: '10px 6px 0px 5px',
      '& p': {
        fontSize: 14,
      },
    },
  },
});

export default function DeviceTopologySiteList(props) {
  const [site, setSite] = useState([]);
  const dispatch = useDispatch();
  const [activeSiteId, setActiveSiteId] = useState({});

  useEffect(() => {
    dispatch({
      type: RESET_TOPOLOGY,
      payload: {},
    });
    loadSite();
  }, []);

  let siteCount = '';
  const [filter, setFilter] = useState({
    id: '',
    name: '',
    parentId: '',
  });
  const loadSite = () => {
    filterSiteV3(filter, 1, 1000, (res) => {
      siteCount = res.data.data.totalCount;
      setSite(res.data.data.sites);
      props.renderSiteCount(siteCount);
    });
  };
  const selectSite = (data) => {
    setActiveSiteId(data.id);
    dispatch({ type: 'RESET_TOPOLOGY', payload: {} });
    dispatch({ type: 'SELECTED_SITE', payload: data });
  };

  const classes = useStyles();
  return (
    <>
      <Grid sx={{
        overflowX: site?.length > 9 && 'auto', height: site?.length > 9 && '34em',
        '::-webkit-scrollbar': {
          width: '2.5px',
        }

      }}>
        <TableContainer>
          <Table className={classes.root}>
            {site.map((data, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: data.id == activeSiteId ? '#c5dbcb' : '',
                  cursor: 'default',
                }}
                onClick={() => {
                  selectSite(data);
                }}
              >
                <TableCell>
                  <Box display='flex' justifyContent='space-between'>
                    {data.name ? (
                      <OverFlowText title={data.name} marginTop={6}>
                        {data.name.length <= 20 ? data.name : data.name.slice(0, 10) + '...'}
                      </OverFlowText>
                    ) : null}

                    <IconButton>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
