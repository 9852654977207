import * as React from 'react';
import { Divider, Container, Toolbar, Box, AppBar, Button } from '@mui/material';
import CompanyProfileMenu from '../layout-componets/CompanyProfileMenu.js';
import UserProfileMenu from '../layout-componets/UserProfileMenu.js';
import { ReactComponent as IotLogo } from '../../assets/images/zlink-ca-logo.svg';
import { REACT_URL_SUBSCRIPTION } from '../../actions/EndPoints.js';
import { useHistory } from 'react-router-dom';
import {ReactComponent as CloudACCLogo} from '../../assets/cloud2.svg';

export default function Header() {
  const history = useHistory();
 

  return (
    <AppBar
      position='sticky'
      style={{
        height: '60px',
        color: '#304669',
        fontFamily: 'Roboto',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 0px 0px',
      }}
    >
      <Container maxWidth='xl' style={{ padding: '0px 12px', margin: '0px', minWidth: '100%' }}>
        <Toolbar disableGutters>
          <CloudACCLogo style={{cursor:'pointer'}} onClick={()=>history.push({pathname:'/acc/z/dashboard'})}/>
          <IotLogo className='pointer' sx={{ display: { xs: 'none', md: 'flex' }}} height={'20px'} width='200px' style={{marginLeft:'-10px',cursor:'pointer'}} onClick={()=>history.push({pathname:'/acc/z/dashboard'})}/>
          <Box display='flex' flexDirection='row-reverse' width='100%'>
            <Box item className='profile-icon' style={{ margin: '0px 35px' }}>
              <UserProfileMenu />
            </Box>
            <Box item className='profile-icon'>
              <CompanyProfileMenu />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
