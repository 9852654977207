import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices, removeDevice } from '../../actions/DeviceApiCalls';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import EditDevice from './EditDevice';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DeviceOperations from './DeviceOperations';
import { REACT_URL_DEVICE_ADD, REACT_URL_DEVICE_LIST } from '../../actions/EndPoints';
import CommonUtil from '../../components/Util/CommonUtils';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
// import DeviceViewAndGetOperations from './DeviceViewAndGetOperations';
import CachedIcon from '@material-ui/icons/Cached';
import { filterDevices, deleteDevice, findDevice } from './DeviceService';
import DeviceFilter from './DeviceFilter';
import EditDevice from './EditDevice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as LeftArrow } from '../../assets/LeftArrow.svg'
import { useLocation } from 'react-router-dom';
import TitleBar from '../../components/v4/TitleBar';
import { syncPersonToDevice } from './DeviceService';
export default function CredentialDevice(props) {
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const { t } = useTranslation();
    const [devices, setDevices] = useState([]);
    const [selectedDevices, onSelectionChange] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [updateDevice, setUpdateDevice] = useState({});
    const [page, setPage] = React.useState(0);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [reloads, setReloads] = useState(false);




    const [filter, setFilter] = useState({
        sn: '',
        alias: '',
        zoneId: '',
    });

    const defaultFilter = {
        sn: '',
        alias: '',
        zoneId: '',
    };

    const applyFilter = (filterData) => {
        const query = {
            sn: filterData.sn,
            alias: filterData.alias,
            zoneId: filterData.zoneId,
        };
        setFilter({
            ...filter,
            ...query,
        });
    };

    const resetFilter = () => {
        setFilter({
            ...defaultFilter,
        });
    };

    useEffect(() => {
        loadDevices(0, rowsPerPage);
    }, [filter]);

    useEffect(() => {
        if (reloads === true) loadDevices(0, rowsPerPage);
    }, [reloads]);

    const loadDevices = (page, rowsPerPage) => {
        let payload = {
            sn: filter.sn,
            alias: filter.alias,
            zoneId: filter.zoneId,
        };

        filterDevices(page + 1, rowsPerPage, payload, (res) => {
            if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.devices instanceof Array) {
                setTotalRecords(res.data.data.totalCount);
                setPage(res.data.data.currentPage - 1);
            } else {
                setTotalRecords(0);
                setPage(0);
            }
            setDevices(res.data.data.devices);
        });
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        loadDevices(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (pageSize) => {
        setRowsPerPage(pageSize);
        setPage(0);
        loadDevices(0, pageSize);
    };

    const columns = [
        {
            field: 'deviceAlias',
            title: t('Device Name'),
            tooltip: t('DEVI0002'),
            render: (rowData) => (!CommonUtil.isEmptyString(rowData.deviceAlias) ? rowData.deviceAlias : rowData.ip),
        },
        { field: 'sn', title: t('COMMON055'), tooltip: t('DEVI0003') },
        { field: 'ip', title: t('COMMON056'), tooltip: t('DEVI0004') },
        { field: 'deviceModel', title: t('Device Model'), tooltip: t('DEVI0005') },
        {
            field: 'registrationDevice',
            title: t('COMMON058'),
            tooltip: t('DEVI0006'),
            render: (rowData) => (rowData.registrationDevice ? 'Yes' : 'No'),
        },
        { field: 'fwVersion', title: t('COMMON059'), tooltip: t('DEVI0007') },
        {
            field: 'deviceStatus',
            title: t('COMMON001'),
            tooltip: t('DEVI0078'),
            render: (rowData) => (
                <span
                    style={{
                        fontWeight: 'bold',
                        color: rowData.status === 1 ? 'green' : 'red',
                    }}
                >
                    {rowData.status === 1 ? 'Online' : 'Offline'}
                </span>
            ),
        },
    ];

    const actions = [
    ];

    const options = {
        actionsColumnIndex: -1,
        search: false,
        selection: true,
        toolbar: false,
        paging: true,
        initialPage: 1,
        pageSize: rowsPerPage,
        maxBodyHeight: "60vh",
    };
    const reload = (value) => {
        setReloads(value);
    };

    const handleSubmit = () => {
        let payload = {
            deviceId: location.state.selectedId.device.id,
            persons: location.state.selectedId.personId
        }
        syncPersonToDevice(payload,(res) => {
            if(res.data.code === "DMSI0000") {
                toast.success(res.data.data.success[0].msg)
            }
            else if(res.data.code === "DMSW0000"){
                toast.error(res.data.data.error[0].msg)
            }
        })

    }

    const handleClose = () => {
        history.push({ pathname: '/acc/z/persons-in-device', state: { selectedDevice: [location.state.selectedId.device] } })

    }
    return (
        <Grid>
            <TitleBar
                title={
                    <Typography ml={'10px'} fontSize={'20px'} sx={{ color: '#3d4977', position: 'relative', top: '2px' }}>
                        {t('Device')}
                    </Typography>
                }
                path={true}
                onClick={() => history.push({ pathname: '/acc/z/persons-in-device', state: { selectedDevice: [location.state.selectedId.device] } })}

            />
            <Toolbar>
                <Box p={1}>
                    <Tooltip title={t('COMMON002')}>
                        <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
                            <CachedIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box p={1}>{<DeviceFilter page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />}</Box>
            </Toolbar>
            <DataTable
                columns={columns}
                data={devices}
                actions={actions}
                onSelectedRoles={(rows) => onSelectionChange(rows)}
                options={options}
                count={totalRecords}
                page={page}
                onChangePage={(newPage) => handleChangePage(newPage)}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
            />
            {openEditDialog && (
                <EditDevice
                    device={updateDevice}
                    open={openEditDialog}
                    onClose={() => {
                        setOpenEditDialog(false);
                        loadDevices(page, rowsPerPage);
                    }}
                />
            )}
            <Grid container lg={12} xs={12} direction='row' justifyContent='flex-end'>
                <Box display='flex' m={1} >
                    <Button size='medium' variant='contained' color='secondary' onClick={() => handleSubmit()} >
                        Save
                    </Button>

                </Box>
                <Box display='flex' m={1}>
                    <Button size='medium' variant='contained' color='secondary' onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
