import { READER_LIST } from "../actions/types";

const initialState = {
    readers: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case READER_LIST:
            return {
                ...state,
                readers: action.payload
            }
        default:
            return state;
    }
}
