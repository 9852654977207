import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import { getTimesegs } from '../../actions/TimesegApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { toast } from 'react-toastify';
import { getTimeZoneIntervalList } from '../schedules/SchedulesService';
import { updateDoor } from '../door/DoorService';

export default function EditDoorAccessTime(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  const [timesegs, setTimesegs] = React.useState([]);
  const [verifyModes, setVerifyModes] = React.useState([]);
  const [enablefield, setField] = React.useState(true);
  const [schedules, setSchedules] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.door.doorId,
    name: props.door.doorParameters.name,
    verifyMode: props.door.doorParameters.verifyMode,
    doorSensorStatus: props.door.doorParameters.doorSensorStatus,
    lockDelay: props.door.doorParameters.lockDelay,
    sensorDelay: props.door.doorParameters.sensorDelay,
    passModeTimeSegBusinessId: props.door.doorParameters.passModeTimeSegBusinessId,
    activeTimeSegBusinessId: props.door.doorParameters.activeTimeSegBusinessId,
    forcePwd: props.door.doorParameters.forcePwd,
    supperPwd: props.door.doorParameters.supperPwd,
    doorType: props.door.doorParameters.doorType,
  });
  const [error, setError] = React.useState({
    name: false,
    verifyMode: false,
    doorSensorStatus: false,
    lockDelay: false,
    sensorDelay: false,
    passModeTimeSegBusinessId: false,
    activeTimeSegBusinessId: false,
    forcePwd: false,
    supperPwd: false,
    doorType: false,
  });
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    setOpen(props.open);
    setState({
      id: props.door.doorId,
      name: props.door.doorParameters.name,
      verifyMode: props.door.doorParameters.verifyMode,
      doorSensorStatus: props.door.doorParameters.doorSensorStatus,
      lockDelay: props.door.doorParameters.lockDelay,
      sensorDelay: props.door.doorParameters.sensorDelay,
      passModeTimeSegBusinessId: props.door.doorParameters.passModeTimeSegBusinessId,
      activeTimeSegBusinessId: props.door.doorParameters.activeTimeSegBusinessId,
      forcePwd: props.door.doorParameters.forcePwd,
      supperPwd: props.door.doorParameters.supperPwd,
      doorType: props.door.doorParameters.doorType,
    });
  }, [props.open, props.door]);

  useEffect(() => {
    getTimeZoneIntervalList(1, 1000, '', (res) => {
      if (state.activeTimeSegBusinessId === '9999') {
        res.data.data.timeZone.map((data) => {
          if (data.operationName === '24-Hours General') {
            setState({ ...state, activeTimeSegBusinessId: data.id })
          }
        })
      }
      setSchedules(res.data.data.timeZone);
    });
  }, []);

  useEffect(() => {
    setVerifyModes(store.door.verifyModes.verifyModeList);
    setTimesegs(store.timeseg.timesegs.timeSeg);
  }, [store.door.verifyModes, store.timeseg.timesegs]);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.doorError)) {
      const { doorError } = store.errors;
      switch (doorError.code) {
        case 'CASE0027':
          setError({ activeTimeSegBusinessId: true });
          break;
        case 'CASE0028':
          setError({ name: true });
          break;
        case 'CASE0101':
          setError({ name: true });
          break;
        case 'CASE0029':
          setError({ verifyMode: true });
          break;
        case 'CASE0030':
          setError({ doorSensorStatus: true });
          break;
        case 'CASE0031':
          setError({ lockDelay: true });
          break;
        case 'acc_door_delay':
          setError({ sensorDelay: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.doorError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const statusValue = event.target.value;
    const name = event.target.name;
    if (name === 'forcePwd' || name === 'supperPwd' || name === 'lockDelay' || name === 'sensorDelay') {
      if (!CommonUtil.isValidPasitiveNumeric(event.target.value)) {
        return;
      }
    }
    if (statusValue == 0) {
      setField(true);
    } else {
      setField(false);
    }
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.lockDelay)) {
      if (CommonUtil.isEmptyString(state.lockDelay)) {
        setError({ lockDelay: true });
        toast.error(t('COMMON210'));
        return;
      }
    }
    if (CommonUtil.isEmptyString(state.activeTimeSegBusinessId)) {
      setError({ activeTimeSegBusinessId: true });
      toast.error(t('DOORI036'));
      return;
    }
    if (!CommonUtil.isValidNumeric(state.lockDelay)) {
      setError({ lockDelay: true });
      toast.error(t('COMMON177'));
      return;
    }
    if (state.lockDelay < 1 || state.lockDelay > 254) {
      setError({ lockDelay: true });
      toast.error(t('COMMON177'));
      return;
    }

    if (state.forcePwd != null) {
      let forcePwd = state.forcePwd + '';
      if (!forcePwd.match('^[0-9]{0,6}$') || !CommonUtil.isValidNumeric(forcePwd)) {
        setError({ ...error, code: true });
        toast.error(t('COMMON178'));
        return;
      }
    }
    if (state.supperPwd != null) {
      let supperPwd = state.supperPwd + '';
      if (!supperPwd.match('^[0-9]{0,8}$')) {
        setError({ ...error, code: true });
        toast.error(t('COMMON179'));
        return;
      }
    }
    if (state.sensorDelay != null || state.sensorDelay != 0) {
      if (state.sensorDelay > 254) {
        setError({ ...error, code: true });
        toast.error(t('DEVI0062'));
        return;
      }
    }

    let obj = {};
    obj.parameters = state;
    updateDoor(state.id, obj, (res) => {
      if (res.data.code === 'DMSI0000') {
        toast.success(res.data.msg);
        handleClose();
      } else {
        toast.error(res.data.msg);
      }
    });
  };
  return (
    <div>
      <Dialog maxWidth='xs' fullWidth open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DOORI015')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled name='deviceAlias' label={t('DEVI0002')} helperText='' variant='outlined' size='small' defaultValue={door.deviceAlias} />
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.name}
                    name='name'
                    label={t('DOORI003')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('DOORI002')} helperText='' variant='outlined' size='small' defaultValue={props.door.doorParameters.doorNo} />
                </Box>
              </Grid> */}

              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1} style={{ width: '100%' }}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DOORI037')}</InputLabel>
                    <Select
                      label={t('DOORI037')}
                      error={error.activeTimeSegBusinessId}
                      name='activeTimeSegBusinessId'
                      onChange={handleChange}
                      value={state.activeTimeSegBusinessId}
                      defaultValue={state.activeTimeSegBusinessId}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {schedules ? (
                        schedules.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.operationName}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DOORI005')}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      label={t('DOORI005')}
                      error={error.verifyMode}
                      name='verifyMode'
                      onChange={handleChange}
                      value={state.verifyMode}
                      defaultValue={state.verifyMode}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {verifyModes ? (
                        verifyModes.map((data, index) => (
                          <MenuItem key={index} value={data.value} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.text}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.lockDelay}
                    name='lockDelay'
                    label={t('DOORI006') + '*'}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={state.lockDelay}
                    defaultValue={state.lockDelay}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      maxlength: 3,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DOORI007')}</InputLabel>
                    <Select
                      error={error.doorSensorStatus}
                      label={t('DOORI007')}
                      name='doorSensorStatus'
                      onChange={handleChange}
                      value={state.doorSensorStatus}
                      defaultValue={state.doorSensorStatus}
                    >
                      <MenuItem value='0' defaultChecked>
                        <em>{t('none')}</em>
                      </MenuItem>
                      <MenuItem value='1' selected>
                        {t('COMMON171')}
                      </MenuItem>
                      <MenuItem value='2' selected>
                        {t('COMMON172')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.sensorDelay}
                    name='sensorDelay'
                    label={t('DOORI008') + ' *'}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={state.sensorDelay}
                    defaultValue={state.sensorDelay}
                    onChange={handleChange}
                    type='text'
                    disabled={enablefield}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.forcePwd}
                    name='forcePwd'
                    label={t('DOORI009')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={state.forcePwd}
                    defaultValue={state.forcePwd}
                    onChange={handleChange}
                    type='text'
                    // InputLabelProps={{ shrink: true }}
                    inputProps={{
                      maxlength: 6,
                    }}
                  />
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DOORI038')}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      error={error.passModeTimeSegBusinessId}
                      label={t('DOORI038')}
                      name='passModeTimeSegBusinessId'
                      onChange={handleChange}
                      value={state.passModeTimeSegBusinessId}
                      defaultValue={state.passModeTimeSegBusinessId}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {schedules ? (
                        schedules.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.operationName}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.supperPwd}
                    name='supperPwd'
                    label={t('DOORI011')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={state.supperPwd}
                    defaultValue={state.supperPwd}
                    onChange={handleChange}
                    type='text'
                    inputProps={{
                      maxlength: 8,
                    }}
                  />
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DOORI032')}</InputLabel>
                    <Select error={error.doorType} label={t('DOORI032')} name='doorType' onChange={handleChange} value={state.doorType} defaultValue={state.doorType}>
                      <MenuItem value='null'>
                        <em>{t('none')}</em>
                      </MenuItem>
                      <MenuItem value='In' selected>
                        {t('DOORI033')}
                      </MenuItem>
                      <MenuItem value='Out' selected>
                        {t('DOORI034')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
