import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';

import { JAVA_API_PERSON, JAVA_API_PERSON_SEARCH, JAVA_API_ADDRESS, JAVA_API_PERSON_ACTIVATION, JAVA_API_PERSON_DELETE, JAVA_API_ADDRESS_V2 } from './ApiEndPoints';
import { ADDRESS_LIST, PERSON_LIST } from './types';

export const getPersons = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_PERSON + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const listPersons = (levelId, pageNumber, payload, pageSize, isPageable, previousData, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/v2.0/access_level/' +
        levelId +
        '/persons' +
        '?page=' +
        pageNumber +
        '&size=' +
        pageSize +
        '&firstName=' +
        payload.firstName +
        '&lastName=' +
        payload.lastName +
        '&employeeCode=' +
        payload.employeeCode +
        '&addPerson=true'
    );
    if (res.status === 200 && res.data.code === 'CASI0001') {
      if (isPageable) {
        if (!CommonUtil.isEmpty(previousData)) {
          var empList = previousData.employee.concat(res.data.data.employee);
          res.data.data.employee = empList;
        }
        dispatch({
          type: PERSON_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: previousData,
        });
      }
    }
  } catch {}
};

export const getPersonByEmployeeCode = (employeeCode, onSuccess, onFailure) => async (dispatch) => {
  try {
    let query = '&firstName=&lastName=&email=';

    if (employeeCode !== '') {
      query += '&employeeCode=' + employeeCode;
    }
    const res = await axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + 1 + '&size=1' + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      onSuccess(res.data.data);
    } else {
      onFailure([]);
    }
  } catch {}
};

export const filterPerson = (firstName, lastName, employeeCode, email, phone, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (firstName !== '') {
      query += '&firstName=' + firstName;
    }
    if (lastName !== '') {
      query += '&lastName=' + lastName;
    }
    if (email !== '') {
      query += '&email=' + email;
    }
    if (phone !== '') {
      query += '&phone=' + phone;
    }
    if (employeeCode !== '') {
      query += '&employeeCode=' + employeeCode;
    }

    

    const res = await axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + page + '&size=' + size + query);
    
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      
      dispatch({
        type: PERSON_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: PERSON_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const addPerson = (payload, callBack) => async (dispatch) => {
  try {
    let res = '';
    res = await axios.post(JAVA_API_PERSON, payload);
    callBack(res.data);
  } catch {}
};

export const updatePerson = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v2.0/emp/' + payload.id, payload);
    callBack(res.data);
  } catch {}
};

export const deletePerson = (str, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_PERSON_DELETE + '?eIds=' + str);
    callBack(res.data);
  } catch {}
};
export const getPersonAddress = (id) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_ADDRESS_V2 + id);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      dispatch({
        type: ADDRESS_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: ADDRESS_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const updateAddress = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_ADDRESS, payload);
      callBack(res.data);
    } else {
      res = await axios.put(JAVA_API_ADDRESS + '/' + payload.id, payload);
      callBack(res.data);
    }
  } catch {}
};

export const activateEmployee = (payload, callBack) => async (dispatch) => {
  try {
    let res = await axios.post(JAVA_API_PERSON_ACTIVATION, payload);
    callBack(res.data);
  } catch (error) {}
};

export const validateActivationLink = (code, payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    let res = await axios.put('/onboard/user/' + code + '/activate', payload);
    if (res.data.code === 'CAAI3003') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {}
};
