import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { listAllSites } from '../Site/SiteService';
import CommonUtil from '../../components/Util/CommonUtils';
import { updateDevice } from './DeviceService';
import { toast } from 'react-toastify';
import { filterZoneV3 } from '../Zone/ZoneService';
export default function EditDevice(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);
  const [sites, setSites] = React.useState([]);
  const [zones, setZones] = React.useState([]);
  const [zoneList, setZoneList] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.device.id,
    sn: props.device.sn,
    registrationDevice: props.device.registrationDevice,
    deviceAlias: !CommonUtil.isEmptyString(props.device.deviceAlias) ? props.device.deviceAlias : props.device.ip,
    ip: props.device.ip,
    siteId: props.device.siteId,
    zoneId: props.device.zoneId,
  });

  const [error, setError] = React.useState({
    deviceAlias: false,
    siteId: false,
    zoneId: false,
    registrationDevice: false,
  });

  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 260,
      },
    },
  };

  useEffect(() => {
    setOpen(props.open);
    setState({
      sn: props.device.sn,
      registrationDevice: props.device.registrationDevice,
      deviceAlias: !CommonUtil.isEmptyString(props.device.deviceAlias) ? props.device.deviceAlias : props.device.ip,
      ip: props.device.ip,
      siteId: props.device.siteId,
      zoneId: props.device.zoneId,
    });
  }, []);

  useEffect(() => {
    loadSites();
  }, []);

  useEffect(() => {
    loadSites();
    loadZones();
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if(name==='siteId'){
      setState({...state,zoneId:'',siteId:event.target.value})
    }else{
      setState({
        ...state,
        [name]: event.target.value,
      });
      setError({
        [name]: '',
      });
    }
  };

  const loadSites = () => {
    listAllSites((res) => {
      if (res.status === 200 && res != null && !CommonUtil.isEmpty(res) && res.data.code === 'LMSI0000' && !CommonUtil.isEmpty(res.data) && res.data.data.site instanceof Array) {
        setSites(res.data.data.site);
      }
    });
  };

  const loadZones = () => {
    let payload = {
      name: '',
      parentId: '',
      zoneId: '',
    };
    filterZoneV3(1, 1000, payload, (res) => {
      if (res.data.code === 'LMSI6000' && !CommonUtil.isEmpty(res.data) && res.data.data.zones instanceof Array) {
        setZones(res.data.data.zones);
      }
    });
  };

  useEffect(() => {
    const zoneFilter = zones.filter((zone) => { return zone.siteId === state.siteId });
    setZoneList(zoneFilter);
    // alert(JSON.stringify(zoneFilter))
  }, [zones, state.siteId]);

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.deviceAlias)) {
      setError({
        ...error,
        deviceAlias: true,
      });
      toast.error(t('DEVI0081'));
      return;
    }

    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({
        ...error,
        siteId: true,
      });
      toast.error(t('ZONE012'));
      return;
    }

    if (CommonUtil.isEmptyString(state.zoneId)) {
      setError({
        ...error,
        zoneId: true,
      });
      toast.error(t('ZONE010'));
      return;
    }

    const payload = {
      id: props.device.id,
      registrationDevice: state.registrationDevice == 'true' ? '1' : '0',
      deviceAlias: state.deviceAlias,
      siteId: state.siteId,
      zoneId: state.zoneId,
    };

    updateDevice(payload.id, payload, (res) => {
      if (res.status === 200 && res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && CommonUtil.isEmpty(res.data.data.devices instanceof Array)) {
        toast.success(t('DEVI0082'));
        handleClose();
      } else {
        toast.error(res.data.message);
      }
    });
  };

  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0022')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.deviceAlias}
                    name='deviceAlias'
                    label={t('DEVI0002')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={state.deviceAlias}
                    defaultValue={state.deviceAlias}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('DEVI0003')} helperText='' variant='outlined' size='small' defaultValue={state.sn} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('DEVI0004')} helperText='' variant='outlined' size='small' value={state.ip} defaultValue={state.ip} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel>{t('DEVI0025')}</InputLabel>
                    <Select
                      MenuProps={MenuProps}
                      error={error.siteId}
                      fullWidth
                      label={t('DEVI0025')}
                      name='siteId'
                      onChange={handleChange}
                      value={state.siteId}
                      defaultValue={state.siteId}
                    >
                      {sites ? (
                        sites.map((sites, index) => (
                          <MenuItem key={index} value={sites.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {sites.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined'  size='small' fullWidth required>
                    <InputLabel>{t('ZONE001')}</InputLabel>
                    <Select
                      required
                      MenuProps={MenuProps}
                      error={error.zoneId}
                      fullWidth
                      label={t('ZONE001')}
                      name='zoneId'
                      onChange={handleChange}
                      value={state.zoneId}
                      defaultValue={state.zoneId}
                    >
                      <MenuItem value='' selected> <em>{t('none')}</em></MenuItem>
                      {zoneList ? (
                        zoneList.map((zones, index) => (
                          <MenuItem key={index} value={zones.id}  style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {zones.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' fullWidth m={1} p={1}>
                  <FormControl variant='outlined' fullWidth size='small'>
                    <InputLabel>{t('DEVI0006')}</InputLabel>
                    <Select name='registrationDevice' value={state.registrationDevice} defaultValue={state.registrationDevice} label='Registration Device' onChange={handleChange}>
                      <MenuItem value={'true'}>{t("COMMON479")}</MenuItem>
                      <MenuItem value={'false'}>{t("COMMON480")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
