import { Grid } from '@mui/material';
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

export default function MainLayout(props) {
  const [isExapnded, setIsExapnded] = React.useState(true);
  const setExpand = (value) => {
    setIsExapnded(value);
  };

  return (
    <Grid style={{ backgroundColor: '#f4f6f7', width: '100%', height: '100%' }}>
      <Header />
      <Grid style={{ height: `calc(100% - 90px)`, margin: '12px', display: 'flex', flexDirection: 'row' }}>
        <Sidebar exapnded={(e) => setExpand(e)} />

        <Grid container style={{ height: '100%', marginLeft: isExapnded ? '77px' : '277px', display: 'block', width:'-webkit-fill-available'}}>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
}
