import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices, removeDevice } from '../../actions/DeviceApiCalls';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button } from '@material-ui/core';
import { filterDevices } from '../device/DeviceService';
import AddAccessGroup from './AddAccessGroup';
import { ReactComponent as RemoteNormallyOpen } from '../../assets/cloudaccess-svg/Remote Open.svg';
import { deleteAcccessGroupById, getAccessGroup, getFilterAccessGroup } from './AccessGroupService';
import { getTimeZoneIntervalList } from '../schedules/SchedulesService';
import FilterLevel from '../../pages/level/FilterLevel';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import AccessGroupFilter from './AccessGroupFilter';

export default function AccessGroup() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [accessLevel, setAccessLevel] = useState([]);
  const [selectedAccessGroup, onSelectionChange] = useState([]);
  const [updateAccessGroup, setUpdateAccessGroup] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openEditDialogBox, setOpenEditDialogBox] = useState(false);
  const [accessGroup, setAccessGroup] = useState([]);
  const history = useHistory();
  const [scheduler, setScheduler] = React.useState([]);
  const [name, setName] = React.useState('');

  const [filter, setFilter] = useState({
    name: '',
  });

  const defaultFilter = {
    name: '',
  };

  const [state] = React.useState({
    name: '',
  });

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadAccessGroup(0, rowsPerPage);
  }, [filter]);

  useEffect(() => {
    //loadSchedule(0, 1000, name);
  }, []);

  const loadAccessGroup = (page, size) => {
    getFilterAccessGroup(page + 1, size, filter, (res) => {
      if (
        res.status === 200 &&
        res != null &&
        !CommonUtil.isEmpty(res) &&
        res.data.code === 'DMSI0000' &&
        !CommonUtil.isEmpty(res.data.data) &&
        res.data.data.accessGroups instanceof Array
      ) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
      } else {
        setTotalRecords(0);
        setPage(0);
      }
      setAccessGroup(res.data.data.accessGroups);
    });
  };
  const handleChangePage = (newPage, id, name) => {
    setPage(newPage);
    loadAccessGroup(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize, id, name) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadAccessGroup(0, pageSize);
  };

  const loadSchedule = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name, (res) => {
      if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.timeZone instanceof Array) {
        setScheduler(res.data.data.timeZone);
      }
    });
  };

  const getTimezoneName = (id) => {
    var operationName = '';
    scheduler.map((t) => {
      if (id == t.id) {
        operationName = t.operationName;
      }
    });
    return operationName;
  };

  const breadcrumb = [{ path: '/acc/z/access-group', name: t('ACCESSGROUP005') }];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const columns = [
    {
      field: 'name',
      title: t('COMMON014'),
      tooltip: t('COMMON014'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
    {
      field: 'schedulerName',
      title: t('Time slots'),
      tooltip: t('Time slots'),
      editable: 'never',
      //render: (rowData) => <span> {rowData.schedulerId ? getTimezoneName(rowData.schedulerId) : '-'}</span>,
    },
  ];

  const actions = [
    {
      icon: () => <RemoteNormallyOpen color='secondary' />,
      tooltip: t('ACCESSGROUP008'),
      position: 'row',
      onClick: (event, rowData) => {
        gotoAccessGroupDoorPage(rowData);
      },
    },
    {
      icon: () => <SensorOccupiedIcon color='secondary' />,
      tooltip: t('ACCESSGROUP007'),
      position: 'row',
      onClick: (event, rowData) => {
        gotoAccessGroupPersonPage(rowData);
      },
    },
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateAccessGroup(rowData);
        setOpenEditDialogBox(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteAccessGroup([rowData]);
      },
    },
  ];

  const openAccessGroupLevelDialog = () => {
    setOpenEditDialogBox(true);
  };

  const gotoAccessGroupPersonPage = (rowData) => {
    history.push({
      pathname: '/acc/z/access-group-person',
      state: { ...rowData },
    });
  };

  const gotoAccessGroupDoorPage = (rowData) => {
    history.push({
      pathname: '/acc/z/access-group-door',
      state: { ...rowData },
    });
  };

  const deleteAccessGroup = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
    }
    var ids = '';
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ',';
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteAcccessGroupById(ids, (res) => {
                  if (res.data.code === 'DMSI0000') {
                    toast.success(t('ACCESSGROUP019'));
                    loadAccessGroup(page, rowsPerPage);
                  }
                  if (res.data.code === 'DMSE00543') {
                    toast.error(t('ACCESSGROUP020'));
                  }
                  if (res.data.code === 'DMSE00542') {
                    toast.error(t('ACCESSGROUP018'));
                  }
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <Button size='small' variant='contained' color='secondary' aria-label={t('COMMON002')} component={Link} onClick={openAccessGroupLevelDialog}>
            {' '}
            {t('ACCESSGROUP002')}
          </Button>
        </Tooltip>
      </Breadcrumb>
      <Toolbar>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <AccessGroupFilter page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={accessGroup}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialogBox && (
        <AddAccessGroup
          level={updateAccessGroup}
          open={openEditDialogBox}
          onClose={() => {
            setOpenEditDialogBox(false);
            setUpdateAccessGroup({});
            loadAccessGroup(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
