import { USER_ROLE_LIST, USER_FILTER_LIST } from '../../actions/organization/types';

const initialState = {
  userRoleList: [],
  userFilterList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_ROLE_LIST:
      return {
        ...state,
        userRoleList: action.payload,
      };
    case USER_FILTER_LIST:
      return {
        ...state,
        userFilterList: action.payload,
      };
    default:
      return state;
  }
}
