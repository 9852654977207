import axios from "axios"
import CommonUtil from "../../components/Util/CommonUtils"

export const getDeviceEventList = async (page, size, filter, callBack) => {

    try {
        const res = await axios.get('/web/device/events?page=' + page + "&size=" + size + buildQuery(filter));
        callBack(res)
        return
    }

    catch (error) { }

    function buildQuery(filter) {
        let query = '';
        if (filter.startDateTime) {
            let encodeStartDateTime  = encodeURIComponent(filter.startDateTime)
            query = query + `&startDateTime=${encodeStartDateTime}`;
        }
        if (filter.endDateTime) {
            let encodeEndDateTime  = encodeURIComponent(filter.endDateTime)
            query = query + `&endDateTime=${encodeEndDateTime}`;
        }

        if (filter.personnelId) {
            query = query + `&personnelId=${filter.personnelId}`
        }

        if (filter.deviceAlias) {
            query = query + `&deviceAlias=${filter.deviceAlias}`
        }

        if (filter.cardNo) {
            query = query + `&cardNo=${filter.cardNo}`
        }

        if (filter.eventPointName) {
            query = query + `&eventPointName=${filter.eventPointName}`
        }
        return query
    }

}

















