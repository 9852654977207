import { Box, Grid, Dialog, DialogTitle, TextField, Button, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as RemoteOpening } from '../../assets/icons/RemoteOpening.svg';
import { doorOperation } from '../door/DoorService';

export default function RemoteOpenDoor(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selecteddoors, setSelectedDoors] = React.useState();
  const [selectedDevices, setSelectedDevices] = React.useState();
  const [state, setState] = React.useState({
    openInterval: 5,
  });
  const [error, setError] = React.useState({
    openInterval: false,
  });
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
  };

  const doorOperations = () => {
    if (props.doors.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }
    if (props.doors.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }
    setOpen(true);

    var ids = '';
    var devices = '';
    const { doors } = props;

    doors.map((door, index) => {
      if (index === doors.length - 1) {
        ids = ids + door.id;
        devices = devices + door.deviceId;
      } else {
        devices = devices + door.deviceId + ',';
        ids = ids + door.id + ',';
      }
    });

    setSelectedDoors(ids);
    setSelectedDevices(devices);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    debugger;
    if (CommonUtil.isEmptyString(state.openInterval)) {
      setError({
        openInterval: true,
      });
      toast.error(t('DEVI0063'));
      return;
    } else if (state.openInterval < 1) {
      setError({ ...error, code: true });
      toast.error(t('DEVI0062'));
      return;
    } else if (state.openInterval > 254) {
      setError({
        openInterval: true,
      });
      toast.error(t('DEVI0062'));
      return;
    }

    let payload = {
      operator: 0,
      type: 0,
      triggerNum: props.doors[0].doorParameters.doorNo,
      delayTime: state.openInterval,
    };

    doorOperation(props.doors[0].deviceId, payload, (res) => {
      setOpen(false);
      if (res.data.code === 'DMSI0000') {
        toast.success(res.data.msg);
      } else {
        toast.error(res.data.msg);
      }
    });
  };
  return (
    <>
      <Tooltip title={t('DOORI023')}>
        <IconButton color='secondary' aria-label={t('DOORI023')} onClick={() => doorOperations()}>
          <RemoteOpening style={{ height: 30, width: 30 }} />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DOORI023')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center' style={{ border: '1px solid #00000026', marginTop: '8px' }}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.openInterval}
                    name='openInterval'
                    label={t('DOORI022') + '*'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.openInterval}
                    defaultValue={state.openInterval}
                    type='number'
                    InputLabelProps={{ shrink: true }}
                    maxWidth={3}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('DOORI023')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
