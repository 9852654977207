import React, { useEffect, useState } from 'react';
import { TableContainer } from '@material-ui/core';
import { Box, Grid, IconButton, Table, TableCell, TableRow } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { getDoor } from '../door/DoorService';
import CommonUtil from '../../components/Util/CommonUtils';
import OverFlowText from '../accessgroup/OverFlowText';
import { toast } from 'react-toastify';
import { findAuxInDetails } from './DeviceService';
import { findAuxOutDetails } from './DeviceService';
import { findReader } from './DeviceService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& td': {
      padding: '10px 6px 0px 5px',
      '& p': {
        fontSize: 14,
      },
    },
  },
});

export default function DeviceTopologySubDeviceList(props) {
  const selectedDevice = useSelector((state) => state.deviceTopology.selectedDevice);
  const store = useSelector((state) => state.deviceTopology);
  const [door, setDoor] = React.useState([]);
  const [auxIn, setAuxIn] = React.useState([]);
  const [auxOut, setAuxOut] = React.useState([]);
  const [reader, setReader] = React.useState([]);
  const [activeDoorId, setActiveDoorId] = React.useState({});
  const [activeAuxInId, setActiveAuxInId] = React.useState({});
  const [activeAuxOutId, setActiveAuxOutId] = React.useState({});
  const [activeReaderId, setActiveReaderId] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.updatedObject === 'reader') {
      loadReader();
    } else if (props.updatedObject === 'door') {
      loadDoor();
    } else if (props.updatedObject === 'auxIn') {
      loadAuxIn();
    } else if (props.updatedObject === 'auxOut') {
      loadAuxOut();
    }
    props.setUpdate();
  }, [props.updatedObject]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedDevice)) {
      loadDoor();
      loadAuxIn();
      loadAuxOut();
      loadReader();
    } else {
      setDoor([]);
      setAuxIn([]);
      setAuxOut([]);
      setReader([]);
    }
  }, [selectedDevice]);

  let doorCount = '';
  const loadDoor = () => {
    getDoor(selectedDevice.id, (res) => {
      doorCount = res.data.data.length;
      if (res.data.code === 'DMSI0000') {
        setDoor(res.data.data);
        dispatch({ type: 'DOOR_COUNT', payload: doorCount });
      } else {
        setDoor('');
        dispatch({ type: 'SELECTED_SUB_DEVICE', payload: {} });
      }
    });
  };

  const loadAuxIn = () => {
    findAuxInDetails(selectedDevice.id, (res) => {
      if (res.data.code === 'DMSI0000') {
        setAuxIn(res.data.data);
      } else {
        setAuxIn('');
        dispatch({ type: 'SELECTED_DEVICE_AUXIN', payload: {} });
      }
    });
  };

  const loadAuxOut = () => {
    findAuxOutDetails(selectedDevice.id, (res) => {
      if (res.data.code === 'DMSI0000') {
        setAuxOut(res.data.data);
      } else {
        setAuxOut('');
        dispatch({ type: 'SELECTED_DEVICE_AUXOUT', payload: {} });
      }
    });
  };

  const loadReader = () => {
    findReader(selectedDevice.id, (res) => {
      if (res.data.code === 'DMSI0000') {
        setReader(res.data.data);
      } else {
        setReader('');
        dispatch({ tyep: 'SELECTED_DEVICE_READER', payload: {} });
      }
    });
  };

  const selectedDoor = (data) => {
    setActiveDoorId(data.doorId);
    dispatch({ type: 'SELECTED_SUB_DEVICE', payload: data });
    setOpen(true);
    setActiveAuxInId('');
    setActiveAuxOutId('');
    setActiveReaderId('');
  };

  const selectedAuxin = (data) => {
    setActiveAuxInId(data.aux_id);
    setActiveDoorId('');
    setActiveAuxOutId('');
    setActiveReaderId('');
    dispatch({ type: 'SELECTED_DEVICE_AUXIN', payload: data });
  };

  const selectAuxOut = (data) => {
    setActiveAuxOutId(data.aux_id);
    setActiveDoorId('');
    setActiveAuxInId('');
    setActiveReaderId('');
    dispatch({ type: 'SELECTED_DEVICE_AUXOUT', payload: data });
  };

  const selectReader = (data) => {
    setActiveReaderId(data.readerId);
    setActiveDoorId('');
    setActiveAuxInId('');
    setActiveAuxOutId('');
    dispatch({ type: 'SELECTED_DEVICE_READER', payload: data });
  };
  const classes = useStyles();
  return (
    <div>
      <Grid>
        <TableContainer>
          <Table className={classes.root}>
            {door &&
              door.map((data, index) => (
                <TableRow
                  Key={index}
                  style={{
                    background: data.doorId === activeDoorId ? '#c5dbcb' : '',

                    cursor: 'default',
                  }}
                  onClick={() => {
                    props.render('door');
                    selectedDoor(data);
                  }}
                >
                  <TableCell>
                    <Box display='flex' justifyContent='space-between'>
                      {data.doorParameters?.name ? (
                        <OverFlowText title={data.name} marginTop={6}>
                          {data.doorParameters?.name.legth <= 20 ? data.doorParameters?.name : data.doorParameters?.name.slice(0, 15) + '...'}
                        </OverFlowText>
                      ) : null}
                      <IconButton>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

            {auxIn &&
              auxIn.map((data, index) => (
                <TableRow
                  Key={index}
                  style={{
                    background: data.aux_id === activeAuxInId ? '#c5dbcb' : '',
                    cursor: 'default',
                  }}
                  onClick={() => {
                    props.render('auxIn');
                    selectedAuxin(data);
                  }}
                >
                  <TableCell>
                    <Box display='flex' justifyContent='space-between'>
                      {data.name ? (
                        <OverFlowText title={data.name} marginTop={6}>
                          {data.name.length <= 20 ? data.name : data.name.slice(0, 15) + '...'}
                        </OverFlowText>
                      ) : null}
                      <IconButton>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

            {auxOut &&
              auxOut.map((data, index) => (
                <TableRow
                  Key={index}
                  style={{
                    background: data.name === activeAuxOutId ? '#c5dbcb' : '',
                    cursor: 'default',
                  }}
                  onClick={() => {
                    props.render('auxOut');
                    selectAuxOut(data);
                  }}
                >
                  <TableCell>
                    <Box display='flex' justifyContent='space-between'>
                      {data.name ? (
                        <OverFlowText title={data.name} marginTop={6}>
                          {data.name.length <= 20 ? data.name : data.name.slice(0, 15) + '...'}
                        </OverFlowText>
                      ) : null}
                      <IconButton>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

            {reader &&
              reader.map((data, index) => (
                <TableRow
                  Key={index}
                  style={{
                    background: data.readerId === activeReaderId ? '#c5dbcb' : '',
                    cursor: 'default',
                  }}
                  onClick={() => {
                    props.render('reader');
                    selectReader(data);
                  }}
                >
                  <TableCell>
                    <Box display='flex' justifyContent='space-between'>
                      {data.name ? (
                        <OverFlowText title={data.name} marginTop={6}>
                          {data.name.length <= 20 ? data.name : data.name.slice(0, 15) + '...'}
                        </OverFlowText>
                      ) : null}
                      <IconButton>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}
