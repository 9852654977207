import React, { useEffect } from "react";
import { Grid, TableContainer, Table, TableRow, TableCell, IconButton, Tooltip, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filterDevices } from "./DeviceService";
import CommonUtil from "../../components/Util/CommonUtils";
import OverFlowText from "../accessgroup/OverFlowText";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { slice } from "lodash";

const useStyles = makeStyles({
    root: {
        '& td': {
            padding: '10px 6px 0px 5px',
            '& p': {
                fontSize: 14
            }
        }
    },
});

export default function DeviceTopologyDeviceList() {

    const selectedZone = useSelector((state) => state.deviceTopology.selectedZone)
    const dispatch = useDispatch()
    const [device, setDevice] = React.useState([])
    const [activeDeviceId, setActiveDeviceId] = React.useState({});


    useEffect(() => {

        if (!CommonUtil.isEmpty(selectedZone)) {
            loadDeviceByZoneId()
            
        }
        else {
            setDevice([])
        }

    }, [selectedZone])

    let deviceCount = ''

    const loadDeviceByZoneId = () => {
        filterDevices(1, 500, { zoneId: selectedZone.id }, (res) => {
            deviceCount = res.data.data.totalCount
            if (!CommonUtil.isEmpty(res.data.data.devices)) {
                setDevice(res.data.data.devices);

                dispatch({ type: 'DEVICE_COUNT', payload: deviceCount })
            }
            else {
                setDevice('')
                dispatch({ type: 'SELECTED_DEVICE', payload: {} })
            }
        })
    }

    const selectedDevice = (data) => {
        setActiveDeviceId(data.id);
        dispatch({ type: 'SELECTED_DEVICE', payload: data })
    }

    const classes = useStyles();

    return (
        <Grid style={{ overflowX: device.length > 9 && 'auto', height: device.length > 9 && '34em',
         '::-webkit-scrollbar':{
            width:'2.5px'
         }
         }}>
            <TableContainer>
                <Table className={classes.root}>
                    {device && device.map((data, index) =>
                    (
                        <TableRow
                            key={index}
                            style={{
                                backgroundColor: data.id == activeDeviceId ? '#c5dbcb' : '',
                                cursor: 'default'
                            }}
                            onClick={() => { selectedDevice(data) }}
                        >
                            <TableCell>
                                <Box display='flex' justifyContent='space-between'>
                                    {data.deviceAlias ?
                                        <OverFlowText title={data.deviceAlias} marginTop ={6}>
                                            {data.deviceAlias.length <= 20 ? data.deviceAlias : data.deviceAlias.slice(0, 10) + '...'}

                                        </OverFlowText> : null}
                                    <IconButton>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>

            </TableContainer>

        </Grid>
    )
}