import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography, Tooltip, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import Box from '@mui/material/Box';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDoorById } from '../door/DoorService';
import Button from '@mui/material/Button';
import { updateDoor } from '../door/DoorService';
import CommonUtil from '../../components/Util/CommonUtils';
import { getTimeZoneIntervalList } from '../schedules/SchedulesService';
import { toast } from 'react-toastify';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff !important',
    color: '#000000 !important',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid green !important',
  },
}))(Tooltip);

export default function DeviceTopologyDoorInformations(props) {
  const { t } = useTranslation();
  const selectedSubDevice = useSelector((state) => state.deviceTopology.selectedSubDevice);
  const [open, setOpen] = React.useState(true);

  const defaultState = {
    activeTimeSegBusinessId: '',
    doorSensorStatus: '',
    doorNo: '',
    forcePwd: '',
    id: '',
    lockDelay: '',
    name: '',
    passModeTimeSegBusinessId: '',
    sensorDelay: '',
    delayOpenTime: '',
    supperPwd: '',
    verifyMode: '',
  };
  const [state, setState] = React.useState({
    activeTimeSegBusinessId: '',
    doorSensorStatus: '',
    doorNo: '',
    forcePwd: '',
    id: '',
    lockDelay: '',
    name: '',
    passModeTimeSegBusinessId: '',
    sensorDelay: '',
    delayOpenTime: '',
    supperPwd: '',
    verifyMode: '',
  });

  const [schedule, setSchedule] = React.useState([]);
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const dispatch = useDispatch();

  const [update, setUpdate] = React.useState(false);

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedSubDevice)) {
      setState({
        activeTimeSegBusinessId: selectedSubDevice.doorParameters.activeTimeSegBusinessId,
        doorSensorStatus: selectedSubDevice.doorParameters.doorSensorStatus,
        forcePwd: selectedSubDevice.doorParameters.forcePwd,
        doorNo: selectedSubDevice.doorParameters.doorNo,
        id: selectedSubDevice.doorId,
        lockDelay: selectedSubDevice.doorParameters.lockDelay,
        name: selectedSubDevice.doorParameters.name,
        passModeTimeSegBusinessId: selectedSubDevice.doorParameters.passModeTimeSegBusinessId,
        sensorDelay: selectedSubDevice.doorParameters.sensorDelay,
        delayOpenTime: selectedSubDevice.doorParameters.delayOpenTime,
        supperPwd: selectedSubDevice.doorParameters.supperPwd,
        verifyMode: selectedSubDevice.doorParameters.verifyMode,
      });
      setOpen(true);
    } else {
      setState(defaultState);
      setOpen(false);
    }
  }, [selectedSubDevice]);

  useEffect(() => {
    setUpdate(props.open);
  }, [props.open]);

  const handleChange = (event) => {
    const name = event.target.name;

    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.lockDelay)) {
      toast.error(t('COMMON210'));
      return;
    }

    if (CommonUtil.isEmptyString(state.activeTimeSegBusinessId)) {
      toast.error(t('DOORI036'));
      return;
    }

    if (!CommonUtil.isValidNumeric(state.lockDelay)) {
      toast.error(t('COMMON177'));
      return;
    }

    if (state.lockDelay < 1 || state.lockDelay > 254) {
      toast.error(t('COMMON177'));
      return;
    }

    if (state.forcePwd != null) {
      let forcePwd = state.forcePwd + '';
      if (!forcePwd.match('^[0-9]{0,6}$') || !CommonUtil.isValidNumeric(forcePwd)) {
        toast.error(t('COMMON178'));
        return;
      }
    }

    if (state.supperPwd != null) {
      let supperPwd = state.supperPwd + '';
      if (!supperPwd.match('^[0-9]{0,8}$')) {
        toast.error(t('COMMON179'));
        return;
      }
    }

    if (state.sensorDelay != null || state.sensorDelay != 1) {
      if (state.sensorDelay > 254) {
        toast.error(t('DEVI0062'));
        return;
      }
    }

    if (state.delayOpenTime != null || state.delayOpenTime != 0) {
      if (state.delayOpenTime > 254) {
        toast.error(t('DEVI0062'));
        return;
      }
    }

    let payloadObj = {};
    payloadObj.parameters = state;
    updateDoor(selectedSubDevice.doorId, payloadObj, (res) => {
      if (res.data.code === 'DMSI0000') {
        toast.success(res.data.msg);
        handleClose();
        setUpdate(true);
        props.applyUpdate(true);
      } else {
        toast.error(res.data.msg);
      }
    });
  };

  const handleClose = () => {
    props.toggleUpdate();
  };

  return (
    <>
      {open && (
        <Box sx={{ flexWrap: 'wrap' }}>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={7}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small', color: '#304669' }}>
                  {t('DEVICETOPOLOGY011')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mb={1} mt={2}>
                <Typography>
                  {state.doorNo}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DOORI003')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mb={1}>
                <Typography>
                  {state.name}
                </Typography>
                {/* <TextField disabled={update} autoComplete='off' fullWidth name='name' value={state.name} defaultValue={state.name} onChange={handleChange} size='small' /> */}
              </Box>
            </Grid>

            {/* <Grid item xs={6}>
              <Box mb={2} mt={2} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  Door Active Time Slots{' '}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontSize: '10px' }}>
                  Door will allow person pass in this time slots.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <FormControl variant='outlined' size='small' fullWidth>
                  <InputLabel>{t('DOORI037')}</InputLabel>
                  <Select
                    disabled={props.open}
                    label={t('DOORI037')}
                    name='activeTimeSegBusinessId'
                    onChange={handleChange}
                    value={state.activeTimeSegBusinessId}
                    defaultValue={state.activeTimeSegBusinessId}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value='' selected>
                      <em>{t('none')}</em>
                    </MenuItem>
                    {schedule ? (
                      schedule.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.operationName}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid> */}
            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DEVICETOPOLOGY014')}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <Typography>
                  {state.lockDelay}
                </Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='off'
                  fullWidth
                  size='small'
                  name={'lockDelay'}
                  value={state.lockDelay}
                  defaultValue={state.lockDelay}
                  onChange={handleChange}
                ></TextField> */}
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DEVICETOPOLOGY015')}
                  <HtmlTooltip arrow title={<>{t('DEVICETOPOLOGY020')}<br />{(t('DEVICETOPOLOGY023'))}<br />{(t('DEVICETOPOLOGY024'))}</>}>
                    <QuestionMarkIcon style={{ height: 16, width: 16 }} />
                  </HtmlTooltip>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <Typography>
                  {state.doorSensorStatus == '0' && 'None'}
                  {state.doorSensorStatus == '1' && 'Normal Open'}
                  {state.doorSensorStatus == '2' && 'Normal Close'}
                  {/* {state.doorSensorStatus} */}
                </Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='false'
                  fullWidth
                  size='small'
                  name='DoorSensorStatus'
                  value={state.doorSensorStatus}
                  defaultValue={state.doorSensorStatus}
                  onChange={handleChange}
                ></TextField> */}
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DEVICETOPOLOGY016')}
                </Typography>
                {/* <Typography variant='subtitle1' sx={{ fontSize: '10px' }}>
                  {t('DEVICETOPOLOGY017')}
                </Typography> */}
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <Typography>
                  {state.sensorDelay}
                </Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='false'
                  size='small'
                  fullWidth
                  name='sensorDelay'
                  value={state.sensorDelay}
                  defaultValue={state.sensorDelay}
                  onChange={handleChange}
                /> */}
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                {/* <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DEVICETOPOLOGY021')}
                </Typography> */}
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DOORI046')}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <Typography>
                  {state.delayOpenTime}
                </Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='false'
                  size='small'
                  fullWidth
                  name='delayOpenTime'
                  value={state.delayOpenTime}
                  defaultValue={state.delayOpenTime}
                  onChange={handleChange}
                /> */}
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DEVICETOPOLOGY018')}
                </Typography>
              </Box>
            </Grid>


            <Grid item xs={5}>
              <Box>
                <Typography>
                  {state.forcePwd}
                </Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='false'
                  fullWidth
                  size='small'
                  name='forcePwd'
                  value={state.forcePwd}
                  defaultValue={state.forcePwd}
                  onChange={handleChange}
                /> */}
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box mb={2} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t('DEVICETOPOLOGY019')}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <Typography>
                  {state.supperPwd}
                </Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='false'
                  fullWidth
                  size='small'
                  name='supperPwd'
                  value={state.supperPwd}
                  defaultValue={state.supperPwd}
                  onChange={handleChange}
                /> */}
              </Box>
            </Grid>

            {/* <Grid item xs={6}>
              <Box mb={2} mt={2} p={1}>
                <Typography variant='h5' fontSize='small'>
                  Passage Mode Type Slot
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <FormControl variant='outlined' size='small' fullWidth>
                  <InputLabel>{t('DOORI038')}</InputLabel>
                  <Select
                    label={t('DOORI038')}
                    disabled={props.open}
                    name='passModeTimeSegBusinessId'
                    onChange={handleChange}
                    value={state.passModeTimeSegBusinessId}
                    defaultValue={state.passModeTimeSegBusinessId}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value='' selected>
                      <em>{t('none')}</em>
                    </MenuItem>

                    {schedule ? (
                      schedule.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ width: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.operationName}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid> */}

            {/* <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
              <Box component={'span'}>
                <Button variant='contained' fullWidth={true} size='small' sx={{ backgroundColor: 'rgb(54, 201, 109)' }} disabled={update} onClick={handleSubmit}>
                  {t('save')}
                </Button>
              </Box>
              <Box component={'span'}>
                <Button
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  disabled={update}
                  sx={{ border: '2px solid rgb(54, 201, 109)', color: 'rgb(54, 201, 109)', marginLeft: '2px' }}
                  onClick={handleClose}
                >
                  {' '}
                  {t('COMMON008')}
                </Button>
              </Box>
            </Box> */}
          </Grid>
        </Box>
      )}
    </>
  );
}
