import * as React from 'react';
import { IconButton, ListItemText, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grid, Button } from '@mui/material';
import { usePopupState, bindTrigger, bindPopover, bindMenu } from 'material-ui-popup-state/hooks';
import { ReactComponent as DownArrow } from '../../assets/down-arrow.svg';
import { ReactComponent as LogoutIcon } from '../../assets/LogoutIcon.svg';
import Typography from '@mui/material/Typography';
import { ReactComponent as SwitchLanguage } from '../../assets/SwitchLanguage.svg';
import { ReactComponent as LeftArrow } from '../../assets/LeftArrow.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import Popover from '@mui/material/Popover';
import SimpleDilogBox from './SimpleDilogBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../Util/CommonUtils';
import Tooltip from '@mui/material/Tooltip';
import AvatarCustom from '../../utils/AvatarCustom';
import { Avatar } from '@material-ui/core';
import { getUserV3 } from '../../actions/organization/UserApiCalls';

const LogoutContainer = (props) => {
    return (
        <Grid>
            <Typography style={{ margin: '1rem 0rem' }}>You want to Logout this application ?</Typography>
            <Grid style={{ display: 'flex', justifyContent: 'end', margin: '12px 0px' }}>
                <Button
                    onClick={() => props.handleSubmit()}
                    style={{
                        width: '180px',
                        backgroundColor: 'rgb(54, 201, 109)',
                        color: '#FFFF',
                        height: '50px',
                        marginRight: '24px',
                    }}
                >
                    Logout
                </Button>
                <Button
                    onClick={() => props.onClose()}
                    variant='outlined'
                    style={{
                        width: '180px',
                        backgroundColor: 'white',
                        color: 'rgb(54, 201, 109)',
                        borderColor: 'rgb(54, 201, 109)',
                        height: '50px',
                    }}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
};
const SwitchLanguageMenu = ({ classes }) => {
    const switchLangPopupState = usePopupState({
        variant: 'popover',
        popupId: 'demoPopover',
    });

    const [leftArrow, setLeftArrow] = React.useState(false);
    return (
        <div>
            <MenuItem {...bindTrigger(switchLangPopupState)}>
                <Box display='flex' width={'200px'}>
                    <Box item>
                        <ListItemIcon>
                            <SwitchLanguage fontSize='small' />
                        </ListItemIcon>
                    </Box>
                    <Box item flexGrow={1}>
                        <ListItemText>Switch Language</ListItemText>
                    </Box>

                    <Box item>{leftArrow ? <LeftArrow fontSize='small' /> : <DownArrow fontSize='small' />}</Box>
                </Box>
            </MenuItem>
            <Popover
                onFocus={() => setLeftArrow(true)}
                onBlur={() => setLeftArrow(false)}
                {...bindPopover(switchLangPopupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem>
                    <Box display='flex' width={'150px'}>
                        <Box item flexGrow={1}>
                            <ListItemText>English</ListItemText>
                        </Box>
                        <Box item>
                            <ListItemIcon>
                                {/* <DoneIcon color='primary' fontSize='small' /> */}
                            </ListItemIcon>
                        </Box>
                    </Box>
                </MenuItem>
                <MenuItem>
                    <Box display='flex' width={'150px'}>
                        <Box item flexGrow={1}>
                            <ListItemText>Spanish</ListItemText>
                        </Box>
                    </Box>
                </MenuItem>
                <MenuItem>
                    <Box display='flex' width={'150px'}>
                        <Box item flexGrow={1}>
                            <ListItemText>Chinese</ListItemText>
                        </Box>
                    </Box>
                </MenuItem>
            </Popover>
        </div>
    );
};

export default function UserProfileMenu() {
    const store = useSelector((state) => state);
    const [profile, setProfile] = React.useState('');
    const [open2, setOpen2] = React.useState(false);
    const [LogOutDialog, setLogOutDialog] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
    React.useEffect(()=>{
        dispatch(getUserV3(store.security.user.userId));
    },[])
    
    return (
        <div>
            {/* <Tooltip title='Open settings'> */}
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton sx={{ p: 0, pr: '5px' }}>
                    <Avatar src={store.user.userData?.imagePreSignedURL?store.user.userData.imagePreSignedURL:''} />
                </IconButton>
                <Tooltip title={CommonUtil.capitalizeFirst(store.user.userData.firstName + ' ' + store.user.userData.lastName)} arrow>
                    <Typography sx={{ fontFamily: 'Roboto', letterSpacing: '0px', color: '#304669', opacity: '1px' }}>
                        {CommonUtil.handleTextOverflowAndCapFirstLetter(store.user.userData.firstName + ' ' + store.user.userData.lastName)}
                    </Typography>
                </Tooltip>
                {/* <KeyboardArrowDownIcon
                    onMouseEnter={(event) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    {...bindTrigger(popupState)}
                    sx={{
                        fontSize: { xs: '14px', lg: '28px' },
                    }}
                /> */}
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ mt: '45px' }}
                id='basic-menu'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            popupState.close();
                        }}
                    >
                        <Box display='flex' width={'200px'}>
                            <Box item>
                                <ListItemIcon>
                                    <User fontSize='small' />
                                </ListItemIcon>
                            </Box>
                            <Box item flexGrow={1}>
                                <ListItemText>Account Profile</ListItemText>
                            </Box>

                            <Box item>
                                <DownArrow fontSize='small' />
                            </Box>
                        </Box>
                    </MenuItem>
                    <SwitchLanguageMenu />
                    <MenuItem onClick={() => setLogOutDialog(true)}>
                        <Box display='flex' width={'200px'}>
                            <Box item>
                                <ListItemIcon>
                                    <LogoutIcon fontSize='small' />
                                </ListItemIcon>
                            </Box>
                            <Box item flexGrow={1}>
                                <ListItemText>Logout</ListItemText>
                            </Box>
                        </Box>
                    </MenuItem>
                </div>
            </Menu>
            <SimpleDilogBox open={open2} onClose={() => setOpen2(!open2)} title={'Reset password'} />
            <SimpleDilogBox
                customWidth={'0px'}
                defalutWidth
                open={LogOutDialog}
                onClose={() => setLogOutDialog(!LogOutDialog)}
                title={'Are you sure!'}
                children={
                    <LogoutContainer
                        onClose={() => setLogOutDialog(!LogOutDialog)}
                        handleSubmit={() => {
                            popupState.close();
                            //  CommonUtil.setCookie('auto_login', '', 0);
                            //CommonUtil.setCookie('zlink_token', '', 0);
                            // CommonUtil.setCookie('zlink_refresh_token', '', 0);
                            // history.push(REACT_LOGIN);
                        }}
                    />
                }
            />
        </div>
    );
}