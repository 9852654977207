import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { filterSiteV3 } from '../../actions/organization/SiteApiCalls';
import { DialogTitle, Divider, Paper, Typography } from '@mui/material';
import OverFlowText from '../accessgroup/OverFlowText';
import CancelIcon from '@material-ui/icons/Cancel';
import { HorizontalRuleOutlined } from '@mui/icons-material';

export default function AddZone(props) {
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  // eslint-disable-next-line
  const [parentZones, setParentZones] = React.useState([]);

  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  // eslint-disable-next-line
  const [sites, setSites] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    name: props.selectedObject.name,
    siteId: props.selectedObject.siteId,
    parentId: props.selectedObject.parentId,
    description: props.selectedObject.description,
  });
  const [error, setError] = React.useState({
    name: false,
    siteId: false,
    parentId: false,
    description: false,
  });

  useEffect(() => {
    setOpen(props.open);
    setState({
      id: props.selectedObject.id,
      name: props.selectedObject.name,
      siteId: props.selectedObject.siteId,
      parentId: props.selectedObject.parentId ? props.selectedObject.parentId : '',
      description: props.selectedObject.description,
    });
  }, [props.open, props.selectedObject]);

  const [filter, setFilter] = React.useState({
    id: '',
    name: '',
    parentId: '',
  });
  useEffect(() => {
    filterSiteV3(filter, 1, 0, (data) => {
      setSites(data.data.data.sites);
    });
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     loadZoneBySite(state.siteId, (data) => {
  //       setParentZones(data.zone);
  //     })
  //   );
  // }, []);

  // const handleZones = (event) => {
  //   //  const siteId = event.target.getAttribute('data-value');
  //   const siteId = event.target.value;
  //   if (siteId === '' || siteId === 'none' || siteId === undefined) {
  //     setParentZones([]);
  //   } else {
  //     dispatch(
  //       loadZoneBySite(siteId, (data) => {
  //         setParentZones(data.zone);
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.zoneError)) {
      const { zoneError } = store.errors;
      switch (zoneError.code) {
        case 'LMSE0015':
          setError({ name: true });
          break;
        default:
      }
    }
  }, [store.errors.zoneError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const findSiteNameById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return '';
    }
    var name = '';
    sites.map((jobTitle) => {
      if (jobTitle.id === id) {
        name = jobTitle.name;
      }
    });
    return name;
  };

  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='filter-dialog'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h6'>Zone Details</Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>

        <Divider orientation='HorizontalRuleOutlined' flexItem />

        {/*   <DialogTitle id='form-dialog-title'>
          <Box display='flex' m={1}>
            <Box flexGrow={1}>
              <Typography variant='h5'>{location.pathname === '/acc/z/zone' ? t('COMMON472') : CommonUtil.isEmptyString(state.id) ? t('ZONE002') : t('ZONE008')}</Typography>
            </Box>
            <Cancel onClick={handleClose} className='close_icon' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    required
                    fullWidth
                    error={error.name}
                    name='name'
                    label={t('COMMON044')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    onChange={handleChange}
                    disabled={!CommonUtil.isEmptyString(zlink)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel>{t('ZONE006')}</InputLabel>
                    <Select
                      required
                      label={t('ZONE006')}
                      error={error.siteId}
                      name='siteId'
                      variant='outlined'
                      size='small'
                      onChange={handleChange}
                      //onClick={handleZones}
                      value={state.siteId}
                      defaultValue={state.siteId}
                      MenuProps={MenuProps}
                      disabled={!CommonUtil.isEmptyString(zlink)}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {sites ? (
                        sites.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
        {/* <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON050')}</InputLabel>
                    <Select
                      label={t('COMMON050')}
                      error={error.parentId}
                      name='parentId'
                      onChange={handleChange}
                      value={state.parentId}
                      defaultValue={state.parentId}
                      variant='outlined'
                      size='small'
                      MenuProps={MenuProps}
                      disabled={!CommonUtil.isEmptyString(zlink)}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {parentZones ? (
                        parentZones.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
        {/* <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    size='small'
                    inputProps={{ className: classes.textarea }}
                    variant='outlined'
                    aria-label='maximum height'
                    placeholder={t('ZONE009')}
                    value={state.description}
                    name='description'
                    onChange={handleChange}
                    multiline
                  />

                  <TextField
                    id='outlined-basic'
                    fullWidth
                    autoComplete='off'
                    label={t('ZONE009')}
                    //  placeholder={t('ZONE009')}
                    variant='outlined'
                    multiline
                    inputProps={{ maxLength: 255 }}
                    name='description'
                    value={state.description}
                    onChange={handleChange}
                    helperText=''
                    disabled={!CommonUtil.isEmptyString(zlink)}
                  />
                </Box>
              </Grid> */}
        {/* </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {!zlink && (
            <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
              {t('COMMON035')}
            </SubmitButton>
          )} */}
        {/* <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton> */}
        {/* </DialogActions>
      </Dialog> */}

        {/* <TitleBar title='View Zone Details'
          path={true} onClick={() => handleClose()}
          // history.push({ pathname: "", state: { index: 0 } })}
          >
        </TitleBar> */}

        <Paper
          elevation={0}
          style={{
            minHeight: '185px',
            minWidth: '455px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            paddingTop: '18px',
            paddingBottom: '16px',
          }}
        >
          <Grid
            container
            direction='row'
            style={{ paddingLeft: 20 }}
            // justifyContent='space-evenly'
            // alignItems='center'
          >
            <Grid item lg={3.5} padding={1}>
              <Box display='flex' paddingBottom={2}>
                <Box item paddingRight={1} minWidth='150px'>
                  <OverFlowText variant='fieldLabel'>{t('COMMON044')}</OverFlowText>
                </Box>
                <Box item>
                  {/* <OverFlowText variant='fieldValue'>{state.name}</OverFlowText> */}
                  <OverFlowText variant='fieldValue' title={state.name}>
                    {state.name}
                  </OverFlowText>
                </Box>
              </Box>

              <Box display='flex' paddingBottom={2}>
                <Box item paddingRight={1} minWidth='150px'>
                  <OverFlowText variant='fieldLabel'>{t('ZONE006')}</OverFlowText>
                </Box>
                <Box item>
                  <OverFlowText variant='fieldValue' title={findSiteNameById(state.siteId)}>
                    {findSiteNameById(state.siteId)}
                  </OverFlowText>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </div>
  );
}
