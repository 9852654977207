import axios from "axios"

export const filterZoneV3 = async (page, size, filter, callBack) => {

    const res = await axios.post('/web/location/zone/filter?' + "page=" + page + "&size=" + size + buildQuery(filter))
    callBack(res)
}

function buildQuery(filter) {

    let query = ""

    if (filter.name) {
        query = query + "&name=" + filter.name
    }
    if (filter.parentId) {
        query = query + "&parentId=" + filter.parentId
    }
    if (filter.siteId) {
        query = query + "&siteId=" + filter.siteId
    }

    return query

}

export const zoneByZoneId = async (id, callBack) => {

    const res = await axios.get/* ('/web/location/zone?id=' + id) */ ('/web/location/zone/'+ id)
    callBack(res)
}



