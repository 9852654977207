import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { updateAuxOut } from '../auxout/AuxOutService';
import { toast } from 'react-toastify';

export default function DeviceTopologyAuxOutInformartion(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedAuxOut = useSelector((state) => state.deviceTopology.selectedDeviceAuxOut);
 

  const [state, setState] = React.useState({ name: '', remark: '', ...selectedAuxOut });
 

  const [open, setOpen] = React.useState(true);
  const[update,setUpdate] = React.useState(false)

  useEffect(()=>{
    setUpdate(props.open)
  },[props.open])

  const handleSubmit = () => {
    let updateObject = {
      name: state.name,
      action: selectedAuxOut.action,
      address: selectedAuxOut.address,
      aux_id: selectedAuxOut.aux_id,
      aux_no: selectedAuxOut.aux_no,
      dev_id: selectedAuxOut.dev_id,
      disable: selectedAuxOut.disable,
      ext_dev_id: selectedAuxOut.ext_dev_id,
      printer_number: selectedAuxOut.printer_number,
      remark: state.remark,
      timeseg_id: selectedAuxOut.timeseg_id,
    };
    updateAuxOut(updateObject, (data) => {
      if (data.data.code === 'DMSI00457') {
        toast.success(data.data.msg);
        handleClose();
        setUpdate(true)
        props.applyUpdate(true)
      }
    });
  };
  const handleClose = () => {
    props.toggleUpdate();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      {open && (
        <Box sx={{ flexWrap: 'wrap' }}>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={4}>
              <Box mb={1} mt={2} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON044")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
              <Typography>{state.name}</Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='off'
                  fullWidth
                  name='name'
                  value={state.name}
                  defaultValue={selectedAuxOut.name}
                  onChange={handleChange}
                  size='small'
                /> */}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("DEVI0001")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedAuxOut.printer_number}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON060")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedAuxOut.aux_no}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON067")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Box>
                <Typography>{selectedAuxOut.printer_number}</Typography>
              </Box>
            </Grid>

            <Grid item xs={4} mb={3}>
              <Box mb={1} mt={1} p={1}>
                <Typography variant='h5' sx={{ fontSize: 'small' }}>
                  {t("COMMON016")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8} mb={3}>
              <Box>
              <Typography>{state.remark}</Typography>
                {/* <TextField
                  disabled={update}
                  autoComplete='off'
                  fullWidth
                  name='remark'
                  value={state.remark}
                  defaultValue={selectedAuxOut.remark}
                  onChange={handleChange}
                  size='small'
                /> */}
              </Box>
            </Grid>
            {/* <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
              <Box component={'span'}>
                <Button variant='contained' fullWidth={true} size='small' sx={{ backgroundColor: 'rgb(54, 201, 109)' }} disabled={update} onClick={handleSubmit}>
                  {t("save")}
                </Button>
              </Box>
              <Box component={'span'}>
                <Button
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  disabled={update}
                  sx={{ border: '2px solid rgb(54, 201, 109)', color: 'rgb(54, 201, 109)', marginLeft: '2px' }}
                  onClick={handleClose}
                >
                  {' '}
                  {t('COMMON008')}
                </Button>
              </Box>
            </Box> */}
          </Grid>
        </Box>
      )}
    </>
  );
}
