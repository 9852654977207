import React from 'react';
import { Box, Button, Grid, IconButton, Tooltip, Typography, FormControl, InputLabel, MenuItem, Select, Menu } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearAdministrator, performDeviceOperations } from '../../actions/DeviceApiCalls';
import { toast } from 'react-toastify';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as DeviceControl } from '../../assets/icons/DevControl.svg';
import { JAVA_URL_DEVICE_ROLE } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';
import { rebootDevice } from './DeviceService';

export default function DeviceOperations(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const deviceOperations = () => {
    if (props.devices instanceof Array && props.devices.length == 0) {
      toast.error(t('COMMON019'));
      return;
    }

    var ids = [];

    props.devices.map((d) => {
      ids.push(d.id);
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('DEVI0028')}
            onConfirm={() => {
              rebootDevice(ids, (res) => {
                if (res.data.code === 'CACCSI0001') {
                  toast.success(res.data.msg);
                } else if (res.data.code === 'CACCSW0001') {
                  toast.warning(res.data.msg);
                } else {
                  toast.error(res.data.msg);
                }
                props.onSuccess();
              });
              onClose();
            }}
            onClose={() => {
              onClose();
            }}
          />
        );
      },
    });
  };

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            {/* <Button color='secondary' size='small' startIcon={<SystemUpdateAltIcon className='delete-btn' />} className='acc-list-action-btn' {...bindTrigger(popupState)}>
              {t('DEVI0021')}
                      </Button> */}
            <Tooltip title={t('DEVI0021')}>
              <IconButton color='secondary' aria-label={t('DEVI0021')} {...bindTrigger(popupState)}>
                <DeviceControl style={{ height: 30, width: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => deviceOperations(popupState, 'rebootDev', t('DEVI0028'))}>{t('DEVI0016')}</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
