import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import SubmitButton from '../../components/SubmitButton';
import CommonUtil from '../../components/Util/CommonUtils';
import CancelButton from '../../components/CancelButton';
import { toast } from 'react-toastify';
import { getDepartments } from '../../actions/organization/DepartmentApiCalls';
import { JAVA_API_PERSON } from '../../actions/organization/ApiEndPoints';
import { getDesignations } from '../../actions/organization/DesignationApiCalls';
import { addPerson } from '../../actions/organization/PersonApiCalls';
import { useHistory } from 'react-router';
import { REACT_URI_PERSON } from '../../actions/organization/EndPoints';
import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';
import defaultProfile from '../../assets/images/defaultProfile.png';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FileBase64 from 'react-file-base64';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MobileInput from '../../components/MobileInput';
import axios from 'axios';
export default function AddPerson(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      marginTop: '16px',
    },
  }));
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const [open, setOpen] = React.useState(props.open);
  const [error, setError] = React.useState({});
  let history = useHistory();

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    employeeCode: '',
    joinDate: '',
    gender: '',
    departmentId: '',
    designationId: '',
    profilePhoto: '',
  });
  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [file, setFile] = useState({});
  const [profile, setProfile] = useState(defaultProfile);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize, 10);
      if (size <= 2000) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(',')[1],
        });
        setProfile(file.base64);
      } else {
        //setFile('');
        //setProfile(state.profilePhoto);
        toast.error(t('REGISTRATIONFORM001'));
      }
    } else {
      //setFile('');
      //setProfile(state.profilePhoto);
      toast.error(t('REGISTRATIONFORM002'));
    }
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    if (isFormValid(state)) {
      let finalcountryCode = CommonUtil.isEmptyString(countryCode) ? CommonUtil.getDefaultCallingCountryCode() : countryCode;
      let personObj = {};
      personObj.firstName = state.firstName;
      personObj.lastName = state.lastName;
      personObj.email = state.email;
      personObj.phone = CommonUtil.isEmptyString(state.phone) ? '' : '+' + finalcountryCode.replace('+', '') + '-' + state.phone;
      personObj.employeeCode = state.employeeCode;
      personObj.joinDate = state.joinDate;
      personObj.gender = state.gender;
      personObj.departmentId = state.departmentId;
      personObj.designationId = state.designationId;
      personObj.profilePhoto = state.profilePhoto;
      personObj.createUser = false;

      let query = '';
      if (state.employeeCode !== '') {
        query += '&employeeCode=' + state.employeeCode;
      }
      axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + 1 + '&size=1' + query).then((response) => {
        if (JSON.stringify(response.data.data).length > 2) {
          setError({ employeeCode: true });
          toast.error(t('COMMON470'));
          return false;
        } else {
          dispatch(
            addPerson(personObj, (data) => {
              handleError(data);
            })
          );
        }
      });
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON016'));
      return false;
    }
    if (!CommonUtil.isValidName(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON017'));
      return false;
    }

    if (!CommonUtil.isValidName(state.lastName)) {
      setError({ lastName: true });
      toast.error(t('PERSON018'));
      return false;
    }

    if (!CommonUtil.isEmptyString(state.phone)) {
      var phone = state.phone + countryCode.replace('+', '');
      if (phone.length < 8 || phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      }
    }

    // if (CommonUtil.isEmptyString(state.email) && CommonUtil.isEmptyString(state.phone)) {
    //   setError({ email: true });
    //   toast.error(t('PERSON021'));
    //   return false;
    // }

    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('PERSON022'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON019'));
      return false;
    }

    if (!CommonUtil.isValidCode(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON020'));
      return false;
    }

    // if (CommonUtil.isEmptyString(state.gender)) {
    //   setError({ gender: true });
    //   toast.error(t('PERSON025'));
    //   return false;
    // }

    return true;
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'OMSI0006':
        history.push(REACT_URI_PERSON);
        break;
      case 'OMSE0097':
        setError({ employeeCode: true });
        break;
      case 'OMSE0184':
        setError({ email: true });
        break;
      case 'OMSE0213':
        setError({ phone: true });
        break;
      case 'OMSE0214':
        setError({ phone: true });
        break;
      case 'OMSE0160':
        setError({ email: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    dispatch(
      getDepartments(0, 0, false, (data) => {
        setDepartments(data.department);
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getDesignations(0, 0, false, (data) => {
        setDesignations(data.designation);
      })
    );
  }, []);

  useEffect(() => {
    if (departments instanceof Array && CommonUtil.isEmptyString(state.departmentId)) {
      departments.map((dept) => {
        if (dept.code === 'Default') {
          setState({ ...state, departmentId: dept.id });
        }
      });
    }
    if (designations instanceof Array && CommonUtil.isEmptyString(state.designationId)) {
      designations.map((desg) => {
        if (desg.code === 'Default') {
          setState({ ...state, designationId: desg.id });
        }
      });
    }
  }, [departments, designations]);

 

  return (
    <div>
    
      <Dialog maxWidth='md' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal' >
        <DialogTitle id='form-dialog-title'>
          <Box display='flex' m={1}>
            <Box flexGrow={1}>
              <Typography variant='h5'>{t('PERSON002')}</Typography>
            </Box>
            <Cancel onClick={handleClose} className='close_icon' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off' >
            <Grid container justifyContent='center' alignItems='center'>
              <Grid container>
                <Grid container justify='center'>
                  <Grid item xs={12}>
                    <div className='d-flex align-items-center justify-content-center'>
                      <Avatar alt='' src={profile} className={classes.large} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className='d-flex align-items-center justify-content-center'>
                      <IconButton variant='contained' component='label' color='secondary'>
                        <PhotoCameraIcon />
                        <div style={{ display: 'none' }}>
                          <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                        </div>
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    required
                    error={error.firstName}
                    name='firstName'
                    label={t('PERSON003')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.firstName}
                    onChange={handleChange}
                    inputProps={{
                      maxlength: 100,
                    }}
                    value={state.firstName}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.lastName}
                    name='lastName'
                    label={t('PERSON004')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.lastName}
                    onChange={handleChange}
                    inputProps={{
                      maxlength: 25,
                    }}
                    value={state.lastName}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.email}
                    name='email'
                    label={t('PERSON005')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.email}
                    onChange={handleChange}
                    value={state.email}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <PhoneInput placeholder={t('PERSON006')} enableSearch='true' value={state.phone} onChange={(phone) => setState({ ...state, phone: phone })} 
                  searchPlaceholder={t('search')}
                  searchNotFound={t('COMMON165')}
                  />
                </Box>
              </Grid> */}
              <MobileInput
                error={error.phone}
                countryCode={countryCode}
                phone={state.phone}
                editMode={true}
                onMobileNoChange={(phone) => {
                  setError({ phone: false });
                  setState({ ...state, phone: phone });
                }}
                onCounryCodeChange={(code) => setCountryCode(code)}
              />
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    required
                    error={error.employeeCode}
                    name='employeeCode'
                    label={t('PERSON046')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.employeeCode}
                    onChange={handleChange}
                    inputProps={{
                      maxlength: 20,
                    }}
                    value={state.employeeCode}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON007')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.gender}
                      name='gender'
                      label={t('PERSON007')}
                      variant='outlined'
                      size='small'
                      value={state.gender}
                      defaultValue={state.gender}
                      onChange={handleChange}
                    >
                      <MenuItem value={'M'}>{t('male')}</MenuItem>
                      <MenuItem value={'F'}>{t('female')}</MenuItem>
                      <MenuItem value={'O'}>{t('others')}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON010')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.departmentId}
                      name='departmentId'
                      label={t('PERSON010')}
                      variant='outlined'
                      size='small'
                      value={state.departmentId}
                      defaultValue={state.departmentId}
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {departments ? (
                        departments.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON011')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.designationId}
                      name='designationId'
                      label={t('PERSON011')}
                      variant='outlined'
                      size='small'
                      value={state.designationId}
                      defaultValue={state.designationId}
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {designations ? (
                        designations.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
  
    </div>
  );
}
