import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/Util/i18n';
import BrowserLanguage from './components/Util/BrowserLanguage';
import { toast } from 'react-toastify';
import { hideLoader } from './actions/LoaderActions';
import store from './config/configureStore';
import CommonUtil from './components/Util/CommonUtils';
import { createTheme, ThemeProvider } from '@mui/material';

serviceWorker.unregister();

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      window.location = CommonUtil.getCookie('zlink_redirect_url');
    }
    if (403 === error.response.status) {
      store.dispatch(hideLoader());
      toast.error(error.response.data.message);
    } else {
      store.dispatch(hideLoader());
      return Promise.reject(error);
    }
  }
);

function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

axios.interceptors.request.use(
  (config) => {
    const language = BrowserLanguage.getDefaultLanguage();
    //axios.defaults.headers.common['accept-Language'] = language;

    config.headers.common['time-zone'] = getTimeZone();

    //config.url = config.url+language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#36C96D',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
          color: '#304669',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          width: '180px',
          height: '46px',
          borderRadius: '8px',
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#304669',
    },
    title: {
      color: '#304669',
      letterSpacing: '0.4px',
      font: 'normal normal normal 20px/29px Roboto',
      opacity: '1',
      fontWeight: 500,
    },
    subtitle3: {
      color: '#304669',
      letterSpacing: '0px',
      font: 'normal normal normal 16px/19px Roboto',
      opacity: '1',
      fontWeight: '400',
    },
    link: {
      color: '#304669',
      letterSpacing: '0.4px',
      font: 'normal normal normal 16px/19px Roboto',
      opacity: '1',
      fontWeight: '400',
    },
    subtitle4: {
      color: '#304669',
      letterSpacing: '0.4px',
      font: 'normal normal normal 16px/19px Roboto',
      fontWeight: '400',
      opacity: '0.8',
    },
    label: {
      color: '#304669',
      letterSpacing: '0',
      font: 'normal normal normal 14px/26px Roboto',
      fontWeight: '400',
      opacity: '0.5',
    },
    value: {
      color: '#304669',
      letterSpacing: '0',
      font: 'normal normal normal 14px/26px Roboto',
      fontWeight: '400',
      opacity: '1',
    },
    fieldLabel: {
      color: '#304669',
      letterSpacing: '0.9px',
      font: 'normal normal normal 14px/22px Roboto',
      fontWeight: '500',
      opacity: '0.5',
    },
    fieldValue: {
      color: '#304669',
      letterSpacing: '0.9px',
      font: 'normal normal normal 14px/22px Roboto',
      fontWeight: '500',
      opacity: '1',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={MuiTheme}>
    <I18nextProvider i18n={i18n}>
      <App props={i18n} />
    </I18nextProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
