import { GET_ANTIPASSBOOK_LIST, SUCCESS} from '../actions/types';

const initialState = {
    antiPassbookList: [],
    isSuccess: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        
        case GET_ANTIPASSBOOK_LIST:
             
            return {
                ...state,
                antiPassbookList: action.payload
                 
            };
        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

        default:
            return state;
    }
}