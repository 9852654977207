import { GET_REPORTS_TRANSACTIONS, SUCCESS, GET_REPORTS_FILTER_TRANSACTIONS, TRANSACTION_LIST } from "../actions/types";

const initialState = {
  transactionsList: [],
  transactions: [],
  isSuccess: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_LIST:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_REPORTS_TRANSACTIONS:
      return {
        ...state,
        transactionsList: action.payload,
      };
    case GET_REPORTS_FILTER_TRANSACTIONS:
      return {
        ...state,
        transactionsList: action.payload,
      };
    case SUCCESS:
      return {
        ...state,
        isSuccess: true,
      };

    default:
      return state;
  }
}
