export const SELECTED_SITE = 'SELECTED_SITE'
export const SELECTED_ZONE = 'SELECTED_ZONE'
export const SELECTED_DEVICE = 'SELECTED_DEVICE'
export const SELECTED_SUB_DEVICE = 'SELECTED_SUB_DEVICE'
export const SELECTED_DEVICE_DOOR = 'SELECTED_SUB_DOOR'
export const SELECTED_DEVICE_AUXIN = 'SELECTED_DEVICE_AUXIN'
export const SELECTED_DEVICE_AUXOUT = 'SELECTED_DEVICE_AUXOUT'
export const SELECTED_DEVICE_READER = 'SELECTED_DEVICE_READER'
export const SELECTED_DOOR_INFORMATION = 'SELECTED_DOOR_INFORMATION'
export const RESET_TOPOLOGY = 'RESET_TOPOLOGY'
export const DEVICE_COUNT = 'DEVICE_COUNT'
export const DOOR_COUNT = 'DOOR_COUNT'


//new