import {
  SELECTED_SITE,
  SELECTED_ZONE,
  SELECTED_DEVICE,
  SELECTED_SUB_DEVICE,
  RESET_TOPOLOGY,
  DEVICE_COUNT,
  DOOR_COUNT,
  SELECTED_DOOR_INFORMATION,
  SELECTED_DEVICE_READER,
  SELECTED_DEVICE_AUXIN,
  SELECTED_DEVICE_AUXOUT,
} from '../device/types';
const initialState = {
  selectedSite: {},
  selectedZone: {},
  selectedDevice: {},
  selectedSubDevice: {},
  selectedInformation: {},
  selectedDeviceReader: {},
  selectedDeviceAuxIn: {},
  selectedDeviceAuxOut: {},
  deviceCount: '',
  doorCount: '',
  selectedReset: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SELECTED_SITE:
      return {
        ...state,
        selectedZone: {},
        selectedDevice: {},
        selectedSubDevice: {},
        selectedInformation: {},
        selectedSite: action.payload,
      };

    case SELECTED_ZONE:
      return {
        ...state,
        selectedDevice: {},
        selectedSubDevice: {},
        selectedInformation: {},
        selectedZone: action.payload,
      };

    case SELECTED_DEVICE:
      return {
        ...state,
        selectedSubDevice: {},
        selectedInformation: {},
        selectedDevice: action.payload,
      };

    case SELECTED_SUB_DEVICE:
      return {
        ...state,
        selectedSubDevice: {},
        selectedSubDevice: action.payload,
      };

    case SELECTED_DEVICE_READER:
      return {
        ...state,
        selectedDeviceReader: {},
        selectedDeviceReader: action.payload,
      };

    case SELECTED_DEVICE_AUXIN:
      return {
        ...state,
        selectedDeviceAuxOut: {},
        selectedDeviceAuxIn: action.payload,
      };

    case SELECTED_DEVICE_AUXOUT:
      return {
        ...state,
        selectedDeviceAuxOut: action.payload,
      };

    case DEVICE_COUNT:
      return {
        ...state,
        deviceCount: action.payload,
      };

    case DOOR_COUNT:
      return {
        ...state,
        doorCount: action.payload,
      };

    case RESET_TOPOLOGY:
      return {
        ...state,
        selectedSite: {},
        selectedZone: {},
        selectedDevice: {},
        selectedSubDevice: {},
        selectedInformation: {},
        selectedDeviceReader: {},
        selectedDeviceAuxIn: {},
        selectedDeviceAuxOut: {},
        deviceCount: '',
        doorCount: '',
      };

    default:
      return state;
  }
}
