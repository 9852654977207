import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { Tooltip, IconButton } from '@material-ui/core';
import { Typography } from 'antd';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-CN';
import ruLocale from 'date-fns/locale/ru';
import jaLocale from 'date-fns/locale/ja';
import idLocale from 'date-fns/locale/id';
import thLocale from 'date-fns/locale/th';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

export default function  EventsFilter(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  let language = BrowserLanguage.getDefaultLanguage();

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12);
    }

    if (numValue < 10) {
      return '-0' + numValue;
    } else {
      return '-' + numValue;
    }
  };
  // var today = new Date();
  // var startDateTime = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + ' 19:48:2';
  // var endDateTime = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + ' 19:48:2';

  const [state, setState] = React.useState({
    startDateTime: null,
    endDateTime: null,
  });

  const localeMap = {
    en: enLocale,
    es: esLocale,
    zh: zhLocale,
    ru: ruLocale,
    ja: jaLocale,
    id: idLocale,
    th: thLocale,
  };

  const maskMap = {
    en: '__/__/____',
    es: '__/__/____',
    zh: '__/__/____',
    ru: '__/__/____',
    ja: '__/__/____',
    id: '__/__/____',
    th: '__/__/____',
  };

  const handleClose = () => {
    setState({
      startDateTime: null,
      endDateTime: null,
    });
    setOpen(false);
  };

  const reset = () => {
    if (state.startDateTime === null && state.endDateTime === null) {
      toast.error(t('COMMON027'));
    }
    setState({
      startDateTime: null,
      endDateTime: null,
    });
  };

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  const handleSubmit = () => {
    if (state.startDateTime === null && state.endDateTime === null) {
      toast.error(t('COMMON015'));
      return;
    }
    var startDateTime = '';
    var endDateTime = '';
    var startDate = state.startDateTime;
    var endDate = state.endDateTime;
    var filterStartDate = new Date(startDate === null ? '' : startDate);
    var filterEndDate = new Date(endDate === null ? '' : endDate);
    var dto = '';
    if ((state.startDateTime != null && state.endDateTime === null) || (state.startDateTime === null && state.endDateTime != null)) {
      if (typeof filterStartDate === 'object' && filterStartDate !== null && 'getFullYear' in filterStartDate) {
        if (!(state.startDateTime === null)) {
          startDateTime =
            filterStartDate.getFullYear() +
            formatDayAndMonth(filterStartDate.getMonth() + 1) +
            formatDayAndMonth(filterStartDate.getDate()) +
            ' ' +
            filterStartDate.getHours() +
            ':' +
            filterStartDate.getMinutes() +
            ':' +
            filterStartDate.getSeconds();
        }
      }

      if (typeof filterEndDate === 'object' && filterEndDate !== null && 'getFullYear' in filterEndDate) {
        if (!(state.endDateTime === null)) {
          endDateTime =
            filterEndDate.getFullYear() +
            formatDayAndMonth(filterEndDate.getMonth() + 1) +
            formatDayAndMonth(filterEndDate.getDate()) +
            ' ' +
            filterEndDate.getHours() +
            ':' +
            filterEndDate.getMinutes() +
            ':' +
            filterEndDate.getSeconds();
        }
      }

      dto = {
        ...state,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      props.applyFilter(dto);
      setState({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      });
      setState({
        startDateTime: '',
        endDateTime: '',
      });
      handleClose();
    } else if (state.startDateTime !== null && state.endDateTime !== null) {
      if (typeof filterStartDate === 'object' && filterStartDate !== null && 'getFullYear' in filterStartDate) {
        startDateTime =
          filterStartDate.getFullYear() +
          formatDayAndMonth(filterStartDate.getMonth() + 1) +
          formatDayAndMonth(filterStartDate.getDate()) +
          ' ' +
          filterStartDate.getHours() +
          ':' +
          filterStartDate.getMinutes() +
          ':' +
          filterStartDate.getSeconds();
        endDateTime =
          filterEndDate.getFullYear() +
          formatDayAndMonth(filterEndDate.getMonth() + 1) +
          formatDayAndMonth(filterEndDate.getDate()) +
          ' ' +
          filterEndDate.getHours() +
          ':' +
          filterEndDate.getMinutes() +
          ':' +
          filterEndDate.getSeconds();

        dto = {
          ...state,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };

        props.applyFilter(dto);
        setState({
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        });
        setState({
          startDateTime: '',
          endDateTime: '',
        });
        handleClose();
        return;
      } else {
        if (new Date(state.startDateTime) > state.endDateTime) {
          toast.error(t('OPE028'));
          return;
        }
      }
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const disabledEndDate = (current) => {
    // Can not select days before today and today
    return current && (current < state.startDateTime || current > moment().endOf('day'));
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton color='secondary' aria-label='Rest filter' onClick={() => setOpen(true)}>
          <FilterListIcon onClick={() => setOpen(true)} />
        </IconButton>
      </Tooltip>

      <Dialog open={open} fullWidth maxWidth={'md'} onClose={handleClose} className='filter-dialog' style={{ zIndex: 1 }}>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>

        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container>
              <Grid item xs={6} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[language == 'idn' ? 'id' : language]}>
                  {/* <div>
                    <DesktopDatePicker
                      mask={maskMap[language == 'idn' ? 'id' : language]}
                      label={t('OPE018')}
                      value={state.startDateTime}
                      maxDate={today}
                      //inputFormat='dd-MM-yyyy'
                      onChange={(startDateTime) => setState({ ...state, startDateTime: startDateTime })}
                      minDateTime={state.startDateTime}
                      leftArrowButtonText={t('COMMON173')}
                      rightArrowButtonText={t('COMMON174')}
                      renderInput={(params) => (
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField fullWidth size='small' variant='outlined' {...params} disabled={true} />
                        </Box>
                      )}
                    />
                  </div> */}

                  <DatePicker
                    showTime
                    placeholder='Start Date'
                    mask={maskMap[language == 'idn' ? 'id' : language]}
                    label={t('OPE018')}
                    value={state.startDateTime}
                    onChange={(startDateTime) => setState({ ...state, startDateTime: startDateTime })}
                    minDateTime={state.startDateTime}
                    disabledDate={disabledDate}
                  // leftArrowButtonText={t('COMMON173')}
                  // rightArrowButtonText={t('COMMON174')}
                  // renderInput={(params) => (
                  //   <Box display='flex' justifyContent='center' m={1} p={1}>
                  //     <TextField fullWidth size='small' variant='outlined' {...params} disabled={true} />
                  //   </Box>
                  // )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[language == 'idn' ? 'id' : language]}>
                  {/* <div>
                    <DesktopDatePicker
                      mask={maskMap[language == 'idn' ? 'idn' : language]}
                      label={t('Event002')}
                      maxDate={today}
                      //inputFormat='dd-MM-yyyy'
                      value={state.endDateTime}
                      onChange={(endDateTime) => setState({ ...state, endDateTime: endDateTime })}
                      minDateTime={state.startDateTime}
                      leftArrowButtonText={t('COMMON173')}
                      rightArrowButtonText={t('COMMON174')}
                      renderInput={(params) => (
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField fullWidth size='small' variant='outlined' {...params} disabled={true} />
                        </Box>
                      )}
                    />
                  </div> */}
                  <DatePicker
                    showTime
                    placeholder='End Date'
                    mask={maskMap[language == 'idn' ? 'id' : language]}
                    label={t('OPE018')}
                    value={state.endDateTime}
                    onChange={(endDateTime) => setState({ ...state, endDateTime: endDateTime })}
                    minDateTime={state.endDateTime}
                    disabledDate={disabledEndDate}
                  // leftArrowButtonText={t('COMMON173')}
                  // rightArrowButtonText={t('COMMON174')}
                  // renderInput={(params) => (
                  //   <Box display='flex' justifyContent='center' m={1} p={1}>
                  //     <TextField fullWidth size='small' variant='outlined' {...params} disabled={true} />
                  //   </Box>
                  // )}
                  />
                </LocalizationProvider>
              </Grid>

              {/* <Grid item xs={6} sm={4}>
                                <Box display='flex' justifyContent='center' m={1} p={1}>
                                    <TextField fullWidth name='personnelId' label={t('OPE017')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.personnelId} />

                                </Box>
                            </Grid> */}

              {/* <Grid item xs={6} sm={4}>
                                <Box display='flex' justifyContent='center' m={1} p={1}>
                                    <TextField fullWidth name='deviceAlias' label={t('OPE020')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.deviceAlias} />
                                </Box>
                            </Grid> */}

              {/* <Grid item xs={6} sm={4}>
                                <Box display='flex' justifyContent='center' m={1} p={1}>
                                    <TextField fullWidth name='cardNo' label={t('OPE004')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.cardNo} />
                                </Box>
                            </Grid> */}

              {/* <Grid item xs={6} sm={4}>
                                <Box display='flex' justifyContent='center' m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name='eventPointName'
                                        label={t('OPE011')}
                                        helperText=''
                                        variant='outlined'
                                        size='small'
                                        onChange={handleChange}
                                        value={state.eventPointName}
                                    />
                                </Box>
                            </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} variant='contained' size='small'>
            {t('COMMON024')}
          </SubmitButton>
          <CancelButton onClick={reset} variant='contained' size='small'>
            {t('COMMON025')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
