import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Button, Typography, Radio, Grid, Avatar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: 'rgb(15 90 156 / 5%)',
        color: '#5A5A5A',
        fontSize: '14px',
        fontWeight: 600,
        borderRadius: 0,
    },
    body: {
        fontSize: 14,
        borderBottom: '0px',
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
    },
}))(TableRow);

const CustomRadio = withStyles({
   
    root: {
        color: '#707070',
        '&.Mui-checked': {
            color: '#F2824C',
        },
    },
})((props) => <Radio color="default" {...props} />);

const ModalCustom = (props) => {
    const {
        bioDetails,
        handleCancel,
        cancelText,
        handleOpen,
        handleClose,
        open,
        imgSrc,
    } = props

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            minWidth: '680px',
            width: '680px',
            backgroundColor: '#fff',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(0, 1, 1, 2),
            position: 'absolute',
           
        },
        innerWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: '15px',
            paddingTop: '25px',
        },
        
        ReadIcon: {
            color: '#227DAB',
        },
        recommendedCard: {
            color: '#227DAB',
            cursor: 'pointer'
        },
        headertext: {
            color: '#fff',
            backgroundColor:'#3b3e66'
        },
        large: {
            width: theme.spacing(13.25),
            height: theme.spacing(13.25),
            background: '#E6E8E6',
            border: '1px solid #707070',
        },
        confirmButton: {
            color: '#227DAB',
            fontSize: '16px',
            backgroundColor: 'transparent !important',
        },
        cancelButton: {
            color: '#fff',
            fontSize: '12px',
            paddingBottom:'5px',
            backgroundColor: '#3b3e66',
            '&.MuiButton-root:hover': {
                backgroundColor: '#3b3e66',
            }
        },
        confirmButtonDelete: {
            color: '#5A5A5A',
            fontSize: '16px',
            '&.MuiButton-root:hover': {
                backgroundColor: 'transparent !important',
            }
        },
        closeButton: {
            fontSize: '16px',
            color: 'red',
            '&.MuiIconButton-root:hover': {
                backgroundColor: 'transparent !important',
            },
            '&.MuiIconButton-root': {
                paddingTop: '10px',
                paddingRight: '10px'
            }
        },
        headerStyle: {
            color:  '#fff',
            fontSize: '18px',
            fontWeight: 500,
            paddingBottom: '25px',
        },
        
        key: {
            fontFamily: 'password'
        },
        accessTitle: {
            paddingLeft: '2px',
            paddingTop: '3px',
            paddingBottom: '3px',
            fontSize: '14px',
        },
        accessTitleWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        
    }));

    const classes = useStyles();

    return (
        <div>
            <button type="button" style={{ display: 'none' }} onClick={handleOpen}>
                {''}
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes: {
                        root: classes.backDrop
                    }
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper} >

                         <Box >
                            <Typography style={{ fontSize:'25px', justifyContent: 'flex-start', paddingTop: '3px',paddingBottom:'3px' }}> Details info</Typography>
                        </Box>

                        {bioDetails !== undefined ?
                            <>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow className='modalCustom'>
                                                <StyledTableCell className={classes.headertext} width='100px'>Biometric Template Type</StyledTableCell>
                                                <StyledTableCell className={classes.headertext} width='110px'>Biometric Template Version</StyledTableCell>
                                                <StyledTableCell className={classes.headertext} width='100px'>Biological Template Quantity</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                    <div style={{ overflow: "auto", height: '260px'}} class="scrollbar1" id="style-2">
                                        <Table>
                                            <TableBody>
                                                {bioDetails.map((row) => (
                                                    <StyledTableRow hover key={row.name}>
                                                        <StyledTableCell width='100px' align='left' scope="row">
                                                            {row.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell width='110px' align='left' scope="row">
                                                            {row.version}
                                                        </StyledTableCell>
                                                        <StyledTableCell width='70px' align='left' scope="row">
                                                            {row.details}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TableContainer>
                                <Grid item xs={6} zeroMinWidth style={{ padding: "5px 16px" }}>
                                        <Box>
                                            {/* <ChooseFileTitle title={'Face Picture'} /> */}
                                            <Box  display="flex" alignItems="center">
                                                <Grid item lg={2} md={2} xs={2}>
                                                    <Avatar src={imgSrc} alt={'Face Picture'} variant="square" className={classes.large} />
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                            </> : null}
                        
                            <Box className={classes.innerWrapper} justifyContent="flex-end !important">
                                <Box>
                                    {cancelText ? <Button className={classes.cancelButton} onClick={handleCancel}>{cancelText}</Button> : ''}
                                </Box>
                            </Box>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default ModalCustom;