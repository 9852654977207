import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';

export const filterDoors = async (page, rowsPerPage, filter, callBack) => {
  let query='';
  
  if(!CommonUtil.isEmptyString(filter.siteId))
  {
    query='&siteId='+filter.siteId
  }else{
    query='';
  }
  try {
    const res = await axios.post('/web/door?page=' + page + '&size=' + rowsPerPage+query);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const doorOperation = async (deviceId, payload, callBack) => {
  try {
    const res = await axios.post('/web/door/' + deviceId + '/control', payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const updateDoor = async (doorId, payload, callBack) => {
  try {
    const res = await axios.put('/web/door/' + doorId, payload);
    callBack(res);
  } catch (error) {
    //callBack(null);
  }
};

export const getDoor = async (deviceId, callBack) => {

  try {
    const res = await axios.get('/web/door/device/' + deviceId);
    callBack(res)
  }
  catch (error) { }
}

export const getDoorById = async (id,callBack) => {
  try {
    const res = await axios.get('/web/door/' + id)
    callBack(res)
  }
  catch (error) {}
}

