import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices, removeDevice } from '../../actions/DeviceApiCalls';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import EditDevice from './EditDevice';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DeviceOperations from './DeviceOperations';
import { REACT_URL_DEVICE_ADD, REACT_URL_DEVICE_LIST } from '../../actions/EndPoints';
// import FilterDevice from './FilterDevice';
// import DeviceSetupOperations from './DeviceSetupOperations';
// import DeviceCommunicationOperations from './DeviceCommunicationOperations';
import CommonUtil from '../../components/Util/CommonUtils';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
// import DeviceViewAndGetOperations from './DeviceViewAndGetOperations';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button, Fab } from '@material-ui/core';
import axios from 'axios';
import { filterDevices, deleteDevice, findDevice } from './DeviceService';
import DeviceFilter from './DeviceFilter';
import EditDevice from './EditDevice';
import DeviceSetupOperations from './DeviceSetupOperations';
import SetRegistrationDevice from './SetRegistrationDevice';
import DevicePersons from '@mui/icons-material/CoPresent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function DeviceList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [selectedDevices, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateDevice, setUpdateDevice] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [reloads, setReloads] = useState(false);

  const [filter, setFilter] = useState({
    sn: '',
    alias: '',
    zoneId: '',
  });

  const defaultFilter = {
    sn: '',
    alias: '',
    zoneId: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      sn: filterData.sn,
      alias: filterData.alias,
      zoneId: filterData.zoneId,
    };
    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadDevices(0, rowsPerPage);
  }, [filter]);

  useEffect(() => {
    if (reloads === true) loadDevices(0, rowsPerPage);
  }, [reloads]);

  const loadDevices = (page, rowsPerPage) => {
    let payload = {
      sn: filter.sn,
      alias: filter.alias,
      zoneId: filter.zoneId,
    };

    filterDevices(page + 1, rowsPerPage, payload, (res) => {
      if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.devices instanceof Array) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
      } else {
        setTotalRecords(0);
        setPage(0);
      }
      setDevices(res.data.data.devices);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadDevices(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadDevices(0, pageSize);
  };

  const deviceDelete = (data) => {
    if (data instanceof Array && data.length == 0) {
      toast.error(t('COMMON019'));
      return;
    }

    var ids = [];

    data.map((d) => {
      ids.push(d.id);
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('DEVI0015')}
            onConfirm={() => {
              deleteDevice(ids, (res) => {
                if (res.data.code === 'CACCSI0002') {
                  toast.success(res.data.msg);
                  loadDevices(page, rowsPerPage);
                } else if (res.data.code === 'CACCSW0001') {
                  toast.warning(res.data.msg);
                  loadDevices(page, rowsPerPage);
                } else {
                  toast.error(res.data.msg);
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleChangeDevicePersons = (device) => {
    if (device instanceof Array && device.length == 0) {
      toast.error(t('DEVI0014'));
      return;
    } else if (device instanceof Array && device.length > 1) {
      toast.error(t('COMMON477'));
      return;
    } else {
      history.push({
        pathname: '/acc/z/persons-in-device',
        state: { selectedDevice: device },
      });
    }
  };
  const breadcrumb = [{ path: REACT_URL_DEVICE_LIST, name: t('DEVI0001') }];

  const columns = [
    {
      field: 'deviceAlias',
      title: t('Device Name'),
      tooltip: t('DEVI0002'),
      render: (rowData) => (!CommonUtil.isEmptyString(rowData.deviceAlias) ? rowData.deviceAlias : rowData.ip),
    },
    { field: 'sn', title: t('COMMON055'), tooltip: t('DEVI0003') },
    { field: 'ip', title: t('COMMON056'), tooltip: t('DEVI0004') },
    { field: 'deviceModel', title: t('Device Model'), tooltip: t('DEVI0005') },
    {
      field: 'registrationDevice',
      title: t('COMMON058'),
      tooltip: t('DEVI0006'),
      render: (rowData) => (rowData.registrationDevice ? 'Yes' : 'No'),
    },
    { field: 'fwVersion', title: t('COMMON059'), tooltip: t('DEVI0007') },
    {
      field: 'deviceStatus',
      title: t('COMMON001'),
      tooltip: t('DEVI0078'),
      render: (rowData) => (
        <span
          style={{
            fontWeight: 'bold',
            color: rowData.status === 1 ? 'green' : 'red',
          }}
        >
          {rowData.status === 1 ? 'Online' : 'Offline'}
        </span>
      ),
    },
  ];

  const actions = [
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateDevice(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deviceDelete([rowData]);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };
  const reload = (value) => {
    setReloads(value);
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        {/* {(store.security.user.client_id === '38c42726ab234fcda88ee9dc418637a0' || totalRecords < 10) && ( */}
        <Tooltip title={t('COMMON009')}>
          <Button
            size='small'
            variant='contained'
            color='secondary'
            aria-label={t('COMMON002')}
            component={Link}
            to={{
              pathname: '/acc/z/device-add-instruction',
              page: 'add',
            }}
          >
            {' '}
            {t("COMMON481")}
          </Button>
        </Tooltip>
        {/* )} */}
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON004')} onClick={() => deviceDelete(selectedDevices)}>
              <DeleteOutlineIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <DeviceOperations style={{ height: 28, width: 28 }} devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} />
        </Box>
        <Box p={1}>
          <DeviceSetupOperations setReload={reload} devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} />
        </Box>
        <Box p={1} margin={'auto'}>
          <Tooltip title={t('DEVI0080')}>
            <DevicePersons
              style={{ color: '#3b3e66', cursor: 'pointer' }}
              onClick={() => {
                handleChangeDevicePersons(selectedDevices);
              }}
            />
          </Tooltip>
        </Box>
        <Box p={1}>{/* <DeviceViewAndGetOperations devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} /> */}</Box>
        <Box p={1} flexGrow={1}>
          {/* <DeviceCommunicationOperations devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} /> */}
        </Box>
        <Box p={1}>{<DeviceFilter page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />}</Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={devices}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      {openEditDialog && (
        <EditDevice
          device={updateDevice}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadDevices(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
