import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CredentialCountBox } from './CredentialCountBox'

import { ReactComponent as Pin } from "../../assets/credentials-svg/Lock.svg";
import { ReactComponent as Card } from "../../assets/credentials-svg/Card.svg";
import { ReactComponent as FingerPrint } from "../../assets/credentials-svg/fingerprint.svg";
import { ReactComponent as Facial } from "../../assets/credentials-svg/Facial.svg";
import { ReactComponent as FingerVein } from "../../assets/credentials-svg/finger.svg";
import { ReactComponent as PalmVein } from "../../assets/credentials-svg/palmVein.svg";
import { toast } from 'react-toastify';
import { remoteRegistration } from './DeviceService';
import BioSpecificNumber from './BioSpecificNumber';
import BioSpecificPassCode from './BioSpecificPasscode';

const CredentialCount = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [needFinger, setNeedFinger] = useState(false);
  const [needPassCode, setNeedPassCode] = useState(false);
  const [openPwdDialog, setOpenPwdDialog]=useState(false);
  const [pin, setPin] = useState(null);

  const { face, palm, fingerPrint, fingerVein } = props.deviceSupports;
  const checkDeviceSpecs = (data) => {
    if (face === data.face) {
      let payload = {
        enrollType: "9",
        pin: data.pin,
        fid: 0
      }
      remoteRegistration(props.devId, payload, (data) => {
        if (data.data.code === 'DMSI0000') {
          toast.success(data.data.msg)
        } else {
          toast.error(data.data.msg)
        }
      })
    } else if (palm === data.palm) {

      setPin(data)
      setNeedFinger(false);
      setOpenDialog(!openDialog);

    } else if (fingerPrint === data.fingerPrint) {

      setPin(data)
      setNeedFinger(true)
      setOpenDialog(!openDialog);

    } else if (fingerVein === data.fingerVein) {
      let payload = {
        enrollType: "7",
        pin: data.pin,
        fid: 0
      }
      remoteRegistration(props.devId, payload, (data) => {
        if (data.data.code === 'DMSI0000') {
          toast.success(data.data.msg)
        } else {
          toast.error(data.data.msg)
        }
      })
    }
    else if(data.passcode==1){
      setPin(data)
      setNeedPassCode(true)
      setOpenPwdDialog(!openPwdDialog);
    }
    else {
      toast.error('This device does not supports')
    }
  }
  const handleClose = () => {
      setOpenDialog(false) 
   }

  
  const handleClick = (data) => {
    if (needFinger === true) {
      let payload = {
        enrollType: "1",
        pin: pin.pin,
        fid: data.fid
      }
      remoteRegistration(props.devId, payload, (data) => {
        if (data.data.code === 'DMSI0000') {
          toast.success(data.data.msg);
          handleClose();
        } else {
          toast.error(data.data.msg)
          handleClose();
        }
      })
    }
    else {
      let payload = {
        enrollType: "8",
        pin: pin.pin,
        fid: data.fid
      }
      remoteRegistration(props.devId, payload, (data) => {
        if (data.data.code === 'DMSI0000') {
          toast.success(data.data.msg)
          handleClose();
        } else {
          toast.error(data.data.msg)
          handleClose();
        }
      })
    }
  }


  return (
    <>
      <Box display={'flex'} width={'100%'} heigth={'100%'}>
        <Box display={'flex'} width={'65%'} marginRight={'1%'} style={{ cursor: 'pointer' }} onClick={() => checkDeviceSpecs({ passcode: '1', pin: props.credential.employeeCode })}>
          <CredentialCountBox icon={<Pin />}
            value={props.credentialCount.passcode !== 0 ? props.credentialCount.passcode : 0}
            data={props.credential}
            credentialType={credentialType.passcode}

          />
        </Box>
        <Box display={'flex'} width={'65%'} marginRight={'1%'} style={{ cursor: 'pointer' }} onClick={() => checkDeviceSpecs({ card: '1', pin: props.credential.employeeCode })}>
          <CredentialCountBox icon={<Card />}
            value={props.credentialCount.card !== 0 ? props.credentialCount.card : 0}
            data={props.credential}
            credentialType={credentialType.card}
          />
        </Box>
        <Box display={'flex'} width={'88%'} marginRight={'1%'} style={{ cursor: 'pointer' }} onClick={() => checkDeviceSpecs({ fingerPrint: '1', pin: props.credential.employeeCode })}>
          <CredentialCountBox icon={<FingerPrint />}
            value={props.credentialCount.fingerPrint !== 0 ? props.credentialCount.fingerPrint : 0}
            data={props.credential}
            credentialType={credentialType.fingerPrint}
          />
        </Box>
        <Box display={'flex'} width={'75%'} marginRight={'1%'} style={{ cursor: 'pointer' }} onClick={() => checkDeviceSpecs({ face: '1', pin: props.credential.employeeCode })}>
          <CredentialCountBox icon={<Facial />}
            value={props.credentialCount.visibleLightFace !== 0 ? props.credentialCount.visibleLightFace : 0}
            data={props.credential}
            credentialType={credentialType.face}
          />
        </Box>
        <Box display={'flex'} width={'86%'} marginRight={'1%'} style={{ cursor: 'pointer' }} onClick={() => checkDeviceSpecs({ fingerVein: '1', pin: props.credential.employeeCode })}>
          <CredentialCountBox icon={<FingerVein />}
            value={props.credentialCount.fingerVein !== 0 ? props.credentialCount.fingerVein : 0}
            data={props.credential}
            credentialType={credentialType.fingerVein}
          />
        </Box>
        <Box display={'flex'} width={'86%'} marginRight={'1%'} style={{ cursor: 'pointer' }} onClick={() => checkDeviceSpecs({ palm: '1', pin: props.credential.employeeCode })}>
          <CredentialCountBox icon={<PalmVein />}
            value={props.credentialCount.palm !== 0 ? props.credentialCount.palm : 0}
            data={props.credential}
            credentialType={credentialType.palm}
          />
        </Box>

      </Box>
      {openDialog && <BioSpecificNumber open={openDialog} needFinger={needFinger} onClose={handleClose} handleClick={handleClick} />}
       {openPwdDialog &&<BioSpecificPassCode open={openPwdDialog}needPassCode={needPassCode} data={{credential:props.credential,devId:props.devId}} handleClick={handleClick}/>}
    </>
  )
}

export default CredentialCount

export const credentialType = {
  passcode: '102',
  card: '100',
  fingerPrint: '1',
  face: '2',
  fingerVein: '7',
  palm: '8'
};
