import React, { useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Menu, Typography, IconButton, MenuItem, Grid } from '@mui/material';
import { ReactComponent as BuildingIcon } from '../../assets/building.svg';
import { ReactComponent as DownArrow } from '../../assets/down-arrow.svg';
import { usePopupState, bindTrigger, bindPopover, bindMenu } from 'material-ui-popup-state/hooks';
import { ListItemIcon, ListItemText } from '@mui/material';
import SimpleDilogBox from './SimpleDilogBox';
import CompanyPic from '../../assets/OrgProfilePic.png';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import Tooltip from '@mui/material/Tooltip';
import AvatarCustom from '../../utils/AvatarCustom';
import { getCompanyV3 } from '../../actions/organization/CompanyApiCalls';
import { Avatar } from '@material-ui/core';

export default function CompanyProfileMenu() {
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
    const [open2, setOpen2] = React.useState(false);
    const store = useSelector((state) => state);
    const [profile, setProfile] = React.useState(CompanyPic);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getCompanyV3(store.security.user.companyId));
    },[])
    useEffect(()=>{
        if(!CommonUtil.isEmpty(store.company.companyData?.imagePreSignedURL)){
            setProfile(store.company.companyData.imagePreSignedURL)
        }
    })
    return (
        <div>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton sx={{ p: 0 ,pr:'5px'}}>
                <Avatar src={profile}/>
                </IconButton>
                <Tooltip title={CommonUtil.capitalizeFirst(store.company.companyData.name)} arrow>
                <Typography sx={{fontFamily:'Roboto',letterSpacing:'0px',color:'#304669',opacity:'1px'}}>
                 {CommonUtil.handleTextOverflowAndCapFirstLetter(store.company.companyData.name)}
                </Typography>
                </Tooltip>
                

                {/* <KeyboardArrowDownIcon
                    onMouseEnter={(event) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    {...bindTrigger(popupState)}
                    sx={{
                        fontSize: { xs: '14px', lg: '28px' },
                    }}
                /> */}
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ mt: '45px' }}
                id='basic-menu'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            popupState.close();

                        }}
                    >
                        <Box display='flex' width={'200px'}>
                            <Box item>
                                <ListItemIcon>
                                    <BuildingIcon fontSize='small' />
                                </ListItemIcon>
                            </Box>
                            <Box item flexGrow={1}>
                                <ListItemText>Organization Profile</ListItemText>
                            </Box>

                            <Box item>
                                <DownArrow fontSize='small' />
                            </Box>
                        </Box>
                    </MenuItem>

                    {/* <SwitchOrganization /> */}

                    <MenuItem onClick={popupState.close}>
                        <Box display='flex' width={'200px'}>
                            <Box item>
                                <ListItemIcon>
                                    <BuildingIcon fontSize='small' />
                                </ListItemIcon>
                            </Box>
                            <Box item flexGrow={1}>
                                <ListItemText onClick={() => setOpen2(true)}>Create Organization</ListItemText>
                            </Box>
                        </Box>
                    </MenuItem>
                </div>
                <SimpleDilogBox
                    customWidth={'600px'}
                    open={open2}
                    onClose={() => setOpen2(!open2)}
                    title={'Create organization'}
                //children={<CreateOrganization onClose={() => setOpen2(!open2)} />}
                />
            </Menu>
        </div>
    );
}
