import React from 'react';
import { Select, Box, Dialog, DialogTitle, MenuItem, Grid, FormControl, InputLabel } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { toast } from 'react-toastify';
import { deviceListLoad, updateDevice } from './DeviceService';
import CommonUtil from '../../components/Util/CommonUtils';
import { useHistory } from 'react-router-dom';

export default function SetRegistrationDevice(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [isRegDevice, setIsRegDevice] = React.useState(props.devices[0]?.registrationDevice);

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }
    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }
    setOpen(true);
  };
  const handleChange = (event) => {
    const name = event.target.value;
    setIsRegDevice(name);
    setError(false);
  };

  const handleSubmit = () => {
    const payload = {
      id: props.devices[0].id,
      registrationDevice: isRegDevice == 'true' ? '1' : '0',
      checkIn: props.devices[0].checkIn,
      checkOut: props.devices[0].checkOut,
      zoneId: props.devices[0].zoneId,
      siteId: props.devices[0].siteId,
      deviceAlias: props.devices[0].deviceAlias,
      timeZone: props.devices[0].timeZone,
    };

    updateDevice(payload.id, payload, (res) => {
      if (res.status === 200 && res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && CommonUtil.isEmpty(res.data.data instanceof Object)) {
        toast.success(t('DEVI0079'));
        props.onSuccess();
        handleClose();
      } else {
        toast.error(res.data.message);
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
    popupState.close();
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0033')}</MenuItem>
      <Dialog open={open} maxWidth='sm' onClose={handleClose} aria-aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0033')}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%', marginLeft: '18px' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DEVI0033') + ' *'}</InputLabel>

                    <Select native label={t('DEVI0033') + ' *'} name='isRegDevice' value={isRegDevice} error={error} onChange={handleChange}>
                      <option value='true'>{t('yes')}</option>
                      <option value='false'>{t('no')}</option>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
