import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({

    lng: '',
    initImmediate: false,
    fallbackLng: "en",
    preload: ["en", "es", "zh"],
    debug: true,
    backend: {
        loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    },

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false
    }
});

export default i18n;
