import axios from 'axios';
import { JAVA_API_ACC_LEVEL_DEPT_DELETE } from '../ApiEndPoints';

import { JAVA_API_DEPARTMENT, JAVA_API_DEPARTMENT_FILTER_LIST_V3, JAVA_API_DEPARTMENT_LIST_V3, JAVA_API_DEPARTMENT_SEARCH } from './ApiEndPoints';
import { DEPARTMENT_LIST } from './types';

export const getDepartments = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DEPARTMENT + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (isPageable) {
        dispatch({
          type: DEPARTMENT_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: DEPARTMENT_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const filterDepartment = (name, code, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    if (code !== '') {
      query += '&code=' + code;
    }
    const res = await axios.get(JAVA_API_DEPARTMENT_SEARCH + '?page=' + page + '&size=' + size + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const updateDepartment = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_DEPARTMENT, [payload]);
    } else {
      res = await axios.put(JAVA_API_DEPARTMENT + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch {}
};

export const deleteDepartment = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_ACC_LEVEL_DEPT_DELETE, { data: deleteObj });
    callBack(res.data);
  } catch {}
};
export const getDepartmentList = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DEPARTMENT + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      callBack(res.data.data);
    }
  }
  catch{}
}

export const getDepartmentListFilter_V3 = (pageNumber, pageSize, callBack) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_DEPARTMENT_FILTER_LIST_V3 + '?page=' + pageNumber + '&size=' + pageSize);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      callBack(res.data.data);
    }
  }
  catch{}
}