import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { filterZone, loadZoneBySite } from '../../actions/organization/ZoneApiCalls';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { Box, Fab, FormControl, Grid, InputLabel, MenuItem, Select, Typography, IconButton, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';
import { getZones } from '../../actions/organization/ZoneApiCalls';
import CancelIcon from '@material-ui/icons/Cancel';
import { filterZoneV3 } from './ZoneService';

export default function FilterZone(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [parentZones, setParentZones] = React.useState([]);

  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const useStyles = makeStyles((theme) => ({
    absolute: {
      margin: theme.spacing(),
      position: 'absolute',
      right: theme.spacing(5),
    },
  }));

  const [state, setState] = React.useState({
    name: '',
    parentId:''
  });


  useEffect(() => {
    loadData(page,rowsPerPage);
  }, []);

  const reset = () => {
      if (state.name.trim() === '' && state.parentId.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      name: '',
      parentId:''
    });
  };
  const handleClose = () => {
    setState({
      name: '',
      parentId:'',
    });
    setOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

    const loadData =(page,rowsPerPage) =>{
    // dispatch(
    //   getZones('','',false,(data) =>{
    //     setParentZones(data.zone)
    //   })

    // )

    let payload ={
      name: state.name,
      parentId: state.parentId,
      siteId:''
    }
    filterZoneV3(page + 1,rowsPerPage,payload,(res)=>{
      setParentZones(res.data.data.zones)
    })

  }
  const handleSubmit = () => {
      if (state.name.trim() === '' && state.parentId.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
props.applyFilter(state)
    setState({
      name: '',
      parentId:''
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} className='filter-dialog'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='name' label={t('COMMON044')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.name} />
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON050')}</InputLabel>
                    <Select label={t('COMMON050')} name='parentId' onChange={handleChange} value={state.parentId} defaultValue={state.parentZone}>
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {parentZones ? (
                        parentZones.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className='mr-4' style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON024')}
          </Button>
          <Button onClick={reset} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON025')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
