import BrowserLanguage from '../../components/Util/BrowserLanguage';

let language = BrowserLanguage.getDefaultLanguage();

export const permissionTree = [
    {
      parent: { title: 'Dashboard', 
      codes: [
        'ACC_DASHBOARD', 
        'VIEW_ACC_DASHBOARD'
         ] 
     },
      child: [
        {
          title: 'View dashboard',
          code: 'VIEW_ACC_DASHBOARD',
        },
      ],
    },
    {
      parent: {
        title: 'Organization',
        codes: [
            'ACC_ORGANIZATION',
            'VIEW_PERSON',
            'ASSING_USER_ROLE'
        ],
      },
      child: [
        {
          title: 'View person list',
          code: 'VIEW_PERSON',
        },
        {
          title: 'Assign user role',
          code: 'ASSING_USER_ROLE',
        },
      ],
    },
    {
      parent: {
        title: 'Role and permission',
        codes: [
          'ACC_ROLE_PERMISSION',
          'ACC_CREATE_ROLE_PERMISSION',
          'ACC_EDIT_ROLE_PERMISSION',
          'ACC_DELETE_ROLE_PERMISSION'
          ],
      },
      child: [
        {
          title: 'Create new role',
          code: 'ACC_CREATE_ROLE_PERMISSION',
        },
        {
          title: 'Edit role',
          code: 'ACC_EDIT_ROLE_PERMISSION',
        },
        {
          title: 'Delete role',
          code: 'ACC_DELETE_ROLE_PERMISSION',
        },
      ],
    },
    {
      parent: { title: 'Site management', 
      codes: [
        'SITE_MANAGMENT', 
        'ACC_VIEW_SITE', 
        'ACC_VIEW_ZONE'
        ] 
    },
      child: [
        {
          title: 'View site',
          code: 'ACC_VIEW_SITE',
        },
        {
          title: 'View zone',
          code: 'ACC_VIEW_ZONE',
        }
      ],
    },
    {
      parent: { title: 'Device management', 
      codes: [
        'DEVICE_MANAGEMENT',
        'ADD_DEVICE',
        'MANAGE_DEVICE',
        ] 
    },
      child: [
        {
            title: 'Add device',
            code: 'ADD_DEVICE',
          },
        {
          title: 'Manage device',
          code: 'MANAGE_DEVICE',
        },
      ],
    },
    {
      parent: { title: 'Access control', 
      codes: [
        'ACCESS_CONTROL', 
        'ADD_TIMESLOT', 
        'VIEW_TIMESLOT', 
        'EDIT_TIMESLOT', 
        'DELETE_TIMESLOT', 
        'MANAGE_ACCESS_GROUP', 
        'ADD_ACCESS_GROUP', 
        'VIEW_ACCESS_GROUP', 
        'EDIT_ACCESS_GROUP', 
        'DELETE_ACCESS_GROUP' 
        ] 
      },
      child: [
        {
          title: 'Add new time slots',
          code: 'ADD_TIMESLOT',
        },
        {
            title: 'View time slots',
            code: 'VIEW_TIMESLOT',
        },
        {
            title: 'Edit time slots',
            code: 'EDIT_TIMESLOT',
        },
        {
            title: 'Delete time slots',
            code: 'DELETE_TIMESLOT',
        },
        {
            title: 'Manage access group',
            code: 'MANAGE_ACCESS_GROUP',
        },
        {
            title: 'Add access group',
            code: 'ADD_ACCESS_GROUP',
        },
        {
            title: 'View access group',
            code: 'VIEW_ACCESS_GROUP',
        },
        {
            title: 'Edit access group',
            code: 'EDIT_ACCESS_GROUP',
        },
        {
            title: 'Delete access group',
            code: 'DELETE_ACCESS_GROUP',
        } 
      ],
    },
    {
      parent: { title: 'Reports', 
      codes: [
        'ACC_REPORT', 
        'VIEW_ACC_REPORT'
         ] 
     },
      child: [
        {
          title: 'View access reports',
          code: 'VIEW_ACC_REPORT',
        },
      ],
    },
  ];

  export default language === 'en'
  ? permissionTree
  : language === 'es'
  