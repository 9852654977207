import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterListIcon from "@material-ui/icons/FilterList";
import { toast } from "react-toastify";
import { Tooltip, IconButton } from "@material-ui/core";

export default function AccessGroupFilter(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    page: 0,
    pageSize: 1000,
    name: "",
  });

  const handleClose = () => {
    setState({
      name: "",
    });
    setOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (state.name.trim() === "") {
      toast.error(t("COMMON015"));
      return;
    }
    props.applyFilter(state);
    setState({
        name:''
    })

    handleClose();
  };

  const reset = () => {
    if (state.name.trim() === "") {
      toast.error(t("COMMON027"));
      return;
    }
    setState({
      name: "",
    });
  };
  return (
    <div>
      <Tooltip title={t("COMMON006")}>
        <IconButton
          color="secondary"
          aria-label="Reset filter"
          onClick={() => setOpen(true)}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        className="filter-dialog"
      >
        <DialogTitle>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h4" color="secondary">
                {t("COMMON006")}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>

        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <TextField
                    fullWidth
                    name="name"
                    label={t("ACCESSGROUP005")}
                    variant="outlined"
                    size="small"
                    helperText=""
                    onChange={handleChange}
                    value={state.name}
                  />
                </Box>
              </Grid>
              <Grid></Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            className="m-2"
            size="small"
          >
            {t("COMMON024")}
          </Button>
          <Button
            onClick={reset}
            color="secondary"
            variant="contained"
            className="m-2"
            size="small"
          >
            {t("COMMON025")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
