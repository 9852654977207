import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import CachedIcon from '@material-ui/icons/Cached';
import { filterPlans, FilterSubscrptionList } from './SubscriptionService';
export default function Oredrs() {
  const { t } = useTranslation();
  const breadcrumb = [{ path: '', name: t('Subscription Order') }];
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const store = useSelector((state) => state);
  const [readers, setReaders] = useState([]);

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  useEffect(() => {
    loadData(page, rowsPerPage);
  }, []);

  const loadData = (pageNumber, pageSize) => {
    FilterSubscrptionList(pageNumber + 1, pageSize, (res) => {
      if (res.data.code === '200' && !CommonUtil.isEmpty(res.data.data)) {
        setData(res.data.data.results);
        setTotalRecords(res.data.data.count);
      } else {
        setData([]);
      }
    });
  };

  const columns = [
    {
      field: 'number',
      title: 'Order No.',
      tooltip: t('Order No.'),
      editable: 'never',
    },
    { field: 'price_strategy_name', title: 'Feature package', tooltip: t('Feature package'), editable: 'never' },
    {
      field: 'period',
      title: 'Subscription period',
      tooltip: t('Subscription period'),
      editable: 'never',
      render: (row) => <>{row.type_name === 'Pay monthly' ? row.period + ' months' : row.period + ' years'}</>,
    },
    { field: 'payment_price', title: 'Subscription price', tooltip: t('Subscription price'), editable: 'never' },
    { field: 'create_time', title: 'Order create time', tooltip: t('Order create time'), editable: 'never', render: (row) => <>{CommonUtil.getLocalDate(row.create_time)}</> },
    { field: 'start_date', title: 'Subscription start Date', tooltip: t('Subscription start Date'), editable: 'never' },
    { field: 'end_date', title: 'Expiry date of the order', tooltip: t('Expiry date of the order'), editable: 'never' },
    { field: 'payment_status_name', title: 'Status', tooltip: t('Status'), editable: 'never' },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadData(0, rowsPerPage)}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={false}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
