import React, { forwardRef } from 'react';
import MaterialTable, { MTableHeader } from 'material-table';
import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import { Button, TablePagination, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';

export default function DataTable(props) {
  const { t } = useTranslation();

  return (
    <MaterialTable
      icons={props.tableIcons}
      title={props.title}
      columns={props.columns}
      data={props.data}
      options={props.options}
      localization={{
        body: {
          emptyDataSourceMessage: t('dataTableEmptyList'),
          editTooltip: t('dataTableEdit'),
          deleteTooltip: t('dataTableDelete'),
          addTooltip: t('dataTableCreate'),
          editRow: {
            deleteText: t('dataTableDeleteWarning'),
            cancelTooltip: t('dataTableCancel'),
            saveTooltip: '',
          },
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} ' + t('dataTableOf') + ' {count}',
          labelRowsSelect: t('dataTableRows'),
          firstAriaLabel: t('dataTableFirstPage'),
          firstTooltip: t('dataTableFirstPage'),
          previousAriaLabel: t('dataTablePreviousPage'),
          previousTooltip: t('dataTablePreviousPage'),
          nextAriaLabel: t('dataTableNextPage'),
          nextTooltip: t('dataTableNextPage'),
          lastAriaLabel: t('dataTableLastPage'),
          lastTooltip: t('dataTableLastPage'),
        },
        header: {
          actions: t('dataTableAction'),
        },
        toolbar: {
          searchPlaceholder: t('dataTableSearchPlaceholder'),
          searchTooltip: t('dataTableSearch'),
        },
      }}
      actions={props.actions}
      components={{
        Pagination: (tableProps) => (
          <TablePagination {...tableProps} count={props.count} page={props.page} rowsPerPage={props.rowsPerPage} onChangePage={(event, page) => props.onChangePage(page)} />
        ),
        Header: (cellProps) => (
          <Tooltip>
            <MTableHeader {...cellProps} />
          </Tooltip>
        ),
      }}
      onChangeRowsPerPage={(pageSize) => props.onChangeRowsPerPage(pageSize)}
      onSelectionChange={(rows) => props.onSelectedRoles(rows)}
    />
  );
}

DataTable.defaultProps = {
  tableIcons: {
    Add: forwardRef((props, ref) => (
      <Button {...props} ref={ref} variant='contained' style={{ background: '#0099FF', color: 'white' }} disabled>
        {'dataTableCreate'}
      </Button>
    )),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'green' }} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'red' }} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />),
  },
  actions: [
    {
      icon: 'save',
      tooltip: 'Save User',
      title: 'save',
      onClick: (event, rowData) => alert('You saved ' + rowData.name),
      position: 'row',
    },
    {
      icon: 'delete',
      tooltip: 'Delete User',
      title: 'Delete',
      onClick: (event, rowData) => alert('You saved ' + rowData.name),
      position: 'row',
    },
  ],
  columns: [
    {
      title: 'Name',
      field: 'name',
    },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthYear' },
    {
      title: 'Birth Place',
      field: 'birthCity',
      lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
    },
  ],
  options: {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    initialPage: 0,
  },
  title: 'Data table',
  onSelectedRoles: (data) => {},
  onChangePage: (page) => {
    //alert(page)
  },
  onChangeRowsPerPage: (pageSize) => {
    //alert(pageSize)
  },
  isPageable: true,
  initialPage: 0,
};
