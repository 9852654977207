
import React from 'react'
import { Button, withStyles } from '@material-ui/core';



export default function CancelButton(props){
    const ColorButton = withStyles((theme) => ({
        root: {
            backgroundColor: '#3b3e66',
            color: 'white',
            opacity: 1,
            fontWeight: 100,
            '&:hover': {
                backgroundColor: '#3b3e66',
            },
        },
    }))(Button);

    return(
        <ColorButton {...props} />
    )
}
