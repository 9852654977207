import React from "react";
import { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow ,MarkerClusterer} from "@react-google-maps/api";
import { Box, Button, TextField, Typography } from '@material-ui/core';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterDevicesFetch, filterDoorsFetch } from "../device/DeviceService";
import CommonUtil from "../../components/Util/CommonUtils";


function GoogleMapDialog(props) {
    const { t } = useTranslation()
    const [map, setMap] = useState(null)
    const [zoom, setZoom] = useState(3)
    const [finallocation, Setfinallocation] = useState([]);
    const [selected, setSelected] = useState('');
    const [searchBox, setSearchBox] = useState(null);
    const store = useSelector((state) => state);
    const [center, setCenter] = useState({ lat: 36.32852408225397, lng: -38.72770055138199 });
    const [selectedSite, setSelectedSite] = useState('');
    const [tempSelected, setTempSelected] = useState('');
    const [sitesData, setSitesData] = React.useState(new Map());
    const [filter, setFilter] = React.useState({
        siteId: '',
      });


      const loadAllCountBySiteId = (item) => {
        let collectData = {};
        collectData.siteCountry = item.country;
        collectData.location = item.location;
        collectData.siteName = item.site.name;
    
        filterDevicesFetch(1, 1000, { ...filter, siteId: item.id }, (res) => {
          if (CommonUtil.isEmptyString(res.data.totalCount)) {
            collectData.deviceCount = 0;
          } else {
            collectData.deviceCount = res.data.totalCount;
          }
    
          filterDoorsFetch(1, 1000, { ...filter, siteId: item.id }, (res) => {
            if (CommonUtil.isEmptyString(res.data.totalCount)) {
              collectData.doorCount = 0;
            } else {
              collectData.doorCount = res.data.totalCount;
            }
    
            setSelectedSite(item.id);
            setSelected({
              ...collectData,
            });
            setTempSelected({
              ...collectData,
            });
            var sites = sitesData;
            sites.set(item.id, collectData);
            setSitesData(sites);
            // dispatch(
            // getEmployeeCountBySiteId(item.id, (res) => {
            //   collectData.employeeCount = res.data.totalCount;
            //   setSelectedSite(item.id);
            //   setSelected({
            //     ...collectData,
            //   });
            //   setTempSelected({
            //     ...collectData,
            //   });
            // })
            // );
          });
        });
      };

      const onSelectHover = async (item) => {
        if (!sitesData.has(item.id)) {
          setSelected({});
          loadAllCountBySiteId(item);
        } else {
            setSelected(sitesData.get(item.id));
        }
      };

    const DisplayRecord = (data) => {

    }

    const onSelectMarker = (item) => {

    }

    const onPlacesChanged = () => { }

    const onSBLoad = () => {

    }

    useEffect(() => {

    }, [])

    const filterLocation = () => {

    }

    const centerSite = () => {

    }


    const onMarkerDragEnd = (e) => {

    }



    const exampleMapStyles = [
        {
            elementType: 'geometry',
            stylers: [
                {
                    color: '#E2E4EB',
                },
            ],
        },
        {
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#eeeeee',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e5e5e5',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#dadada',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#616161',
                },
            ],
        },
        {
            featureType: 'road.local',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9e9e9e',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e5e5e5',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#eeeeee',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#FFFFFF',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9e9e9e',
                },
            ],
        },
    ];

    const image = {
        url: 'https://img.icons8.com/color/50/000000/place-marker--v2.png'
    }


    useEffect(() => {
        if (store.site.siteList.site !== undefined) {
          let storedata = store.site.siteList.site;
          filterlocation(storedata);
        }
      }, [store.site.siteList.site]);
    
      const filterlocation = (storedata) => {
        let requiredData = [];
        storedata.map((item) => requiredData.push({ address: item.address, id: item.id, site: item }));
        let result = [];
        requiredData.map((item) =>
          item.address.latitude
            ? result.push({
                location: { lat: parseFloat(item.address.latitude), lng: parseFloat(item.address.longitude) },
                id: item.id,
                country: item.address.country,
                site: item.site,
              })
            : ''
        );
        if (result.length > 0) {
          setCenter(result[result.length - 1].location);
          Setfinallocation(result);
        }
      };

    return (
        <>
            <GoogleMap
                onLoad={(map) => {
                    setMap(map);
                }}
                options={{
                    styles: exampleMapStyles,
                    fullscreenControl: false,
                }}
                mapContainerStyle={{
                    height: '80vh',
                    width: '100%',
                }}
                zoom={zoom}
                center={center}
                // onZoomChanged={() => handleZoomChange()}
            >
                <Box display='flex' flexDirection='row-reverse' p={1} m={1} bgcolor="red">
                    <Box p={1} bgcolor = 'grey.300'>
                        <Button variant="contained" color="default" size="small" startIcon={<FullscreenExitIcon />} onClick={() => props.toggleFullScreen(false)}>

                        </Button>
                    </Box>
                </Box>

                {finallocation &&(
              <>
                <MarkerClusterer>
                  
                  {(clusterer) =>
                    finallocation.map((item) => (
                      <Marker
                        key={item.id}
                        position={item.location}
                        onMouseOver={() => onSelectHover(item)}
                        onMouseOut={() => setSelected({})}
                        onClick={() => onSelectMarker(item)}
                        // icon={{ url: logo }}
                        icon={image}
                        onDragEnd={(e) => onMarkerDragEnd(e)}
                        draggable={false}
                        clusterer={clusterer}
                      ></Marker>
                    ))
                  }
                </MarkerClusterer>
              </>                
            )}

                {selected.location && (
                    <InfoWindow
                        options={{ maxWidth: 200, padding: 50, pixelOffset: { width: 0, height: -50 } }}
                        position={selected.location}
                        clickable={true}
                        visible={true}
                        onCloseClick={() => setSelected({})}

                    >
                        <div>
                  <Typography gutterBottom>
                    {' '}
                    <ApartmentIcon color='disabled' />
                    &nbsp; {t('COMMON184')} : <span style={{ color: 'green' }}>{selected.siteName} </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <PhonelinkSetupIcon color='disabled' />
                    &nbsp; {t('COMMON185')} <span style={{ color: 'green' }}>({selected.deviceCount}) </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <MeetingRoomOutlinedIcon color='disabled' />
                    &nbsp; {t('COMMON186')} <span style={{ color: 'green' }}>({selected.doorCount}) </span>
                  </Typography>
                  {/* <Typography gutterBottom>
                    {' '}
                    <PersonOutlineOutlinedIcon color='disabled' />
                    &nbsp; {t('COMMON187')} <span style={{ color: 'green' }}>({selected.employeeCount})</span>
                  </Typography> */}
                  {/* <Typography gutterBottom>
                  {' '}
                  <EventOutlinedIcon color='disabled' />
                  &nbsp; {t('COMMON188')} <span style={{ color: 'green' }}>({selected.location.CardValue.eventCount})</span>
                </Typography> */}
                  <Typography gutterBottom>
                    {' '}
                    <LocationOnOutlinedIcon color='disabled' />
                    &nbsp; {t('COMMON189')} : <span style={{ color: 'green' }}>{selected.siteCountry}</span>
                  </Typography>
                </div>

                    </InfoWindow>
                )}




            </GoogleMap>

        </>
    )
}
export default GoogleMapDialog