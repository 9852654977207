import React from "react"
import { Grid, Hidden } from "@material-ui/core"

export default function SiteDashboardTiles(props) {
   return (
    <>
      <Hidden mdUp>
            <Grid style={{ minWidth: '100%' }} item xl={1} lg={2} md={1} sm={1} xs={1}>
               {props.children}
            </Grid>

         </Hidden>
         <Hidden smDown>
            <Grid style={{ minWidth: props.minWidth }} item xl={1} lg={2} md={1} sm={1} xs={1}>
               {props.children}
            </Grid>

         </Hidden>
    </>
   )
}

