import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DataTable from '../../components/DataTable';
import { filterDoors } from '../door/DoorService';
import CommonUtil from '../../components/Util/CommonUtils';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DoneIcon from '@material-ui/icons/Done';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { addDoorsToAccessGroup } from './AccessGroupService';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';

export default function AddAccessGroupDoor() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [totalRecords, setTotalRecords] = React.useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRecords, SetSelectRecords] = React.useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    sn: '',
    doorName: '',
  });

  const defaultFilter = {
    sn: '',
    doorName: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      sn: filterData.sn,
      doorName: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(page, rowsPerPage);
  }, [filter]);

  const breadcrumb = [
    { path: '/acc/z/access-group', name: t('ACCESSGROUP005') },
    { path: '/acc/z/access-group-add-door', name: t('LEVEL003') },
  ];

  const loadData = (page, rowsPerPage) => {
    filterDoors(page + 1, rowsPerPage, filter, (res) => {
      if (res.data.code === 'DMSI0000') {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
      } else {
        setTotalRecords(0);
        setPage(0);
      }
      setData(res.data.data.doors);
    });
  };

  const columns = [
    {
      field: 'doorParameters.name',
      title: t('COMMON014'),
      tooltip: t('DOORI003'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
    { field: 'deviceName', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'doorParameters.doorNo', title: t('COMMON060'), tooltip: t('DOORI002'), editable: 'never' },
    {
      field: 'doorParameters.enabled',
      title: t('DEVI0018'),
      editable: 'never',
      render: (rowData) =>
        rowData.doorParameters.enabled ? <DoneIcon style={{ color: 'green' }} className='refresh-btn' /> : <NotInterestedIcon style={{ color: 'red' }} className='refresh-btn' />,
    },
    { field: 'doorParameters.verifyMode', title: t('DOORI005'), tooltip: t('DOORI030'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <AddToPhotosIcon color='secondary' />,
      tooltip: t('COMMON009'),
      position: 'row',
      onClick: (event, rowData) => {
        alert([rowData]);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const handleChangePage = (newPage, id, name) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize, id, name) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const handleAddDoorsToAccessGroup = () => {
    if (selectedRecords.length < 1) {
      return;
    } else {
      var doors = [];
      selectedRecords.map((door) => {
        doors.push(door.doorId);
      });
      var payload = {
        doors: doors,
      };
      addDoorsToAccessGroup(location.state.id, payload, (res) => {
        if (res.data.code === 'DMSI0010') {
          toast.success(t('ACCESSGROUP012'));
          history.goBack();
        } else {
          toast.error(res.data.msg);
        }
      });
    }
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}></Breadcrumb>
      <DataTable
        columns={columns}
        data={data}
        actions={[]}
        onSelectedRoles={(rows) => SetSelectRecords(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      <Grid container direction='row' justifyContent='flex-end' alignItems='flex-start'>
        <Box pt={1} pr={1}>
          <SubmitButton onClick={() => handleAddDoorsToAccessGroup(selectedRecords)} color='primary' variant='contained' size='large'>
            {t('COMMON007')}
          </SubmitButton>
        </Box>
        <Box pt={1}>
          <CancelButton
            onClick={() => {
              history.goBack();
            }}
            color='primary'
            variant='contained'
            size='large'
          >
            {t('COMMON008')}
          </CancelButton>
        </Box>
      </Grid>
    </Grid>
  );
}
