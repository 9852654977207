import React from 'react';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ReactComponent as Moreicon } from '../../assets/v4/Moreicon.svg';
import { ReactComponent as Union } from '../../assets/v4/Union304.svg';
import { ReactComponent as Group } from '../../assets/v4/Group10112.svg';
import DeviceTopologyMainPage from './DeviceTopologyMainPage';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { BorderBottom, BorderRight } from '@material-ui/icons';

export default function DeviceTopologyToggle() {
  const [alignment, setAlignment] = React.useState('left');
  const [chart, setChart] = React.useState(true);
  const [deviceMainPage, SetDeviceMainPage] = React.useState(false);
  const { t } = useTranslation();

  const [active, setActive] = React.useState(false);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    if (chart === true) {
      SetDeviceMainPage(true);

      setChart(false);
      return <Group />;
    } else if (deviceMainPage === true) {
      setChart(true);
      SetDeviceMainPage(false);
      return <DeviceTopologyMainPage />;
    }
  };

  const handleStatus = () => {
    if (chart === true || deviceMainPage === true) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box component='span'>
        <Grid item xs={8} sx={{ top: '20px', marginLeft: '20px' }}>
          <Typography sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: '16px', color: '#3d4977' }}>{t('DEVICETOPOLOGY001')}</Typography>
        </Grid>
      </Box>

      {/* <Box component='span'>
        <ToggleButtonGroup value={alignment} exclusive onClick={handleAlignment} aria-label='text alignment' sx={{ float: 'right' }}>
          <ToggleButton
            value='right'
            aria-label='right aligned'
            sx={{ padding: 0, border: '0' }}
            style={{
              backgroundColor: chart === true ? '#36c96d' : 'white',
              border: '1.5px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '5px',
              marginRight: '-1px',
              BorderRight: '1px solid transparent',
            }}
          >
            <Moreicon
              style={{
                backgroundColor: chart === true ? '#36c96d' : 'white',
              }}
            />
          </ToggleButton>
          <ToggleButton
            value='center'
            aria-label='centered'
            sx={{ padding: 0, border: '1' }}
            style={{
              backgroundColor: deviceMainPage === true ? '#36c96d' : 'white',
            }}
          >
            <Union
              style={{
                backgroundColor: deviceMainPage === true ? '#36c96d' : 'white',
              }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box> */}

      <Box mt={5} display='flex' justifyContent='center' width='100%'>
        {chart === true ? <DeviceTopologyMainPage /> : <DeviceTopologyMainPage />}
      </Box>
    </Box>
  );
}
