import { Box, Grid, IconButton } from '@material-ui/core';
import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_URL_DOOR, REACT_URL_EDIT_DOOR_V4 } from '../../actions/EndPoints';
import Breadcrumb from '../../components/Breadcrumb';
import DataTable from '../../components/DataTable';
import Toolbar from '../../components/Toolbar';
import CommonUtil from '../../components/Util/CommonUtils';
import { doorOperation, filterDoors } from './DoorService';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CachedIcon from '@material-ui/icons/Cached';
import RemoteOpenDoor from './RemoteOpenDoor';
import { ReactComponent as RemoteClose } from '../../assets/icons/RemoteClose.svg';
import { toast } from 'react-toastify';
import EditDoor from './EditDoor';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { getTimeZoneIntervalList } from '../schedules/SchedulesService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function DoorList() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateDoor, setUpdateDoor] = useState({});
  const [scheduler, setScheduler] = React.useState([]);
  const history=useHistory();

  const [filter, setFilter] = useState({
    sn: '',
    doorName: '',
    siteId: '',
  });

  const defaultFilter = {
    sn: '',
    doorName: '',
    siteId: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      sn: filterData.sn,
      doorName: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
  }, [filter]);

  // useEffect(() => {
  //   loadSchedule(0, 1000, '');
  // }, []);

  const loadData = (page, rowsPerPage) => {
    filterDoors(page + 1, rowsPerPage, filter, (res) => {
      if (res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data)) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.currentPage - 1);
        setData(res.data.data.doors);
      } else {
        setTotalRecords(0);
        setPage(0);
        setData([]);
      }
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const breadcrumb = [{ path: '/acc/z/door', name: t('DOORI001') }];

  const columns = [
    {
      field: 'doorParameters.name',
      title: t('DOORI003'),
      tooltip: t('DOORI003'),
      headerStyle: {
        color: 'black',
        backgroundColor: '#fff',
      },
    },
    // { field: 'zoneName', title: t("zoneName"), editable: 'never' },
    { field: 'deviceName', title: t('DEVI0002'), tooltip: t('DEVI0002'), editable: 'never' },
    // { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'doorParameters.doorNo', title: t('DOORI002'), tooltip: t('DOORI002'), editable: 'never' },
    {
      field: 'doorParameters.enabled',
      title: t('DEVI0018'),
      editable: 'never',
      render: (rowData) =>
        rowData.doorParameters.enabled ? <DoneIcon style={{ color: 'green' }} className='refresh-btn' /> : <NotInterestedIcon style={{ color: 'red' }} className='refresh-btn' />,
    },
    {
      field: 'doorParameters.activeTimeSegBusinessName',
      title: t('DOORI037'),
      tooltip: t('DOORI037'),
    },
    { field: 'doorParameters.verifyMode', title: t('DOORI005'), tooltip: t('DOORI030'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        // setUpdateDoor(rowData);
        // setOpenEditDialog(true);
        history.push({pathname:REACT_URL_EDIT_DOOR_V4,state:rowData});
      },
    },
    // {
    //   icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
    //   tooltip: t('COMMON004'),
    //   position: 'row',
    //   onClick: (event, rowData) => {
    //     deviceDelete([rowData]);
    //   },
    // },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const doorOperations = (type) => {
    if (selectedRecords.length < 1) {
      toast.warning(t('DEVI0014'));
      return;
    }

    if (selectedRecords.length > 1) {
      toast.warning(t('DEVI0039'));
      return;
    }

    var payload = {
      operator: 0,

      type: type,

      triggerNum: selectedRecords[0].doorNo,

      delayTime: 0,
    };

    doorOperation(selectedRecords[0].devId, payload, (res) => {
      if (res.data.code === 'DMSI0000') {
        toast.success(res.data.msg);
      } else {
        toast.error(res.data.msg);
      }
    });
  };

  const loadSchedule = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name, (res) => {
      if (res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data.data) && res.data.data.timeZone instanceof Array) {
        setScheduler(res.data.data.timeZone);
      }
    });
  };

  const getTimezoneName = (id) => {
    var operationName = '';
    scheduler.map((t) => {
      if (id == t.id) {
        operationName = t.operationName;
      }
    });
    return operationName;
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}></Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1}>
          <RemoteOpenDoor doors={selectedRecords} />
        </Box>
        <Box p={1}>
          <Tooltip title={t('DOORI024')}>
            <IconButton color='secondary' aria-label={t('DOORI024')} onClick={() => doorOperations(0)}>
              <RemoteClose style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box> */}
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => setSelectedRecords(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {/* {openEditDialog && (
        <EditDoor
          door={updateDoor}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
          }}
        />
      )} */}
    </Grid>
  );
}
