import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Close from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory, useLocation } from 'react-router';

import PerfectScrollbar from 'react-perfect-scrollbar';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import navItemZlink from './navItemZlink';
import { Box, Typography } from '@material-ui/core';

export default function Sidebar(props) {
  let history = useHistory();
  let location = useLocation();
  const isZlink = window.location.toString();
  const zlink = isZlink.includes('/z/');
  const [contextMenu, setContextMenu] = React.useState(true);
  const [Selected, setSelected] = React.useState(0);

  const [selectedMenu, setSelectedMenu] = React.useState('');
  const [selectedSubMenu, setSelectedSubMenu] = React.useState('');

  const handleMenu = (menu) => {
    if (!contextMenu || menu.name == 'Dashboard') {
      if (selectedMenu === menu.name) {
        setSelectedMenu('');
      } else {
        setSelectedMenu(menu.name);
        if (menu.link) history.push(menu.link);
      }
    }
    if (contextMenu) toggleSidebar(!contextMenu);
  };
  const handleClose = () => {
    setSelectedMenu('');
  };
  const handleSubMenu = (menu) => {
    if (selectedSubMenu === menu.name) {
      setSelectedSubMenu('');
    } else {
      setSelectedSubMenu(menu.name);
      if (menu.link) history.push(menu.link);
    }
  };

  const toggleSidebar = (toggle) => {
    props.exapnded(toggle);
    setContextMenu(toggle);
    localStorage.setItem('toggleSidebar', toggle);
  };

  const CustomSidebarGrid = styled(Grid)((e) => ({
    display: 'flex',
    width: '100%',
    fontSize: '18px',
    color: '#304669',
    alignItems: 'center',
    height: '50px',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontWeight: Selected == e.data ? '500' : '400',
  }));

  const handleSelection = (parms) => {
    setSelected(parms.title);
    alert(parms.title);
    // history.push(parms.path)
  };

  return (
    <Grid
      style={{
        display: 'flex',
        padding: '8px 0px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        position: 'fixed',
        height: '600px',
      }}
    >
      <Grid style={{ display: 'flex', flexDirection: 'column', width: contextMenu ? '70px' : '270px' }}>
        <PerfectScrollbar>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component='nav' aria-labelledby='nested-list-subheader'>
            {navItemZlink.map((item, index) => (
              <>
                {' '}
                <ListItemButton onClick={() => handleMenu(item)} style={{ padding: '10px 10px' }}>
                  <ListItemIcon style={{ paddingLeft: item.id === '1' ? '10px' : '14px' }}>
                    <item.Icon />
                  </ListItemIcon>
                  {contextMenu === false && <ListItemText primary={item.name} />}
                  {item.items instanceof Array && item.items.length > 0 && (selectedMenu === item.name ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                <Collapse in={selectedMenu === item.name} timeout='auto' unmountOnExit>
                  {item.items instanceof Array &&
                    item.items.map((cItem, cIndex) => (
                      <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 10 }} onClick={() => handleSubMenu(cItem)}>
                          {/* <ListItemIcon>{cItem.icon}</ListItemIcon> */}
                          {contextMenu === false && (
                            <ListItemText
                              primary={cItem.name}
                              primaryTypographyProps={{
                                fontWeight: selectedSubMenu === cItem.name ? 'bold' : 'normal',
                                color: '#304669',
                              }}
                            />
                          )}
                        </ListItemButton>
                      </List>
                    ))}
                </Collapse>
              </>
            ))}
          </List>
        </PerfectScrollbar>
        <Box sx={{ display: 'flex', mb: '2.5em' }}>
          <IconButton
            style={{ alignSelf: 'center', backgroundColor: '#fff', position: 'relative', borderRadius: 'inherit' }}
            onClick={() => toggleSidebar(!contextMenu)}
            disableRipple
          >
            {contextMenu ? (
              <Typography style={{ marginLeft: '5px' }}>
                {' '}
                V 1.0.3
                <br />
                <MenuOpenIcon style={{ opacity: '0.8', fill: '#304669', position: 'fixed', left: '35px' }} />
              </Typography>
            ) : (
              <Typography style={{ marginLeft: '5px' }}>
                APP version V 1.0.3
                <Close style={{ opacity: '0.8', fill: '#304669', position: 'fixed', marginLeft: '90px' }} onClick={() => handleClose()} />
              </Typography>
            )}
          </IconButton>
        </Box>
      </Grid>
      {/* <Grid style={{ display: contextMenu ? 'none' : 'block', minWidth: '170px', height: '100px' }}>
        {SlidebarICone.map((item, index) => (
          <CustomSidebarGrid key={index} data={item.title} onClick={() => handleSelection(item)}>
            {item.title}
          </CustomSidebarGrid>
        ))}
      </Grid> */}
    </Grid>
  );
}
