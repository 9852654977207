import React, { useContext, useEffect, useRef, useState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// import CloseIcon from "../../assets/BiometricsRegisterSvgIcons/CloseIcon";
// import CustomCheckbox from "../CheckBox/CustomCheckbox";
import {
  Box,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Checkbox,
  TextField,
} from "@material-ui/core";
// import './style.css'
// import './another-stylesheet.css';

import image1 from "./images/base_fv.png";
import image4 from "./images/base_palm_handscanner.svg";
import image5 from "./images/base_palm_handscanner_success.svg";
import image6 from "./images/base_palm_hand_sucess.png";
import image2 from "./images/base_fv0.svg";
import hand from "./images/Hand.svg";
import image3 from "./images/base_fpVerify_clearImage.svg";
import { createTheme } from "@material-ui/core/styles";
// import DeatilsTextFieldTitle from "../TextField/DeatilsTextFieldTitle";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
// import SwipeableViews from 'react-swipeable-views';
import AppBar from "@material-ui/core/AppBar";
// import TextField from "../TextField/TextField";
 import downloadDrivers from '../../../assets/downloadDrivers/ISSOnline.exe';
 import $ from 'jquery'
 import { findDOMNode } from "react-dom";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from "../../../components/ConfirmModal";
// import CustomStyleStatus from "./ModalSwitchStyles";
// import ModalPersonDevice from "./ModalPersonDevice";

const jQuery = require( "jquery" )( window );
const ZK = require( "jquery" )( window );

const CustomRadio = withStyles({
  /*
    root: {
      color: '#F2824C',
      '&$checked': {
        color: '#F2824C',
      },
    },
    checked: {},
    */
  root: {
    color: "#707070",
    "&.Mui-checked": {
      color: "#F2824C",
    },
    // '&:hover': {
    //   background: 'transparent',
    // },
    // '& .MuiSvgIcon-root': {
    //   fontSize: 18,
    // },
  },
})((props) => <Radio color="default" {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CardholderRegisterModal = (props) => {
  const {
    deviceCapacityData,
    deviceCapacityTtile,
    handleText,
    moreDetails,
    bioDetails,
    // title,
    // handleConfirm,
    handleCancel,
    // cancelText,
    // confirmText,
    style,
    otherStyle,
    handleOpen,
    handleClose,
    open,
  } = props;
  // const { darkMode } = useContext([]);

  const [fpIdValue,setFpIdValue] = useState(null);
  const [selectedValueRadio, setSelectedValueRadio] = useState("0");
  const [selectedValueRadio3, setSelectedValueRadio3] = useState(null);
  const theme = useTheme();
  const [statusMessage, setStatusMessage] = useState('');
  const [statusTitle, setStatusTitle] = useState('');
  const [modalStatePersonDetails, setModalStatePersonDetails] = useState(false)
  const [statusPersonDetails, setStatusPersonDetails] = useState('');
  const [confirmText, setConfirmText] = useState('');
  const [state, setState] = useState('');
  const [status, setStatus] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [driverDownload,setDriverDownload] = useState(false);
  const [deleteGlobalfinger,setDeleteGlobalFinger] = useState('');
  const [deleteContextfinger,setDeleteContextFinger] = useState('');

  const [globalContextValue, setGlobalContextValue] = useState('');
  const [devTypeValue, setDevTypeValue] = useState('');
  const [bioTemplateJsonValue, setBioTemplateJsonValue] = useState(bioDetails);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#ffffff",
      width: 500,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      minWidth: "680px",
      minHeight: "200px",
      backgroundColor: '#fff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 1, 1, 2),
      position: "absolute",
      // top: '180px',
      width: "min-content",
    },
    innerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "15px",
      paddingTop: "5px",
    },
    IconHeader: {
      paddingRight: "25px",
    },
    accessModalTitle: {
      color: "#3b3e66",
      display: "block",
      fontSize: 14,
      fontWeight: 500,
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    DriverText: {
      color: "#3b3e66",
      display: "block",
      textAlign:'center',
      fontSize: 14,
      fontWeight: 500,
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    DriverLinkStyle: {
      '&.MuiLink-root': {
        color:'#F2824C !important'
      }
   
    },
    subText: {
      color:  "#5A5A5A",
      display: "block",
      fontSize: 11,
      fontWeight: 500,
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    backDrop: {
      background: "rgb(112 112 112 / 47%) !important",
    },
    headertext: {
      color: "#fff" ,
    },
    confirmButton: {
      color: "#227DAB",
      fontSize: "16px",
      "&.MuiButton-root:hover": {
        backgroundColor: "transparent !important",
      },
    },
    cancelButton: {
      color:"#5A5A5A",
      fontSize: "16px",
      "&.MuiButton-root:hover": {
        backgroundColor: "transparent !important",
      },
    },
    confirmButtonDelete: {
      color: "#5A5A5A",
      fontSize: "16px",
      "&.MuiButton-root:hover": {
        backgroundColor: "transparent !important",
      },
    },
    closeButton: {
      fontSize: "16px",
      color: '#fff',
      "&.MuiIconButton-root:hover": {
        backgroundColor: "transparent !important",
      },
      "&.MuiIconButton-root": {
        paddingTop: "10px",
        paddingRight: "0px",
      },
    },
    selectedTab: {
      "&.MuiTab-root": {
        fontSize: "16px",
        textTransform: "none",
      },
      "&.MuiTab-textColorPrimary.Mui-selected": {
        color: "#0F5A9C",
      },
    },
    selectRadioGroup: {
      "&.MuiFormGroup-root": {
        display: "flex",
        flexDirection: "row",
      },
    },
    wrapperHeader: {
      paddingTop: "15px",
      "&.MuiPaper-elevation4": {
        boxShadow: "none",
      },
      "&.MuiAppBar-colorDefault": {
        backgroundColor: "#f5f5f500",
      },
    },
    headerStyle: {
      color: "#5A5A5A",
      fontSize: "18px",
      fontWeight: 500,
      paddingBottom: "25px",
    },
    tableWrapperInner: {
      maxHeight: "45px",
      minHeight: "45px",
      overflow: "auto",
    },
    modalContainer: {
      width: "97%",
      marginTop: "15px",
      marginBottom: "15px",
      boxShadow: "none",
    },
    loadSpinner: {
      color: "#226BAB",
    },
  }));

  //
  const classes = useStyles();
  
  useEffect(()=>{
    setTimeout(() => {
      dataInit();
      collectTips();
      setBioTemplateJsonValue(bioDetails);
    }, 200);
  },[tabValue,open,bioDetails]);
  
  // useEffect(() => {
  //   return () => {
  //     setTimeout(() => {
  //       if (globalContext == null) {
  //       globalContext = document.getElementById("newCanvas").getContext("2d")
  //       }
  //   }, 5000);
  //   }
  // }, [tabValue,open]);

  // ============== issonline.js Start ==================

/**
 * @author juvenile
 */
 (function() {
  var newVersion = "2.0.63";
  var DevOptions = (function(){
	  var severPort = function(){
		  if(window.location.protocol.indexOf("https:") != -1)
		  {
			  return 24308;
		  }
		  return 24008;
	  }
	  function DevOptions(){
		  this.devOnlineUrl = window.location.protocol + "//127.0.0.1:"+severPort();
		  this.funUrl = "/ISSOnline";
		  this.url = "/info";
		  this.type = "GET";
		  this.async = true;
		  this.timeout = 5000;
		  this.dataType = "text";
	  }
	  DevOptions.prototype.getUrl = function(){
		  return this.devOnlineUrl + this.funUrl + this.url;
	  }
	  return DevOptions;
  })();

  var IssControls={
	  initFlag:false,//是否初始化
	  installed:true,//是否安装  true:已安装
  	  upgrade:false,//是否需要进行升级  true:需要进行升级
      version:newVersion,
      url:"/middleware/ISSOnline.exe",
      callbackList:[],
      push:function(callback){
          this.callbackList.push(callback);
      },
      callback:function(result){
          this.initFlag = result;
          this.installed = result;
          var l = this.callbackList.length;
          if(l > 0){
              for (var i = 0;i <l;i++){
                  var call = this.callbackList.shift();
                  if(typeof call == "function"){
                      call(this);
                  }
              }
          }
      }
  }

  var ISSOnline = (function() {
    function ISSOnline(options) {
    	this.options = new DevOptions();
    	ZK.extend(this.options,options);
    	if(IssControls.initFlag == "checking"){
            IssControls.push(options.initCallBack);
        }
    	else if(!IssControls.initFlag)
    	{
            IssControls.push(options.initCallBack);
    		this.init();
    	}
    	else if(typeof options.initCallBack == "function")
    	{
    		options.initCallBack(IssControls);
    	}
    }
    ISSOnline.prototype.init = function() {
		var self = this;
        IssControls.initFlag = "checking";
		self.ajax({
    		callback:function(result){
    			//检测版本
    			var oldVersion = result.data.server_version;
                IssControls.version = oldVersion;
    		    if (oldVersion != newVersion){
    		        var newVerArr = newVersion.split(".");
    		        var oldVerArr = oldVersion.split(".");
    		        var length = newVerArr.length > oldVerArr.length ? oldVerArr.length : newVerArr.length;
    		        for (var i=0;i<length;i++){
    		            if (parseInt(newVerArr[i]) > parseInt(oldVerArr[i])){
    		            	IssControls.upgrade=true;//需要升级
    		                break;
    		            }
    		        }
    		    }
                IssControls.callback(true);
    		},errorback:function(XMLHttpRequest, textStatus, errorThrown){
                IssControls.callback(false);
    		}
    	});
    };
    ISSOnline.prototype.checkDrever=function(){
    	if(!IssControls.installed){
    		throw "1";//未安装
    	}
    	if(IssControls.upgrade){
    		throw "2";//需要升级
    	}
    };
    ISSOnline.prototype.ajax = function(options){
    	options = ZK.extend({},this.options,options);
        var header = $.ajaxSettings.headers;
        $.ajaxSettings.headers = null;

        // console.log("options.type    =========",options.type    );
        // console.log("options.getUrl()=========",options.getUrl());
        // console.log("options.dataType=========",options.dataType);
        // console.log("options.async   =========",options.async   );
        // console.log("options.timeout =========",options.timeout );
        // console.log("options.data    =========",options.data    );

        
    	$.ajax({
			type: options.type,
			url: options.getUrl(),
			dataType: options.dataType,
			async: options.async,
			timeout: options.timeout,
			data: options.data,
			success: function(data)
			{
				if(typeof data == "string"){
					data = data.replace(/\\/g,"/");
					var result = JSON.parse(data);
					if(typeof options.callback == "function")
					{
						options.callback(result);
					}
				}else{
					options.callback(data);
				}
			},
			error : function(XMLHttpRequest, textStatus, errorThrown) 
			{
				if(typeof options.errorback == "function"){
					options.errorback(XMLHttpRequest, textStatus, errorThrown);
				}else{
                    // messageBox({messageType: "alert", title: I18n.getValue('common_prompt_title'), text: I18n.getValue('base_fp_connectPrompt')});
                }
			},
		});
        $.ajaxSettings.headers = header;
    };
    return ISSOnline;
  })();
  /** 指纹采集 */
  ZK.Fingerprint = (function(){
	  function Fingerprint(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 开始采集，需带参数 options.data.type  1: Enroll 登记	2: Verify 比对 默认 */
			  beginCapture:function(options){
				  dev.options.url = "/beginCapture";
				  dev.ajax(options);
			  },
			  /** 取消采集 */
			  cancelCapture:function(options){
				  dev.options.url = "/cancelCapture";
				  dev.ajax(options);
			  },
			  /** 获取图片 */
			  getImage:function(options){
				  dev.options.url = "/getImage";
				  dev.ajax(options);
			  },
			  /** 获取模板 */
			  getTemplate:function(options){
				  dev.options.url = "/getTemplate";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return Fingerprint;
  })();

  /** 指静脉采集 */
  ZK.Fingervein = (function(){
	  function Fingervein(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 开始采集，需带参数 options.data.type  1: Enroll 登记	2: Verify 比对 默认 */
			  beginCapture:function(options){
				  dev.options.url = "/fingervein/beginCapture";
				  dev.ajax(options);
			  },
			  /** 取消采集 */
			  cancelCapture:function(options){
				  dev.options.url = "/fingervein/cancelCapture";
				  dev.ajax(options);
			  },
			  /** 获取图片 */
			  getImage:function(options){
				  dev.options.url = "/fingervein/getImage";
				  dev.ajax(options);
			  },
			  /** 获取模板 */
			  getTemplate:function(options){
				  dev.options.url = "/fingervein/getTemplate";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return Fingervein;
  })();

  /** IC卡读写 */
  ZK.ICCardInfo = (function(){
	  function ICCardInfo(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 
			   * /ICCardInfoEx?DATA=XXX&SESSION=123456789   // 读IC卡物理卡号
			   */
			  ReadICCard:function(options){
				  dev.options.url = "/ICCardInfoEx";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return ICCardInfo;
  })();

  /** 扫描信息 */
  ZK.ScanInfo = (function(){
	  function ScanInfo(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 读取卡号信息，需带参数 
			   * /ScanReadIdCardInfo?OP-DEV=1&CMD-URL=4 //读取身份证信息GBK
			   * /ScanReadIdCardInfo?OP-DEV=1&CMD-URL=7 //读身份证物理卡号
			   * /ScanReadIdCardInfo?OP-DEV=1&CMD-URL=8 //读IC卡物理卡号
			   * /ScanReadIdCardInfo?OP-DEV=1&CMD-URL=9 //读身份证信息及物理卡号，若设备不支持，则返回空串
			   * 
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=2&random=212  //扫描证件：(二代身份证)
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=4&random=212  //扫描证件：(护照)
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=6&Width=400&Height=260&PhotoName=photo.jpg&ImageName=image.jpg  //扫描证件：(照片)
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=1&random=212  //扫描证件：(驾驶证)
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=3&random=212  //扫描证件：(行驶证)
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=5&random=212  //扫描证件：(厦门社保卡)
			   * /ScanReadIdCardInfo?OP-DEV=2&CMD-URL=1&CertificateType=7&random=212  //扫描证件：(社保卡(北京和重庆))
			   * 
			   * /ScanReadIdCardInfo?OP-DEV=3&CMD-URL=1&TimeOut=10&COMNAME=COM1  //激光扫描
			   *  以下是高拍仪的调用
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=1&EloamName=**  //打开设备
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=2&EloamName=**  //关闭设备
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=3  //获取设备信息 
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=4  //释放设备信息
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=5&Size=0&EloamName=**  //抓拍
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=6&EloamName=**&ResIdx=3  //设置分辨率
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=14&EloamName=**  //获取分辨率
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=7  //获取分辨率数量和组合
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=8&CHOOSENUM=传过来的界面获取的Listnbox的值，这些值只能从6获取的分辨率中选择。目前主要有：0:2592X1944,1:2048X1536,2:1600X1200,3:1280X1024,4:800X600,5:640X480
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=9  //获取扫描尺寸数(返回值为8，说明有8种尺寸) 
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=10&CHOOSESIZE=传过来的界面获取的Listnbox的值	目前良田的主要的值有0:ALL,1:A3,2:A4,3:A5,4:A6,5:A7,5:名片,6:身份证,7:自定义
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=11&SIZEA=10&SIZEB=10&SIZEC=10&SIZED=10 // 设置自己定义扫描尺寸 SIZEA,SIZEB,SIZEC,SIZED分别代表设置左上横坐标，左上纵坐标，右下横坐标，右下纵坐标。
			   * /ScanReadIdCardInfo?OP-DEV=6&CMD-URL=13&COLOR=0  //设置颜色模式  注：颜色有0彩色，1灰度，2黑白
			   */
			  ScanInfo:function(options){
			  	  if(!IssControls.installed){
			  	  	return;
				  }
				  dev.options.url = "/ScanReadIdCardInfo";
				  dev.ajax(options);
			  },
			//设置高拍仪分辨率
			  SetHighDeviceResolving:function(options){
				  if(!IssControls.installed){
				  	  	return;
				  }
				  dev.options.url = "/ScanReadIdCardInfo?EloamName="+options.data.EloamName;
				  options.data={
						  "OP-DEV" : 6,
						  "CMD-URL" : 6,
						  "ResIdx" : options.data.ResIdx
				  };
				  dev.ajax(options); 
              },
            //打开高拍仪设备
              OpenHighDevice:function(options){
				  if(!IssControls.installed){
				  	  	return;
				  }
				  dev.options.url = "/ScanReadIdCardInfo?EloamName="+options.data.EloamName;
				  dev.options.timeout=5000;
				  options.data={
						  "OP-DEV" : 6,
						  "CMD-URL" : 1
				  };
				  dev.ajax(options);
              },
              //高拍仪抓拍
              CaptureHighDevice:function(options){
				  if(!IssControls.installed){
				  	  	return;
				  }
				  dev.options.url = "/ScanReadIdCardInfo?EloamName="+options.data.EloamName;
				  dev.options.timeout=5000;
				  options.data={
						  "OP-DEV" : 6,
						  "CMD-URL" : 5,
						  "Size" : options.data.Size
				  };
				  dev.ajax(options); 
              },
              //关闭高拍仪
              CloseHighDevice:function(options){
				  if(!IssControls.installed){
				  	  	return;
				  }
				  dev.options.url = "/ScanReadIdCardInfo?EloamName="+options.data.EloamName;
				  dev.options.timeout=5000;
				  options.data={
						  "OP-DEV" : 6,
						  "CMD-URL" : 2
				  };
				  dev.ajax(options); 
              },
            //获取高拍仪分辨率
              GetHighDevResolvingPower:function(options){
				  if(!IssControls.installed){
				  	  	return;
				  }
				  dev.options.url = "/ScanReadIdCardInfo?EloamName="+options.data.EloamName;
				  dev.options.timeout=5000;
				  options.data={
						  "OP-DEV" : 6,
						  "CMD-URL" : 14
				  };
				  dev.ajax(options); 
              },
              /** 检测身份证是否安装 */
              CheckIDCardReader:function(callback){
                  this.ScanInfo({
                      data:{
                          "OP-DEV" : 1,
                          "CMD-URL" : 9,
                          "DllType" : 1, // 非一代访客机DllType=1 一代访客机DllType=0或者不传
                          "common" : 1
                      },
                      timeout:15000,
                      callback:function(result){
                          if(result.ret == 1 && result.data != "Device is Busy."){//没有检测到身份证阅读器
                              callback();
                          }
                      }
                  });
              },
              /** 读取身份证 */
			  ReadIDCard:function(callback){
			  	  this.ScanInfo({
                      data:{
                        "OP-DEV" : 1,
                        "CMD-URL" : 9, // 读取身份证信息及物理卡号
                        "DllType" : 1, // 非一代访客机DllType=1 一代访客机DllType=0或者不传
                        "common" : 1,
                        "random":new Date().getTime()
                      },
                      timeout:15000,
                      callback:function(result){
                        if(result.ret == 0){//成功
                            callback(result);
                        }else{//失败
                            if(result.data != "Device is Busy."){
                                issonlineMsgTip("ReadIDCard", result.ret);//消息提示
                            }else{
                                issonlineMsgTip("ReadIDCard", 3);//设备繁忙，请稍等
                            }
                        }
                      }
                  });
			  }
		  });
		  return dev;
	  }
	  return ScanInfo;
  })();

  /** DesFire信息 */
  ZK.DesFire = (function(){
	  function DesFire(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** DesFireHelper信息，需带参数 
			   * 1、/DesFireHelper?CMD=1&MarkInfor=**  读取物理卡号
			   * 2、创建应用
			   * /DesFireHelper?CMD=2&MarkInfor=ZKRFID&MasterkeyType=**&MastermainKeyData=**&AID=**&MasterKeySettings=**&KeyType=**&KeyVersion=**&NewKeyData=**&KeyNum=**
			   * 3、在应用下创建文件
			   * /DesFireHelper?CMD=3&MarkInfor=ZKRFID&AID=**&KeyType&=**&KeyData=**&FileID=**&FileType=**&CommType=**&KeyNoFile1=**&KeyNoFile2=**&FileSpace=**
			   * 4、写文件数据（post请求）
			   * /DesFireHelper?CMD=4&MarkInfor=ZKRFID&MastermainKeyData=**&AID=**&KeyType&=**&KeyData=**&FileID=**&FileType=**&CommType=**&Offset=**&WriteLength=**
			   * 5、读文件数据
			   * /DesFireHelper?CMD=5&MarkInfor=ZKRFID&MastermainKeyData=**&AID=**&KeyType&=**&KeyData=**&FileID=**&FileType=**&CommType=**&Offset=**&ReadLength=**&SingleRecSize=**
			   * 6、读取应用信息及文件信息
			   * /DesFireHelper?CMD=6&MarkInfor=ZKRFID&MasterkeyType=**&MastermainKeyData=**&AID=**&KeyType&=**&KeyData=**
			   * 7、删除指定应用
			   * /DesFireHelper?CMD=7&MarkInfor=ZKRFID&Flag=**&IsControlCardFlag=**&MasterkeyType=**&MastermainKeyData=**&AID=**&KeyType&=**&KeyData=**&FileID=**
			   * 8、格式化卡
			   * /DesFireHelper?CMD=8&MarkInfor=ZKRFID&MasterkeyType=**&MastermainKeyData=**
			   * 9、修改应用秘钥
			   * /DesFireHelper?CMD=9&MarkInfor=ZKRFID&AID=**&KeyType=**&OldKeyData=**&keyVersion=**&newKeyData=**
			   */
			  DesFire:function(options){
				  dev.options.url = "/DesFireHelper";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return DesFire;
  })();

  /** 消费 */
  ZK.PosHelper = (function(){
	  function PosHelper(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 
			   * /ZKPosHelper?CardSender=CR10MW&DATA=**&SESSION=123456789
			   */
			  PosHelper:function(options){
				  dev.options.url = "/ZKPosHelper";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return PosHelper;
  })();
  /** 水控 */
  ZK.WaterConsumption = (function(){
	  function WaterConsumption(initCallBack){

		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  WaterConsumption:function(options){

				  dev.options.url = "/WaterConsumption";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return WaterConsumption;
  })();

  /** 电话猫 */
  ZK.PhoneCat = (function(){
	  function PhoneCat(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 
			   * /DialOut/StartDial?TelNum=18659287144  拨号
			   */
			  StartDial:function(options){
				  dev.options.url = "/DialOut/StartDial";
				  dev.ajax(options);
			  },
			  /** 状态检测 */
			  CheckStatus:function(options){
				  dev.options.url = "/DialOut/CheckStatus";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return PhoneCat;
  })();
  
    /** 获取mac地址和CPU序列号 */
  ZK.checkMacAndCPUSeri = (function(){
	  function checkMacAndCPUSeri(initCallBack){
		  this.Device = new ISSOnline({initCallBack:initCallBack});
		  var dev = this.Device;
		  ZK.extend(this.Device,{
			  /** 
			   * /checkMacAndCPUSeri   // 获取mac地址和CPU序列号
			   */
			  checkMacAndCPUSeri:function(options){
				  dev.options.url = "/checkMacAndCPUSeri";
				  dev.ajax(options);
			  }
		  });
          return dev;
	  }
	  return checkMacAndCPUSeri;
  })();

})(jQuery);
/**
 * @author juvenile
 */
(function() {
    var newVersion = "2.0.27";
    var DevOptions = (function(type){
        var severPort = function(){
            if(window.location.protocol.indexOf("https:") != -1)
            {
                return 25011;
            }
            return 25001;
        }
        function DevOptions(type){
			if(type != undefined && type == "IDN_OCR")
			{
				newVersion = "0.0.0";
				this.devOnlineUrl = window.location.protocol + "//127.0.0.1:25002";
			}
			else
			{
				this.devOnlineUrl = window.location.protocol + "//127.0.0.1:"+severPort();

			}
            this.funUrl = "/ZKDeviceAuth";
            this.url = "/info";
            this.type = "GET";
            this.async = true;
            this.timeout = 5000;
            this.dataType = "text";
        }
        DevOptions.prototype.getUrl = function(){
            return this.devOnlineUrl + this.funUrl + this.url;
        }
        return DevOptions;
    })();

    var IssControls={
        initFlag:false,//是否初始化
        installed:true,//是否安装  true:已安装
        upgrade:false,//是否需要进行升级  true:需要进行升级
        version:newVersion,
        url:"/middleware/ZKDeviceAuthOnline.exe",
        callbackList:[],
        push:function(callback){
            this.callbackList.push(callback);
        },
        callback:function(result){
            this.initFlag = result;
            this.installed = result;
            var l = this.callbackList.length;
            if(l > 0){
                for (var i = 0;i <l;i++){
                    var call = this.callbackList.shift();
                    if(typeof call == "function"){
                        call(this);
                    }
                }
            }
        }
    }

    var DevOnline = (function(initCallBack, type) {
        function DevOnline(options, type) {
			this.options = new DevOptions(type);
			ZK.extend(this.options,options);
            if(IssControls.initFlag == "checking"){
                IssControls.push(options.initCallBack);
            }
			else
			{
				IssControls.push(options.initCallBack);
				this.init();
			}
        }
        DevOnline.prototype.init = function() {
            var self = this;
            IssControls.initFlag = "checking";
            self.ajax({
				async : false,
                callback:function(result){
                    //检测版本
                    var oldVersion = result.data.server_version;
                    IssControls.version = oldVersion;
                    if (oldVersion != newVersion){
                        var newVerArr = newVersion.split(".");
                        var oldVerArr = oldVersion.split(".");
                        var length = newVerArr.length > oldVerArr.length ? oldVerArr.length : newVerArr.length;
                        for (var i=0;i<length;i++){
                            if (parseInt(newVerArr[i]) > parseInt(oldVerArr[i])){
                                IssControls.upgrade=true;//需要升级
                                break;
                            }
                        }
                    }
                    IssControls.callback(true);
                },errorback:function(XMLHttpRequest, textStatus, errorThrown){
                    IssControls.callback(false);
                }
            });
        };
        DevOnline.prototype.ajax = function(options){
            options = ZK.extend({},this.options,options);
			var header = $.ajaxSettings.headers;
			$.ajaxSettings.headers = null;
            $.ajax({
                type: options.type,
                url: options.getUrl(),
                dataType: options.dataType,
                async: options.async,
                timeout: options.timeout,
                data: options.data,
				success: function(data)
                {
                    if(typeof data == "string"){
                        data = data.replace(/\\/g,"/");
                        var result = JSON.parse(data);
                        if(typeof options.callback == "function"){
                            options.callback(result);
                        }
                    }else{
                        options.callback(data);
                    }
                },
                error : function(XMLHttpRequest, textStatus, errorThrown)
                {
                    if(typeof options.errorback == "function")
                    {
                        options.errorback(XMLHttpRequest, textStatus, errorThrown);
                    } else {
                        // messageBox({messageType: "alert", title: I18n.getValue('common_prompt_title'), text: I18n.getValue('base_fp_connectPrompt')});
                    }
                },

            });
			$.ajaxSettings.headers = header;
        };
        return DevOnline;
    })();
    ZK.CardPrinter = (function(){
        function CardPrinter(initCallBack){
			this.Device = new DevOnline({initCallBack:initCallBack});
			var dev = this.Device;
            ZK.extend(this.Device,{
                EnumDevices:function(options){
                    dev.options.url = "/CardPrinter/EnumDevices?ClientType="+options.ClientType;
					dev.options.type = "GET";
                    dev.ajax(options);
                },
                /** options.data = {DeviceName:"",TimeOut:5000}  */
                DoPrint:function(options){
                    dev.options.url = "/CardPrinter/DoPrint?DeviceName="+options.DeviceName+"&TimeOut="+options.TimeOut+"&ClientType="+options.ClientType;
                    dev.options.dataType = "json";
                    dev.options.type = "POST";
					dev.options.timeout = 220000;
                    dev.ajax(options);
                },
            });
            return dev;
        }
        return CardPrinter;
    })();

    ZK.NationCard = (function(){
        function NationCard(initCallBack,ocrType){
			this.Device = new DevOnline({initCallBack:initCallBack}, ocrType);
			var dev = this.Device;
            ZK.extend(this.Device,{
                Register:function(options){
                    dev.options.url = "/Register";
                    dev.options.dataType = "json";
                    dev.options.type = "POST";
                    dev.ajax(options);
                },
                /** options.data = {nationID:"",clientType:""} */
                GetRegisteInfo:function(options){
                    dev.options.url = "/GetRegisteInfo?nationID="+options.nationID+"&clientType="+options.clientType;
					dev.options.type = "GET";
					if(!options.async)
					{
						dev.options.async = options.async;
					}
                    dev.ajax(options);
                },
                /** options.data = {nationID:"",certType:"",clientType:""} */
                ReadCard:function(options){
                    dev.options.funUrl = "/NationCard";
                    dev.options.url = "/ReadCard?nationID="+options.nationID+"&certType="+options.certType+"&clientType="+options.clientType;
                    dev.ajax(options);
                },
                /** options.data = {nationID:"",certType:"",clientType:"",photoPath:""} */
                CardRecog:function(options){
                    dev.options.url = "/NationCard/CardRecog?nationID="+options.nationID+"&certType="+options.certType+"&clientType="+options.clientType+"&photoPath="+options.photoPath;
                    dev.ajax(options);
                },
				/** options.data = {clientType:""} */
				AssureID:function(options){
					dev.options.url = "/AssureID/CardRecog?&clientType="+options.clientType;
					dev.ajax(options);
				}
            });
            return dev;
        }
        return NationCard;
    })();
})(jQuery);


(function() {
	var newVersion = "5.0.1";
	var DevOptions = (function(type){
		var severPort = function(){
			if(window.location.protocol.indexOf("https:") != -1)
			{
				return 22002;
			}
			return 22001;
		}
		function DevOptions(type){
			this.devOnlineUrl = window.location.protocol + "//127.0.0.1:"+severPort();
			this.funUrl = "/zkbioonline";
			this.url = "/info";
			this.type = "GET";
			this.async = true;
			this.timeout = 5000;
			this.dataType = "text";
		}
		DevOptions.prototype.getUrl = function(){
			return this.devOnlineUrl + this.funUrl + this.url;
		}
		return DevOptions;
	})();

	var IssControls={
		initFlag:false,//是否初始化
		installed:true,//是否安装  true:已安装
		upgrade:false,//是否需要进行升级  true:需要进行升级
		version:newVersion,
		url:"/middleware/ISSOnline.exe",
		callbackList:[],
		push:function(callback){
			this.callbackList.push(callback);
		},
		callback:function(result){
			this.initFlag = result;
			this.installed = result;
			var l = this.callbackList.length;
			if(l > 0){
				for (var i = 0;i <l;i++){
					var call = this.callbackList.shift();
					if(typeof call == "function"){
						call(this);
					}
				}
			}
		}
	}

	var BioOnline = (function(initCallBack, type) {
		function BioOnline(options, type) {
			this.options = new DevOptions(type);
			ZK.extend(this.options,options);
			if(IssControls.initFlag == "checking"){
				IssControls.push(options.initCallBack);
			}
			else
			{
				IssControls.push(options.initCallBack);
				this.init();
			}
		}
		BioOnline.prototype.init = function() {
			var self = this;
			IssControls.initFlag = "checking";
			self.ajax({
				async : false,
				callback:function(result){
					//检测版本
					var oldVersion = result.data.server_version;
					IssControls.version = oldVersion;
					if (oldVersion != newVersion){
						var newVerArr = newVersion.split(".");
						var oldVerArr = oldVersion.split(".");
						var length = newVerArr.length > oldVerArr.length ? oldVerArr.length : newVerArr.length;
						for (var i=0;i<length;i++){
							if (parseInt(newVerArr[i]) > parseInt(oldVerArr[i])){
								IssControls.upgrade=true;//需要升级
								break;
							}
						}
					}
					IssControls.callback(true);
				},errorback:function(XMLHttpRequest, textStatus, errorThrown){
					IssControls.callback(false);
				}
			});
		};
		BioOnline.prototype.ajax = function(options){
			options = ZK.extend({},this.options,options);
			var header = $.ajaxSettings.headers;
			$.ajaxSettings.headers = null;
			$.ajax({
				type: options.type,
				url: options.getUrl(),
				dataType: options.dataType,
				async: options.async,
				timeout: options.timeout,
				data: options.data,
				success: function(data)
				{
					if(typeof data == "string"){
						data = data.replace(/\\/g,"/");
						var result = JSON.parse(data);
						if(typeof options.callback == "function"){
							options.callback(result);
						}
					}else{
						options.callback(data);
					}
				},
				error : function(XMLHttpRequest, textStatus, errorThrown) {
					if(typeof options.errorback == "function") {
						options.errorback(XMLHttpRequest, textStatus, errorThrown);
					}
				},

			});
			$.ajaxSettings.headers = header;
		};
		return BioOnline;
	})();
	ZK.PalmPrint = (function(){
		function PalmPrint(initCallBack){
			this.Device = new BioOnline({initCallBack:initCallBack});
			var dev = this.Device;
			ZK.extend(this.Device,{
				/** 开始采集，需带参数 options.data.type  1: Enroll 登记	2: Verify 比对 默认 */
				beginCapture:function(options){
					dev.options.url = "/palm/beginCapture";
					dev.ajax(options);
				},
				/** 取消采集 */
				cancelCapture:function(options){
					dev.options.url = "/palm/cancelCapture";
					dev.ajax(options);
				},
				/** 获取图片 */
				getImage:function(options){
					dev.options.url = "/palm/getImage";
					dev.ajax(options);
				},
				/** 获取模板 */
				getTemplate:function(options){
					dev.options.url = "/palm/getTemplate";
					dev.ajax(options);
				}
			});
			return dev;
		}
		return PalmPrint;
	})();
})(jQuery);

window.ZKIssonlineMsg = {
  ReadIDCard:{
    0:{msg:"Swipe successful!",type:'success'},//成功
    1:{msg:"ID card reader is not detected!",type:'attention'},//端口打开失败
    2:{msg:"ID card reader is not detected!",type:'error'},//数据传输超时
    3:{msg:"Device busy, please wait",type:'attention'},//设备繁忙，请等待
    10:{msg:"ID card not found, please try again!",type:'attention'},//没有找到卡
    11:{msg:"ID card reader is not detected!",type:'error'},//读卡操作失败
    20:{msg:"Swipe failure!",type:'error'},//自检失败
    30:{msg:"Swipe failure!",type:'error'},//其他错误
    40:{msg:"Swipe failure!",type:'error'},//相片解码失败
    100:{msg:"ID card not found, please try again!",type:'attention'},//超时
    200:{msg:"Swipe failure!",type:'error'}
  }
};

/**
 * issonline 消息提示
 * @param type
 * @param ret
 */
function issonlineMsgTip(type,ret){
    var obj = window.ZKIssonlineMsg[type][ret];
    if(obj){
      // openMessage(obj.type, obj.msg);
       setModalStatePersonDetails(true);
       setStatusPersonDetails(type);
       setErrorMsg(obj.msg);
  
     }else{
      //  openMessage(msgType.error, I18n.getValue("common_commStatus_unknownError"));
       setModalStatePersonDetails(true);
       setStatusPersonDetails('attention');
       setErrorMsg("Unknown error");
     }
};

var ZKDevType={
    Fingerprint:"Fingerprint",
    Fingervein:"Fingervein",
    ICCardInfo:"ICCardInfo",
    ScanInfo:"ScanInfo",
    DesFire:"DesFire",
    PosHelper:"PosHelper",
    PhoneCat:"PhoneCat",
    CardPrinter:"CardPrinter",
    NationCard:"NationCard",
    checkMacAndCPUSeri:"checkMacAndCPUSeri",
	  WaterConsumption : "WaterConsumption",
    PalmPrint : "PalmPrint"
};

var ZKDevice = {
    devs:[],
    get:function(type,initCallBack,ocrType) {
        if(typeof ZKDevType[type] == undefined){
            throw "设备类型错误";
        }
        var dev = this.devs[type];
        if(typeof dev == "undefined" || typeof initCallBack != 'undefined'){
            dev = eval('new ZK.'+type+"(initCallBack,ocrType);");
            this.devs[type] = dev;
        }
        return dev;
    }
};
//$(function(){

    // 有需要初始化调用
    // ZKDevice.get(ZKDevType.Fingerprint,function(){
    //      //初始化调函数，例如要做一些处理
    // });

    //  例：指纹采集调用
    //  ZKDevice.get(ZKDevType.Fingerprint).beginCapture({
    //      data:{type:1},
    //      callback:function(result){
    //          //继续自己的业务逻辑
    //      }
    // })
//});

  // ============== issonline.js End =================

  // =================================================================
 
  // ============== baseFPRegister.js Start =================

//采集指纹次数
var FINGERPRINT_NUMBER = 3;
//胁迫指纹数--胁迫指纹时，在普通指纹上加的数
var DURESS_FINGER_NUM = 16;
//胁迫指纹标记
var duressFingerFlag = null;
//是否显示胁迫指纹(用户登记指纹时，不需要胁迫指纹)，默认显示胁迫指纹
var duressFingerShowFlag = true;
var compareOneToManyFlag = false;
var bioTemplateJson;
// setInterval(() => {
//   console.log("bioTemplateJson=====",bioTemplateJson);
// }, 2000);
//手指标记数组
var fingerIdArray = new Array();//[]
//指纹模板数据数组
var templateDataArray = new Array();//[]
//静脉标记数组
var fvIdArray = new Array();
//指静脉模板数据数组
var templateFVDataArray = new Array();
//定时器--关闭setTimeOut时用到
var bioTemplateTimer = null;
//定时器--验证
var verifyTimer = null;
//判断当前手指是否正在采集中
var collectFlag = false;
//当前点击的手指标记
var fpIdNum = null;

//访问ISSOnline_server的ip
var serverIp = null;
//访问的ISSOnline_server端口
var serverPort = null;
var httpsServerPort = null;
//ISSOnline_server的url的公共部分:http://localhost:24008/ISSOnline
var issOnlineUrl = null;
//是否是访客
var isVisPager = false;
/**
 * 表单提交
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function formSubmit(id)
{
    $('#'+id).serialize();
    $('#'+id).ajaxForm(function(data){
     //   callBackFormSubmit(data); 
    });
    $('#'+id).submit(); //表单提交。
}
function cleanDataArray() {
    templateDataArray = new Array();
    templateFVDataArray = new Array();
}
/**
 * 检查指纹采集器
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param paramArray 存放国际化元素的数组
 * @param browserFlag 浏览器标记 simple:简易版本，表示是ie浏览器；html5:表示支持html5的浏览器
 */
function checkFPReader(context, paramArray, browserFlag)
{
    ZKDevice.get(devType).beginCapture({
        data:{type:1, "random":new Date().getTime()},
        dataType : "json",
        async: false,
        callback:function(result){
            //返回码
            var ret = null;
            ret = result.ret;
            //接口调用成功返回时
            if(ret == 0){
                collectTips(context, paramArray[0], browserFlag);//Display box--collection prompt
            } else if(ret == -2001||(ret == -2 && devType == ZKDevType.Fingervein)){//指静脉的-2可能是指静脉动态链接库初始化失败或未检测到指静脉采集器。
                collectTips(context, paramArray[1], browserFlag, "warning");//Display box--collection prompt
            } else if(ret == -2002) {
                if(browserFlag == "html5"){
                    getWebServerInfo(null, null, "1");
                }else if(browserFlag == "simple"){
                    getWebServerInfoForSimple(null, null, "1");
                }
            } else if(ret == -2005) {
                collectTips(context, paramArray[3], browserFlag);//Display box--collection prompt
            }
            collectFlag = true;
            //取消采集
            cancelRegister();
        }
    });
}

/**
 * 取消采集，当采集中断时
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function cancelRegister()
{
    //当前有手指在采集指纹
    if(collectFlag)
    {
        //将定时器的递归调用关闭
        clearTimeout(bioTemplateTimer);
        //取消采集
        ZKDevice.get(devType).cancelCapture({
            data:{"random":new Date().getTime()},
            async : false,
            callback:function(result){
                //如果胁迫指纹选中，则取消选中
                if(duressFingerFlag)
                {
                     // if($("#contend").size() > 0)	
                    // {	
                        // $("#duressFinger").attr("checked", false);	
                        document.getElementById("duressFinger").checked = false	
                    // }	
                    // else{	
                    //     $("#duressFinger").prop("checked", false);	
                    // }
                }
                if(fpModifyFlag)
                {
                   // if($("#contend").size() > 0)	
                    // {	
                    //     $("#submitButtonId").attr("disabled", false);	
                    // }	
                    // else	
                    // {	
                        // $("#submitButtonId").attr("disabled", false);	
                    // }
                }
                 // if($("#contend").size() > 0)	
                // {	
                //     $("#duressFinger").attr("disabled", false);	
                // }	
                // else	
                // {	
                  $("#duressFinger").attr("disabled", false);	
                  // }
                if(fpIdNum != null)
                {
                    //消除原来手指的颜色
                    renderAfterColl(globalContext, lastFPIdNum, bgColor, false);
                }
                collectFlag = false;
            }
        });
    }
}

/**
 * 显示指纹登录--点击进行指纹验证
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function showFPVerify()
{
    $("#userLoginForm .but_fing_disabled").hide();
    $("#userLoginForm .but_fing").show();
}

/**
 * 关闭页面前，如果正在进行验证，则先取消采集
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param browserFlag 浏览器标记 simple:简易版本，表示是ie浏览器；html5:表示支持html5的浏览器
 */

var verifyFlag = true;

function cancelCaptureBeforeClose(browserFlag)
{
    //关闭页面时，监听关闭的onclick事件
    // DhxCommon.getCurrentWindow().attachEvent("onClose", function(){
    //     clearTimeout(verifyTimer);
    //     if(browserFlag == "html5")
    //     {
    //         //正在进行验证，还没有关闭指纹采集
    //         if(verifyFlag)
    //         {
    //             //取消采集
    //             cancelCapture();
    //         }
    //         //关闭页面
    //         return true;
    //     }
    //     else if(browserFlag == "simple")
    //     {
    //         //alert("cancel capture before close window!");
    //         //将定时器的递归调用关闭
    //         clearTimeout(bioTemplateTimer);
    //         //取消采集
    //         cancelRegister();
    //         //此处应该在取消结束后，再关闭窗
    //         return true;
    //     }
    // });
}

/**
 * 指纹验证
 * @param title 页面标题国际化内容
 * @param isDriverInstall 是否安装了驱动
 * @param downloadPrompt 提示安装驱动国际化内容
 */
function fpVerification(title, downloadPrompt, isDriverInstall)
{
    //安装驱动
    if(isDriverInstall)
    {
        //支持html5
        if(typeof(Worker) != "undefined")
        {
            // DhxCommon.createWindow('portal/opBaseFPVerify^0^0^465^320^'+title);
            //关闭页面前，取消采集
            cancelCaptureBeforeClose("html5");
        }
        else
        {
            // DhxCommon.createWindow('portal/opBaseFPVerifySimple^0^0^465^320^'+title);
            cancelCaptureBeforeClose("simple");
        }
    }
    else
    {
       // messageBox({messageType: "alert", title: "Prompt", text: downloadPrompt});
        setModalStatePersonDetails(true);
        setStatusPersonDetails('attention');
       setErrorMsg(downloadPrompt);
    }
}

/**
 * 保存生物模版
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function saveBioTemplate(){
    $("#bioTemplateJson").val(JSON.stringify(bioTemplateJson));  
    setTabValue(0)
    // if(typeof (bioCallback) == "function")
    // {
    //     bioCallback();
    // }
    // DhxCommon.closeWindow();
}
function saveDefaultBioTemplate()
{
    // $("#fpCountMessage span").text(bioTemplateJson.fpCount);
    // $("#fvCountMessage span").text(bioTemplateJson.fvCount);
    // $("#faceCountMessage span").text(bioTemplateJson.faceCount);
    // $("#palmCountMessage span").text(bioTemplateJson.palmCount);
}

/**
 * 初始化生物模版
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function initBioTemplate(){
    bioTemplateJson = $("#bioTemplateJson").val();
    // bioTemplateJson = document.getElementById("bioTemplateJson").value;
    if(bioTemplateJson != ""){
        bioTemplateJson = eval("("+bioTemplateJson+")");
    }else{
        bioTemplateJson = {
            fpCount:0,
            fpList:{},
            fvCount:0,
            fvList:{},
            faceCount:0,
            faceList:{},
            palmCount:0,
            palmList:{}
        }
    }

    // $("#fpCountMessage span").text(bioTemplateJson.fpCount);
    // $("#fvCountMessage span").text(bioTemplateJson.fvCount);
    // $("#faceCountMessage span").text(bioTemplateJson.faceCount);
    // $("#palmCountMessage span").text(bioTemplateJson.palmCount);

    // console.log("bioTemplateJson.fpCount  =========="+bioTemplateJson.fpCount  ); 
    // console.log("bioTemplateJson.fvCount  =========="+bioTemplateJson.fvCount  ); 
    // console.log("bioTemplateJson.faceCount=========="+bioTemplateJson.faceCount); 
    // console.log("bioTemplateJson.palmCount=========="+bioTemplateJson.palmCount); 
}

/**
 * 设置生物模版
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param type 类型 fv,fp,face
 * @param templateNo 生物特征模板编号
 * @param templateNoIndex 生物特征模板对应索引
 * @param template 生物特征模板内容  可选
 * @param duress 是否胁迫  可选
 */
function setBioTemplate(type,templateNo,templateNoIndex,template,duress,fpVersion){
    var listKey = templateNo+"_"+templateNoIndex;
    // if(bioType.indexOf("bio") != -1)
    // {
        if(fpVersion == undefined)
        {
            if(type == "fp")
            {
                fpVersion = "10";
            }
            else
            {
                fpVersion = "3";
            }
        }
        listKey = listKey+"_"+fpVersion;
    // }
    bioTemplateJson[type+"List"][listKey] = {
        templateNo:templateNo,
        templateNoIndex:templateNoIndex,
        template:template,
        duress:Boolean(duress)
    };
    if(fpVersion != undefined)
    {
        bioTemplateJson[type+"List"][listKey]["version"] = fpVersion;
    }
}

/**
 * 设置数量
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param type
 * @param addCount 是否加1
 */
function setBioTemplateCount(type,templateNo,isAdd){
    if(isAdd){
        bioTemplateJson[type+"Count"] = parseInt(bioTemplateJson[type+"Count"]) + 1;
    }else{
        bioTemplateJson[type+"Count"] = parseInt(bioTemplateJson[type+"Count"]) -1;
    }
}

/**
 * 获取生物模板数量
 * @param type
 * @returns {number}
 */
function getBioTemplateCount(type){
    if (!$.isEmptyObject(bioTemplateJson[type+"List"]))
    {
        return true;
    }
    return false;
}

/**
 * 获取生物模板数据
 * @param type
 * @returns {any[]}
 */
function getBioTemplateDataArray(type, templateNo){
    var bioDataArray = new Array(),bioList = bioTemplateJson[type+"List"];
    for (var index in bioList){
        if(isBioTemplateExists(type, templateNo,0)){
            continue;
        }
        bioDataArray.push(bioList[index].template);
    }
    return bioDataArray;
}

/**
 * 删除生物模版
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param type 类型 fv,fp,face
 * @param templateNo 生物特征模板编号
 */
function delBioTemplate(type,templateNo){
  //alert("delFPData===bioTemplateJson===="+bioTemplateJson);
  if(bioTemplateJson == undefined){
    initBioTemplate();
  } 
    bioTemplateJson[type+"Count"] = parseInt(bioTemplateJson[type+"Count"]) - 1;
    for (var index in bioTemplateJson[type+"List"]){
        if(index.startsWith(templateNo+"_")){
            delete bioTemplateJson[type+"List"][index]; 
        }
    }
    $("#bioTemplateJson").val(JSON.stringify(bioTemplateJson));
    setBioTemplateJsonValue(bioTemplateJson);
    props.getData(bioTemplateJson)
}

/**
 * 删除数组元素 -- 从dx下标开始，删除一个元素
 * @param dx 要删除元素的下标
 */
function removeItem(array, dx)
{
    array.splice(dx, 1);
}

/**
 * 判断生物模版是否存在
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param type
 * @param templateNo
 * @param templateNoIndex
 * @returns {boolean}
 */
function isBioTemplateExists(type,templateNo,templateNoIndex){
    var list = bioTemplateJson[type+"List"];
    var isBioTemplateExist = false;
    $.each(list, function(key) {
        if(key.startsWith(templateNo+"_"+templateNoIndex))
        {
            isBioTemplateExist = true;
            return false;
        }
    });

    // Object.keys(list).forEach(key => {
    //   //    $.each(list, function(key) {
    //           if(key.startsWith(templateNo+"_"+templateNoIndex))
    //           {
    //               isBioTemplateExist = true;
    //               return false;
    //           }
    //       });
    return isBioTemplateExist;
}

/**
 * 是否胁迫
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param type
 * @param templateNo
 * @param templateNoIndex
 * @returns {boolean|*}
 */
function isBioTemplateDuress(type,templateNo,templateNoIndex){
    var list = bioTemplateJson[type+"List"];
    var isDuress = false;
    var bioTemplate = "";
    $.each(list, function(key) {
        if(key.startsWith(templateNo+"_"+templateNoIndex))
        {
            bioTemplate = list[key];
            return false;
        }
    });
    return typeof bioTemplate != "undefined" && bioTemplate.duress;
}

function showDriverInfoMessage()
{
    // if(isHttpsProtocol() && $('body').children('#driverInfoMessage').length == 0)
    // {
    //     var html = "<div id='driverInfoMessage' style='position:absolute;left:0px;right:0px;top:0px;text-align:center;cursor:default;z-index:10000;padding: 3px 5px 1px 5px;color:" +
    //         " #474b4f;background-color: #f3f5f0' onclick='installCert()'>"+
    //         "<img src='"+ (sysCfg.headLogo || ("public/images/favicon" + (sysCfg.skin == "access" ? "_acc":"") + ".png")) + "' style='margin-top: -2px'/>" +
    //         "<span style='padding-left: 5px'>"+ I18n.getValue("base_driver_checkmsg1") +"<span></div>";
    //     $('body').prepend(html);
    //     $(".top_fix_cla").css("top","34px");
    //     if(system && system.contentLayout) {//系统布局向下偏移-----modify by train.chen 2019-7-19 10:57:47
    //         system.contentLayout.setOffsets({
    //             top : 34,
    //             right : 0,
    //             bottom : 0,
    //             left : 0
    //         });
    //         document.body.style.overflowY = "hidden";
    //         resizeUI();
    //     }
    // }

}

/**
 *
 * TODO https提示安装证书
 *
 * @author chenPF
 * @since 2016年4月25日 上午11:24:58
 */
function installCert()
{
    if(isHttpsProtocol())
    {
        // DhxCommon.createWindow("portal/opBaseInstallCert^0^0^700^300^" + I18n.getValue("common_prompt"));
    }
    $("#driverInfoMessage").css("display","none");
    $(".top_fix_cla").css("top","0px");
    $(".dhxlayout_cont:eq(0)").css("top","62px");
    // if(system && system.contentLayout) {//系统布局还原----modify by train.chen 2019-7-19 10:57:02
    //     system.contentLayout.setOffsets({
    //         top : 0,
    //         right : 0,
    //         bottom : 0,
    //         left : 0
    //     });
    //     document.body.style.overflowY = "auto";
    //     resizeUI();
    // }
}


/**
 *
 * TODO 是否https协议
 *
 * @author chenPF
 * @since 2016年4月22日 下午5:23:15
 * @returns {Boolean}
 */
function isHttpsProtocol()
{
    return window.location.protocol.indexOf("https:") == 0;
}

/**
 * 点击登记，触发事件
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
var devType = ZKDevType.Fingerprint;
var bioCallback = saveDefaultBioTemplate;
var bioType = "fp";
function submitRegister(obj, callback)
{
    bioType = $(obj).attr("type");
    if(typeof (callback) == "function")
    {
        bioCallback = callback;
    }
    if($(obj).attr("class") == "showGray"){
        return;
    }
    switch ($(obj).attr("type")){
        case "fp":
        case "bio":
            devType = ZKDevType.Fingerprint;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseBioRegister^0^0^465^560^' + $(obj).attr("title"));
            break;
        case "bioPers":
            devType = ZKDevType.Fingerprint;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseBioRemoteRegister^0^0^465^610^' + $(obj).attr("title"));
            break;
        case "fpPers":
            devType = ZKDevType.Fingerprint;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseFPRemoteRegister^0^0^465^560^' + $(obj).attr("title"));
            break;
        case "fpSimple":
            devType = ZKDevType.Fingerprint;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseFPRegisterSimple^0^0^465^460^' + $(obj).attr("title"));
            break;
        case "fv":
            devType = ZKDevType.Fingervein;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseFVRegister^0^0^465^480^' + $(obj).attr("title"));
            break;
        case "fvPers":
            devType = ZKDevType.Fingervein;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseFVRemoteRegister^0^0^465^560^' + $(obj).attr("title"));
            break;
        case "fvSimple":
            devType = ZKDevType.Fingervein;
            // DhxCommon.createWindow('/skip.do?page=system_device_baseFVRegisterSimple^0^0^465^460^' + $(obj).attr("title"));
            break;
            setDevTypeValue(devType);
    }
    //关闭页面时，监听关闭的onclick事件
    // DhxCommon.getCurrentWindow().button("close").attachEvent("onClick", closedEvent);
}

function closedEvent(){
    //判断是否修改了数据(包括新增和删除)
    if ($("#whetherModify").val() != undefined && (fpModifyFlag != undefined && fpModifyFlag))
    {

      // ========Need to replace with Confirmation box============
        // messageBox({
        //     messageType : "confirm",
        //     text : "Save current modifications?",
        //     callback : function(result)
        //     {
        //         if(collectFlag)
        //         {
        //             //取消采集
        //             cancelRegister();
        //             //将定时器的递归调用关闭
        //             clearTimeout(bioTemplateTimer);
        //         }
        //         if(result)
        //         {
        //             saveBioTemplate();
        //         }
        //         else
        //         {
        //             initBioTemplate();
        //             clearImageData();

        //             // DhxCommon.closeWindow();
        //         }
        //     }
        // });
    }
    else
    {
        if(collectFlag) {
            //取消采集
            cancelRegister();
            //将定时器的递归调用关闭
            clearTimeout(bioTemplateTimer);
        }
        // DhxCommon.closeWindow();
    }
}

/**
 * 点击已经采集指纹的手指时，弹出框删除数据
 * 删除时的回调函数
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param result 弹出框选择确定还是取消
 * @param context 2d画布上下文
 * @param browserFlag 浏览器标记 simple:简易版本，表示是ie浏览器；html5:表示支持html5的浏览器
 */
var delFPData = function(result, context, browserFlag)
{
    if(result)
    {
        //将数组中的指定元素删除
        if(devType == ZKDevType.Fingerprint)
        {

          //alert("delFPData===fpIdNum===="+fpIdNum);
            delBioTemplate("fp",fpIdNum);
            if ($("input[name='registType']:checked").size() > 0 && $("input[name='registType']:checked").val() == "1")
                for(var i=0; i<fingerIdArray.length; i++)
                {
                    var fingerId = eval(fingerIdArray[i]);
                    if(fingerId >= DURESS_FINGER_NUM)
                    {
                        fingerId = fingerId - DURESS_FINGER_NUM;
                        if(fingerId == fpIdNum)
                        {
                            removeItem(fingerIdArray, i);
                            removeItem(templateDataArray, i);
                        }
                    }
                    else {
                        if (fingerId == fpIdNum) {
                            removeItem(fingerIdArray, i);
                            removeItem(templateDataArray, i);
                        }
                    }
                }
        }
        if(devType == ZKDevType.Fingervein){
            delBioTemplate("fv",fpIdNum);
            if ($("input[name='registType']:checked").size() > 0 && $("input[name='registType']:checked").val() == "1") {
                for(var i=0; i<fvIdArray.length; i++)
                {
                    //fvId = eval(fvIdArray[i]);
                    var fvId = fvIdArray[i];
                    fvId = fvId.split("_")[0];
                    if(fvId == fpIdNum)
                    {
                        removeItem(fvIdArray, i);
                        removeItem(templateFVDataArray, i);
                        i=-1;
                    }
                }
            }
        }
        if(browserFlag == "simple")
        {
            document.getElementById("finger" + fpIdNum).checked = false;
        }
        else if(browserFlag == "html5")
        {
            //将手指颜色改变--重画时也要判断
            context.fillStyle = bgColor;
            context.fill();
            if(lastFPIdNum != null && lastFPIdNum != lastFPIdNum)
            {
                //Remove the color of the original finger
                renderAfterColl(globalContext, lastFPIdNum, bgColor, false);
            }
            //Eliminate finger colors that need to be removed
            renderAfterColl(globalContext, fpIdNum, bgColor, false);
        }
        fpModifyFlag = true;
        $("#duressFinger").attr("disabled", false);
        $("#submitButtonId").attr("disabled", false);
    }
    else
    {
        if(browserFlag == "simple")
        {
            document.getElementById("finger" + fpIdNum).checked = true
            collectFlag = true;
        }
        else if(browserFlag == "html5")
        {
            //Remove the color of the original finger -- there is a problem, if the original finger is the same as the current one, there is a problem
            //renderAfterColl(globalContext, lastFPIdNum, bgColor, false);
        }
    }
}

/**
 * 页面加载和重画时渲染手指
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param type 类型  fp,fv
 * @param num 当前需要渲染的手指编号
 * @param browserFlag 浏览器标记 simple:简易版本，表示是ie浏览器；html5:表示支持html5的浏览器
 */
function renderInit(context,type, num, browserFlag)
{
  var finger = fingerList[num];	
  if(finger != undefined && finger.duress){	
      context.fillStyle = "red";	
      context.fill();	
  }	
  if(isBioTemplateExists(type, num, 0)){	
      if(isBioTemplateDuress(type, num, 0) == 'true' || isBioTemplateDuress(type, num, 0) == true){	
        context.fillStyle = "red";	
          context.fill();	
      }else{	
          context.fillStyle = "rgb(122,193,66)";	
          context.fill();	
      }	
  }
    if(browserFlag == "simple") {
        document.getElementById("finger" + num).checked = true;
    }
}

/**
 * 获取webserver的信息
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param paramArray 存放国际化元素的数组
 * @param isFPLogin 是否是指纹登录 true:是；false:否
 * @param type 0 表示发送完请求后,还有别的操作。1 表示发送完请求后，没有其余的操作了
 */

 function getWebServerInfo(paramArray, isFPLogin, type)
 {
   //Modify the visitor registration to use the webcam to capture, and the prompt of "plug-in loading failed" appears
   setTimeout(function(){
     $.ajax( {
       type : "GET",
       url : issOnlineUrl+"/info",
       dataType : "json",
       async: false,
   //		timeout:1000,
       success : function(result)
       {
         //检查驱动
         if(type == "0")
         {
           getWebServerInfoCallBack(result, paramArray, isFPLogin);
         }
         //检查动态库连接
         else if(type == "1")
         {
           getDLLConnectCallBack(result);
         }
       },
       error : function(XMLHttpRequest, textStatus, errorThrown)
       {
         showDriverInfoMessage();
         }
     });
   },5);
 }

 function getWebServerInfoCallBack(result, paramArray, isFPLogin)
{
	//返回码
	var ret = null;
	ret = result.ret;
	//When the interface call returns successfully
	if(ret == 0)
	{
		if(isFPLogin)
		{
			//显示指纹登录
			showFPVerify(paramArray);
		}
		else
		{
			//Display registration - click to collect fingerprints
			showRegister(paramArray);
			//Display finger vein registration - click to collect finger veins
			showFVRegister(paramArray);
			//Calculate the number of fingerprints Calculate the number of fingerprints: Number of fingerprints
			showFPCountInit(paramArray[5], paramArray[6]);
			//Calculate the number of finger veins
			showFVCountInit(paramArray[11], paramArray[9]);
			//mouseover event
			//mouseOverEvent();
			// Compare fingerprint driver
			if (result.data&&result.data.server_version){
			    compareFPDriver(result.data.server_version);
			}
		}

	}
}

function showRegister(paramArray)
{
	var hrefStr = "";
	var param = '"'+paramArray[0]+'", "'+paramArray[1]+'", "'+paramArray[2]+'", '+null+'';
	$("#fpRegister").remove();
	$("#downloadDriver").remove();
	$(".countMessage").attr("driverDownload","");
	//webservice接口调用成功，说明驱动已经安装
	hrefStr = "<a id='fpRegister' onclick='submitRegister("+param+", true"+")' title='"+paramArray[3]+"' class='linkStyle'>"+paramArray[3]+"</a>";
	$("#fpRegisterDiv").append(hrefStr);
	$("#fpCountMessage").attr("fpRegister",hrefStr);
}

function showFVRegister(paramArray)
{
	var hrefStr = "";
	var param = '"'+paramArray[8]+'", "'+paramArray[9]+'", "'+paramArray[2]+'", '+null+'';
	$("#fvRegister").remove();
	$("#fvDownloadDriver").remove();
	hrefStr = "<a id='fvRegister' onclick='submitFVRegister("+param+", true"+")' title='"+paramArray[3]+"' class='linkStyle'>"+paramArray[3]+"</a>";
	$("#fvRegisterDiv").append(hrefStr);
	$("#fvCountMessage").attr("fpRegister",hrefStr);
}

function showFPCountInit(fingerIdCount, text)
{
	$("#fpCountMessage").empty();
	$("#fpCountMessage").append("<img alt=\"\" src=\"pers/images/ico_fingerprint.png\" onmousemove=\"imgMouseMove(event,'指纹数')\">" +" "+ fingerIdCount);
}
function showFVCountInit(fvIdCount, text)
{
	$("#fvCountMessage").empty();
	$("#fvCountMessage").append("<img alt=\"\" src=\"pers/images/ico_finger_vein.png\" onmousemove=\"imgMouseMove(event,'指静脉数')\">" +" "+ fvIdCount);
}

function compareFPDriver(oldVersion)
{
    var newVersion = "2.0.40";
    if (oldVersion==newVersion){
        return;
    }else{
        var newVerArr = newVersion.split(".");
        var oldVerArr = oldVersion.split(".");
        var length = newVerArr.length>oldVerArr.length?oldVerArr.length:newVerArr.length;
        for (var i=0;i<length;i++){
            if (parseInt(newVerArr[i])>parseInt(oldVerArr[i])){
                showNewDriver();
                showFVNewDriver();
                return;
            }
        }
    }
}

function showNewDriver(){
  var hrefStr = "<a id='downloadDriver' href='base/middleware/ISSOnline.exe' title='下载新驱动'>下载新驱动</a>";
  $("#driverDownload").append(hrefStr);
  $("#driverDownload").removeAttr("style");
  $(".countMessage").attr("driverDownload",hrefStr);
}
function showFVNewDriver(){
  var hrefStr = "<a id='fvDownloadDriver' href='base/middleware/ISSOnline.exe' title='下载新驱动'>下载新驱动</a>";
  $("#fvDriverDownload").append(hrefStr);
  $("#fvDriverDownload").removeAttr("style");
}

function getWebServerInfoForSimple(isFPLogin, type)
{
    ZKDevice.get(devType,function(){
        //Check the driver
        if(type == "0")
        {
            if(isFPLogin)
            {
                //Display fingerprint comparison
                showFPVerify();
            }
        }
        //Check dynamic library connections
        else if(type == "1")
        {
            getDLLConnectCallBack();
        }
    });
}

function getFpServerInfo()
{
    $.ajax({
        type : "POST",
        url : "login.do?getFingerServiceConn",
        dataType : "json",
        async: false,
        success : function(result)
        {
            if(result.ret == "ok")
            {
                //显示指纹登录
                showFPVerify();
            }
        }
    });
}
var readyStateForSimple = false;//Judging the status flag of the interface call--Fingerprint collection simplified version prevents frequent calls to the webservice interface
/**
 * 取消采集(简易版)
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function cancelCaptureForSimple()
{
    readyStateForSimple = false;
    // ZKDevice.get(devType).cancelCapture({
    //     callback:cancelCaptureCallBack,
    //     errorback:errorMsg
    // });
}
/**
 * 打开采集，开始扫描
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function beginFPRegister(type)
{
    // ZKDevice.get(devType).beginCapture({
    //     data:{type:type, "random":new Date().getTime()},
    //     callback:beginCaptureCallBack,
    //     errorback:errorMsg
    // });
}
/**
 * 采集指纹、显示采集次数、指纹图像
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function captureAndGetImage()
{
    // ZKDevice.get(devType).getImage({
    //     callback:getImageCallBack,
    //     errorback:errorMsg
    // });
}
/**
 * 获取指纹模板(简易版)
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function getFPTemplateForSimple()
{
    // ZKDevice.get(devType).getTemplate({
    //     callback:getTemplateCallBack,
    //     errorback:errorMsg
    // });
}
/**
 * 清空指纹图像
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
function clearFPImage(context, browserFlag)
{
    if(browserFlag == "verification")
    {
        if(devType == ZKDevType.Fingerprint || devType == ZKDevType.PalmPrint)
        {
           showImage(context, image3, "clearForVerify");
        }
        else
        {
            showImage(context, image3, "clearForVerify");
        }
    }
    else if(browserFlag == "register")
    {
        if(devType == ZKDevType.Fingerprint)
        {
            showImage(context, image3, "clearForRegister");
        } else if(devType == ZKDevType.PalmPrint) 
        {
          showImage(context, image4, "clearForRegister");
        }
        else
        {
            showImage(context, image1, "clearForRegister");
        }
    }
    else if(browserFlag == "verifyForSimple" || browserFlag == "registerForSimple")
    {
        showImage(null, "", "clearForSimple");
    }
}
/**
 * 显示指纹图像
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param browserFlag 浏览器标记 simple:简易版本，表示是ie浏览器；html5:表示支持html5的浏览器
 */
var baseShowImageInterval,flag=0,fvFlag=0;
function showImage(context, base64FPImg, browserFlag)
{
    var img,imgGif;
    var imgSrc = "data:image/jpg;base64,"+base64FPImg;
    if(browserFlag == "html5"){
        img = new Image();
        img.src = imgSrc;
        img.onload=function() {
            // Save the current drawing state
            context.save();
            // Start creating a path
            context.beginPath();
            // draw an ellipse
            if ($("input[name='registType']:checked").size() > 0) {
                //  context.drawImage(img, 70, 150, 112, 145);

                context.drawImage(img, 380, 81, 130, 138); // commented made change to finger image // 02-09 last change	
              } else {	
			  //  context.drawImage(img, 70, 90, 112, 145); 	
         context.drawImage(img, 380, 81, 130, 138); // commented made change to finger image  // 02-09 last change
            }
            // close path
            context.closePath();
            // clipping path
            context.clip();
            //draw the picture onto the canvas
            if ($("input[name='registType']:checked").size() > 0) {
                // context.drawImage(img, 70, 150, 112, 145);
                context.drawImage(img, 360, 70, 130, 130); // commented made change to finger image  // 02-09 last change
            }
            else {
                // context.drawImage(img, 70, 90, 112, 145);
                context.drawImage(img, 360, 70, 130, 130); // commented made change to finger image  // 02-09 last change
            }

            //The last stored state will be restored when restore is called
            context.restore();
            fvFlag=1;
        }
    }
	 // commented did some changes to show in third tab  start here
    else if (browserFlag == "verification") {
      img = new Image();
      img.src = imgSrc;
      img.onload = function() {
        // Save the current drawing state
        context.save();
        // Start creating a path
        context.beginPath();
        // draw an ellipse
        context.arc(380, 200, 280, 200, 10);
        // close path
        context.closePath();
        // clipping path
        context.clip();
        //draw the picture onto the canvas
        context.drawImage(img, 300, 140, 240, 120); 
        //The last stored state will be restored when restore is called
        context.restore();
      };
    } 
    else if(browserFlag == "clearForVerify" || browserFlag == "clearForRegister")
    {
        img = new Image();
        img.src = base64FPImg;
        img.onload=function() {
            // Save the current drawing state
            context.save();
            // Start creating a path
            context.beginPath();
            // draw an ellipse
            if(browserFlag == "clearForVerify")
            {
               context.rect(91, 160, 112, 145);
            }
            else if(browserFlag == "clearForRegister")
            {
			    //alert('Flag 1');
                if ($("input[name='registType']:checked").size() > 0) {
                    context.arc(380, 200, 280, 200, 10);
                   
                } else {
                    context.arc(380, 200, 280, 200, 10);
                }
            }
            // close path
            context.closePath();
            // clipping path
            context.clip();
            //draw the picture onto the canvas
            if(browserFlag == "clearForVerify")
            {
                context.drawImage(img, 12, 54, 160, 213);
            }
            else if(browserFlag == "clearForRegister")
            {
                clearInterval(baseShowImageInterval);
                if(devType == ZKDevType.Fingervein)//finger vein
                {
                    imgGif = new Image();
					imgGif.src = image2;
                    clearInterval(baseShowImageInterval);
                    baseShowImageInterval = setInterval(function () {
                        if ($("input[name='registType']:checked").size() > 0) {
                            if (fvFlag == 0) {
							// alert('Flag 2');
                                context.clearRect(320, 80, 270, 140);
                                if (flag == 0) {
                                    context.drawImage(img, 320, 80, 270, 140);
                                    flag = 1;
                                } else {
                                  context.drawImage(imgGif, 320, 80, 270, 140);
                                    // context.drawImage(imgGif, 300, 140, 240, 120);
                                    flag = 0;
                                }
                            }
                            fvFlag = 0;
                        } else {
                            context.clearRect(320, 80, 270, 140);
                            if (flag == 0) {
                              context.drawImage(img, 320, 80, 270, 140);// commented to do draw image right side thumd gif image
							  flag = 1;
							} else {
                context.drawImage(imgGif, 320, 80, 270, 140);
							  // context.drawImage(imgGif, 300, 140, 240, 120); // commented to do draw image right side thumd gif image
							  flag = 0;
							}
                        }
                    }, 700);
                }
                else
                { //alert('Flag 3');
                    if ($("input[name='registType']:checked").size() > 0) {
                        context.clearRect(320, 80, 270, 120);
                        context.drawImage(img, 320, 80, 270, 140);
                    } else {
                       context.drawImage(img, 320, 80, 270, 140);
                    }
                }
            }
            //The last stored state will be restored when restore is called
            context.restore();
        }
    }
    else if(browserFlag == "simple")
    {
        $("#showFPImageDiv").html("<img src="+imgSrc+" width='112' height='145' />");
    }
    else if(browserFlag == "verifySimple")
    {
        $("#showSeachingDiv").show();
        $("#showSeachingDiv").html("&nbsp;&nbsp;<img src=\"/public/images/searching.gif\" width='20' height='20'/></br><label style='color:yellow;align:center;font-size: 14px;'>${base_fp_waiting}</label>");
    }
    else if(browserFlag == "clearForSimple")
    {
        $("#showFPImageDiv").html("");
    }
}
/**
 * 获取指纹模板
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param paramArray 存放国际化元素的数组
 * @param flag 判断是登记和验证标记 register:登记；verification:验证
 */
function getFPTemplate(paramArray, flag)
{
    var fpTemplate = "";
    var fpTemplate12 = "";
    var fvTemplate = "";
    var collectSuccessFlag = false;
    ZKDevice.get(devType).getTemplate({
        data:{"random":new Date().getTime()},
        dataType : "json",
        async: false,
        callback:function(result){
            if(result.ret != 0) {//Acquisition failed
                //After collecting the fingerprint, render the finger
                renderAfterColl(globalContext, fpIdNum, bgColor, false);
                //Display box--collection prompt
                collectTips(globalContext, paramArray[1], "html5", "warning");
                return;
            }
            if(devType == ZKDevType.Fingerprint)//指纹
            {
                fpTemplate = result.data.template;
                // At present, the personnel module only saves the 12th version when the fingerprint is entered.
                // if(bioType.indexOf("bio") != -1)
                // {
                    fpTemplate12 = result.data.template12;
                // }
                if((fpTemplate == undefined || fpTemplate=="") && (fpTemplate12 == undefined || fpTemplate12=="")){ //采集失败
                    //After collecting the fingerprint, render the finger
                    renderAfterColl(globalContext, fpIdNum, bgColor, false);
                    //Display box--collection prompt
                    collectTips(globalContext, paramArray[1], "html5", "warning");
                    return;
                }
            } else if(devType == ZKDevType.Fingervein) {//指静脉
                fpTemplate = result.data.fingerprint.template;
            }

            collectSuccessFlag = true;
            if(flag == "register") {
                //Determine whether the finger has been fingerprinted
                var compareRet = "";
                if(getBioTemplateCount("fp"))//Fingerprint If fingerprints have been entered before, background fingerprint comparison is required
                {
                    if(fpTemplate != undefined && fpTemplate != "")
                    {
                        compareRet = fpComparision(fpTemplate, getBioTemplateDataArray("fp", fpIdNum), paramArray[3]);
                    }
                    // 1The fingerprint of version 0 is not duplicated. Verify version 12.
                    if(compareRet == ""){
                        if(fpTemplate12 != undefined && fpTemplate12 != "") {
                            compareRet = fpComparision(fpTemplate12, getBioTemplateDataArray("fp", fpIdNum), paramArray[3]);
                        }
                    }
                }
                if($.trim(compareRet) == "dllNotExist") {
                    //After collecting the fingerprint, render the finger
                    renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
                    //Display box--collection prompt
                    collectTips(globalContext, "auth_login_loadLibraryError", "html5");
                } else if(compareRet == "noFingerServer"){
                    //After collecting the fingerprint, render the finger
                    renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
                    //Display box--collection prompt
                    collectTips(globalContext, "Did not start the finger service", "html5");
                } else if(compareRet != "ok") {//此手指未采集指纹
                    if(devType == ZKDevType.Fingervein){//指静脉会采集一枚指纹三枚指静脉
                        //templateFVDataArray[templateFVDataArray.length] = fpTemplate;//指静脉采集时的指纹
                        if(result.data.fingervein.length < 3)
                        {
                            renderAfterColl(globalContext, lastFPIdNum, bgColor, false);
                            //Display box--collection prompt  采集失败
                            collectTips(globalContext, paramArray[2], "html5");
                        }
                        else
                        {
                            //After collecting the fingerprint, render the finger
                            renderAfterColl(globalContext, fpIdNum, bgColor, true);//bgColor判断
                            var fvIdNum = fpIdNum;
                            if (fpTemplate == "" && fvTemplateExit(result.data.fingervein))
                            {


                               // ========Need to replace with Confirmation box============
                                // messageBox({messageType:"confirm", text: "The fingerprint was not collected successfully. Do you want to collect it again?", callback: function(sureToSave){
                                //         if(sureToSave) {
                                //             renderAfterColl(globalContext, fvIdNum, bgColor, false);
                                //             collectTips(globalContext, "400.", "html5");
                                //             //Display box--collection prompt
                                //             drawProgressBar(globalContext, 0);
                                //         } else {
                                //             //Display box--collection prompt
                                //             collectTips(globalContext, paramArray[0], "html5");
                                //             setFVTemplate(result.data.fingervein, fvIdNum);
                                //         }
                                //     }});
                            }
                            else if(fpTemplate != "" && !fvTemplateExit(result.data.fingervein)) {

                               // ========Need to replace with Confirmation box============
                                // messageBox({messageType:"confirm", text: "The finger vein was not collected successfully. Is it collected again?", callback: function(sureToSave){
                                //         if(sureToSave) {
                                //             renderAfterColl(globalContext, fvIdNum, bgColor, false);
                                //             collectTips(globalContext, "400.", "html5");
                                //             //Display box--collection prompt
                                //             drawProgressBar(globalContext, 0);
                                //         } else {
                                //             //Display box--collection prompt
                                //             collectTips(globalContext, paramArray[0], "html5");
                                //             setBioTemplateCount("fp",fvIdNum,true);
                                //             setBioTemplate("fp",fvIdNum,0,fpTemplate,duressFingerFlag);
                                //         }
                                //     }});
                            }
                            else {
                                //Display box--collection prompt
                                collectTips(globalContext, paramArray[0], "html5");
                                setBioTemplateCount("fp",fvIdNum,true);
                                setBioTemplate("fp",fvIdNum,0,fpTemplate,duressFingerFlag);
                                setFVTemplate(result.data.fingervein, fvIdNum);
                            }
                        }
                    } else if(devType == ZKDevType.Fingerprint) {//指纹
                        var compareOneToManyRet = "ok"
                        // 与指纹服务比对
                        if(compareOneToManyFlag)
                        {
                            compareOneToManyRet = fpComparisionOneToMany(fpTemplate);
                        }
                        if(compareOneToManyRet == "ok")
                        {
                            //After collecting the fingerprint, render the finger
                            renderAfterColl(globalContext, fpIdNum, bgColor, true);//bgColor判断
                            //Display box--collection prompt
                            collectTips(globalContext, paramArray[0], "html5");

                            if(fpTemplate != undefined && fpTemplate!="")
                            {
                                setBioTemplateCount("fp",fpIdNum,true);
                                setBioTemplate("fp",fpIdNum,0,fpTemplate,duressFingerFlag,"10");
                            }
                            if(fpTemplate12 != undefined && fpTemplate12!="")
                            {
                                setBioTemplateCount("fp",fpIdNum,true);
                                setBioTemplate("fp",fpIdNum,0,fpTemplate12,duressFingerFlag,"12");
                            }
                        }
                        else
                        {
                            renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
                            //该指纹已存在
                            //Display box--collection prompt  采集失败
                            collectTips(globalContext, paramArray[4], "html5");
                        }
                    }
                } else {
                    //After collecting the fingerprint, render the finger
                    renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
                    //Please don't repeat input fingerprint!
                    //Display box--collection prompt  采集失败
                    collectTips(globalContext, paramArray[2], "html5");
                }
            } else if(flag == "verification") {
                verifyFlag = false;
                  //指纹比对
                  ZK.fpComparison(fpTemplate);
              }
              $("#bioTemplateJson").val(JSON.stringify(bioTemplateJson));
              setBioTemplateJsonValue(bioTemplateJson);
              props.getData(bioTemplateJson)
          }
          
      });
      return collectSuccessFlag;  
}

function fvTemplateExit(fvTemplateArray) {
    var exitTemplate = false;
    for (var i = 0; i < fvTemplateArray.length; i++) {
        if (fvTemplateArray[i] != "") {  
            exitTemplate = true;
            break;
        }
    }
    return exitTemplate;
}

function setFVTemplate(fvTemplateArray, fvIdNum) {
    setBioTemplateCount("fv",fvIdNum,true);
    for (var i = 0; i< fvTemplateArray.length; i++) {
        setBioTemplate("fv",fvIdNum,i,fvTemplateArray[i].template,duressFingerFlag);
    }
}

/**
 * 发送请求到后台，进行比对
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param fpTemplate 指纹模板
 * @param templateArray 指纹模板数组
 * @param errorMsg ajax请求报错，错误信息
 */
function fpComparision(fpTemplate, templateArray, errorMsg)
{
    var ret = "";
    var templates = templateArray.toString();
    var comparisionUrl = "/baseBioVerify.do?fpComparison";
    // 与指纹服务比对
    if(compareOneToManyFlag)
    {
        comparisionUrl = "/authBioVerify.do?fpComparison";
    }


    $.ajax( {
        type : "POST",
        url : comparisionUrl,
        contentType : "application/x-www-form-urlencoded;charset=UTF-8",
        data:{
            "verifyTemplate":fpTemplate,
            "templates":templates
        },
        dataType : "json",
        async: false,
        success : function(result)
        {
            if(result.ret == "ok")
            {
                ret = "ok";
            }
            if(result.msg == "noFingerServer")
            {
                ret = "noFingerServer";
            }
            if(result.msg == "dllNotExist")
            {
                ret = "dllNotExist";
            }
        }
    });
    return ret;
}

function fpComparisionOneToMany(fpTemplate)
{
    var ret = "";
    $.ajax({
        type : "POST",
        url : "/authBioVerify.do?fpComparisonOneToMany",
        contentType : "application/x-www-form-urlencoded;charset=UTF-8",
        data:{
            "verifyTemplate":fpTemplate,
        },
        dataType : "json",
        async: false,
        success : function(result)
        {
            ret = result.ret;
        }
    });
    return ret;
}

/**
 * ----------------------------------------------------------common end--------------------------------------------------------------
 */
/**
 * ----------------------------------------------------------html5 start-------------------------------------------------------------
 */
/**
 * 初始化绘画手指、手掌、圆弧的起始坐标,并做成json格式
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
 function initCoordJson() {
  
    var coordJson = [
      { num: 0, coord: { x: x + 3, y: y - 37 } },
      { num: 1, coord: { x: x + 25, y: y - 37 } },
      { num: 2, coord: { x: x + 47, y: y - 34 } },
      { num: 3, coord: { x: x + 67, y: y - 26 } },
      { num: 4, coord: { x: x + 77, y: y + 18 } },
      { num: 5, coord: { x: x + 153, y: y + 34 } },
      { num: 6, coord: { x: x + 159, y: y - 19 } },
      { num: 7, coord: { x: x + 177, y: y - 30 } },
      { num: 8, coord: { x: x + 198, y: y - 36 } },
      { num: 9, coord: { x: x + 220, y: y - 36 } },
      { num: 10, coord: { x: x, y: y } },
      { num: 11, coord: { x: x + 170, y: y + 12 } },
      { num: 12, coord: { x: x + 210, y: y - 346 } },
    ];
    return coordJson;
    
  }
 
/**
 * 采集完指纹后渲染手指
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param num 当前需要渲染的手指编号
 * @param fillColor 采集完后填充颜色
 * @param successOrNot 采集是否成功--布尔值 true:采集成功；false:采集失败
 */
function renderAfterColl(context, num, fillColor, successOrNot)
{
    var canvas;
  
    if (activeTab == 1) {
      canvas = document.getElementById("newCanvas1");
    } else if (activeTab == 2) {
      canvas = document.getElementById("newCanvas2");
    } else if (activeTab == 0)  {
      canvas = document.getElementById("newCanvas");
    }
	
	
    if(canvas == null)
        return;
    var localContext = canvas.getContext("2d");
    var coordArray = new Array();
    //Initialize the starting coordinates and return the data in json format
    var coordJson = initCoordJson();
    //In the page, click delete
    if(num == null)
    {
        num = fpIdNum;
    }
    //The finger number clicked is equal to num in json
   
    if(coordJson[num].num == num)
    {
        //Initialize the coordinate array and draw the finger
        initCoordAndDrawFinger(context, coordArray, coordJson[num].coord.x, coordJson[num].coord.y, num);
    }

    //Successful acquisition, fill color (red, green)
    if(successOrNot)
    {
        if(duressFingerFlag)
        {
            localContext.fillStyle = "red";//fillColor
            localContext.fill();
            fpModifyFlag = true;
        }
        else
        {
            localContext.fillStyle = "rgb(122,193,66)";//fillColor
            localContext.fill();
            fpModifyFlag = true;
        }
    }
    else
    {
        //采集失败，填充背景色--消除颜色(黄)
        localContext.fillStyle = fillColor;
        localContext.fill();
    }
}
/**
 * 坐标点对象
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 */
var Coord = function(x, y)
{
    this.x = x;
    this.y = y;
}

/**
 * 初始化坐标数组和绘画手指--获取当前的context
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param pointArray 坐标点数组
 * @param x,y 绘画当前手指的起始坐标
 * @param num 手指标记
 */
function initCoordAndDrawFinger(context, coordArray, x, y, num)
{
    coordArray = initCoordArray(coordArray, x, y, num);
    new renderFinger(context, coordArray).drawFinger(strokeStyle, fingerBorderColor);
}
/**
 * 绘画手指
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param pointArray 坐标点数组
 * @param renderFlag 渲染标记 stroke:绘画边线；fill:填充
 * @param color 渲染颜色
 */
var renderFinger = function(context, pointArray)
{
    this.context = context;
    this.pointArray = pointArray;
    this.isClick = false;
    this.drawFinger = function(renderFlag, color)
    {
        if(renderFlag == "stroke")
        {
            this.context.strokeStyle = color;
        }
        else if(renderFlag == "fill")
        {
            this.context.fillStyle = color;
        }
        this.context.lineWidth = 1;
        this.context.beginPath();
        for(var i=0; i<this.pointArray.length; i++)
        {
            if(i == 0)
            {
                this.context.moveTo(this.pointArray[0].x, this.pointArray[0].y);
            }
            else
            {
                this.context.lineTo(this.pointArray[i].x, this.pointArray[i].y);
            }
        }
        if(renderFlag == "stroke")
        {
            this.context.stroke();
        }
        else if(renderFlag == "fill")
        {
            this.context.fill();
        }
    };
};
/**
 * 绘画手掌
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param pointArray 坐标点数组
 * @param color 渲染颜色
 */
var renderHand = function(context, pointArray)
{
    this.context = context;
    this.pointArray = pointArray;
    this.isClick = false;
    this.drawHand = function(color)
    {
        this.context.strokeStyle = color;
        this.context.lineWidth = 1;
        this.context.beginPath();
        for(var i=0; i<this.pointArray.length; i++)
        {
            if(i == 0)
            {
                this.context.moveTo(this.pointArray[0].x, this.pointArray[0].y);
            }
            else
            {
                this.context.lineTo(this.pointArray[i].x, this.pointArray[i].y);
            }
        }
        this.context.stroke();
    };
};
/**
 * 将绘画的坐标点放入数组
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param coordArray 传入的数组，放入坐标后，返回
 * @param x, y 绘画手指的起点的坐标
 * @param num 手指、手掌编号0-9：手指编号；10：左手掌，11：右手掌,12:圆弧。
 */
function initCoordArray(coordArray, x, y, num)
{
    if(num == 0)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x + 2, y - 35);
        coordArray[2] = new Coord(x + 5, y - 40);
        coordArray[3] = new Coord(x + 11, y - 42);
        coordArray[4] = new Coord(x + 16, y - 40);
        coordArray[5] = new Coord(x + 18, y - 35);
        coordArray[6] = new Coord(x + 18, y + 1);
        coordArray[7] = new Coord(x + 15, y + 5);
        coordArray[8] = new Coord(x + 9, y + 7);
        coordArray[9] = new Coord(x + 3, y + 5);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 1)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x + 8, y - 50);
        coordArray[2] = new Coord(x + 12, y - 54);
        coordArray[3] = new Coord(x + 19, y - 55);
        coordArray[4] = new Coord(x + 22, y - 53);
        coordArray[5] = new Coord(x + 24, y - 49);
        coordArray[6] = new Coord(x + 18, y + 1);
        coordArray[7] = new Coord(x + 15, y + 6);
        coordArray[8] = new Coord(x + 8, y + 7);
        coordArray[9] = new Coord(x + 3, y + 4);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 2)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x + 14, y - 54);
        coordArray[2] = new Coord(x + 16, y - 57);
        coordArray[3] = new Coord(x + 23, y - 58);
        coordArray[4] = new Coord(x + 28, y - 55);
        coordArray[5] = new Coord(x + 29, y - 50);
        coordArray[6] = new Coord(x + 17, y + 4);
        coordArray[7] = new Coord(x + 13, y + 8);
        coordArray[8] = new Coord(x + 6, y + 9);
        coordArray[9] = new Coord(x + 1, y + 5);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 3)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x + 19, y - 37);
        coordArray[2] = new Coord(x + 21, y - 39);
        coordArray[3] = new Coord(x + 28, y - 39);
        coordArray[4] = new Coord(x + 32, y - 36);
        coordArray[5] = new Coord(x + 33, y - 31);
        coordArray[6] = new Coord(x + 17, y + 6);
        coordArray[7] = new Coord(x + 12, y + 10);
        coordArray[8] = new Coord(x + 6, y + 10);
        coordArray[9] = new Coord(x + 1, y + 6);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 4){
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x + 30, y - 18);
        coordArray[2] = new Coord(x + 34, y - 17);
        coordArray[3] = new Coord(x + 37, y - 14);
        coordArray[4] = new Coord(x + 39, y - 11);
        coordArray[5] = new Coord(x + 39, y - 8);
        coordArray[6] = new Coord(x + 38, y - 6);
        coordArray[7] = new Coord(x + 12, y + 15);
        coordArray[8] = new Coord(x + 8, y + 17);
        coordArray[9] = new Coord(x + 2, y + 14);
        coordArray[10] = new Coord(x - 2, y + 8);
        coordArray[11] = new Coord(x, y);
    }
    else if(num == 5)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x - 26, y - 21);
        coordArray[2] = new Coord(x - 27, y - 24);
        coordArray[3] = new Coord(x - 26, y - 30);
        coordArray[4] = new Coord(x - 21, y - 34);
        coordArray[5] = new Coord(x - 16, y - 34);
        coordArray[6] = new Coord(x + 12, y - 18);
        coordArray[7] = new Coord(x + 15, y - 10);
        coordArray[8] = new Coord(x + 13, y - 3);
        coordArray[9] = new Coord(x + 7, y + 1);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 6)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x - 17, y - 46);
        coordArray[2] = new Coord(x - 17, y - 50);
        coordArray[3] = new Coord(x - 13, y - 56);
        coordArray[4] = new Coord(x - 6, y - 56);
        coordArray[5] = new Coord(x - 3, y - 54);
        coordArray[6] = new Coord(x + 15, y - 11);
        coordArray[7] = new Coord(x + 15, y - 4);
        coordArray[8] = new Coord(x + 11, y + 2);
        coordArray[9] = new Coord(x + 4, y + 2);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 7)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x - 12, y - 54);
        coordArray[2] = new Coord(x - 10, y - 58);
        coordArray[3] = new Coord(x - 5, y - 62);
        coordArray[4] = new Coord(x + 1, y - 61);
        coordArray[5] = new Coord(x + 4, y - 58);
        coordArray[6] = new Coord(x + 18, y - 4);
        coordArray[7] = new Coord(x + 16, y + 1);
        coordArray[8] = new Coord(x + 11, y + 5);
        coordArray[9] = new Coord(x + 5, y + 4);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 8)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x - 5, y - 50);
        coordArray[2] = new Coord(x - 2, y - 54);
        coordArray[3] = new Coord(x + 3, y - 57);
        coordArray[4] = new Coord(x + 9, y - 55);
        coordArray[5] = new Coord(x + 11, y - 52);
        coordArray[6] = new Coord(x + 18, y - 1);
        coordArray[7] = new Coord(x + 14, y + 4);
        coordArray[8] = new Coord(x + 9, y + 6);
        coordArray[9] = new Coord(x + 4, y + 5);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 9)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x, y - 37);
        coordArray[2] = new Coord(x + 3, y - 41);
        coordArray[3] = new Coord(x + 7, y - 43);
        coordArray[4] = new Coord(x + 13, y - 41);
        coordArray[5] = new Coord(x + 15, y - 37);
        coordArray[6] = new Coord(x + 17, y + 1);
        coordArray[7] = new Coord(x + 15, y + 3);
        coordArray[8] = new Coord(x + 10, y + 6);
        coordArray[9] = new Coord(x + 3, y + 4);
        coordArray[10] = new Coord(x, y);
    }
    else if(num == 10)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x + 2, y - 8);
        coordArray[2] = new Coord(x + 6, y - 16);
        coordArray[3] = new Coord(x + 13, y - 23);
        coordArray[4] = new Coord(x + 27, y - 27);
        coordArray[5] = new Coord(x + 37, y - 25);
        coordArray[6] = new Coord(x + 43, y - 23);
        coordArray[7] = new Coord(x + 64, y - 16);
        coordArray[8] = new Coord(x + 69, y - 11);
        coordArray[9] = new Coord(x + 73, y - 3);

        coordArray[10] = new Coord(x + 73, y + 10);
        coordArray[11] = new Coord(x + 71, y + 18);
        coordArray[12] = new Coord(x + 57, y + 40);
        coordArray[13] = new Coord(x + 50, y + 46);
        coordArray[14] = new Coord(x + 41, y + 49);
        coordArray[15] = new Coord(x + 34, y + 49);
        coordArray[16] = new Coord(x + 14, y + 43);
        coordArray[17] = new Coord(x + 10, y + 41);
        coordArray[18] = new Coord(x + 6, y + 36);
        coordArray[19] = new Coord(x + 2, y + 29);
        coordArray[20] = new Coord(x, y);
    }
    else if(num == 11)
    {
        coordArray[0] = new Coord(x, y);
        coordArray[1] = new Coord(x - 2, y - 10);
        coordArray[2] = new Coord(x + 1, y - 20);
        coordArray[3] = new Coord(x + 14, y - 31);
        coordArray[4] = new Coord(x + 47, y - 39);
        coordArray[5] = new Coord(x + 55, y - 38);
        coordArray[6] = new Coord(x + 61, y - 34);
        coordArray[7] = new Coord(x + 68, y - 26);
        coordArray[8] = new Coord(x + 72, y - 16);
        coordArray[9] = new Coord(x + 72, y + 13);

        coordArray[10] = new Coord(x + 68, y + 22);
        coordArray[11] = new Coord(x + 62, y + 29);
        coordArray[12] = new Coord(x + 60, y + 30);
        coordArray[13] = new Coord(x + 39, y + 36);
        coordArray[14] = new Coord(x + 34, y + 36);
        coordArray[15] = new Coord(x + 20, y + 33);
        coordArray[16] = new Coord(x + 16, y + 29);
        coordArray[17] = new Coord(x, y);
    }
    else if(num == 12)
    {
        coordArray[0] = new Coord(x - 10, y);
        coordArray[1] = new Coord(x + 212, y);
        coordArray[2] = new Coord(x + 212, y + 129);
        coordArray[3] = new Coord(x + 201, y + 130);
        coordArray[4] = new Coord(x + 191, y + 131);
        coordArray[5] = new Coord(x + 174, y + 131);
        coordArray[6] = new Coord(x + 159, y + 129);
        coordArray[7] = new Coord(x + 142, y + 127);
        coordArray[8] = new Coord(x + 133, y + 125);
        coordArray[9] = new Coord(x + 114, y + 120);

        coordArray[10] = new Coord(x + 97, y + 113);
        coordArray[11] = new Coord(x + 86, y + 108);
        coordArray[12] = new Coord(x + 72, y + 100);
        coordArray[13] = new Coord(x + 52, y + 87);
        coordArray[14] = new Coord(x + 40, y + 76);
        coordArray[15] = new Coord(x + 29, y + 64);
        coordArray[16] = new Coord(x + 16, y + 48);
        coordArray[17] = new Coord(x + 5, y + 30);
        coordArray[18] = new Coord(x - 10, y);
    }
    return coordArray;
}
/**
 * 绘画页面右上角的圆弧并填充颜色
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param x, y 绘画圆弧的起点的坐标
 * @param color 绘画多边形的填充颜色--右上角的圆弧区域
 */
var FillArc = function(context, pointArray)
{
    this.context = context;
    this.pointArray = pointArray;
    this.drawArc = function(color)
    {
        this.context.fillStyle = color;
        this.context.beginPath();
        for(var i=0; i<this.pointArray.length; i++)
        {
            if(i == 0)
            {
                this.context.moveTo(this.pointArray[0].x, this.pointArray[0].y);
            }
            else
            {
                this.context.lineTo(this.pointArray[i].x, this.pointArray[i].y);
            }
        }
        //this.context.stroke();
        this.context.fill();
    };
};

//清除画布内容
CanvasRenderingContext2D.prototype.clear =
    CanvasRenderingContext2D.prototype.clear || function (preserveTransform) {
        if (preserveTransform) {
            this.save();
            this.setTransform(1, 0, 0, 1, 0, 0);
        }

        this.clearRect(0, 0, this.canvas.width, this.canvas.height);

        if (preserveTransform) {
            this.restore();
        }
    };

/**
 * 显示框--显示采集次数、采集成功、失败等信息
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param context 2d画布上下文
 * @param text  显示信息内容
 * @param browserFlag 浏览器标记或比对验证标记 simple:简易版本，表示是ie浏览器；html5:表示支持html5的浏览器
 * --verification:指纹验证标记
 */
 $(function() {
  $.fn.size = function() {
      return this.length;
  }
});

function collectTips(context, text, browserFlag, type)
{

  if(document.querySelector("input[name='registType']")!=null){
    var color;
    if(document.querySelector("input[name='registType']").checked == true && document.querySelector("input[name='registType']:checked").value  == "1")
    {
        return;
    }
    if(browserFlag == "simple")
    {
        if(type=="warning")
        {
            color = "color:#e57a14";
        }
        else
        {
            color = "#5A5A5A";  // commented change scan alert message color;
        }
        document.getElementById("showCollInfoDiv").innerHTML = "<span style='" + color + "; font-size: 12px;word-break: break-all; word-wrap: break-word;'>" + text + "</span>";
        // console.log("collectTips=======" + text);
    }
    else if(browserFlag == "html5")
    {
        context.fillStyle = "#ffffff"; //bgColor; // commented change color as darkmode
        //修改在非中文环境下文本显示排版问题   modified by 陈彩云  20160526
        // context.fillRect(0, 0, 350, 16);  // commented ...made chages for scan left:3 position ...  bg color
		
		// alert("Flag 4");
        // if($("input[name='registType']:checked").size() > 0 && $("input[name='registType']:checked").val() == "0")
        // {
        //     context.fillRect(75, 118, 350, 16);
        // }
        // else
        // {
         context.fillRect(0, 0, 350, 16);  // commented ...made chages for scan left:3 position ...  bg color
        // }
        //修改防假提示语颜色   modified by 陈彩云  20160616
        //        if(text == I18n.getValue("base_fp_fakeFinger"))
      if (text == "base_fp_fakeFinger") {
            if(type=="warning") {
                context.fillStyle = "#e57a14";
            } else {
                context.fillStyle =  "#5A5A5A";  // commented change scan alert message color;
            }
        } else {
        if (type == "warning") {
          context.fillStyle = "#e57a14";
        } else {
          context.fillStyle = "#5A5A5A";  // commented change scan alert message color;
        }
      }
        context.font ="12px Arial,微软雅黑";
        //context.shadowColor = 'white';
        //context.shadowBlur = 10;
        //context.strokeText(text, 230, 30);
        context.textAlign = "end";
      //   if(document.querySelector("input[name='registType']").checked == true && document.querySelector("input[name='registType']:checked").value  == "0")
      // {
      //      context.fillText(text, 120, 70);
      // }
      //  else
      //  {
      context.fillText(text, 300, 10);  // commented ...made chages for scan left:3 position ...  message
      //  }
    }
    else if(browserFlag == "verification")
    {
        //#6BA5D7
        if(type=="warning")
        {
            context.fillStyle = "#e57a14";
        }
        else
        {
             context.fillStyle = "#5A5A5A"; //#6BA5D7 // commented change scan alert message color;
        }
        context.fillRect(0, 0, 350, 16); 
        //获取canvas对象
        var canvas;

      if (activeTab == 1) {
        canvas = document.getElementById("newCanvas1");
      } else if (activeTab == 2) {
        canvas = document.getElementById("newCanvas2");
      } else if (activeTab == 0)  {
        canvas = document.getElementById("newCanvas");
      }
	  
//		canvas.width = canvas.width;
        //返回一个文本的度量信息对象metrics
        var metrics = context.measureText(text);
        //文本宽度
        var textWidth = metrics.width;
        //canvas宽度

        var canvasWidth;
        canvas != null?canvasWidth = canvas.width:canvasWidth = 640;
        //文本开始x坐标
        var x = textWidth/2 + (canvasWidth - textWidth)/2;


        //context.fillStyle = bgColor;
        // context.fillRect(0, 18, 445, 16);

        if(type=="warning")
        {
            context.fillStyle = "#e57a14";
        }
        else
        {
            context.fillStyle = "rgb(122,193,66)";
        }
        context.font ="24px Arial,微软雅黑";
        context.textAlign = "center";
        //自动换行
        autoWordBreak(context,text,canvasWidth,x);
        context.restore();
    }
    else if(browserFlag == "verifyForSimple")
    {
        $("#showCollInfoDiv").html("<span style='color:yellow;align:center;font-size: 18px;word-break: break-all; word-wrap: break-word;'>"+text+"</span>");
    }

  }
}

/**
 * 画布文本自动换行
 * @author chenpf
 * @create 2015-03-10 16:56:31 pm
 * @param context 2d画布上下文
 * @param text  显示信息内容
 * @param CWidth 画布宽度
 * @param x 文本X坐标值
 *
 */
function autoWordBreak(context,text,CWidth,x){
    context.clear();
    var rownum = CWidth / 10;
	// alert('Flag 5');
    // var len = strlen(text);
	var len = text.length;
    if (rownum > len)
    {
        context.fillText(text, x, 30);
    }
    else
    {
        var endInd = rownum<text.length?rownum:text.length;
        var beginInd = 0;
        var endTemp=0;
        for (var i = 0; i <= text.length / rownum; i++)
        {
            endTemp = text.substr(beginInd, endInd).lastIndexOf(" ");
            if(endTemp!=-1)
                endInd=beginInd+endTemp;
            context.fillText(text.substr(beginInd, endInd), x, (i + 1) * 30);
            beginInd = endInd+1;
            if(beginInd>=text.length)
                break;
            endInd = beginInd + rownum;
        }
    }
}

function drawProgressBar(context, collCount)
{
    var x = 185;
    var y = 30;
    var width = 90;
    var height = 20;
    if (
      document.querySelector("input[name='registType']").checked == true &&
      document.querySelector("input[name='registType']:checked").value == "1"
    ) {
      return;
    } else if (
      document.querySelector("input[name='registType']").checked == true &&
      document.querySelector("input[name='registType']:checked").value != "1"
    ) {
      y = 30; // commented  position progress bar
    }
    context.fillStyle = "#fff0"; // commented position progress bar
    context.fillRect(x, y, width, height);
    if(collCount == 0)
    {
        context.fillStyle = "rgb(175,181,185)";
        context.fillRect(x + 4, y + 2, width - 52, height - 4);
        context.fillRect(x + 46, y + 2, width -52, height - 4);
        context.fillRect(x + 86, y + 2, width - 52, height - 4);
    }
    else if(collCount == 1)
    {
        context.fillStyle = "rgb(122,193,66)";
        context.fillRect(x + 4, y + 2, width - 52, height - 4);
        context.fillStyle = "rgb(175,181,185)";
        context.fillRect(x + 46, y + 2, width - 52, height - 4);
        context.fillRect(x + 86, y + 2, width - 52, height - 4);
    }
    else if(collCount == 2)
    {
        context.fillStyle = "rgb(122,193,66)";
        context.fillRect(x + 4, y + 2, width - 52, height - 4);
        context.fillRect(x + 46, y + 2, width - 52, height - 4);
        context.fillStyle = "rgb(175,181,185)";
        context.fillRect(x + 86, y + 2, width - 52, height - 4);
    }
    else if(collCount == 3)
    {
        context.fillStyle = "rgb(122,193,66)";
        context.fillRect(x + 4, y + 2, width - 52, height - 4);
        context.fillRect(x + 46, y + 2, width - 52, height - 4);
        context.fillRect(x + 86, y + 2, width - 52, height - 4);
    }
}
  function drawPalmPrint(context) {
    showImage(context, image4, "clearForRegister"); // commented to shwo image on thrid tab
     collectTips(context, "Please click on the palm to start collecting", "html5");//Display box--collection prompt
    if (isBioTemplateExists("palm", 0, 0)) {
      //        $("#startPalmId").hide();
      //        $("#endPalmId").show();
            document.getElementById("startPalmId").style.display = "none";
            document.getElementById("endPalmId").style.display = "block";
            document.getElementById("endPalmId").removeAttribute('hidden');
    }

    //将确定按钮置灰
    //    $("#submitButtonId").attr("disabled", true);
    //   document.getElementById("submitButtonId").disabled = true;
  }
  function cancelPalmPrint() {
    //    $("#startPalmPrintDivId").show();
    //    $("#endPalmPrintDivId").hide();
       document.getElementById("startPalmPrintDivId").style.display = "block";
      document.getElementById("endPalmPrintDivId").style.display = "none";
    collectTips( globalContext, "Please click on the palm to start collecting", "html5" );
    cancelPalmPrintRegister();
  }

function cancelPalmPrintRegister()
{
    //将定时器的递归调用关闭
    clearTimeout(bioTemplateTimer);
    //取消采集
    ZKDevice.get(devType).cancelCapture({
        data:{"random":new Date().getTime()},
        async : false,
        callback:function(result){}
    });
}
  function startPalmPrintRegister() {

    // $("#startPalmPrintDivId").hide();
    // $("#endPalmPrintDivId").show();
    cancelPalmPrintRegister();
    //按压指纹剩余次数
    //Display box--collection prompt
    globalContext = globalContextValue;
    devType = devTypeValue;
    bioTemplateJson = bioTemplateJsonValue;
    
    collectTips(globalContext, "Please place your palm correctly", "html5");
    //掌纹采集
    ppCollection();
  }

/**
 * 采集指纹 type:0 表示比对采集， type:1表示登记采集
 * @author wenxin
 * @create 2013-05-13 10:18:31 am
 * @param context 2d画布上下文
 */
  function ppCollection() {
    //alert("devType===="+devType);
    bioTemplateJson = bioTemplateJsonValue;
        ZKDevice.get(devType).beginCapture({
            data:{type:2, "random":new Date().getTime()},
            callback:function(result){
                //返回码
                var ret = result.ret;
                //接口调用成功返回时
                if(ret == 0)
                {
                    collectTips(globalContext, "Collecting...", "html5");
                    //检查采集次数、显示图像
                    checkPpCollCount();
                }
                else if(ret == -2 || ret == -6)
                {
                    collectTips(globalContext, "The palm collector is not detected", "html5", "warning");
                }
            }
        });
  }

/**
 * 检查采集次数
 * @author wenxin
 * @create 2013-05-22 09:24:31 am
 * @param collCount 采集次数
 */

const [palmSuccess,setPalmSuccess] = useState(false)
function checkPpCollCount() {
  bioTemplateJson = bioTemplateJsonValue;
  // console.log("bioTemplateJson====",bioTemplateJson);
        var base64PpImg = "";
        //返回码
        var ret = null;
            ZKDevice.get(devType).getImage({
                dataType : "json",
                data:{"random":new Date().getTime()},
                async: false,
                callback:function(result){
                    //指纹采集次数
                    var collCount = 0;
                    ret = result.ret;
                    if(ret == 0)
                    {
                        collCount = result.data.enroll_index;
                        collectTips(globalContext, "Collecting...", "html5");
                        base64PpImg = result.data.image;
                    }
                    else if(ret == -23)
                    {
                        collectTips(globalContext, "No palm detected", "html5", "warning");
                    }
                    //显示掌纹图像
                    if(base64PpImg != "")
                    {
                        showImage(globalContext, base64PpImg, "html5");
                        base64PpImg = "";
                        //清空图像
                        setTimeout(function(){clearImageData();}, 1000);
                    }
                    if(collCount != 5)
                    {
                        //定时器
                        bioTemplateTimer = setTimeout(function(){checkPpCollCount();}, 1000);
                    }
                    else
                    {
                        //存放国际化元素数组
                       
                        var paramArray = new Array();
                        paramArray[0] = "Successfully registered palm";//成功登记掌纹
                        paramArray[1] = "Collect failed, please re-register.";//请重新采集
                        //获取指纹模板
                        getPPTemplate(paramArray, "register");
                        return collCount; 
                    }
                }
            });
      }
/**
 * 获取指纹模板
 * @author <a href="mailto:juvenile.li@zkteco.com">juvenile.li</a>
 * @date 2018/6/25 22:06
 * @param paramArray 存放国际化元素的数组
 * @param flag 判断是登记和验证标记 register:登记；verification:验证
 */
function getPPTemplate(paramArray, flag)
{
    var ppTemplate = "";
    var collectSuccessFlag = false;
    ZKDevice.get(devType).getTemplate({
        data:{"random":new Date().getTime()},
        dataType : "json",
        async: false,
        callback:function(result){
           // $("#startPalmPrintDivId").show();
           // $("#endPalmPrintDivId").hide();
            if(result.ret != 0) {//采集失败
                //Display box--collection prompt
                collectTips(globalContext, paramArray[1], "html5", "warning");
                return;
            }
            // $("#startPalmId").hide();
            // $("#endPalmId").show();
 			      document.getElementById("startPalmId").style.display = "none";
            document.getElementById("endPalmId").style.display = "block";
            document.getElementById("endPalmId").removeAttribute('hidden');

            setPalmSuccess(paramArray[0] != '' ? true : false)
            collectTips(globalContext, paramArray[0], "html5");
            
            ppTemplate = result.data.template;
            // var biotype = result.data.biotype;
            // biotype = 8;
            // bioTemplateJson["palmCount"] = 1;
            // setBioTemplate("palm",0,0,ppTemplate,false, biotype);
            setPPTemplate(result.data, 0);
            collectSuccessFlag = true;
            $("#bioTemplateJson").val(JSON.stringify(bioTemplateJson));
            setBioTemplateJsonValue(bioTemplateJson);
            props.getData(bioTemplateJson)
           // $("#submitButtonId").attr("disabled", false);
        }
    });
    return collectSuccessFlag;
}
function deletePPTemplate() {	

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmModal
          open={true}
          text={'Are you sure do you want to delete Palm?'}
          onConfirm={() => {
          
          delBioTemplate("palm",0);	
          document.getElementById("endPalmId").style.display = "none";	
          document.getElementById("startPalmId").style.display = "block";	

          toast.success('Palm deleted successfully')
          onClose();
          }}
          onClose={() => onClose()}
        />
      );
    },
  });

  // alert()	
  // setModalStatePersonDetails(true)	
  // setStatusPersonDetails('confirm')	
  // setStatusTitle('Do you want to delete the current palm?')	
  // setConfirmText('Delete Palm')	
	//取消采集	
	//cancelPalmPrintRegister();	
  	
  //  ========Need to replace with Confirmation box============	
		// messageBox({	
		// 	messageType : "confirm",	
		// 	text : "Delete the current palm?",//删除当前选中的指纹吗?	
		// 	callback : function(result){	
		// 		if (result)	
		// 		{	
		// 			// $("#endPalmId").hide();	
		// 			// $("#startPalmId").show();	
					// delBioTemplate("palm",0);	
          // document.getElementById("endPalmId").style.display = "none";	
          // document.getElementById("startPalmId").style.display = "block";	

          // toast.success('Palm deleted successfully')
  // setModalStatePersonDetails(true);	
  // setStatusPersonDetails('successPerson');	
  // setErrorMsg('Palm deleted successfully');	
// setTimeout(() => {	
//   setModalStatePersonDetails(false);	
//   setStatusPersonDetails('');	
//   setErrorMsg('');	
// }, 2000);
		
		// 			// $("#submitButtonId").attr("disabled", false);	
		// 			// document.getElementById("submitButtonId").disabled == false	
		// 		}	
		// 	}	
    //   // collectTips	
		// });	
  }	

  // ===========================handleConfirm STart================================
  const handleConfirm = () => {
    var valueContext = deleteContextfinger;
    var valueGlobal = deleteGlobalfinger;
        if (confirmText == 'Delete Finger') {
          delFPData(true, valueContext, "html5");
          collectTips(valueGlobal, "Please select a finger.", "html5");
          setTimeout(() => {
            setModalStatePersonDetails(false);
            setErrorMsg('');
          }, 500);
      setTimeout(() => {
        setModalStatePersonDetails(true);
        setStatusPersonDetails('successPerson');
        setErrorMsg('Finger print deleted successfully');
      }, 1200);
    }
    if (confirmText == 'Delete Palm') {
      delBioTemplate("palm", 0);
      setTimeout(() => {
        cancelPalmPrintRegister();
        document.getElementById("endPalmId").style.display = "none";
        document.getElementById("startPalmId").style.display = "block";
        setModalStatePersonDetails(false);
        setErrorMsg('');
      }, 100);
      setTimeout(() => {
        setModalStatePersonDetails(true);
        setStatusPersonDetails('successPerson');
        setErrorMsg('Palm deleted successfully');
      }, 300);
      setTimeout(() => {
        setModalStatePersonDetails(false);
        setErrorMsg('');
      }, 2000);
        }
      }
 // ===========================handleConfirm End================================
function setPPTemplate(ppTemplateArray, ppIdNum) {
    setBioTemplateCount("palm",ppIdNum,true);
    for (var i = 0; i< ppTemplateArray.length; i++) {
        var version = ppTemplateArray[i].engversion;
        if(version.indexOf(".0") != -1)
        {
            version = version.split(".0")[0];
        }
        setBioTemplate("palm",ppIdNum,i,ppTemplateArray[i].template,false, version);
    }
}
  // =============================baseFPregister.js End====================================

  //绘画双手的起点横坐标
  var x = 5;
  //绘画双手的起点纵坐标
  var y = 175;
  //存放画手指函数的数组
  var fingerList = [];
  //保存当前正在采集的手指标记--删除时，消除当前正在采集的手指颜色时用到
  var lastFPIdNum = null;
  //全局2d画笔
  var globalContext = null;
  //绘画手指的边框颜色
  var fingerBorderColor = "#0F5A9C";

  //页面右上角圆弧的背景色
  //var arcBgColor = "rgb(243, 245,240)";
  //页面背景色
  var bgColor = "rgb(243, 245, 240)";

  var bgColorScans = ''
  //绘画的图形边框样式--边线绘图
  var strokeStyle = "stroke";
  //绘画的图形填充样式--填充绘图
  var fillStyle = "fill";
  //点击手指 的颜色
  var fillFingerColor = "#F2824C";
  //判断是否修改了数据(包括新增和删除)
  var fpModifyFlag = false;

  /**
   * 画椭圆 -- 给context添加绘画椭圆的属性
   * @author wenxin
   * @create 2013-05-15 10:11:21 am
   * @param x, y 椭圆定位的坐标
   * @param width, height 椭圆的宽度和高度
   */
  CanvasRenderingContext2D.prototype.rect = function(x, y, width, height) {
    var k = width / 0.75 / 2,
      w = width / 2,
      h = height / 2;
    this.strokeStyle = bgColor;
    this.beginPath();

    this.moveTo(x, y - h);
    this.bezierCurveTo(x + k, y - h, x + k, y + h, x, y + h);
    this.bezierCurveTo(x - k, y + h, x - k, y - h, x, y - h);
    this.closePath();
    this.stroke();
    return this;
  };

  /**
   * 查动态库连接回调函数
   * @author wenxin
   */
  function getDLLConnectCallBack() {
    if (globalContext == null) {
      if (activeTab == 1) {
        globalContext = document.getElementById("newCanvas1").getContext("2d");
      } else if (activeTab == 2) {
        globalContext = document.getElementById("newCanvas2").getContext("2d");
      } else if (activeTab == 0)  {
        globalContext = document.getElementById("newCanvas").getContext("2d");
      }
      setGlobalContextValue(globalContext);
    }
  }
  /**
   * 采集指纹
   * @author wenxin
   * @create 2013-05-13 10:18:31 am
   * @param context 2d画布上下文
   */
   function fpCollection(context) {
       ZKDevice.get(devType).beginCapture({
           data:{type:1, "random":new Date().getTime()},
           callback:function(result){
               //返回码
               var ret = null;
               ret = result.ret;
               //接口调用成功返回时
               if(ret == 0) {
                   //检查采集次数、显示图像
                   checkCollCount();
               } else if(ret == -2001) {
                   if(devType == ZKDevType.Fingerprint) {//指纹 
                       collectTips(globalContext, "fingerprint Connection fail...", "html5", "warning");
                   }  else {
                       collectTips(globalContext, "fingervein Connection fail...", "html5", "warning");
                   }
               } else if(ret == -2) {
// 				显示框--采集提示
                   collectTips(globalContext, "fingervein Connection fail...", "html5", "warning");
               } else if(ret == -2002) {
                   //getWebServerInfo(null, null, "1");
               } else if(ret == -2005) {
                   //取消采集
                   cancelRegister();
                   //切换手指后，渲染手指(消除原来手指的颜色)
                   renderAfterColl(globalContext, fpIdNum, bgColor, false);
                   //<@i18n 'base_fp_pressFinger'/>:请选择手指
                   //显示框--采集提示
                   collectTips(globalContext, "fingervein Connection...", "html5");
               }
           }
       });
   }
  /**
   * 检查采集次数
   * @author wenxin
   * @create 2013-05-22 09:24:31 am
   * @param collCount 采集次数
   */
   function checkCollCount()
   {
    var base64FPImg = "";
    //返回码
    var ret = null;
    ZKDevice.get(devType).getImage({
        dataType : "json",
        data:{"random":new Date().getTime()},
        async: false,
        callback:function(result){
            //指纹采集次数
            var collCount = 0;
            ret = result.ret;
            if(ret == 0)
            {
                collCount = result.data.enroll_index;
                if(devType == ZKDevType.Fingerprint)
                {
                    base64FPImg = result.data.jpg_base64;
                }
                else
                {
                    base64FPImg = result.data.fg_jpg_base64;
                }
            }
            //显示疑似假指纹的提示信息   modified by 陈彩云  20160526
            else if(ret == -2009)
            {
                collectTips(globalContext, "Suspected fake fingerprint, please re-register.", "html5");
            }
            if(collCount != 3)
            {
                //第一次和第二次采集，显示采集次数、指纹图像、进度条
                if(collCount == 1 || collCount == 2)
                {
                    //Scans Left::按压指纹剩余次数:
                    var text = "Scans Left:"+(FINGERPRINT_NUMBER - collCount);
                    //显示框--采集提示
                    collectTips(globalContext, text, "html5");
                    //进度条
                    drawProgressBar(globalContext, collCount);
                    //显示指纹图像
                    showImage(globalContext, base64FPImg, "html5");
                    //清空图像
                    setTimeout(function(){clearImageData();}, 1000);
                }
                //定时器
                bioTemplateTimer = setTimeout(function(){checkCollCount();}, 1000);
            }
            else
            {
                //显示指纹图像
                showImage(globalContext, base64FPImg, "html5");
                //清空图像
                setTimeout(function(){clearImageData();}, 1000);
                //存放国际化元素数组
                var paramArray = new Array();
                paramArray[0] = "Fingerprint registered Successfully.";//base_fp_registerSuccess:成功登记指纹
                paramArray[1] = "Collect failed, please re-register.";//base_fp_pressFingerAgain:请重按手指
                paramArray[2] = "Do not enroll the duplicate fingerprint!";//base_fp_repeatCollection:请不要重复录入指纹!
                paramArray[3] = "Please install the fingerprint driver or start the service!";//base_fp_connectPrompt:请检查网络连接
                if(devType == ZKDevType.Fingervein)
                {
                    paramArray[0] = "Enroll the finger vein successfully.";//base_fp_registerSuccess:成功登记指纹
                    paramArray[2] = "Please do not enroll the duplicate finger vein!";//base_fp_repeatCollection:请不要重复录入指纹!
                }
                //进度条
                drawProgressBar(globalContext, collCount);
                //获取指纹模板
                if(!getFPTemplate(paramArray, "register"))
                {
                    drawProgressBar(globalContext, 0);//进度条灰显
                }

                //如果胁迫指纹选中，则取消选中
                if(duressFingerFlag)
                {
                   // $("#duressFinger").prop("checked", false);	
                   document.getElementById("duressFinger").checked = false
                }
                $("#duressFinger").attr("disabled", false);
                $("#submitButtonId").attr("disabled", false);
                collectFlag = false;
                fpIdNum = -1;
                return collCount;
            }
        }
    });
   }

  /**
   * 绘画
   * @author wenxin
   * @create 2013-05-13 10:18:31 am
   * @param context 2d画布上下文
   * @param x, y 绘画左手掌的第一个点的坐标，后面的绘画手指和右手掌的坐标都是相对于此点坐标来计算
   * @param color 绘画手指和手掌的边框颜色
   */
  function draw(context, x, y, color) {
    var coordArray = new Array();
    //初始化起始坐标,并返回json格式数据
    var coordJson = initCoordJson();
    for (var i = 0; i < coordJson.length; i++) {
      //绘画双手和圆弧
      drawHandAndArc(
        context,
        coordArray,
        color,
        coordJson[i].coord.x,
        coordJson[i].coord.y,
        coordJson[i].num
      );
     
      //drawHandAndArc(context, coordArray, color, coordJson[i].coord.x, coordJson[i].coord.y, i);
      coordArray = null;
      coordArray = new Array();
    }

    if (devType == "PalmPrint") {
      showImage(context, image4, "clearForRegister");
    } else{
 		showImage(context, image3, "clearForRegister");
	}

    collectTips(context, "Please select a finger.", "html5"); //Display box--collection prompt

    //进度条
    drawProgressBar(context, 0);
    //将确定按钮置灰
    //   $("#submitButtonId").attr("disabled", true);
    //  document.getElementById("submitButtonId").disabled = true;
  }
  /**
   * 绘画双手和圆弧
   * @author wenxin
   * @create 2013-06-17 10:18:31 am
   * @param context 2d画布上下文
   * @param coordArray 坐标数组
   * @param x, y 绘画左手掌的第一个点的坐标，后面的绘画手指和右手掌的坐标都是相对于此点坐标来计算
   * @param color 绘画手指和手掌的边框颜色
   * @param num 当前会话对象编号
   */
  function drawHandAndArc(context, coordArray, color, x, y, num) {
    //初始化坐标
    coordArray = initCoordArray(coordArray, x, y, num);
    var drawObj = null;
    //绘画手指
    if (num < 10) {
      drawObj = "finger" + num;
      drawObj = new renderFinger(context, coordArray);
      drawObj.drawFinger(strokeStyle, color);
      //初始化时，渲染手指
      if (devType == "Fingervein") {
        renderInit(context, "fv", num, "html5");
      } else {
        renderInit(context, "fp", num, "html5");
      }
      //将绘画的手指实例放入数组，方便重画时用
      fingerList.push(drawObj);
    }
    //绘画双手掌心
    else if (num < 12) {
      new renderHand(context, coordArray).drawHand(color);
    }
    //绘画圆圈
    showImage(context, image3, "clearForRegister");
  }
  /**
   * 重画
   * @author wenxin
   * @create 2013-05-13 10:18:31 am
   * @param x, y 鼠标点击处的坐标
   */
  function redraw(x, y) {
    var canvas;

    if (activeTab == 1) {
      canvas = document.getElementById("newCanvas1");
    } else if (activeTab == 2) {
      canvas = document.getElementById("newCanvas2");
    } else if (activeTab == 0)  {
      canvas = document.getElementById("newCanvas");
    }

    if (canvas.getContext) {
         var context = canvas.getContext("2d");
         setGlobalContextValue(context);

         //是否点击在手指区域
         var isInFingerArea = false;
         //判断当前点击是否在手指区域
      for (var i = 0; i < fingerList.length; i++) {
             var finger = fingerList[i];
             finger.drawFinger(strokeStyle, fingerBorderColor);
        if (context.isPointInPath(x, y)) {
                 isInFingerArea = true;
                 break;
             }
         }

      outerloop: for (var i = 0; i < fingerList.length; i++) {
        if (collectFlag) {
                     //当点击的是同一个手指时，如何判断?(编辑指纹时，有问题)
          if (fpIdNum == i) {
                         //切换手指后，渲染手指(消除原来手指的颜色)
                         renderAfterColl(globalContext, fpIdNum, bgColor, false);
                     }
                 }
                 var finger = fingerList[i];
                 finger.drawFinger(strokeStyle, fingerBorderColor);
                 //currentContext = context;
        if (context.isPointInPath(x, y)) {
                     globalContext = context;
                     //两次是否点击的同一个手指进行采集。如果是，则第二次点击时取消采集。
                     var iaSameFinger = false;
          if (fpIdNum == i && collectFlag) {
                         iaSameFinger = true;
                     }
                     var fingerId;
                     //判断该手指是否已经有指纹
                     var isCollected = false;
                     //判断该手指是否已经有指纹
                     if(devType == ZKDevType.Fingerprint) {
                         isCollected = isBioTemplateExists("fp", i, 0);
          } else {
                         isCollected = isBioTemplateExists("fv", i, 0);
                     }
                     fpIdNum = i; 
          if (!isCollected) {
                         //保存当前正在采集的手指标记
                         lastFPIdNum = fpIdNum;
                     }
                     //如果已经有指纹
          if (isCollected) {
                         //取消采集
                        

                      
                        cancelRegister();
                        // setModalStatePersonDetails(true);
                        // setStatusPersonDetails('confirm');
                        // setStatusTitle('Delete the currently selected finger prints?');
                        // setConfirmText('Delete Finger');
                        // setDeleteGlobalFinger(globalContext);
                        // setDeleteContextFinger(context);


                        // setModalStatePersonDetails(true);
                        // setStatusPersonDetails('successPerson');
                        // setErrorMsg('Finger print deleted successfully');
                        delFPData(true, context, "html5");
                        collectTips(globalContext, "Please select a finger.", "html5");
                        //进度条
                        toast.success('Finger print deleted successfully');
                        drawProgressBar(globalContext, 0);

                        setTimeout(() => {
                          setModalStatePersonDetails(false);
                          setStatusPersonDetails('')
                          setErrorMsg('');
                        }, 1200);


             // ========Need to replace with Confirmation box============
            //                       messageBox({
            //                           messageType : "confirm",
            //                           text : "Delete the currently selected finger prints?",//Delete the currently selected finger prints?:删除当前选中的指纹吗?
            //                           callback : function(result){
            //                               delFPData(result, context, "html5");
            //                               collectTips(globalContext, "Please select a finger.", "html5");
            //                               //进度条
            //                               drawProgressBar(globalContext, 0);
            //                           }
            //                       });
                         break outerloop;
          } else {
                         //两次点击的同一个手指进行采集，则第二次取消采集。
            if (iaSameFinger) {
                             //取消采集
                             cancelRegister();
                             //取消采集后重新提示请选择手指
                             collectTips(globalContext, "Please select a finger.", "html5");
                             //取消采集后重新绘制进度条
                             drawProgressBar(context, 0);
                             fpIdNum = -1;
            } else {
                             //取消采集
                             cancelRegister();
                             context.fillStyle = fillFingerColor;
                             context.fill();
                             //globalContext = context;
              collectFlag = true; //需要判断，当重复点击时，颜色改变

                                        $("#duressFinger").attr("disabled", true);
              //                           $("#submitButtonId").attr("disabled", true);

              //                          document.getElementById("duressFinger").disabled = true;
              //                         document.getElementById("submitButtonId").disabled = true;

                             //按压指纹剩余次数
              var text = "Scans Left:" + FINGERPRINT_NUMBER;
                             //进度条
                             drawProgressBar(globalContext, 0);
                             //显示框--采集提示
                             collectTips(globalContext, text, "html5");
                             //指纹采集
                             fpCollection(context);
                         }
                     }
                  } else {
                     context.fillStyle = bgColor;
                     context.fill();
                        if(devType == ZKDevType.Fingervein){
                         renderInit(context, "fv", i, "html5");
                        } else {
                         renderInit(context, "fp", i, "html5");
                        }
                        if (collectFlag) {
                          if (fpIdNum == i && !isInFingerArea) {
                             context.fillStyle = fillFingerColor;
                             context.fill();
                          }
                        }
                 }
             }
     }
  }

  /**
   * 清空
   * @author wenxin
   * @create 2013-09-05 16:57:11 pm
   */
  function clearImageData() {
    //清空指纹图像
    clearFPImage(globalContext, "register");
  }

  var biometricTabbar = null;
  var fpMakeSure = true;
  var fvMakeSure = true;
  var globalContext = null;

  var activeTab = tabValue;

  const handleChange = (event, newValue) => {

    //alert("newValue==="+newValue);
    activeTab = newValue;
    initBioTemplate();
    setTabValue(newValue);
    setSelectedValueRadio('0')

    // if(newValue==0){
    setTimeout(() => {
      var id = "";
      //alert("tabValue==="+tabValue);
      if (newValue == 1) {
        id = "FVTab";
        devType = ZKDevType.Fingervein;
        globalContext = document.getElementById("newCanvas1").getContext("2d");
      } else if (newValue == 2) {
        id = "PPTab";
        devType = ZKDevType.PalmPrint;
        globalContext = document.getElementById("newCanvas2").getContext("2d");
      } else if (newValue == 0) {
        id = "FPTab";
        devType = ZKDevType.Fingerprint;
        globalContext = document.getElementById("newCanvas").getContext("2d");
      }

    
      setDevTypeValue(devType);
      setBioTemplateJsonValue(bioTemplateJson);
      setGlobalContextValue(globalContext);
      clearTimeout(bioTemplateTimer);

      props.getData(bioTemplateJson);

      globalContext.clear();
      globalContext.rect();

      fingerList = [];
      fpIdNum = -1;
      if (id == "PPTab") {
        drawPalmPrint(globalContext);
      }
      //	重新绘画手指和手掌，并检查指纹、指静脉采集器
      else {
        draw(globalContext, x, y, fingerBorderColor);
      }
    }, 50);
    // }
  };

  function registTypeDivChange() {
    //   $("input[name='registType']").change(function(){
    var x = 185;
    //存放国际化元素数组
    var paramArray = new Array();
    paramArray[0] = "Please select a finger.";
    paramArray[1] = "No fingerprint readers detected.";
    paramArray[2] = "Failed to load the dynamic libraries.";
    paramArray[3] = "Please select a finger.";
    paramArray[4] = "Please install the fingerprint driver or start the service!";

    if (globalContext == null) {
      if (activeTab == 1) {
        globalContext = document.getElementById("newCanvas1").getContext("2d");
      } else if (activeTab == 2) {
        globalContext = document.getElementById("newCanvas2").getContext("2d");
      } else if (activeTab == 0)  {
        globalContext = document.getElementById("newCanvas").getContext("2d");
      }
      setGlobalContextValue(globalContext);
    }
    //取消采集
    // cancelRegister();
    fpIdNum = null;
    if (
      document.querySelector("input[name='registType']:checked").value == "1"
    ) {
      // alert('value===1')
      //       document.getElementById("deviceSelectDiv").style.display = "block";

      // 人员未填写pin
      //           if(document.getElementById("pers_pin_register_id").value == "") {
 // ========Need to replace with Confirmation box============
      //               messageBox({messageType:"confirm", text: "Please fill in the personnel id first!",
      //                   callback: function(result){
      //                       if(!result) {
      //                           document.getElementById("deviceSelectDiv").style.display = "none";
      //                           document.querySelector("input[name='registType'][value='0']").checked = true;

      //							// $("#registTypeDiv").trigger("click");
      //                           var registTypeDivClick = document.getElementById("registTypeDiv");
      //                           var evnt = registTypeDivClick["onclick"];
      //                           evnt.call(registTypeDivClick);

      //                           /* fpIdValue = null;
      //                           //检查指纹采集器
      //                           checkFPReader(globalContext, paramArray, "html5");
      //                           //进度条
      //                           drawProgressBar(globalContext, 0); 
      //                       } else {
      ////                             close this window
      //                           // DhxCommon.closeWindow();

      //                       }
      //                   }
      //               });
      //           }
      // 		清除提示语
      globalContext.clearRect(75, 30, 90, 20); // commented shadow in issue progres bar on 
      // 清除提示语
      globalContext.clearRect(x + 4, 30, 90, 20);  // commented shadow in issue progres bar on 
      globalContext.clearRect(x + 46, 30,90, 20); ;  // commented shadow in issue progres bar on 
      globalContext.clearRect(x + 86, 30, 90, 20);   // commented shadow in issue progres bar on hand image
    } else {
      //           $("#deviceSelectDiv").hide();
      //         document.getElementById("deviceSelectDiv").style.display = "none";
      fpIdNum = null;
      //检查指纹采集器
      // checkFPReader(globalContext, paramArray, "html5");
      collectTips(globalContext, "Please select a finger.", "html5"); //Display box--collection prompt
      //进度条
      drawProgressBar(globalContext, 0);
       draw(globalContext, x, y, fingerBorderColor);
    }
    //   });
  }

  //   if ("pers,system,".toLowerCase().indexOf("acc") != -1) {
  //       deviceCombo = new dhtmlXCombo("deviceDiv", "deviceDiv", 150);
  //       deviceCombo.readonly(true);
  //       function getRemoteRegistDev(optionName)
  //       {
  //           var optionArray = [];
  //           ajax({
  //               type : "POST",
  //               url : "accDevice.do?getRemoteRegistDev",
  //               dataType : "json",
  //               async: false,
  //               data : {
  //                   "optionName" : optionName
  //               },
  //               success : function(result)
  //               {
  //                   if(result[sysCfg.ret] == sysCfg.success)
  //                   {
  //                       deviceCombo.unSelectOption();
  //                       deviceCombo.clearAll();
  //                       each(result.data, function(index, value) {
  //                           deviceCombo.addOption([[value.id,value.text]]);
  //                       })
  //                   }
  //               }
  //           });
  //       }
  //       // 获取远程登记设备
  //       getRemoteRegistDev("FingerFunOn");

  //       // 发送远程登记命令
  //       $("#confirmButtonId").click(function(){
  //           var devId = deviceCombo.getSelectedValue();
  //           var templateType = 1;
  //           var template = "templatev10";
  //           if(devId == null)
  //           {
  //               messageBox({messageType: "alert", title: "Prompt", text: "Please select the access control device!"});
  //               return;
  //           }
  //           if(lastFPIdNum == null || fpIdNum == -1 || fpIdNum == null)
  //           {
  //               messageBox({messageType: "alert", title: "Prompt", text: "Please select your finger!"});
  //               return;
  //           }
  //           var curTime = new Date().getTime();
  //           openMessage(msgType.loading, "Data processing, please wait...");
  //           ajax({
  //               type : "POST",
  //               url : "accDevice.do?remoteRegistration",
  //               dataType : "json",
  //               data : {
  //                   "devId" : devId,
  //                   "templateNo" : lastFPIdNum,
  //                   "pin" : $("#pers_pin_register_id").val(),
  //                   "templateType" : templateType

  //               },
  //               success : function(result)
  //               {
  //                   var cmdId = result.data;
  //                   ajax({
  //                       type: "POST",
  //                       url: "accDevice.do?getRemoteTemplate",
  //                       data: {"personPin" : $("#pers_pin_register_id").val(),
  //                           "curTime" : curTime,
  //                           "template" : template,
  //                           "fpvType" : 1,
  //                           "cmdId": cmdId},
  //                       dataType: "json",
  //                       success: function(retData)
  //                       {
  //                           if(retData[sysCfg.ret] == sysCfg.success)
  //                           {
  //                               //判断手指是否已经采集指纹
  //                               var compareRet = "";
  //                               //存放国际化元素数组
  //                               var paramArray = new Array();
  //                               paramArray[0] = "Fingerprint registered Successfully.";//base_fp_registerSuccess:成功登记指纹
  //                               paramArray[1] = "Collect failed, please re-register.";//base_fp_pressFingerAgain:请重按手指
  //                               paramArray[2] = "Do not enroll the duplicate fingerprint!";//base_fp_repeatCollection:请不要重复录入指纹!
  //                               paramArray[3] = "Please install the fingerprint driver or start the service!";//base_fp_connectPrompt:请检查网络连接

  //                               var data = retData[sysCfg.data];
  //                               if(data.templatev10 != "" && data.templatev10 != undefined)
  //                               {
  //                                   // 		远程登记指纹
  //                                   if(templateDataArray.length > 0)
  //                                   {
  //                                       //发送请求，进行后台指纹比对
  //                                       compareRet = fpComparision(data.templatev10, templateDataArray, paramArray[3]);
  //                                   }

  //                                   if($.trim(compareRet) == "dllNotExist")
  //                                   {
  //                                       //After collecting the fingerprint, render the finger
  //                                       renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
  //                                       //Display box--collection prompt
  //                                       openMessage(msgType.success, "Load Library error.");
  //                                   }
  //                                   else if(compareRet == "noFingerServer")
  //                                   {
  //                                       //After collecting the fingerprint, render the finger
  //                                       renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
  //                                       //Display box--collection prompt
  //                                       openMessage(msgType.success, "Did not start the finger service");
  //                                   }
  //                                   else if(compareRet != "ok")
  //                                   {
  //                                       //胁迫指纹
  //                                       if(duressFingerFlag)
  //                                       {
  //                                           //将手指标记保存到数组中
  //                                           fingerIdArray[fingerIdArray.length] = fpIdNum + DURESS_FINGER_NUM;
  //                                       }
  //                                       else
  //                                       {
  //                                           //将手指标记保存到数组中
  //                                           fingerIdArray[fingerIdArray.length] = fpIdNum;
  //                                       }
  //                                       templateDataArray[templateDataArray.length] = data.templatev10;
  //                                       renderAfterColl(globalContext, lastFPIdNum, bgColor, true);//bgColor判断
  //                                       $("#submitButtonId").attr("disabled", false);
  //                                       openMessage(msgType.success, "Remote registration successful!");
  //                                       setBioTemplateCount("fp",fpIdNum,true);
  //                                       setBioTemplate("fp",fpIdNum,0,data.templatev10,duressFingerFlag);
  //                                   }
  //                                   else
  //                                   {
  //                                       //After collecting the fingerprint, render the finger
  //                                       renderAfterColl(globalContext, fpIdNum, bgColor, false);//bgColor判断
  //                                       //Display box--collection prompt
  //                                       openMessage(msgType.success, paramArray[2]);
  //                                       setBioTemplateCount("fp",fpIdNum,true);
  //                                       setBioTemplate("fp",fpIdNum,0,data.templatev10,duressFingerFlag);
  //                                   }
  //                               }
  //                               if(duressFingerFlag)
  //                               {
  //                                   $("#duressFinger").attr("checked", false);
  //                               }
  // //                             $("#duressFinger").attr("disabled", false);
  //document.getElementById("duressFinger").disabled = false
  //                               collectFlag = false;
  //                               fpIdNum = -1;
  //                           }
  //                           else if(retData[sysCfg.ret] == sysCfg.error)
  //                           {
  //                               openMessage(msgType.error, retData[sysCfg.msg]);
  //                           }
  //                           else
  //                           {
  //                               openMessage(msgType.error, "Remote registration failed!Please check if the device is online!");
  //                           }
  //                       },
  //                       error : function(XMLHttpRequest, textStatus, errorThrown)
  //                       {
  //                           openMessage(msgType.error, "Remote registration failed!");
  //                       }
  //                   });
  //               }
  //           });
  //       });
  //   }

  /**
   * 页面加载时，初始化数据
   * @author wenxin
   * @create 2013-07-09 15:18:31 pm
   */
  
  function dataInit() {
    var issInstall = "";
          ZKDevice.get("Fingerprint",function(IssControls){ 
              if(IssControls.installed)//已安装
              {
                  if(IssControls.upgrade)//需要升级
                  {
                    console.log("Driver Download----IF");
                    setDriverDownload(true)
                     // issInstall = "<span>{0} needs to upgrade to the new version:".format("Device Driver") + "</span><a href="+IssControls.url+">Driver Download</a>";
                  } else {
                     // issInstall = "{0} is properly installed.".format("Device Driver");
                     console.log("Driver Download----ELSE");
                     setDriverDownload(true)
                  }
              } else {
                 // issInstall = "<span>Detected {0} is not installed:".format("Device Driver") + "</span><a href="+IssControls.url+">Driver Download</a>";
                 console.log("Driver Download----ELSE main");
                 setDriverDownload(false)
              }
    //             $("#bioDeviceDev").html(issInstall);
              //document.getElementById("bioDeviceDev").innerHTML = issInstall;
          });

    cleanDataArray();
    if (!duressFingerShowFlag) {
      //	$("#duressFingerDiv").hide();
      //      document.getElementById("deviceSelectDiv").style.display = "none";
    }

    var context;
    var canvas;


  if (activeTab == 1) {
    canvas = document.getElementById("newCanvas1");
   // context = canvas.getContext("2d");
  } else if (activeTab == 2) {
    canvas = document.getElementById("newCanvas2");
   // context = canvas.getContext("2d");
  } else if (activeTab == 0)  {
    canvas = document.getElementById("newCanvas");
   // context = canvas.getContext("2d");
  }
 if ( canvas != null) {
  context = canvas.getContext("2d");
    setGlobalContextValue(context);

    fpIdNum = null;
    //获取页面的指纹数据
    initBioTemplate();
    //绘画
    if (activeTab != 2) {
        draw(context, x, y, fingerBorderColor);

        //jquery在ie下实现cors跨域请求
        //       jQuery.support.cors = true;
        //鼠标事件
        canvas.onmousedown = function(event) {
          //event.which == 1--鼠标左键
          if (event.which == 1) {
            var pageInfo = canvas.getBoundingClientRect();
            var x = event.clientX - pageInfo.left;
            var y = event.clientY - pageInfo.top;
  
            duressFingerFlag = document.getElementById("duressFinger").checked;
            //重画
            redraw(x, y);
          }
        };
      }
    }
  }
  //初始化数据
 

  const handleChangeRadio = (event) => {
    // alert('value==='+event.target.value)
    setSelectedValueRadio(event.target.value);
    if( tabValue === 2) {
      setSelectedValueRadio3(event.target.value)
    }
  };

// const handleCancel = () => {
//   setModalState(false);
//   setErrorMsg('');
// }

const handleCancelTop = () => {
  setModalStatePersonDetails(false);
  setPalmSuccess(false)
  setErrorMsg('');
}

const handleCloseTop = () => {
  setModalStatePersonDetails(false);
  setPalmSuccess(false)
  setErrorMsg('');
}

const handleResetTab = () => {
  // alert()
    // return () => {
     setTabValue(0)
    // }
}


  return (
    <div>
       {/* <ModalPersonDevice errormsg={statusMessage} responseStatus={statusPersonDetails} handleCancel={handleCancelTop} handleConfirm={handleConfirm} handleClose={handleCloseTop} open={modalStatePersonDetails} errormsg={errorMsg} statusTitle={statusTitle}  cancelText={'cancel'} confirmText={confirmText} /> */}
      <button type="button" style={{ display: "none" }} onClick={handleOpen}>
        {""}
      </button>
      <Modal
        // className="component-a"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={style}>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "3px",
              }}
            >
              <IconButton className={classes.closeButton} onClick={()=>{handleClose();handleResetTab()}}>
                {/* <CloseIcon /> */}
              </IconButton>
            </Box>
            {/* <DeatilsTextFieldTitle title={'Remote Opening'} /> */}
            {/* <DeatilsTextFieldTitle title={'Register'} /> */}
            <div className={classes.root}>
              <AppBar
                className={classes.wrapperHeader}
                position="static"
                color="default"
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    className={classes.selectedTab}
                    label="Fingerprint"
                    {...a11yProps(0)}
                  />
                  <Tab
                  hidden='hidden'
                    className={classes.selectedTab}
                    label="Finger Vein"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={classes.selectedTab}
                    label="Palm Registration"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={tabValue} index={0}>
                <RadioGroup
                  aria-label="newtork-connection"
                  className={classes.selectRadioGroup}
                  // defaultValue="wired"
                  name="registType"
                  value={selectedValueRadio}
                   onChange={registTypeDivChange}
                >
                  <FormControlLabel
                    // value="0"
                    control={<CustomRadio value='0' size="small" hidden='hidden' />}
                    // label="Fingerprint Scanner"
                    onChange={registTypeDivChange}
                  />
                  <FormControlLabel
                    // value="1"
                    control={<CustomRadio value='1' size="small" hidden='hidden' />}
                    // label="Remote Registration"
                    onChange={registTypeDivChange}
                  />
                </RadioGroup>

                <input id='bioTemplateJson' name='bioTemplateJson' type='text'
                 value={JSON.stringify(bioTemplateJsonValue)} />

                {selectedValueRadio === "1" ? (
                  <>
                    <Box
                      style={{
                        paddingTop: "15px",
                        paddingRight: "20px",
                        display: "flex",
                        paddingBottom: "25px",
                      }}
                    >
                      {/* <DeatilsTextFieldTitle title={'wirelessKey *'} /> */}
                      <TextField
                        // value={wirelessKeyValue}
                        // onChange={handleWirelessKeyValue}
                        select
                      />
                      <Button
                        className={classes.confirmButton}
                        onClick={handleConfirm}
                       // id="confirmButtonId"
                        name="confirmName"
                      >
                        {"Confirm"}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography className={classes.accessModalTitle} noWrap>
                      please select finger to start enrolling
                    </Typography>
                    {/* <Typography className={classes.subText} noWrap>
                      Scans right: 1
                    </Typography> */}
                  </>
                )}
                <canvas
                  id="newCanvas"
                  //  onClick={handleSelect}
                  //  ref={canvasRef1}
                  width="640"
                  height="302"
                ></canvas>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <RadioGroup
                  aria-label="newtork-connection"
                  className={classes.selectRadioGroup}
                  // defaultValue="wired"
                  name="registType"
                  value={selectedValueRadio}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value="0"
                    control={<CustomRadio size="small" hidden='hidden' />}
                    // label="Fingerprint Scanner" 
                    onChange={registTypeDivChange}
                  />
                  <FormControlLabel
                    value="1"
                    control={<CustomRadio size="small" hidden='hidden' />}
                    // label="Remote Registration" 
                    onChange={registTypeDivChange}
                  />
                </RadioGroup>
                <input id='bioTemplateJson' name='bioTemplateJson' type='hidden'  value={JSON.stringify(bioTemplateJsonValue)}/>
                 {selectedValueRadio === "1" ? ( 
                <>
                  <Box
                    // id="deviceSelectDiv"
                    // hidden="hidden"
                    style={{
                      paddingTop: "30px",
                      paddingRight: "20px",
                      paddingBottom: "48px",
                      display: "flex",
                    }}
                  >
                    {/* <DeatilsTextFieldTitle title={'wirelessKey *'} /> */}
                    <TextField
                      // value={wirelessKeyValue}
                      // onChange={handleWirelessKeyValue}
                      select
                    />
                    <Button
                      className={classes.confirmButton}
                      onClick={handleConfirm}
                  //    id="confirmButtonId"
                      name="confirmName"
                    >
                      {"Confirm"}
                    </Button>
                  </Box>
                </>
               ) : ( 
                <>
                  <Typography className={classes.accessModalTitle} noWrap>
                    please select finger to start enrolling
                  </Typography>
                  {/* <Typography className={classes.subText} noWrap>
                    Scans right: 1
                  </Typography> */}
                </>
                 )}
                <canvas
                  id="newCanvas1"
                  // ref={canvasRef2}
                  width="640"
                  height="270"
                ></canvas>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>

              <RadioGroup
                  aria-label="newtork-connection"
                  className={classes.selectRadioGroup}
                  // defaultValue="wired"
                  name="registType"
                  hidden='hidden'
                  value={selectedValueRadio3}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value="0"
                    control={<CustomRadio size="small" />}
                    label="Fingerprint Scanner"
                    onChange={registTypeDivChange}
                  />
                  <FormControlLabel
                    value="1"
                    control={<CustomRadio size="small" />}
                    label="Remote Registration"
                    onChange={registTypeDivChange}
                  />
                </RadioGroup>
                <input id='bioTemplateJson' name='bioTemplateJson' type='hidden' value={JSON.stringify(bioTemplateJsonValue)}/>

              <Typography className={classes.accessModalTitle} noWrap> Please click on the palm to start enrolling</Typography>
                <div style={{display:'flex',paddingTop:'50px',paddingBottom:'25px'}}>
                  <canvas
                    id="newCanvas2"
                    //  onClick={handleSelect}
                    //  ref={canvasRef1}
                    width="640"
                    height="270"
                    // hidden="hidden"
                  >
                    
                  </canvas>
                 {/* {palmSuccess  == false ?    */}
                   <div >
              <div id="startPalmPrintDivId"  
                  style={{position:'absolute',left:'50px',marginTop:'75px'}}>
                    <img
                      src={hand}
                      id="startPalmId"
                      onClick={startPalmPrintRegister}
                      width="240px"
                      height="160px"
                    />
                  </div>
                </div> 
                <div id="endPalmPrintDivId"   style={{position:'absolute',left:'50px',marginTop:'75px'}}>
                  {/* <div >
            <img src={image5} />

            </div > */}
                  <img
                    src={image5}
                    id="endPalmId"
                    onClick={deletePPTemplate}
                    width="240px"
                    height="160px"
                    hidden="hidden"
                  />
                </div>
                {/* /} */}
                </div>
              </TabPanel>
         {tabValue == 0 || tabValue == 1?
         <FormControlLabel
        hidden={tabValue == 1 ? 'hidden': ''}
        //  style={{hidden: tabValue == 1 ? 'hidden': ''}}	
        control={	
          <input style={{ width:'20px',height:'20px',marginRight:'5px',marginLeft:'5px'}} type={'checkbox'}  id="duressFinger" name="checkboxName" />	
        }	
        label="Duress Fingerprint"	
        // style={{ color: darkMode? '#fff' : '#2286AB' }}	
      /> 	
      : null}
            </div>
         { driverDownload ? <div style={{backgroundColor:'#5a5a5a14', width:'85%',margin:'auto',marginTop:'5px',marginBottom:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Typography className={classes.DriverText}>Device Driver is properly installed.</Typography></div> : <div style={{backgroundColor:'#5a5a5a14', width:'85%',margin:'auto',marginTop:'5px',marginBottom:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Typography className={classes.DriverText}>Device Driver is not installed : </Typography><Link className={classes.DriverLinkStyle} href={downloadDrivers} target="_blank" download={'ISSOnline.exe'}>Download Driver</Link>
            </div>}

            {/* {confirmText ? ( */}
              <Box className={classes.innerWrapper}>
                <Box>
                  {moreDetails ? (
                    <Typography
                      onClick={handleText}
                      style={{
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      More Details
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  {/* {cancelText ? ( */}
                    <Button
                      className={classes.cancelButton}
                      onClick={() => {
                        closedEvent();handleCancel();handleResetTab()
                      }}
                    >
                      {'cancel'}
                    </Button>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {confirmText ? ( */}
                    <Button
                      className={classes.confirmButton}
                      // onClick={handleClose}
                      onClick={() => {
                        saveBioTemplate();handleClose()
                      }}
                    >
                      {'Save'}
                    </Button>
                  {/* ) : (
                    ""
                  )} */}
                </Box>
              </Box>
            {/* ) : (
              <Box
                className={classes.innerWrapper}
                justifyContent="flex-end !important"
              >
                <Box>
               
                    <Button
                      className={classes.cancelButton}
                      onClick={handleCancel}
                    >
                      {'cancel'}
                    </Button>
                 
                </Box>
              </Box>
            )} */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CardholderRegisterModal;
