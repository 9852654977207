import React from "react";
import { Autocomplete, TextField } from '@mui/material';

export default function CustomAutocomplete(props) {
    const { onSelectedValue, label, displayoption } = props;

    return <Autocomplete
        {...props}
        getOptionLabel={displayoption}
        fullWidth
        style={{ margin: '5px' }}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(event, newValue) => onSelectedValue(newValue)}
    />
}
CustomAutocomplete.defaultProps = {
    label: 'None',
    displayoption:[]
}
