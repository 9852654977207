import { DEVICE_SYNC_TIME, SET_DEVICE_TIME_ZONE, DEVICE_SYNC_All_DATA } from '../actions/types';

const initialState = {
    deviceSyncTime: [],
    setdeviceTimeZone: [],
    syncAllDataToDevList: [],
}
export default function (state = initialState, action) {
    switch (action.type) {
        case DEVICE_SYNC_TIME:
            return {
                ...state,
                deviceSyncTime: action.payload
            }
        case SET_DEVICE_TIME_ZONE:
            return {
                ...state,
                setdeviceTimeZone: action.payload
            }
        case DEVICE_SYNC_All_DATA:
            return {
                ...state,
                syncAllDataToDevList: action.payload
            }
        default:
            return state;


    }
}