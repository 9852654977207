import { Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as LeftArrowIcon } from '../../assets/v4/LeftArrow.svg';
import CommonUtil from '../Util/CommonUtils';

export default function TitleBar(props) {
  const history = useHistory();
  return (
    <>
      <Box display='flex' alignItems='center' paddingTop={2} paddingBottom={2}>
        {props.title && (
          <Box item paddingRight={1} paddingBottom={0.5} alignSelf={'center'}>
            <LeftArrowIcon fontSize='small' onClick={() => (props.path ? props.onClick() : history.goBack())} />
          </Box>
        )}
        <Box item flexGrow={1}>
          <Typography variant='title' gutterBottom component='div' style={{ fontWeight: 'bold', color: '#304669' }}>
            {CommonUtil.isEmptyString(props.title) ? props.header : props.title}
          </Typography>
        </Box>
        <Box item>{props.children}</Box>
      </Box>
    </>
  );
}
