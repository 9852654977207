import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Grid, IconButton, Typography, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { filterPerson } from '../../actions/organization/PersonApiCalls';
import CancelIcon from '@material-ui/icons/Cancel';
import MobileInput from './PersonMobileInput';
import CommonUtil from '../../components/Util/CommonUtils';

export default function FilterPerson(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({});
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    //employeeCode: '',
    code: '',
    email: '',
    phone: '',
  });

  const useStyles = makeStyles((theme) => ({
    absolute: {
      margin: theme.spacing(),
      position: 'absolute',
      right: theme.spacing(5),
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setState({
      firstName: '',
      lastName: '',
      // employeeCode: '',
      code: "",
      email: '',
      phone: '',
    });
    setOpen(false);
  };

  const reset = () => {
    if (state.firstName.trim() === '' && state.lastName.trim() === '' && state.code.trim() === '' && state.email.trim() === '' && state.phone.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      firstName: '',
      lastName: '',
      //  employeeCode: '',
      code: '',
      email: '',
      phone: '',
    });
  };

  const handleChange = (event) => {

    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  // const loadData = () => {
  //   dispatch(filterPerson(state.firstName, state.lastName, state.employeeCode, state.email, state.phone, 1, props.rowsPerPage));
  // };

  const handleSubmit = () => {

    if (state.firstName.trim() === '' && state.lastName.trim() === '' && state.code.trim() === '' && state.email.trim() === '' && state.phone.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    let personObj = {};
    personObj.firstName = state.firstName;
    personObj.lastName = state.lastName;
    // personObj.employeeCode = state.employeeCode;
    personObj.code = state.code
    personObj.email = state.email;
    personObj.phone = CommonUtil.isEmptyString(state.phone) ? '' : countryCode + '-' + state.phone;
    props.applyFilter(personObj);
    setState({
      firstName: '',
      lastName: '',
      // employeeCode: '',
      code: '',
      email: '',
      phone: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth={'md'} open={open} onClose={handleClose} className='filter-dialog'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid item xs={12} sm={12}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {/* <TextField  autoComplete='off' fullWidth name='phone' label={t('PERSON006')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.phone} /> */}
                <MobileInput
                  error={error.phone}
                  countryCode={countryCode}
                  phone={state.phone}
                  name='phone'
                  value={state.phone}
                  editMode={true}
                  onMobileNoChange={(phone) => {
                    setError({ phone: false });
                    setState({ ...state, phone: phone });
                  }}
                  onCounryCodeChange={(code) => setCountryCode(code)}
                />
              </Box>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='email'
                    label={t('PERSON005')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.email}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='firstName'
                    label={t('USER0001')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.firstName}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='lastName'
                    label={t('USER0002')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.lastName}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='code'
                    label={t('COMMON045')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.code}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
          <Grid container style={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleSubmit} color='secondary' variant='contained' className='m-2' size='small'>
              {t('COMMON024')}
            </Button>
            <Button onClick={reset} color='secondary' variant='contained' className='m-2' size='small'>
              {t('COMMON025')}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
