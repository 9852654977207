import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip, Typography, Grid, MenuItem, InputAdornment, TextField, Paper } from '@material-ui/core';
import defaultProfile from '../../assets/images/defaultProfile.png';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MobileInput from '../../components/MobileInput';
import CommonUtil from '../../components/Util/CommonUtils';
import AddressFromMap from '../../components/AddressFromMap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePerson } from '../../actions/organization/PersonApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList, getDepartmentListFilter_V3, getDepartmentList_V3 } from '../../actions/organization/DepartmentApiCalls';
import { getDesignationsFilterList_V3, getDesignationsList } from '../../actions/organization/DesignationApiCalls';
import { getPersonAddress } from '../../actions/organization/PersonApiCalls';
import { updateAddress } from '../../actions/organization/PersonApiCalls';
import { cityData, countryData, stateData, timeZoneData } from '../../components/AddressFormData';
import { countryList } from '../../components/Util/CountryList';
import Breadcrumb from '../../components/Breadcrumb';
import OverFlowText from '../accessgroup/OverFlowText';
import { Avatar, Divider } from '@mui/material';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewPerson() {
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState(defaultProfile);
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const store = useSelector((state) => state);
  const [cities, setCities] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);

  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = React.useState({
    addressLine1: false,
    country: false,
    state: false,
    city: false,
    areaCode: false,
    latitude: false,
    longitude: false,
    radius: false,
    timezone: false,
    addressTypeId: false,
    notValid: false,
    gender: false,
    password: false,
  });

  const [personState, setPersonState] = React.useState({
    id: location.state.selectedObject.id,
    firstName: location.state.selectedObject.firstName,
    lastName: location.state.selectedObject.lastName,
    email: location.state.selectedObject.email,
    phone: location.state.selectedObject.phone,
    employeeCode: location.state.selectedObject.code,
    joinDate: location.state.selectedObject.joinDate,
    gender: location.state.selectedObject.gender,
    departmentId: location.state.selectedObject.departmentId ? location.state.selectedObject.departmentId : '',
    designationId: location.state.selectedObject.designationId ? location.state.selectedObject.designationId : '',
    siteId: location.state.selectedObject.siteId,
    profilePhoto: location.state.selectedObject.profilePhoto,
    createUser: false,
  });

  const breadcrumb = [
    { path: '/acc/z/person', name: t('PERSON001') },
    {
      path: '',
      name: CommonUtil.isEmptyString(location.state.selectedObject.id) ? t('PERSON001') : t('COMMON473'),
    },
  ];

  React.useEffect(() => {
    if (!CommonUtil.isEmptyString(location.state.selectedObject)) {
      if (!CommonUtil.isEmptyString(location.state.selectedObject.profilePhoto)) {
        setPersonState({
          ...personState,
          profilePhoto: 'data:image/jpeg;base64,' + location.state.selectedObject.profilePhoto,
        });
      }
    }
  }, [location.state.selectedObject.profilePhoto]);

  React.useEffect(() => {
    if (!CommonUtil.isEmptyString(personState.phone)) {
      if (personState.phone.includes('-')) {
        setCountryCode(personState.phone.split('-')[0]);
        setPersonState({
          ...personState,
          phone: personState.phone.split('-')[1],
        });
      }
    }
  }, [personState.phone]);

  const [addressState, setAddressState] = React.useState({
    employeeId: location.state.selectedObject.id,
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    userId: '',
    siteId: '',
  });

  const [addressDefaultState, setAddressDefaultState] = React.useState({
    employeeId: location.state.selectedObject.id,
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    userId: '',
    siteId: '',
  });

  React.useEffect(() => {
    dispatch(getPersonAddress(location.state.selectedObject.id));
  }, []);

  React.useEffect(() => {
    if (!CommonUtil.isEmpty(store.address.addressList)) {
      if (!CommonUtil.isEmpty(store.address.addressList.address[0])) {
        var address = store.address.addressList.address[0];
        if (CommonUtil.isEmptyString(address.country)) {
          address.country = CommonUtil.getLocationDetails('country_name');
        }
        setAddressState({
          ...addressState,
          ...address,
        });

        setAddressDefaultState({
          ...addressDefaultState,
          ...address,
        });
      }
    }
    setCities(cityData);
    setCountries(countryList);
    setSelectStates(stateData);
  }, [store.address.addressList]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      marginTop: '16px',
    },
    bodyFlex: {
      display: 'flex',
      backgroundColor: 'rgb(15 90 156 / 10%)',
      paddingTop: '5px',
      paddingBottom: '5px',
      borderRadius: '6px',
      paddingRight: '20px',
      paddingLeft: '10px',
      marginRight: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'blue',
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    if (!CommonUtil.isEmpty(location.state.selectedObject)) {
      if (!CommonUtil.isEmptyString(location.state.selectedObject.profilePhotoURL)) {
        setProfile(location.state.selectedObject.profilePhotoURL);
      }
    }
  }, [location.state.selectedObject]);

  const [countryCode, setCountryCode] = React.useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );

  const handleChangeCountry = (event) => {
    if (event === null) {
      setAddressState({
        ...addressState,
        country: '',
      });
    } else {
      setAddressState({
        ...addressState,
        country: event.country,
      });
    }
    setError({
      country: false,
    });
  };

  const isAddressFormValid = () => {
    if (CommonUtil.isEmptyString(addressState.addressLine1)) {
      setError({ addressLine1: true });
      toast.error(t('ADD014'));
      return false;
    }
    if (CommonUtil.isEmptyString(addressState.country)) {
      setError({ country: true });
      toast.error(t('ADD021'));
      return false;
    }
    if (!CommonUtil.isEmptyString(addressState.city)) {
      if (!CommonUtil.isAlphaNumericSpace(addressState.city)) {
        setError({ city: true });
        toast.error(t('ADD028'));
        return false;
      }
    } else {
      setError({ city: true });
      toast.error(t('ADD015'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressState.state)) {
      if (!CommonUtil.isAlphaNumericSpace(addressState.state)) {
        setError({ state: true });
        toast.error(t('ADD028'));
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(addressState.areaCode) && !CommonUtil.isAlphaNumericSlash(addressState.areaCode)) {
      setError({ areaCode: true });
      toast.error(t('ADD028'));
      return false;
    }

    if (CommonUtil.isEmptyString(addressState.addressTypeId)) {
      setError({ addressTypeId: true });
      toast.error(t('ADD016'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressState.radius)) {
      if (CommonUtil.isEmptyString(addressState.radius.trim()) || !CommonUtil.isValidNumeric(addressState.radius)) {
        setError({ radius: true });
        toast.error(t('ADD020'));
        return false;
      }
    }

    return true;
  };

  const handleAddressChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    if (event.target.name === 'latitude' || event.target.name === 'longitude') {
      if (!CommonUtil.isValidDoubleValue(event.target.value)) {
        return;
      }
    }
    setAddressState({
      ...addressState,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handlePersonChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setPersonState({
      ...personState,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  React.useEffect(() => {
    dispatch(
      getDepartmentListFilter_V3(1, 0, (data) => {
        setDepartments(data.department);
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      getDesignationsFilterList_V3(1, 0, (data) => {
        setDesignations(data.designation);
      })
    );
  }, []);

  const onSelectLocation = (address) => {
    setAddressState({
      ...addressState,
      timeZone: address.timeZone,
      city: CommonUtil.isAlphaNumericSlash(address.city.trim()) ? address.city.trim() : '',
      addressLine1: address.addressLine1,
      state: CommonUtil.isAlphaNumericSlash(address.state.trim()) ? address.state.trim() : '',
      country: CommonUtil.isAlphaNumericSlash(address.country.trim()) ? address.country.trim() : '',
      areaCode: CommonUtil.isAlphaNumericSlash(address.pincode) ? address.pincode : '',
      latitude: address.lat,
      longitude: address.lng,
    });
  };

  React.useEffect(() => {
    if (departments instanceof Array && (personState.departmentId === undefined || CommonUtil.isEmptyString(personState.departmentId))) {
      departments.map((dept) => {
        if (dept.code === 'Default') {
          setPersonState({ ...personState, departmentId: dept.id });
        }
      });
    }
    if (designations instanceof Array && (personState.designationId === undefined || CommonUtil.isEmptyString(personState.designationId))) {
      designations.map((desg) => {
        if (desg.code === 'Default') {
          setPersonState({ ...personState, designationId: desg.id });
        }
      });
    }
  }, [departments, designations]);

  const findDepartmentById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return '';
    }
    var departmentName = '';
    departments.map((department) => {
      if (department.id === id) {
        departmentName = department.name;
      }
    });
    return departmentName.toLowerCase() !== 'default' ? departmentName : 'Default department';
  };

  const findDesignationById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return '';
    }
    var name = '';
    designations.map((jobTitle) => {
      if (jobTitle.id === id) {
        name = jobTitle.name;
      }
    });
    return name.toLowerCase() !== 'default' ? name : 'Default Designation';
  };

  return (
    <>
      <div>
        <Breadcrumb links={breadcrumb}></Breadcrumb>
        <Tooltip title={t('PERSON013')}>
          <IconButton color='secondary' aria-label={t('PERSON013')}></IconButton>
        </Tooltip>
      </div>

      {/* <div className='custom-container'>
        <>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid container>
              <Grid container justify='center'>
                <Grid item xs={12}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <Avatar alt='' src={location.state.selectedObject.profilePhotoURL} className={classes.large} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} spacing={4} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  required
                  error={error.firstName}
                  name='firstName'
                  label={t('PERSON003')}
                  variant='outlined'
                  size='small'
                  defaultValue={personState.firstName}
                  value={personState.firstName}
                  onChange={handlePersonChange}
                  inputProps={{
                    maxlength: 100,
                    disabled: location.state.selectedObject.disable,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  error={error.lastName}
                  name='lastName'
                  label={t('PERSON004')}
                  variant='outlined'
                  size='small'
                  defaultValue={personState.lastName}
                  value={personState.lastName}
                  onChange={handlePersonChange}
                  inputProps={{
                    maxlength: 25,
                    disabled: location.state.selectedObject.disable,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  error={error.email}
                  name='email'
                  label={t('PERSON005')}
                  variant='outlined'
                  size='small'
                  defaultValue={personState.email}
                  value={personState.email}
                  onChange={handlePersonChange}
                  inputProps={{
                    disabled: location.state.selectedObject.disable,
                  }}
                />
              </Box>
            </Grid>
            <MobileInput
              sm={12}
              lg={6}
              error={error.phone}
              countryCode={countryCode}
              phone={personState.phone}
              editMode={location.state.selectedObject.disable ? false : true}
              onMobileNoChange={(phone) => {
                setError({ phone: false });
                setPersonState({ ...personState, phone: phone });
              }}
              onCounryCodeChange={(code) => setCountryCode(code)}
            />
            <Grid item xs={12} sm={12} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  required
                  error={error.employeeCode}
                  name='employeeCode'
                  label={t('PERSON046')}
                  variant='outlined'
                  size='small'
                  defaultValue={personState.employeeCode}
                  value={personState.employeeCode}
                  onChange={handlePersonChange}
                  inputProps={{
                    maxlength: 20,
                    disabled: location.state.selectedObject.disable,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FormControl variant='outlined' size='small' fullWidth>
                  <InputLabel>{t('PERSON007')}</InputLabel>
                  <Select
                    fullWidth
                    error={error.gender}
                    name='gender'
                    label={t('PERSON007')}
                    variant='outlined'
                    size='small'
                    value={personState.gender}
                    defaultValue={personState.gender}
                    onChange={handlePersonChange}
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                  >
                    <MenuItem value={'M'}>{t('COMMON103')}</MenuItem>
                    <MenuItem value={'F'}>{t('COMMON104')}</MenuItem>
                    <MenuItem value={'O'}>{t('Others')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FormControl variant='outlined' size='small' fullWidth>
                  <InputLabel>{t('PERSON010')}</InputLabel>
                  <Select
                    fullWidth
                    error={error.departmentId}
                    name='departmentId'
                    label={t('PERSON010') + ' *'}
                    variant='outlined'
                    size='small'
                    value={personState.departmentId}
                    defaultValue={personState.departmentId}
                    onChange={handlePersonChange}
                    MenuProps={MenuProps}
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                  >
                    {departments ? (
                      departments.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.name}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FormControl variant='outlined' size='small' fullWidth>
                  <InputLabel>{t('PERSON011')}</InputLabel>
                  <Select
                    fullWidth
                    error={error.designationId}
                    name='designationId'
                    label={t('PERSON011') + ' *'}
                    variant='outlined'
                    size='small'
                    value={personState.designationId}
                    defaultValue={personState.designationId}
                    onChange={handlePersonChange}
                    MenuProps={MenuProps}
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                  >
                    {designations ? (
                      designations.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.name}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  disabled
                  autoComplete='off'
                  required
                  fullWidth
                  error={error.addressLine1}
                  name='addressLine1'
                  label={t('ADD001')}
                  helperText=''
                  variant='outlined'
                  size='small'
                  value={addressState.addressLine1}
                  defaultValue={addressState.addressLine1}
                  onChange={handleAddressChange}
                  inputProps={{
                    maxlength: 255,
                  }}
                  // InputProps={{
                  //     maxlength: 255,
                  //     disabled: location.state.selectedObject.disable,
                  //     endAdornment: (
                  //         <InputAdornment position='end'>
                  //             <AddressFromMap onSelectLocation={(address) => onSelectLocation(address)} />
                  //         </InputAdornment>
                  //     ),
                  // }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1} fullWidth>
                <Box width={'800px'}>
                  <Autocomplete
                    clearText={t('COMMON216')}
                    openText={t('COMMON169')}
                    closeText={t('COMMON170')}
                    noOptionsText={t('COMMON214')}
                    fullWidth
                    name='country'
                    options={countries}
                    getOptionLabel={(option) => option.country}
                    onChange={(event, newValue) => {
                      handleChangeCountry(newValue);
                    }}
                    value={{ country: addressState.country }}
                    disabled={location.state.selectedObject.disable}
                    renderInput={(params) => (
                      <TextField {...params} name='country' className='login_input' variant='outlined' fullWidth label={t('ADD003') + ' *'} error={error.country} size='small' />
                    )}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  error={error.state}
                  name='state'
                  label={t('ADD004')}
                  helperText=''
                  variant='outlined'
                  size='small'
                  value={addressState.state}
                  defaultValue={addressState.state}
                  onChange={handleAddressChange}
                  inputProps={{
                    maxlength: 50,
                    disabled: location.state.selectedObject.disable,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  required
                  fullWidth
                  error={error.city}
                  name='city'
                  label={t('ADD005')}
                  helperText=''
                  variant='outlined'
                  size='small'
                  value={addressState.city}
                  defaultValue={addressState.city}
                  onChange={handleAddressChange}
                  inputProps={{
                    maxlength: 50,
                    disabled: location.state.selectedObject.disable,
                  }}
                  style={{ zIndex: 0 }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  error={error.areaCode}
                  name='areaCode'
                  label={t('ADD006')}
                  helperText=''
                  variant='outlined'
                  size='small'
                  value={addressState.areaCode}
                  defaultValue={addressState.areaCode}
                  onChange={handleAddressChange}
                  inputProps={{
                    maxlength: 50,
                    disabled: location.state.selectedObject.disable,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}></Grid>
          </Grid>
        </>
      </div> */}

      <Paper
        elevation={0}
        style={{
          minHeight: '200px',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Grid container direction='row' justifyContent='space-evenly' alignItems='flex-start'>
          <Grid item lg={2}>
            <Avatar alt='Remy Sharp' sx={{ width: 200, height: 200 }} src={profile} />
          </Grid>
          <Grid item lg={3.5} padding={1}>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('PERSON003')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={personState.firstName}>
                  {personState.firstName}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('PERSON004')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={personState.lastName}>
                  {personState.lastName}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('PERSON005')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={personState.email}>
                  {personState.email}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('phoneNumber')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={personState.phone}>
                  {countryCode}
                  {personState.phone}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('PERSON046')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={personState.employeeCode}>
                  {personState.employeeCode}
                </OverFlowText>
              </Box>
            </Box>
          </Grid>

          <Divider orientation='vertical' flexItem />

          <Grid item lg={3} paddingLeft='15px'>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                {/* <Typography variant='fieldLabel'>{t('PERSON007')}</Typography> */}
                <OverFlowText variant='fieldLabel'>{t('PERSON007')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={personState.gender === 'M' ? 'Male' : personState.gender === 'F' ? 'Female' : 'Others'}>
                  {personState.gender === 'M' ? 'Male' : personState.gender === 'F' ? 'Female' : 'Others'}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('PERSON010')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={findDepartmentById(personState.departmentId)}>
                  {findDepartmentById(personState.departmentId)}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('PERSON011')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={findDesignationById(personState.designationId)}>
                  {findDesignationById(personState.designationId)}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('ADD001')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressState.addressLine1}>
                  {addressState.addressLine1}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('COMMON189')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressState.country}>
                  {addressState.country}
                </OverFlowText>
              </Box>
            </Box>
          </Grid>

          <Divider orientation='vertical' flexItem />

          <Grid item lg={3} paddingLeft='15px'>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('ADD004')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressState.state}>
                  {addressState.state}
                </OverFlowText>
              </Box>
            </Box>

            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('ADD005')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressState.city}>
                  {addressState.city}
                </OverFlowText>
              </Box>
            </Box>
            <Box display='flex' paddingBottom={2}>
              <Box item paddingRight={1} minWidth='150px'>
                <OverFlowText variant='fieldLabel'>{t('ADD006')}</OverFlowText>
              </Box>
              <Box item>
                <OverFlowText variant='fieldValue' title={addressState.areaCode}>
                  {addressState.areaCode}
                </OverFlowText>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
