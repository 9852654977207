import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import CommonUtil from './Util/CommonUtils';

export default function CustomInput(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const [value, setValue] = useState(props.value);
  const handleChange = (e) => {
    var val = e.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
    }
    if (props.regex == 'none' && props.validation) {
      switch (props.validation) {
        case 'alpha-numeric':
          handleAlphaNumeric(val);
          break;
        case 'numeric':
          handleNumeric(val);
          break;
          case 'numericWithoutDot':
            handleNumericWithoutDot(val);
            break;  
        default:
          props.handleChange(val);
      }
    }
  };
  
  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleAlphaNumeric = (value) => {
    if (CommonUtil.isAlphaNumericSpace(value)) {
      props.handleChange(value);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText('Only accepts alphabets, numerics and space');
    }
  };

  const handleNumeric = (value) => {
    if (CommonUtil.isValidNumeric(value)) {
      props.handleChange(value);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText('Only accepts numerics');
    }
  };

  const handleNumericWithoutDot = (value) => {
    if (CommonUtil.isValidPasitiveNumeric(value)) {
      props.handleChange(value);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText('Only accepts numerics');
    }
  };

  return <TextField {...props} value={value} helperText={helperText} error={error} onChange={(e) => handleChange(e)} />;
}

CustomInput.defaultProps = {
  validation: 'none',
  regex: 'none',
};

CustomInput.propType = {
  validation: PropTypes.oneOf(['alpha-numeric', 'alphabets', 'numeric']),
};
