import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as Export } from '../../assets/cloudaccess-svg/Export.svg';
import { Box, Button, Grid, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import CachedIcon from '@material-ui/icons/Cached';
import EventsFilter from './EventsFilter';
import { REACT_URL_EVENT_V4 } from '../../actions/EndPoints';
import { getDeviceEventList } from './EventService';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#1976d2',
  },
}));

export default function Event() {
  const { t } = useTranslation(0);
  const classes = useStyles();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedEvents, onSelectionChange] = useState([]);
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState({
    startDateTime: '',
    endDateTime: '',
    personnelId: '',
    deviceAlias: '',
    cardNo: '',
    eventPointName: '',
  });

  const defaultFilter = {
    startDateTime: null,
    endDateTime: null,
    personnelId: '',
    deviceAlias: '',
    cardNo: '',
    eventPointName: '',
  };
  const applyFilter = (filterData) => {
    const query = {
      startDateTime: filterData.startDateTime,
      endDateTime: filterData.endDateTime,
      personnelId: filterData.personnelId,
      deviceAlias: filterData.deviceAlias,
      deptCode: filterData.cardNo,
      deptName: filterData.eventPointName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };
  const resetFilter = () => {
    setFilter({ ...defaultFilter });
  };

  useEffect(() => {
    loadEvents(0, 10);
  }, [filter]);

  const loadEvents = (page, size) => {
    getDeviceEventList(page + 1, size, filter, (res) => {
      if (res.status === 200 && res != null && !CommonUtil.isEmpty(res) && res.data.code === 'DMSI0000' && !CommonUtil.isEmpty(res.data) && res.data.data.list instanceof Array) {
        setTotalRecords(res.data.data.totalCount);
        setPage(res.data.data.pageNumber - 1);
      } else {
        setTotalRecords(0);
        setPage(0);
      }
      setEvents(res.data.data.list);
    });
  };

  const columns = [
    {
      field: 'personnel',
      title: t('PersonInfo'),
      editable: 'never',
      tooltip: t('PersonInfo'),
      render: (rowData) => {
        const pid = CommonUtil.isEmpty(rowData.personnel) ? '' : rowData.personnel;
        const cardNo = CommonUtil.isEmpty(rowData.cardNo) ? '' : rowData.cardNo;
        return CommonUtil.isEmpty(cardNo) ? pid : pid + '(' + cardNo + ')';
      },
    },
    { field: 'deviceAlias', title: t('OPE007'), editable: 'never' },
    { field: 'sn', title: t('COMMON055'), tooltip: t('OPE008'), editable: 'never' },
    { field: 'eventTime', title: t('OPE021'), editable: 'never',
    render: (rowData) => {
      return rowData.eventTime.replaceAll("T"," ").replaceAll("Z","");
    },
    },
    { field: 'eventAddress', title: t('OPE009'), editable: 'never' },
    { field: 'eventName', title: t('OPE010'), editable: 'never' },
    { field: 'epName', title: t('COMMON073'), tooltip: t('OPE011'), editable: 'never' },
    { field: 'mode', title: t('Verification mode'), tooltip: t('OPE012'), editable: 'never' },
  ];

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadEvents(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadEvents(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    initialPage: 1,
    pageSize: rowsPerPage,
    maxBodyHeight: "60vh"
  };

  const breadcrumb = [{ REACT_URL_EVENT_V4, name: t('OPE027') }];

  return (
    <Grid  >
      <div>
        <Breadcrumb links={breadcrumb} />
      </div>
      <Toolbar>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1} flexGrow={1}>
                    <Tooltip title={t('COMMON004')}>
                        <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord()}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Box> */}
        {/* <Box p={1}>
                    <Button className={classes.button} color='primary' size='small' onClick={() => handleTransactionExport()}
                        startIcon={<Export style={{ height: 30, width: 30 }} />}>
                        {t('EMP014')}
                    </Button>
                </Box> */}
        <Box p={1}  >
          <EventsFilter applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={events}
        actions={[]}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
