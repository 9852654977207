import { GET_ACCESS_DOOR_LIST, SUCCESS,UPDATE_ACCRESPONSE,GET_ACCRESPONSE,ACCESS_LEVEL_DOOR_LIST} from "../actions/types";

const initialState = {
    doorsList: [],
    isSuccess: false,
    updatedoorsList: [],
    response : false,
    acclevelDoorList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ACCESS_DOOR_LIST:
            return {
                ...state,
                doorsList: action.payload
            };
            case ACCESS_LEVEL_DOOR_LIST:
            return {
                ...state,
                acclevelDoorList: action.payload
            };
        case UPDATE_ACCRESPONSE:
            return {
                ...state,
                updatedoorsList: action.payload
            };
            case GET_ACCRESPONSE:
            return {
                ...state,
                response: action.payload
            };
        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

        default:
            return state;
    }
}