import { SET_CURRENT_USER, GET_COMPANIES, SET_COMPANY, REFRESH_TOKEN_TIMER, SET_REFRESH_TOKEN, SET_ACCESS_TOKEN } from '../actions/types';

const initialState = {
  validToken: false,
  user: {},
  companyList: [],
  userData: {},
  companyData: {},
  tokenRefreshTimer: 600000,
  AccJwtRefreshToken: '',
  AccJwtToken: '',
};

const booleanActionPayload = (payload) => {
  if (payload) {
    return true;
  } else {
    return false;
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        validToken: booleanActionPayload(action.payload),
        user: action.payload,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companyList: action.payload,
      };

    case 'SET_USER':
      return {
        ...state,
        userData: action.payload,
      };

    case SET_COMPANY:
      return {
        ...state,
        companyData: action.payload,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        AccJwtRefreshToken: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        AccJwtToken: action.payload,
      };
    case REFRESH_TOKEN_TIMER:
      return {
        ...state,
        tokenRefreshTimer: action.payload,
      };

    default:
      return state;
  }
}
